import React, { ReactElement } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      justifyContent: "center",
      textAlign: "center",
      maxHeight: "80vh",
      position: "relative",
    },
  })
);

export interface ElementCarouselItem {
  label: string;
  component: ReactElement;
}

interface ElementCarouselProps {
  activeStep: number;
  data: ElementCarouselItem[];
}

export default function ImageCarousel(props: ElementCarouselProps) {
  const classes = useStyles();
  const activeStep = props.activeStep;

  return (
    <div className={classes.root}>
      {props.data[activeStep] && props.data[activeStep].component}
    </div>
  );
}
