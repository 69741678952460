import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  AttachMoney as MoneyIcon,
  Help as SupportIcon,
  // Home as HomeIcon,
  Image as ImageIcon,
  InsertDriveFile as FileIcon,
  ListAlt as ListIcon,
  Visibility as EyeIcon,
  DonutLargeTwoTone as DonutIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

import desktopSidebarStyles from "../../../../styles/DesktopSidebarStyles";
import { SidebarReduxProps } from "./SidebarListContainer";
import { Link, useHistory } from "react-router-dom";
import { SidebarPages } from "../../../../store/navigation/types";
import NewReportDialog from "../../dialogs/SessionExpired/NewReport";
import { ReportInterface } from "../../../../store/data/types";
import { logout } from "../../../../store/login/actions";
import {
  fecthReportRequest,
  fetchAlertsRequest,
  setSnapTablePage,
} from "../../../../store/data/actions";
import { RootState } from "typesafe-actions";
import {
  choose_tab,
  set_newPage,
  set_ResultsPerPage,
  set_sidebar_page,
  set_TotalPages,
  toggle_sidebar,
} from "../../../../store/navigation/actions";
import { clearAllParameters } from "../../../../store/search/actions";

const ReportItem = (props: ReportInterface) => {
  /**
   * Campaigns será campañas? Por ahora solo redirijo dos.
   */
  const { selectedPage, selectedTab } = useSelector(
    (state: RootState) => state.navigation
  );
  const { advancedFilters } = useSelector((state: RootState) => state.search);
  const history = useHistory();
  const pathName = history.location.pathname.split("/");
  const dispatch = useDispatch();

  let path: string;
  if (selectedPage === "snapshots") {
    path = `/dashboard/shareofvoice/${props.id}`;
  } else {
    path = `/${pathName[1]}/${pathName[2]}/${props.id}`;
  }
  const classes = desktopSidebarStyles();

  function handleEnterPage() {
    const page =
      selectedPage === "snapshots" ? SidebarPages.shareofvoice : pathName[1];
    dispatch(set_sidebar_page(page));
    if (
      advancedFilters.advertisers.length === 0 &&
      advancedFilters.products.length === 0 &&
      selectedTab === 2
    ) {
      dispatch(choose_tab(0));
    }
    dispatch(set_newPage(1));
    dispatch(setSnapTablePage(1));
    dispatch(set_ResultsPerPage(15));
    dispatch(set_TotalPages(0));
    dispatch(toggle_sidebar(false));
  }

  return (
    <ListItem
      button
      component={Link}
      to={path}
      onClick={() => handleEnterPage()}
      className={classes.nested}
    >
      <ListItemIcon>
        {props.formats.some((item) => item.includes("pdf")) ? (
          <PictureAsPdfIcon />
        ) : (
          <AssessmentIcon />
        )}
      </ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
};

const SidebarListView = (props: SidebarReduxProps) => {
  const { roles, token } = props.login_state;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Array.isArray(roles)) {
      dispatch(logout());
      dispatch(clearAllParameters());
    }
  }, [roles, dispatch]);

  useEffect(() => {
    dispatch(fecthReportRequest(token));
    dispatch(fetchAlertsRequest(token));
  }, [token, dispatch]);

  const sidebar_structure = {
    // home: {
    //   id: 1,
    //   label: <FormattedMessage id={"navigation.sidebar.home"} />,
    //   name: SidebarPages.home,
    //   link: "/dashboard/home",
    //   icon: <HomeIcon />,
    // },
    pages: [
      {
        id: 2,
        label: <FormattedMessage id={"navigation.sidebar.snapshots"} />,
        name: SidebarPages.snapshots,
        link: "/dashboard/snapshots",
        icon: <AssessmentIcon />,
      },
      {
        id: 3,
        label: <FormattedMessage id={"navigation.sidebar.share_of_voice"} />,
        name: SidebarPages.shareofvoice,
        link: "/dashboard/shareofvoice",
        icon: <DonutIcon />,
      },
      {
        id: 4,
        label: <FormattedMessage id={"navigation.sidebar.investment"} />,
        name: SidebarPages.investment,
        link: "/dashboard/investment",
        icon: <MoneyIcon />,
      },
      {
        id: 5,
        label: <FormattedMessage id={"navigation.sidebar.spots_preview"} />,
        name: SidebarPages.spotspreview,
        link: "/dashboard/campaigns",
        icon: <EyeIcon />,
      },
      {
        id: 6,
        label: <FormattedMessage id={"navigation.sidebar.spots_list"} />,
        name: SidebarPages.spotslist,
        link: "/dashboard/spotslist",
        icon: <ImageIcon />,
      },
    ],
    help: {
      id: 7,
      label: <FormattedMessage id={"navigation.sidebar.help"} />,
      name: SidebarPages.support,
      icon: <SupportIcon />,
    },
    support: {
      id: 8,
      label: <FormattedMessage id={"navigation.sidebar.userguide"} />,
      name: SidebarPages.guides,
      icon: <FileIcon />,
      link: "https://customer.adcuality.com/support/solutions",
    },
  };

  function handleEnterPage(selectedPage: string) {
    props.set_sidebar_page(selectedPage);
    if (
      props.search_state.advancedFilters.advertisers.length === 0 &&
      props.search_state.advancedFilters.products.length === 0 &&
      props.navigation_state.selectedTab === 2
    ) {
      props.set_tab(0);
    }
    dispatch(set_newPage(1));
    dispatch(setSnapTablePage(1));
    dispatch(set_ResultsPerPage(15));
    dispatch(set_TotalPages(0));
    props.toggleDrawer(false);
  }

  const [open, setOpen] = React.useState(false);
  const [newReportOpen, setNewReport] = React.useState(false);
  const formatMessage = useFormatMessage();

  const classes = desktopSidebarStyles();
  return (
    <div>
      <div className={classes.fakeToolbar} id={"Fake Toolbar"} />
      <List>
        {/* <ListItem
          button
          component={Link}
          to={sidebar_structure.home.link}
          className={classes.link}
          onClick={() => handleEnterPage(sidebar_structure.home.name)}
          selected={
            props.navigation_state.selectedPage === sidebar_structure.home.name
          }
        >
          <ListItemIcon>{sidebar_structure.home.icon}</ListItemIcon>
          <ListItemText>{sidebar_structure.home.label}</ListItemText>
        </ListItem>
        <Divider /> */}
        {sidebar_structure.pages.map((structure) =>
          (structure.name === "shareofvoice" &&
            roles.some((item) => item === "ROLE_FEATURE_SHARE_OF_VOICE")) ||
          (structure.name === "investment" &&
            roles.some((item) => item === "ROLE_FEATURE_INVESTMENT")) ||
          structure.name === "export" ||
          ((structure.name === "spotslist" || structure.name === "campaigns") &&
            roles.some((item) => item === "ROLE_FEATURE_CAMPAIGN")) ||
          (structure.name === "snapshots" &&
            roles.some((item) => item === "ROLE_FEATURE_SHARE_OF_VOICE")) ? (
            <ListItem
              button
              component={Link}
              to={structure.link}
              className={classes.link}
              key={structure.id}
              onClick={() => handleEnterPage(structure.name)}
              selected={props.navigation_state.selectedPage === structure.name}
            >
              <ListItemIcon>{structure.icon}</ListItemIcon>
              <ListItemText>{structure.label}</ListItemText>
            </ListItem>
          ) : (
            ""
          )
        )}
        <Divider />
        <ListItem className={classes.link} button onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="navigation.sidebar.my_reports" />
          </ListItemText>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.data.userReports.map((report) => (
              <ReportItem {...report} key={report.name} />
            ))}
            <ListItem
              button
              className={classes.nested}
              onClick={() => setNewReport(true)}
            >
              <ListItemIcon>
                <AddCircleRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage("navigation.sidebar.save-report")}
              />
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem
          button
          className={classes.link}
          key={sidebar_structure.support.id}
          component={"button"}
          onClick={() =>
            window.open("https://customer.adcuality.com/support/solutions", "_blank")
          }
        >
          <ListItemIcon>{sidebar_structure.support.icon}</ListItemIcon>
          <ListItemText>{sidebar_structure.support.label}</ListItemText>
        </ListItem>
        <ListItem
          button
          className={classes.link}
          key={sidebar_structure.help.id}
          component={"button"}
          onClick={() => {
            // @ts-expect-error
            if (window.FreshworksWidget) {
              // @ts-expect-error
              window.FreshworksWidget("open");
            }
          }}
        >
          <ListItemIcon>{sidebar_structure.help.icon}</ListItemIcon>
          <ListItemText>{sidebar_structure.help.label}</ListItemText>
        </ListItem>
      </List>
      <NewReportDialog open={newReportOpen} setOpen={setNewReport} />
    </div>
  );
};

export default SidebarListView;
