import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import GalleryView from "./GalleryView"
import { Dispatch } from "react"
import {
  set_newPage,
  set_ResultsPerPage,
  toggle_MoreInfoDialog,
} from "../../store/navigation/actions"
import {
  campaignsDataFetchRequest,
  setMoreInfoCampaign,
} from "../../store/data/actions"
import { MoreInfoProps } from "../../store/data/types"
import { injectIntl, InjectedIntlProps } from "react-intl"
import {
  PaginationParametersInterface, AdvancedFilters, SearchParameters,
} from "../../store/search/types"
import {
  setAllParameters, setAdvancedFilters, clearAllParameters,
} from "../../store/search/actions"
import { logout } from "../../store/login/actions"

const mapStateToProps = (state: RootState) => ({
  data_state: state.data,
  navigation_state: state.navigation,
  login_state: state.login,
  search_state: state.search,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggleMoreInfo: () => dispatch(toggle_MoreInfoDialog()),
    setMoreInfoDialog: (info: MoreInfoProps) => dispatch(setMoreInfoCampaign(info)),
    setNewPage: (newPage: number) => dispatch(set_newPage(newPage)),
    setResultsPerPage: (numberOfResults: number) =>
      dispatch(set_ResultsPerPage(numberOfResults)),
    campaignsRequest: (
      searchParameters: SearchParameters,
      token: string,
      pagination?: PaginationParametersInterface
    ) => dispatch(campaignsDataFetchRequest(searchParameters, pagination!, token)),
    setAdvancedSearchParameters: (parameters: AdvancedFilters) =>
      dispatch(setAdvancedFilters(parameters)),
    setAllSearchParams: (countries: string[], startDate: Date, endDate: Date, advancedFilters: AdvancedFilters) =>
      dispatch(setAllParameters(countries, startDate, endDate, advancedFilters)),
    logoutUser: () => {
      dispatch(logout())
      dispatch(clearAllParameters())
    }
  }
}

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GalleryView))
