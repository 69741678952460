import { DataActionTypes, DataState } from "./types";
import { ActionType } from "typesafe-actions";
import * as dataActions from "./actions";
import Axios from "axios";

type DataAction = ActionType<typeof dataActions>;

const initial_state: DataState = {
  investment: [],
  unfilteredInvestment: [],
  shareOfVoice: [],
  snapPlatforms: [],
  snapPublishers: [],
  snapPublishersSOI: [],
  snapSources: [],
  snapTablePage: 1,
  snapTable: {
    items: [],
  },
  snapStats: {
    items: [],
  },
  audiences: null,
  unfilteredShareOfVoice: [],
  campaigns: [],
  moreInfoCampaign: {
    title: "",
    lastSeenOn: "",
    lastSeenDate: new Date(),
    pView: 0,
    spotsNumber: 0,
    advertiser: "",
    advertiserId: 0,
    product: "",
    productId: 0,
    industry: "",
    industryId: 0,
  },
  unfilteredDataLoading: false,
  requestedPage: 0,
  spotList: [],
  hasReportRequestFailed: false,
  hasRequestFailed: false,
  hasRequestFailedCreativities: false,
  hasRequestFailedAudiences: false,
  errorMessage: "",
  errorMessageAudiences: "",
  pieData: [],
  savedReports: [],
  userReports: [],
  userAlerts: [],
  userAlertToEdit: null,
  shareOfVoiceCancelToken: Axios.CancelToken.source(),
  snapPlatformsCancelToken: Axios.CancelToken.source(),
  snapPublishersCancelToken: Axios.CancelToken.source(),
  snapPublishersSOICancelToken: Axios.CancelToken.source(),
  snapSourcesCancelToken: Axios.CancelToken.source(),
  snapStatsCancelToken: Axios.CancelToken.source(),
  snapTableCancelToken: Axios.CancelToken.source(),
  campaignsCancelToken: Axios.CancelToken.source(),
  investmentCancelToken: Axios.CancelToken.source(),
  spotListCancelToken: Axios.CancelToken.source(),
  snapAudiencesCancelToken: Axios.CancelToken.source(),
  unfilteredInvestmentCancelToken: Axios.CancelToken.source(),
  unfilteredShareOfVoiceCancelToken: Axios.CancelToken.source(),
  exportReportsCancelToken: Axios.CancelToken.source(),
  userReportsCancelToken: Axios.CancelToken.source(),
  snapTableDownloadCancelToken: Axios.CancelToken.source(),
  galleryDownloadCancelToken: Axios.CancelToken.source(),

  // LOADING STATES
  isDataLoading: false,
  isSnapSOILoading: false,
  isSnapSOVLoading: false,
  isSnapTableLoading: false,
  isSnapCasting: false,
  isSnapTableDownloading: false,
  isGalleryDownloading: false,
  isAudiencesLoading: false,
  isDdsWithoutCaptureLoading: false,
  isUserAlertsLoading: false,
  isUserAlertCreated: false,
};

function reducer(state: DataState = initial_state, action: DataAction) {
  switch (action.type) {
    case DataActionTypes.REQUEST_SHAREOFVOICE:
      state.shareOfVoiceCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        isSnapSOVLoading: true,
        shareOfVoiceCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SHAREOFVOICE:
      return {
        ...state,
        shareOfVoice: action.payload.shareOfVoice,
        isDataLoading: false,
        isSnapSOVLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SHAREOFVOICE:
      return {
        ...state,
        isDataLoading: false,
        isSnapSOVLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_SNAPPLATFORMS:
      state.snapPlatformsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        snapPlatformsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SNAPPLATFORMS:
      return {
        ...state,
        snapPlatforms: action.payload.shareOfVoice,
        isDataLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SNAPPLATFORMS:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_SNAPPUBLISHERS:
      state.snapPublishersCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        snapPublishersCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SNAPPUBLISHERS:
      return {
        ...state,
        snapPublishers: action.payload.shareOfVoice,
        isDataLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SNAPPUBLISHERS:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_SNAPPUBLISHERS_SOI:
      state.snapPublishersSOICancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        snapPublishersSOICancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SNAPPUBLISHERS_SOI:
      return {
        ...state,
        snapPublishersSOI: action.payload.investment,
        isDataLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SNAPPUBLISHERS_SOI:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_SNAPSOURCES:
      state.snapSourcesCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        snapSourcesCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SNAPSOURCES:
      return {
        ...state,
        snapSources: action.payload.shareOfVoice,
        isDataLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SNAPSOURCES:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.SET_SNAPTABLEPAGE:
      return {
        ...state,
        snapTablePage: action.payload.page,
      };
    case DataActionTypes.REQUEST_SNAPTABLE:
      state.snapTableCancelToken.cancel();
      return {
        ...state,
        isSnapTableLoading: true,
        snapTableCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SNAPTABLE:
      return {
        ...state,
        snapTable: action.payload.table,
        isSnapTableLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_SNAPTABLE:
      return {
        ...state,
        isSnapTableLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.FETCH_SNAP_STATS_REQUEST:
      state.snapStatsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        snapStatsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.FETCH_SNAP_STATS_SUCCESS:
      return {
        ...state,
        snapStats: action.payload.stats,
        isDataLoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FETCH_SNAP_STATS_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_AUDIENCES:
      state.snapAudiencesCancelToken.cancel();
      return {
        ...state,
        isAudiencesLoading: true,
        snapAudiencesCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_AUDIENCES:
      return {
        ...state,
        audiences: action.payload.audiences,
        isAudiencesLoading: false,
        hasRequestFailedAudiences: false,
        errorMessageAudiences: "",
      };
    case DataActionTypes.FAILURE_AUDIENCES:
      return {
        ...state,
        isAudiencesLoading: false,
        hasRequestFailedAudiences: true,
        errorMessageAudiences: action.payload.error,
      };
    case DataActionTypes.REQUEST_INVESTMENT:
      state.investmentCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        isSnapSOILoading: true,
        investmentCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_INVESTMENT:
      return {
        ...state,
        investment: action.payload.investment,
        isDataLoading: false,
        isSnapSOILoading: false,
        hasRequestFailed: false,
      };
    case DataActionTypes.FAILURE_INVESTMENT:
      return {
        ...state,
        errorMessage: action.payload.error,
        hasRequestFailed: true,
        isDataLoading: false,
        isSnapSOILoading: false,
        unfilteredDataLoading: false,
      };
    case DataActionTypes.REQUEST_CAMPAIGNS:
      state.campaignsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        campaignsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_CAMPAINGS:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        isDataLoading: false,
        hasRequestFailedCreativities: false,
      };
    case DataActionTypes.FAILURE_CAMPAINGS:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailedCreativities: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.REQUEST_SPOTSLIST:
      state.spotListCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        spotListCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_SPOTSLIST:
      return {
        ...state,
        spotList: action.payload.spotsList,
        hasRequestFailedCreativities: false,
        isDataLoading: false,
      };
    case DataActionTypes.FAILURE_SPOTSLIST:
      return {
        ...state,
        isDataLoading: false,
        hasRequestFailedCreativities: true,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.SET_MOREINFO:
      return { ...state, moreInfoCampaign: action.payload.info };
    case DataActionTypes.SET_PIE_DATA:
      return { ...state, pieData: action.payload.data };
    case DataActionTypes.REQUEST_UF_SHAREOFVOICE:
      state.unfilteredShareOfVoiceCancelToken.cancel();
      return {
        ...state,
        unfilteredDataLoading: true,
        unfilteredShareOfVoiceCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_UF_SHAREOFVOICE:
      return {
        ...state,
        unfilteredShareOfVoice: action.payload.shareOfVoice,
        hasRequestFailed: false,
        unfilteredDataLoading: false,
      };
    case DataActionTypes.FAILURE_UF_SHAREOFVOICE:
      return {
        ...state,
        hasRequestFailed: true,
        errorMessage: action.payload.error,
        unfilteredDataLoading: false,
      };
    case DataActionTypes.REQUEST_UF_INVESTMENT:
      state.unfilteredInvestmentCancelToken.cancel();
      return {
        ...state,
        unfilteredDataLoading: true,
        unfilteredInvestmentCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.SUCCESS_UF_INVESTMENT:
      return {
        ...state,
        unfilteredInvestment: action.payload.investment,
        hasRequestFailed: false,
        unfilteredDataLoading: false,
      };
    case DataActionTypes.FAILURE_UF_INVESTMENT:
      return {
        ...state,
        errorMessage: action.payload.error,
        hasRequestFailed: true,
        unfilteredDataLoading: false,
      };
    case DataActionTypes.REPORT_REQUEST:
      state.exportReportsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        exportReportsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.REPORT_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.REPORT_FAILURE:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.REPORT_MAIL_REQUEST:
      state.exportReportsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        exportReportsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.REPORT_MAIL_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.REPORT_MAIL_FAILURE:
      return {
        ...state,
        isDataLoading: false,
      };
    /**
     * No hay endpoint para guardar reportes.
     */
    case DataActionTypes.SAVE_CUSTOM_REPORT:
      return {
        ...state,
        savedReports: [...state.savedReports, action.payload.params],
      };
    // Reports
    case DataActionTypes.FETCH_USER_REPORTS:
      state.userReportsCancelToken.cancel();
      return {
        ...state,
        isDataLoading: true,
        userReportsCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.FETCH_USER_REPORTS_SUCCESS:
      return {
        ...state,
        userReports: action.payload.reports,
        isDataLoading: false,
      };
    case DataActionTypes.FETCH_USER_REPORTS_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasReportRequestFailed: true,
      };
    case DataActionTypes.CREATE_USER_REPORT:
      return {
        ...state,
        isDataLoading: true,
      };
    case DataActionTypes.CREATE_USER_REPORT_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.CREATE_USER_REPORT_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasReportRequestFailed: true,
      };
    case DataActionTypes.EDIT_USER_REPORT:
      return {
        ...state,
        isDataLoading: true,
      };
    case DataActionTypes.EDIT_USER_REPORT_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.EDIT_USER_REPORT_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasReportRequestFailed: true,
      };
    case DataActionTypes.DELETE_USER_REPORT:
      return {
        ...state,
        isDataLoading: true,
      };
    case DataActionTypes.DELETE_USER_REPORT_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.DELETE_USER_REPORT_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasReportRequestFailed: true,
      };
    case DataActionTypes.TOGGLE_USER_REPORT:
      return {
        ...state,
        isDataLoading: true,
      };
    case DataActionTypes.TOGGLE_USER_REPORT_SUCCESS:
      return {
        ...state,
        isDataLoading: false,
      };
    case DataActionTypes.TOGGLE_USER_REPORT_FAILURE:
      return {
        ...state,
        isDataLoading: false,
        hasReportRequestFailed: true,
      };
    case DataActionTypes.CREATE_ALERT_REQUEST:
      return {
        ...state,
        isUserAlertsLoading: true,
        isUserAlertCreated: false,
      };
    case DataActionTypes.CREATE_ALERT_SUCCESS:
      return {
        ...state,
        isUserAlertsLoading: false,
        isUserAlertCreated: true,
      };
    case DataActionTypes.CREATE_ALERT_FAILURE:
      return {
        ...state,
        isUserAlertsLoading: false,
        isUserAlertCreated: false,
      };
    case DataActionTypes.ALERTS_REQUEST:
      return {
        ...state,
        isUserAlertsLoading: true,
      };
    case DataActionTypes.ALERTS_SUCCESS:
      return {
        ...state,
        userAlerts: action.payload.alerts,
        isUserAlertsLoading: false,
      };
    case DataActionTypes.ALERTS_FAILURE:
      return {
        ...state,
        isUserAlertsLoading: false,
      };
    case DataActionTypes.DELETE_ALERT_REQUEST:
      return {
        ...state,
        isUserAlertsLoading: true,
      };
    case DataActionTypes.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        isUserAlertsLoading: false,
      };
    case DataActionTypes.DELETE_ALERT_FAILURE:
      return {
        ...state,
        isUserAlertsLoading: false,
      };
    case DataActionTypes.SET_ALERT_TO_EDIT:
      return {
        ...state,
        userAlertToEdit: action.payload.alert,
        isUserAlertCreated: false,
      };
    case DataActionTypes.SET_ALERT_TO_EDIT_NULL:
      return {
        ...state,
        userAlertToEdit: null,
        isUserAlertCreated: false,
      };
    case DataActionTypes.EDIT_ALERT_REQUEST:
      return {
        ...state,
        isUserAlertsLoading: true,
        isUserAlertCreated: false,
      };
    case DataActionTypes.EDIT_ALERT_SUCCESS:
      return {
        ...state,
        isUserAlertsLoading: false,
        userAlertToEdit: null,
        isUserAlertCreated: true,
      };
    case DataActionTypes.EDIT_ALERT_FAILURE:
      return {
        ...state,
        isUserAlertsLoading: false,
        isUserAlertCreated: false,
      };
    case DataActionTypes.SET_SNAP_CASTING:
      return {
        ...state,
        isSnapCasting: action.payload.casting,
      };
    case DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_REQUEST:
      state.snapTableDownloadCancelToken.cancel();
      return {
        ...state,
        isSnapTableDownloading: true,
        snapTableDownloadCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_SUCCESS:
      return {
        ...state,
        isSnapTableDownloading: false,
      };
    case DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_FAILURE:
      return {
        ...state,
        isSnapTableDownloading: false,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.DOWNLOAD_GALLERY_REQUEST:
      state.galleryDownloadCancelToken.cancel();
      return {
        ...state,
        isGalleryDownloading: true,
        galleryDownloadCancelToken: action.payload.cancelToken,
      };
    case DataActionTypes.DOWNLOAD_GALLERY_SUCCESS:
      return {
        ...state,
        isGalleryDownloading: false,
      };
    case DataActionTypes.DOWNLOAD_GALLERY_FAILURE:
      return {
        ...state,
        isGalleryDownloading: false,
        errorMessage: action.payload.error,
      };
    case DataActionTypes.DDS_WITHOUT_CAPTURES_REQUEST:
      return {
        ...state,
        isDdsWithoutCaptureLoading: true,
      };
    case DataActionTypes.DDS_WITHOUT_CAPTURES_SUCCESS:
      return {
        ...state,
        isDdsWithoutCaptureLoading: false,
      };
    case DataActionTypes.DDS_WITHOUT_CAPTURES_FAILURE:
      return {
        ...state,
        isDdsWithoutCaptureLoading: false,
        errorMessage: action.payload.error,
      };
    default:
      return state;
  }
}

export { reducer as dataReducer };
