import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, Theme } from "@material-ui/core";

const LayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      maxWidth: "100vw",
      overflowX: "hidden",
      "@media print": {
        width: `100%`,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: `calc(100vw - 240px)`,
      minHeight: "100vh",
      "@media print": {
        width: `100%`,
      },
    },
    contentShift: {
      width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      "@media print": {
        width: `100%`,
      },
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
  })
);

export default LayoutStyles;
