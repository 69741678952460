export enum SearchActionTypes {
  SET_DATE_RANGE = "@search/SET_DATE_RANGE",
  SET_COUNTRIES = "@search/SET_COUNTRIES",
  SET_ADVANCED_FILTERS = "@search/SET_ADVANCED_FILTERS",
  SET_SNAP_TYPE = "@search/SET_SNAP_TYPE",
  SET_SNAP_FILTER = "@search/SET_SNAP_FILTER",
  SET_PAGINATION_PARAMETERS = "@search/SET_PAGINATION_PARAMETERS",
  SET_ALL = "@search/SET_ALL_PARAMETERS",
  QUEUE_PARARM = "@search/QUEUE_PARAMETER",
  CLEAR_ALL = "@search/CLEAR_ALL_PARAMETERS",
}

export interface AdvancedSearchInterface {
  id: number;
  label: string;
  value?: number;
}

export interface AdvancedFilters {
  [index: string]: AdvancedSearchInterface[];
  advertisers: AdvancedSearchInterface[];
  formats: AdvancedSearchInterface[];
  platforms: AdvancedSearchInterface[];
  publishers: AdvancedSearchInterface[];
  excludedPublishers: AdvancedSearchInterface[];
  categories: AdvancedSearchInterface[];
  industries: AdvancedSearchInterface[];
  products: AdvancedSearchInterface[];
  sources: AdvancedSearchInterface[];
}

export interface FiltersDownload {
  [index: string]: number[];
  advertisers: number[];
  formats: number[];
  platforms: number[];
  publishers: number[];
  excludedPublishers: number[];
  categories: number[];
  industries: number[];
  products: number[];
  sources: number[];
}

export interface PaginationParametersInterface {
  requestedPageNumber: number;
  requestedResults: number;
}

export interface SearchParameters {
  countries: string[];
  startDate: Date;
  endDate: Date;
  advancedFilters: AdvancedFilters;
}

export interface ReportMailParameters {
  countries: string[];
  startDate: Date;
  endDate: Date;
  advancedFilters: FiltersDownload;
}

export interface SearchState {
  readonly countries: string[];
  readonly startDate: Date;
  readonly endDate: Date;
  readonly advancedFilters: AdvancedFilters;
  readonly paginationParameters: PaginationParametersInterface;
  readonly parameterQueue: AdvancedFilters;
  readonly snapType: "industries" | "advertisers" | "publishers";
  readonly snapFilter: AdvancedSearchInterface | null;
}

export interface AlertsInterface {
  id: number;
  name: string;
  advertisers: AdvancedSearchInterface[];
}
