import React from "react";
import {
  makeStyles,
  Theme,
  Paper,
  AppBar,
  Tabs,
  Tab,
  LinearProgress,
  Collapse,
  Divider,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { choose_tab } from "../store/navigation/actions";
import { FormattedMessage } from "react-intl";
import {
  LocationCity as IndustriesIcon,
  Work as AdIcon,
  Category as CategoriesIcon,
  AspectRatio as FormatsIcon,
  RecordVoiceOver as PublishersIcon,
  ImportantDevices as PlatformsIcon,
  Receipt as SourcesIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons";
import { TabPanel } from "./generic/TabPanel";
import { SearchToolbar } from "./dashboard/search/SearchToolbarContainer";
import { ActiveFiltersToolbar } from "./dashboard/search/ActiveFiltersToolbarContainer";
import Alert from "@material-ui/lab/Alert";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import LoadingDialog from "./dashboard/dialogs/LoadingDialog";

interface TabLayoutProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
  tabBar: {
    paddingTop: 20,
  },
  percentage: {
    height: 15,
  },
  pieContainer: {
    height: "25vh",
  },
  tab: {
    fontSize: "0.5rem",
    "@media (min-width: 600px)": {
      minWidth: "80px",
    },
    "@media (min-width: 1024px)": {
      fontSize: "0.55rem",
    },
    "@media (min-width: 1200px)": {
      fontSize: "0.65rem",
    },
    "@media (min-width: 1440px)": {
      fontSize: "0.75rem",
    },
    "@media (min-width: 1600px)": {
      fontSize: "0.84rem",
    },
  },
  dividerError: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const TabLayout = (props: TabLayoutProps) => {
  const { selectedTab } = useSelector((state: RootState) => state.navigation);
  const { advertisers: advertisersFilter, products: productsFilter } = useSelector(
    (state: RootState) => state.search.advancedFilters
  );
  const { isDataLoading, unfilteredDataLoading, hasRequestFailed, errorMessage } =
    useSelector((state: RootState) => state.data);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(choose_tab(newValue));
  };
  const formatMessage = useFormatMessage();

  const styles = useStyles();
  return (
    <>
      <SearchToolbar />
      <Collapse
        in={isDataLoading || unfilteredDataLoading}
        mountOnEnter
        unmountOnExit
      >
        <LinearProgress />
      </Collapse>
      <ActiveFiltersToolbar />
      {hasRequestFailed ? (
        <>
          <Divider className={styles.dividerError} />
          <Alert severity="warning">
            {errorMessage === "No data available. Try with other criteria."
              ? formatMessage("errors.data.noDataAvailable")
              : errorMessage}
          </Alert>
        </>
      ) : (
        <Paper className={styles.root}>
          <AppBar position="static" color="inherit">
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              onChange={handleChange}
              textColor="primary"
              variant={"fullWidth"}
              scrollButtons="auto"
            >
              <Tab
                label={<FormattedMessage id={"navigation.tabs.industries"} />}
                icon={<IndustriesIcon />}
                tabIndex={0}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.advertisers"} />}
                icon={<AdIcon />}
                tabIndex={1}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.products"} />}
                icon={<ShoppingCartIcon />}
                tabIndex={2}
                disabled={
                  advertisersFilter.length === 0 && productsFilter.length === 0
                }
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.categories"} />}
                icon={<CategoriesIcon />}
                tabIndex={3}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.formats"} />}
                icon={<FormatsIcon />}
                tabIndex={4}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.publishers"} />}
                icon={<PublishersIcon />}
                tabIndex={5}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.platforms"} />}
                icon={<PlatformsIcon />}
                tabIndex={6}
                className={styles.tab}
              />
              <Tab
                label={<FormattedMessage id={"navigation.tabs.sources"} />}
                icon={<SourcesIcon />}
                tabIndex={7}
                className={styles.tab}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedTab} index={selectedTab}>
            <React.Fragment>{props.children}</React.Fragment>
          </TabPanel>
        </Paper>
      )}
      <LoadingDialog isLoading={isDataLoading} />
    </>
  );
};

export default TabLayout;
