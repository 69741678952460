import { createStyles, makeStyles, Theme } from "@material-ui/core"

const drawerWidth = 240

const desktopSidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      //width: drawerWidth,
      flexShrink: 0,
      "z-index": 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    link: {
      textDecoration: "none",
      paddingLeft: theme.spacing(4.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    linkWhatspp: {
      bottom: 0,
      textDecoration: "none",
      flexGrow: 1,
      paddingLeft: theme.spacing(4.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      top: 'auto'
    },
    fakeToolbar: {
      ...theme.mixins.toolbar,
    },
    nested: {
      paddingLeft: theme.spacing(5.5),
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
)

export default desktopSidebarStyles
