import React from "react"
import { CustomerIframeProps } from "./CustomerIframeContainer"
import {
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import {CloseSharp} from '@material-ui/icons';

const CustomerIframeView = (props: CustomerIframeProps) => {
  return (
    <Dialog
      open={props.navigation_state.isCustomerOpen}
      onClose={() => props.toggleCustomer()}
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        <IconButton onClick={() => props.toggleCustomer()} color="primary">
          <CloseSharp />
        </IconButton>
        <FormattedMessage id={"navigation.sidebar.userguide"} />
      </DialogTitle>
      <DialogContent style={{height: '70vh'}}>
        <iframe
          src="https://customer.adcuality.com/support/solutions"
          frameBorder="0"
          title={"Customer Service"}
          height={"100%"}
          width={"100%"}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CustomerIframeView;
