import { call, put, takeLatest, fork, all, select } from "redux-saga/effects";
import {
  campaignsDataFetchFailure,
  fetchCampaignsDataSuccess,
  investmentDataFailure,
  investmentDataSuccess,
  shareOfVoiceFetchSuccess,
  shareVoiceFetchFailure,
  spotsListDataFailure,
  spotsListFetchSuccess,
  unfilteredShareOfVoiceFetchSuccess,
  unfilteredShareVoiceFetchFailure,
  unfilteredInvestmentDataSuccess,
  shareOfVoiceDataRequest,
  unfilteredShareOfVoiceRequest,
  investmentDataRequest,
  unfilteredInvestmentDataRequest,
  campaignsDataFetchRequest,
  spotsListDataRequest,
  reportRequest,
  reportSuccess,
  reportFailure,
  fecthReportRequest,
  fecthReportSuccess,
  fecthReportFailure,
  createReportRequest,
  createReportSuccess,
  createReportFailure,
  editReportRequest,
  editReportSuccess,
  editReportFailure,
  toggleReportRequest,
  toggleReportSuccess,
  toggleReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
  snapPlatformsDataRequest,
  snapPlatformsFetchSuccess,
  snapPlatformsFetchFailure,
  snapPublishersDataRequest,
  snapPublishersFetchSuccess,
  snapPublishersFetchFailure,
  snapSourcesDataRequest,
  snapSourcesFetchSuccess,
  snapSourcesFetchFailure,
  snapTableDataRequest,
  snapTableFetchSuccess,
  snapTableFetchFailure,
  snapStatsFetchSuccess,
  snapStatsDataRequest,
  snapStatsFetchFailure,
  snapPublishersSOIDataRequest,
  snapPublishersSOIFetchSuccess,
  snapPublishersSOIFetchFailure,
  reportMailRequest,
  reportMailFailure,
  reportMailSuccess,
  downloadSnapTableRequest,
  downloadSnapTableSuccess,
  downloadSnapTableFailure,
  downloadGalleryRequest,
  downloadGallerySuccess,
  downloadGalleryFailure,
  fetchAudiencesRequest,
  fetchAudiencesSuccess,
  fetchAudiencesFailure,
  fetchDdsWithoutCaptureRequest,
  fetchDdsWithoutCaptureSuccess,
  fetchDdsWithoutCaptureFailure,
  fetchAlertsRequest,
  fetchAlertsSuccess,
  fetchAlertsFailure,
  createAlertRequest,
  createAlertSuccess,
  createAlertFailure,
  editAlertRequest,
  editAlertSuccess,
  editAlertFailure,
  deleteAlertRequest,
  deleteAlertSuccess,
  deleteAlertFailure,
} from "./actions";
import {
  DataActionTypes,
  ShareVoice,
  Investment,
  SnapTable,
  SnapStats,
  CampaignsItem,
  ListedSpotInterface,
  ReportInterface,
  Audiences,
} from "./types";
import { set_TotalPages } from "../navigation/actions";
import { setTokenExpired } from "../login/actions";
import {
  exportCampaign,
  fetchShareOfVoiceDataAPIv2,
  fetchInvestmentDataAPIv2,
  fetchCampaignsDataAPIv2,
  fetchReportsAPIv2,
  createReportAPIv2,
  editReportAPIv2,
  toggleReportAPIv2,
  deleteReportAPIv2,
  fetchSnapTableDataAPIv2,
  fetchSnapStatsDataAPIv2,
  exportMailCampaign,
  exportGallery,
  fetchSnapAudiencesAPI,
  ddsWithoutCaptureAPIv2,
  fetchAlertsAPI,
  createAlertAPI,
  editAlertAPI,
  deleteAlertAPI,
  // fetchAutocompleteAPIv2
} from "../../services/api";
import { RootState } from "typesafe-actions";
import { AlertsInterface } from "../search/types";

const getLang = (state: RootState) => state.general.language;

export function getLocalizedDate(date: Date) {
  if (date.toString().startsWith("20")) {
    return date;
  } else {
    return date.toISOString().split("T")[0];
  }
}

function* handleUnfilteredShareOfVoiceRequest(
  action: ReturnType<typeof unfilteredShareOfVoiceRequest>
) {
  try {
    const multiShareOfVoice: ShareVoice[] = yield call(
      fetchShareOfVoiceDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.cancelToken.token
    );
    yield put(unfilteredShareOfVoiceFetchSuccess(multiShareOfVoice));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(unfilteredShareVoiceFetchFailure(e.response.data.error));
    } else {
      yield put(unfilteredShareVoiceFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleExportCampaignReport(action: ReturnType<typeof reportRequest>) {
  try {
    yield call(
      exportCampaign,
      action.payload.params.filters,
      action.payload.params.type,
      action.payload.params.reportFormat,
      action.payload.token,
      action.payload.cancelToken.token,
      action.payload.params.snapType
    );
    yield put(reportSuccess());
  } catch (e) {
    console.log(e);
    yield put(reportFailure());
  }
}

function* handleExportMailReport(action: ReturnType<typeof reportMailRequest>) {
  try {
    yield call(
      exportMailCampaign,
      action.payload.params.filters,
      action.payload.params.type,
      action.payload.params.reportFormat,
      action.payload.token,
      action.payload.cancelToken.token
    );
    yield put(reportMailSuccess());
  } catch (e) {
    console.log(e);
    yield put(reportMailFailure());
  }
}

function* handleShareOfVoiceRequest(
  action: ReturnType<typeof shareOfVoiceDataRequest>
) {
  try {
    const lang: string = yield select(getLang);
    const typeSOV = action.payload.type;
    const snap = action.payload.snapPage && typeSOV === "products";
    const multiShareOfVoice: ShareVoice[] = yield call(
      fetchShareOfVoiceDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.cancelToken.token,
      snap,
      lang
    );
    yield put(shareOfVoiceFetchSuccess(multiShareOfVoice));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(shareVoiceFetchFailure(e.response.data.error));
    } else {
      yield put(shareVoiceFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapPlatformsRequest(
  action: ReturnType<typeof snapPlatformsDataRequest>
) {
  try {
    const multiShareOfVoice: ShareVoice[] = yield call(
      fetchShareOfVoiceDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      "platforms",
      action.payload.cancelToken.token
    );
    yield put(snapPlatformsFetchSuccess(multiShareOfVoice));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapPlatformsFetchFailure(e.response.data.error));
    } else {
      yield put(snapPlatformsFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapPublisherRequest(
  action: ReturnType<typeof snapPublishersDataRequest>
) {
  try {
    const multiShareOfVoice: ShareVoice[] = yield call(
      fetchShareOfVoiceDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.advertisers ? "advertisers" : "publishers",
      action.payload.cancelToken.token
    );
    yield put(snapPublishersFetchSuccess(multiShareOfVoice));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapPublishersFetchFailure(e.response.data.error));
    } else {
      yield put(snapPublishersFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapPublishersInvestmentRequest(
  action: ReturnType<typeof snapPublishersSOIDataRequest>
) {
  try {
    const multiInvestment: Investment[] = yield call(
      fetchInvestmentDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.advertisers ? "advertisers" : "publishers",
      action.payload.cancelToken.token
    );
    // if (investment.length > 1) {
    //   investment = ConvertCurrencies(multiInvestment.items)
    // }
    yield put(snapPublishersSOIFetchSuccess(multiInvestment));
  } catch (e) {
    console.log("error" + e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapPublishersSOIFetchFailure(e.response.data.error));
    } else {
      yield put(snapPublishersSOIFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapSourcesRequest(
  action: ReturnType<typeof snapSourcesDataRequest>
) {
  try {
    const multiShareOfVoice: ShareVoice[] = yield call(
      fetchShareOfVoiceDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      "sources",
      action.payload.cancelToken.token
    );
    yield put(snapSourcesFetchSuccess(multiShareOfVoice));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapSourcesFetchFailure(e.response.data.error));
    } else {
      yield put(snapSourcesFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapAdvertisersRequest(
  action: ReturnType<typeof snapTableDataRequest>
) {
  try {
    const lang: string = yield select(getLang);
    const multiSnapAdvertiser: SnapTable = yield call(
      fetchSnapTableDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.page,
      action.payload.cancelToken.token,
      lang
    );
    yield put(snapTableFetchSuccess(multiSnapAdvertiser));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapTableFetchFailure(e.response.data.error));
    } else {
      yield put(snapTableFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSnapStatsDataRequest(
  action: ReturnType<typeof snapStatsDataRequest>
) {
  try {
    const multiSnapStats: SnapStats = yield call(
      fetchSnapStatsDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.cancelToken.token
    );
    yield put(snapStatsFetchSuccess(multiSnapStats));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(snapStatsFetchFailure(e.response.data.error));
    } else {
      yield put(snapStatsFetchFailure("Error al consultar los datos"));
    }
  }
}

/**
 * Acá debería ir lo de conversión de divisas. Hay que ver que API se contrata,
 * la cantidad de accesos permitidos y las divisas. De tener pocos accesos se
 * puede guardar en el localstorage una o dos veces por día.
 * Lo ideal sería que el back lo pida...
 */
function* handleInvestmentRequest(action: ReturnType<typeof investmentDataRequest>) {
  try {
    const lang: string = yield select(getLang);
    const typeSOV = action.payload.type;
    const snap = action.payload.snapPage && typeSOV === "products";
    const multiInvestment: Investment[] = yield call(
      fetchInvestmentDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.cancelToken.token,
      snap,
      lang
    );
    // if (investment.length > 1) {
    //   investment = ConvertCurrencies(multiInvestment.items)
    // }
    yield put(investmentDataSuccess(multiInvestment));
  } catch (e) {
    console.log("error" + e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(investmentDataFailure(e.response.data.error));
    } else {
      yield put(investmentDataFailure("Error al consultar los datos"));
    }
  }
}

function* handleUnfilteredInvestmentRequest(
  action: ReturnType<typeof unfilteredInvestmentDataRequest>
) {
  try {
    const multiInvestment: Investment[] = yield call(
      fetchInvestmentDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.cancelToken.token
    );
    yield put(unfilteredInvestmentDataSuccess(multiInvestment));
  } catch (e) {
    console.log("error" + e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(investmentDataFailure(e.response.data.error));
    } else {
      yield put(investmentDataFailure("Error al consultar los datos"));
    }
  }
}

function* handleCampaignsFetchRequest(
  action: ReturnType<typeof campaignsDataFetchRequest>
) {
  try {
    const lang = yield select(getLang);
    const campaigns: {
      metadata: {
        total: number;
      };
      items: CampaignsItem[];
    } = yield call(
      fetchCampaignsDataAPIv2,
      action.payload.searchParameters,
      action.payload.paginationParameters,
      action.payload.token,
      1,
      lang,
      action.payload.cancelToken.token
    );
    yield put(set_TotalPages(campaigns.metadata.total));
    yield put(fetchCampaignsDataSuccess(campaigns.items));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(campaignsDataFetchFailure(e.response.data.error));
    } else {
      yield put(campaignsDataFetchFailure("Error al consultar los datos"));
    }
  }
}

function* handleSpotsListRequest(action: ReturnType<typeof spotsListDataRequest>) {
  try {
    const lang = yield select(getLang);
    const spots: {
      metadata: {
        total: number;
      };
      items: ListedSpotInterface[];
    } = yield call(
      fetchCampaignsDataAPIv2,
      action.payload.searchParameters,
      action.payload.paginationParameters,
      action.payload.token,
      0,
      lang,
      action.payload.cancelToken.token
    );
    yield put(spotsListFetchSuccess(spots.items));
    yield put(set_TotalPages(spots.metadata.total));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(spotsListDataFailure(e.response.data.error));
    } else {
      yield put(spotsListDataFailure("Error al consultar los datos"));
    }
  }
}

function* handleUserReportsRequest(action: ReturnType<typeof fecthReportRequest>) {
  try {
    const reports: {
      reports: ReportInterface[];
    } = yield call(
      fetchReportsAPIv2,
      action.payload.token,
      action.payload.cancelToken.token
    );
    yield put(fecthReportSuccess(reports.reports));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(fecthReportFailure());
    }
  }
}

function* handleCreateUserReportRequest(
  action: ReturnType<typeof createReportRequest>
) {
  try {
    const reports: unknown = yield call(
      createReportAPIv2,
      action.payload.token,
      action.payload.report
    );
    if (reports) {
      yield put(createReportSuccess());
      yield put(fecthReportRequest(action.payload.token));
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(createReportFailure());
    }
  }
}

function* handleEditUserReportRequest(action: ReturnType<typeof editReportRequest>) {
  try {
    const reports: unknown = yield call(
      editReportAPIv2,
      action.payload.token,
      action.payload.report
    );
    if (reports) {
      yield put(editReportSuccess());
      yield put(fecthReportRequest(action.payload.token));
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(editReportFailure());
    }
  }
}

function* handleToggleUserReportRequest(
  action: ReturnType<typeof toggleReportRequest>
) {
  try {
    const reports: unknown = yield call(
      toggleReportAPIv2,
      action.payload.token,
      action.payload.id,
      action.payload.enabled
    );
    if (reports) {
      yield put(toggleReportSuccess());
      yield put(fecthReportRequest(action.payload.token));
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(toggleReportFailure());
    }
  }
}

function* handleDeleteUserReportRequest(
  action: ReturnType<typeof deleteReportRequest>
) {
  try {
    const reports: unknown = yield call(
      deleteReportAPIv2,
      action.payload.token,
      action.payload.id
    );
    if (reports) {
      yield put(deleteReportSuccess());
      yield put(fecthReportRequest(action.payload.token));
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(deleteReportFailure());
    }
  }
}

function* handleFetchAlertsRequest(action: ReturnType<typeof fetchAlertsRequest>) {
  try {
    const alerts: AlertsInterface[] = yield call(
      fetchAlertsAPI,
      action.payload.token,
      action.payload.cancelToken.token
    );
    if (alerts) {
      yield put(fetchAlertsSuccess(alerts));
    }
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(fetchAlertsFailure("Error al consultar los datos"));
    }
  }
}

function* handleCreateAlertsRequest(action: ReturnType<typeof createAlertRequest>) {
  try {
    const alerts: unknown = yield call(
      createAlertAPI,
      action.payload.token,
      action.payload.alert
    );
    if (alerts) {
      yield put(createAlertSuccess());
      yield put(fetchAlertsRequest(action.payload.token));
    }
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(createAlertFailure());
    }
  }
}

function* handleEditAlertsRequest(action: ReturnType<typeof editAlertRequest>) {
  try {
    const alerts: unknown = yield call(
      editAlertAPI,
      action.payload.token,
      action.payload.alert
    );
    if (alerts) {
      yield put(editAlertSuccess());
      yield put(fetchAlertsRequest(action.payload.token));
    }
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(editAlertFailure());
    }
  }
}

function* handleDeleteAlertsRequest(action: ReturnType<typeof deleteAlertRequest>) {
  try {
    const alerts: unknown = yield call(
      deleteAlertAPI,
      action.payload.token,
      action.payload.id
    );
    if (alerts) {
      yield put(deleteAlertSuccess());
      yield put(fetchAlertsRequest(action.payload.token));
    }
  } catch (e: any) {
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else {
      yield put(deleteAlertFailure());
    }
  }
}

function* handleSnapDownloadRequest(
  action: ReturnType<typeof downloadSnapTableRequest>
) {
  try {
    const lang = yield select(getLang);
    yield call(
      fetchSnapTableDataAPIv2,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.type,
      action.payload.page,
      action.payload.cancelToken.token,
      lang,
      action.payload.limit,
      true
    );
    yield put(downloadSnapTableSuccess());
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(downloadSnapTableFailure(e.response.data.error));
    } else {
      yield put(downloadSnapTableFailure("Error al consultar los datos"));
    }
  }
}

function* handleGalleryDownloadRequest(
  action: ReturnType<typeof downloadGalleryRequest>
) {
  try {
    yield call(
      exportGallery,
      action.payload.searchParameters,
      action.payload.page,
      action.payload.limit,
      action.payload.unique,
      action.payload.token,
      action.payload.cancelToken.token
    );
    yield put(downloadGallerySuccess());
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(downloadGalleryFailure(e.response.data.error));
    } else {
      yield put(downloadGalleryFailure("Error al consultar los datos"));
    }
  }
}

function* handleFetchAudiencesRequest(
  action: ReturnType<typeof fetchAudiencesRequest>
) {
  try {
    const audiences: Audiences = yield call(
      fetchSnapAudiencesAPI,
      action.payload.searchParameters,
      action.payload.token,
      action.payload.cancelToken.token
    );
    yield put(fetchAudiencesSuccess(audiences));
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(fetchAudiencesFailure(e.response.data.error));
    } else {
      yield put(fetchAudiencesFailure("Error al consultar los datos"));
    }
  }
}

function* handleFetchDdsWithoutCaptureRequest(
  action: ReturnType<typeof fetchDdsWithoutCaptureRequest>
) {
  try {
    yield call(
      ddsWithoutCaptureAPIv2,
      action.payload.token,
      action.payload.country,
      action.payload.since,
      action.payload.until,
      action.payload.cancelToken.token
    );
    yield put(fetchDdsWithoutCaptureSuccess());
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 401) {
      yield put(setTokenExpired());
    } else if (e.response && e.response.data.error) {
      yield put(fetchDdsWithoutCaptureFailure(e.response.data.error));
    } else {
      yield put(fetchDdsWithoutCaptureFailure("Error al solicitar el reporte"));
    }
  }
}

function* watchCampaignDataRequests() {
  yield takeLatest(DataActionTypes.REQUEST_CAMPAIGNS, handleCampaignsFetchRequest);
}

function* watchSpotsListRequests() {
  yield takeLatest(DataActionTypes.REQUEST_SPOTSLIST, handleSpotsListRequest);
}

function* watchShareOfVoiceRequests() {
  yield takeLatest(DataActionTypes.REQUEST_SHAREOFVOICE, handleShareOfVoiceRequest);
}

function* watchSnapPlatformsRequests() {
  yield takeLatest(
    DataActionTypes.REQUEST_SNAPPLATFORMS,
    handleSnapPlatformsRequest
  );
}

function* watchSnapSourcesRequests() {
  yield takeLatest(DataActionTypes.REQUEST_SNAPSOURCES, handleSnapSourcesRequest);
}

function* watchSnapPublishersRequests() {
  yield takeLatest(
    DataActionTypes.REQUEST_SNAPPUBLISHERS,
    handleSnapPublisherRequest
  );
}

function* watchSnapPublishersSOIRequests() {
  yield takeLatest(
    DataActionTypes.REQUEST_SNAPPUBLISHERS_SOI,
    handleSnapPublishersInvestmentRequest
  );
}

function* watchSnapAdvertisersRequests() {
  yield takeLatest(DataActionTypes.REQUEST_SNAPTABLE, handleSnapAdvertisersRequest);
}

function* watchSnapStatsRequests() {
  yield takeLatest(
    DataActionTypes.FETCH_SNAP_STATS_REQUEST,
    handleSnapStatsDataRequest
  );
}

function* watchInvestmentRequests() {
  yield takeLatest(DataActionTypes.REQUEST_INVESTMENT, handleInvestmentRequest);
}

function* watchExportReportRequests() {
  yield takeLatest(DataActionTypes.REPORT_REQUEST, handleExportCampaignReport);
}

function* watchExportMailReportRequests() {
  yield takeLatest(DataActionTypes.REPORT_MAIL_REQUEST, handleExportMailReport);
}

function* watchUnfilteredRequests() {
  yield takeLatest(
    DataActionTypes.REQUEST_UF_SHAREOFVOICE,
    handleUnfilteredShareOfVoiceRequest
  );
}

function* watchUnfilteredInvestment() {
  yield takeLatest(
    DataActionTypes.REQUEST_UF_INVESTMENT,
    handleUnfilteredInvestmentRequest
  );
}

function* watchfetchReportRequests() {
  yield takeLatest(DataActionTypes.FETCH_USER_REPORTS, handleUserReportsRequest);
}

function* watchCreateReportRequests() {
  yield takeLatest(
    DataActionTypes.CREATE_USER_REPORT,
    handleCreateUserReportRequest
  );
}

function* watchEditReportRequests() {
  yield takeLatest(DataActionTypes.EDIT_USER_REPORT, handleEditUserReportRequest);
}

function* watchToggleReportRequests() {
  yield takeLatest(
    DataActionTypes.TOGGLE_USER_REPORT,
    handleToggleUserReportRequest
  );
}

function* watchDeleteReportRequests() {
  yield takeLatest(
    DataActionTypes.DELETE_USER_REPORT,
    handleDeleteUserReportRequest
  );
}

function* watchFetchAlertsRequests() {
  yield takeLatest(DataActionTypes.ALERTS_REQUEST, handleFetchAlertsRequest);
}

function* watchCreateAlertRequests() {
  yield takeLatest(DataActionTypes.CREATE_ALERT_REQUEST, handleCreateAlertsRequest);
}

function* watchEditAlertRequests() {
  yield takeLatest(DataActionTypes.EDIT_ALERT_REQUEST, handleEditAlertsRequest);
}

function* watchDeleteAlertRequests() {
  yield takeLatest(DataActionTypes.DELETE_ALERT_REQUEST, handleDeleteAlertsRequest);
}

function* watchSnapshotsDownloadRequests() {
  yield takeLatest(
    DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_REQUEST,
    handleSnapDownloadRequest
  );
}

function* watchGalleryDownloadRequests() {
  yield takeLatest(
    DataActionTypes.DOWNLOAD_GALLERY_REQUEST,
    handleGalleryDownloadRequest
  );
}

function* watchFetchAudiencesRequests() {
  yield takeLatest(DataActionTypes.REQUEST_AUDIENCES, handleFetchAudiencesRequest);
}

function* watchFetchDdsWithoutCaptureRequest() {
  yield takeLatest(
    DataActionTypes.DDS_WITHOUT_CAPTURES_REQUEST,
    handleFetchDdsWithoutCaptureRequest
  );
}

function* dataSaga() {
  yield all([
    fork(watchCampaignDataRequests),
    fork(watchSpotsListRequests),
    fork(watchShareOfVoiceRequests),
    fork(watchSnapPlatformsRequests),
    fork(watchSnapSourcesRequests),
    fork(watchSnapPublishersRequests),
    fork(watchSnapPublishersSOIRequests),
    fork(watchSnapAdvertisersRequests),
    fork(watchSnapStatsRequests),
    fork(watchInvestmentRequests),
    fork(watchUnfilteredRequests),
    fork(watchUnfilteredInvestment),
    fork(watchExportReportRequests),
    fork(watchExportMailReportRequests),
    fork(watchfetchReportRequests),
    fork(watchCreateReportRequests),
    fork(watchEditReportRequests),
    fork(watchToggleReportRequests),
    fork(watchDeleteReportRequests),
    fork(watchFetchAlertsRequests),
    fork(watchCreateAlertRequests),
    fork(watchEditAlertRequests),
    fork(watchDeleteAlertRequests),
    fork(watchSnapshotsDownloadRequests),
    fork(watchGalleryDownloadRequests),
    fork(watchFetchAudiencesRequests),
    fork(watchFetchDdsWithoutCaptureRequest),
  ]);
}

export default dataSaga;
