import {
  // put,
  takeEvery,
  fork,
  all,
  // select
} from "redux-saga/effects";
/* import { RootState } from "typesafe-actions";
import { choose_tab } from "../navigation/actions";
import { NavigationState } from "../navigation/types"; */
import { setAdvancedFilters } from "./actions";
import { SearchActionTypes } from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* handleSetAdvancedFilters(action: ReturnType<typeof setAdvancedFilters>) {
  /* try {
    const navigation: NavigationState = yield select(
      (state: RootState) => state.navigation
    );
    const filters = action.payload.filters;

    if (filters.products.length > 0 && navigation.selectedTab < 2) {
      yield put(choose_tab(2));
    } else if (filters.advertisers.length > 0 && navigation.selectedTab < 1) {
      yield put(choose_tab(1));
    }
  } catch (e) {
    console.log(e);
  } */
}

function* watchAdvancedFilters() {
  yield takeEvery(SearchActionTypes.SET_ADVANCED_FILTERS, handleSetAdvancedFilters);
}

function* searchSaga() {
  yield all([fork(watchAdvancedFilters)]);
}

export default searchSaga;
