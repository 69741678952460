import { action } from "typesafe-actions"
import { GeneralActionTypes, HelpFormDataInterface } from "./types"

//Action creators

export const changeLanguage = (language: string) =>
  action(GeneralActionTypes.SELECT_LANGUAGE, { language })

export const helpTicketRequest = (helpFormData: HelpFormDataInterface) =>
  action(GeneralActionTypes.HELP_TICKET_REQUEST, { helpFormData })

export const helpTicketSuccess = () => action(GeneralActionTypes.HELP_TICKET_SUCCESS)

export const toggleDarkMode = () => action(GeneralActionTypes.TOGGLE_DARK_MODE);