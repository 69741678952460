import { useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  Creativity,
  MediaInterface,
} from "../../components/creativity/image-carousel/Creativity";
import LoadingDialog from "../../components/dashboard/dialogs/LoadingDialog";

const spotViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(10, 0, 6),
    },
    large: {
      width: theme.spacing(40),
      height: theme.spacing(40),
    },
  })
);

export const SpotView: React.FC = () => {
  const classes = spotViewStyles();
  const params = useParams<{ id: string }>();
  const adcualityUrl = `${process.env.REACT_APP_MAIL_ENDPOINT || ""}/getspot`;
  const [spot, setSpot] = useState<MediaInterface | null>(null);

  useEffect(() => {
    fetch(`${adcualityUrl}/${params.id}`)
      .then((response) => response.json())
      .then((data) => {
        setSpot(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSpot(null);
      });
  }, [adcualityUrl, params.id]);

  return (
    <Grid container className={classes.container} justify="center" spacing={2}>
      <Grid item xs={12}>
        <LoadingDialog isLoading={spot === null} />
        {spot ? <Creativity media={spot} /> : null}
      </Grid>
    </Grid>
  );
};
