/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { Canceler } from "axios";
import { unstable_trace as trace } from "scheduler/tracing";
import MaterialTable, { MTableBody, MTableToolbar } from "material-table";
import {
  CSVObject,
  Investment,
  InvestmentItemTable,
  ShareVoice,
  ShareVoiceItemTable,
  SnapTable,
  SnapTableItem,
} from "../../../store/data/types";
import { QuickFilterMenu } from "../menus/QuickFilterMenuContainer";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";
import { CsvBuilder } from "filefy";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { RootState } from "typesafe-actions";
import {
  downloadSnapTableRequest,
  setSnapTablePage,
} from "../../../store/data/actions";
import TablePaging from "./TablePagination";
import {
  getPercentageWithFilterSOI,
  getPercentageWithFilterSOV,
} from "../../../utils/functions";
import { TabPropNames } from "../../../utils/TabCodes";
import { GetExchangeRate } from "../../../services/api";
import { SidebarPages } from "../../../store/navigation/types";
import { SaveAlt } from "@material-ui/icons";
import { AdvancedFilters } from "../../../store/search/types";

/* export interface MultiShareItem extends ShareVoiceItem {
  country: string;
} */

/* export interface MultiInvestmentItem extends InvestmentItem {
  country: string;
} */

export interface MultiCountryRow {
  label: string;
  id: number;
}

export interface Column {
  title: string;
  field: string;
  render?: (value?: any) => {};
  type?:
    | "boolean"
    | "time"
    | "numeric"
    | "date"
    | "datetime"
    | "currency"
    | undefined;
  defaultSort?: "asc" | "desc";
  defaultGroupOrder?: number;
  grouping?: boolean;
  headerStyle?: { [p: string]: string };
  cellStyle?: { [p: string]: string };
  customSort?: (x: any, y: any) => number;
}

export interface ShortTableProps {
  columns: Column[];
  data: ShareVoice[] | Investment[] | SnapTable;
  tabType: string;
  currentPage: string;
  multiCountries: boolean;
  snap?: boolean;
}

const initialState = {
  mouseX: null,
  mouseY: null,
};

const ShortTableView = React.memo(
  (props: ShortTableProps) => {
    const [position, setPosition] = React.useState<{
      mouseX: null | number;
      mouseY: null | number;
    }>(initialState);
    const formatMessage = useFormatMessage();
    const formatNumber = useFormatNumber();
    const [row, setRow] = useState<MultiCountryRow>({
      label: "",
      id: 0,
    });
    const { token } = useSelector((state: RootState) => state.login);
    const { selectedPage, selectedTab } = useSelector(
      (state: RootState) => state.navigation
    );
    const { advancedFilters, countries, snapType, snapFilter, startDate, endDate } =
      useSelector((state: RootState) => state.search);
    const {
      isDataLoading,
      unfilteredDataLoading,
      snapTablePage,
      isSnapTableDownloading,
    } = useSelector((state: RootState) => state.data);
    const [paginationSize, setPaginationSize] = useState(
      props.currentPage === "snapshots"
        ? 20
        : props.tabType === "industries"
        ? 60
        : props.tabType === "advertisers" || props.tabType === "publishers"
        ? 100
        : props.tabType === "products"
        ? 50
        : 10
    );
    const dispatch = useDispatch();
    const tableRef =
      useRef<
        MaterialTable<MultiCountryRow | ShareVoice[] | Investment[] | SnapTable>
      >();
    const toolbarRef = useRef<any>();
    const [rates, setRates] = useState<{ [p: string]: number } | false>(false);
    const multiCountriesView = countries.length > 1;

    const [emptyTable, setEmptyTable] = useState(false);

    const fetchConverted = useCallback(async () => {
      let cancelT: Canceler;
      const cancelToken = new axios.CancelToken((cancel) => (cancelT = cancel));
      if (
        selectedPage === "investment" &&
        Array.isArray(props.data) &&
        (props.data as Investment[])[0] &&
        (props.data as Investment[])[0].tableData
      ) {
        const currenciesEffect = (props.data as Investment[]).map(
          (item) => item.currency
        );
        const getRates = await GetExchangeRate(currenciesEffect, cancelToken);
        setRates(getRates);
      }

      return () => {
        if (cancelT) {
          cancelT();
        }
      };
    }, [props.data, selectedPage]);

    useEffect(() => {
      fetchConverted();
    }, [fetchConverted]);

    useEffect(() => {
      trace("update table search", performance.now(), () => {
        if (props.currentPage !== "snapshots") {
          if (toolbarRef.current) {
            toolbarRef.current.onSearchChange("");
          }
          const pageSize =
            props.currentPage === "snapshots"
              ? 20
              : props.tabType === "industries"
              ? 60
              : props.tabType === "advertisers" || props.tabType === "publishers"
              ? 100
              : props.tabType === "products"
              ? 50
              : 10;
          setPaginationSize(pageSize);
          if (tableRef.current) {
            // @ts-expect-error
            tableRef.current.dataManager.changePageSize(pageSize);
          }
        }
      });
    }, [selectedTab, props.currentPage, props.tabType]);

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      if (selectedPage === "shareofvoice") {
        const dataSOV = [...(props.data as ShareVoice[])];
        const dataSOVOrdered = dataSOV.sort(
          (a, b) => b.tableData.length - a.tableData.length
        );
        Array.isArray(dataSOVOrdered) &&
          dataSOVOrdered[0] &&
          dataSOVOrdered.forEach((item) => {
            headers.push(
              `${formatMessage(
                "shareofvoicePage.table.column.percentage"
              )} - ${formatMessage(item.country)}`
            );
            headers.push(`Spots - ${formatMessage(item.country)}`);
            headers.push(`pViewability - ${formatMessage(item.country)}`);
          });
        data.push(headers);
        Array.isArray(dataSOVOrdered) &&
          dataSOVOrdered[0] &&
          dataSOVOrdered[0].tableData.forEach((item) => {
            const row: string[] = [];
            row.push(item.label);
            const percentageItem =
              dataSOVOrdered.length < 2 &&
              advancedFilters[TabPropNames[selectedTab]].length > 0
                ? getPercentageWithFilterSOV(dataSOVOrdered[0].tableData, item.total)
                : item.percentage;
            row.push(percentageItem.toFixed(2));
            const spots = item.total;
            row.push(spots.toString());
            row.push(item.viewability.toString());
            for (let i = 1; i < dataSOVOrdered.length; i++) {
              const otherItem = dataSOVOrdered[i].tableData.find(
                (x) => x.id === item.id
              );
              const spotsOther = otherItem ? otherItem.total : 0;
              otherItem && row.push(otherItem.percentage.toFixed(2));
              row.push(spotsOther.toString());
              otherItem && row.push(otherItem.viewability.toString());
            }
            data.push(row);
          });
        Array.isArray(dataSOVOrdered) && dataSOVOrdered.shift();
        Array.isArray(dataSOVOrdered) &&
          dataSOVOrdered[0] &&
          dataSOVOrdered.forEach((itemSOV, index) => {
            itemSOV.tableData.forEach((tableItem) => {
              const row: string[] = Array(3 * (index + 1)).fill("");
              row.push("");
              const isInData0 = data.some((d0item) => d0item[0] === tableItem.label);
              if (!isInData0) {
                row[0] = tableItem.label;
                const percentageItem =
                  dataSOVOrdered.length < 2 &&
                  advancedFilters[TabPropNames[selectedTab]].length > 0
                    ? getPercentageWithFilterSOV(
                        dataSOVOrdered[0].tableData,
                        tableItem.total
                      )
                    : tableItem.percentage;
                row.push(percentageItem.toFixed(2));
                const spots = tableItem.total;
                row.push(spots.toString());
                row.push(tableItem.viewability.toString());
                for (let i = index + 1; i < dataSOVOrdered.length; i++) {
                  const otherItem = dataSOVOrdered[i].tableData.find(
                    (x) => x.id === tableItem.id
                  );
                  const spotsOther = otherItem ? otherItem.total : 0;
                  otherItem && row.push(otherItem.percentage.toFixed(2));
                  row.push(spotsOther.toString());
                  otherItem && row.push(otherItem.viewability.toString());
                }
                data.push(row);
              }
            });
          });
      } else if (selectedPage === "snapshots") {
        (props.data as SnapTable).items &&
          (props.data as SnapTable).items.forEach((item) => {
            headers.push(`pViewability - ${formatMessage(item.country)}`);
            headers.push(
              `${formatMessage(
                "shareofvoicePage.table.column.percentage"
              )} - ${formatMessage(item.country)}`
            );
            headers.push(
              `${formatMessage("investmentPage.amount")} - ${formatMessage(
                item.country
              )}`
            );
          });
        data.push(headers);
        (props.data as SnapTable).items &&
          (props.data as SnapTable).items[0].table.items.forEach((item) => {
            const row: string[] = [];
            row.push(item.label);
            row.push(item.viewability);
            row.push(item.percentage.toFixed(2));
            row.push(
              formatNumber(item.amount, {
                style: "currency",
                currency: (props.data as SnapTable).items[0].table.currency,
              })
            );
            for (let i = 1; i < (props.data as SnapTable).items.length; i++) {
              const otherItem = (props.data as SnapTable).items[i].table.items.find(
                (x) => x.id === item.id
              );
              otherItem && row.push(otherItem.percentage.toFixed(2));
              otherItem && row.push(otherItem.viewability.toString());
              otherItem &&
                row.push(
                  formatNumber(otherItem.amount, {
                    style: "currency",
                    currency: (props.data as SnapTable).items[0].table.currency,
                  })
                );
            }
            data.push(row);
          });
      } else {
        const dataSOI = [...(props.data as Investment[])];
        const dataSOIOrdered = dataSOI.sort(
          (a, b) => b.tableData.length - a.tableData.length
        );
        Array.isArray(dataSOIOrdered) &&
          dataSOIOrdered &&
          dataSOIOrdered.forEach((item) => {
            headers.push(
              `${formatMessage(
                "shareofvoicePage.table.column.percentage"
              )} - ${formatMessage(item.country)}`
            );
            headers.push(
              `${formatMessage("investmentPage.prints")} - ${formatMessage(
                item.country
              )}`
            );
            headers.push(
              `${formatMessage("investmentPage.amount")} - ${formatMessage(
                item.country
              )}`
            );
          });
        data.push(headers);
        Array.isArray(dataSOIOrdered) &&
          dataSOIOrdered[0] &&
          dataSOIOrdered[0].tableData.forEach((item) => {
            const row: string[] = [];
            row.push(item.label);
            const percentageItem =
              dataSOIOrdered.length < 2 &&
              advancedFilters[TabPropNames[selectedTab]].length > 0
                ? getPercentageWithFilterSOI(
                    dataSOIOrdered[0].tableData,
                    item.amount
                  )
                : item.percentage;
            row.push(percentageItem.toFixed(2));
            row.push(item.prints.toString());
            const amountItem =
              multiCountriesView && rates
                ? item.amount / rates[`USD${dataSOIOrdered[0].currency}`]
                : item.amount;
            row.push(amountItem.toString());
            for (let i = 1; i < dataSOIOrdered.length; i++) {
              const otherItem = dataSOIOrdered[i].tableData.find(
                (x) => x.id === item.id
              );
              otherItem && row.push(otherItem.percentage.toFixed(2));
              otherItem && row.push(otherItem.prints.toString());
              const amountOtherItem = otherItem
                ? multiCountriesView && rates
                  ? otherItem.amount / rates[`USD${dataSOIOrdered[i].currency}`]
                  : otherItem.amount
                : 0;
              otherItem && row.push(amountOtherItem.toString());
            }
            data.push(row);
          });
        Array.isArray(dataSOIOrdered) && dataSOIOrdered.shift();
        Array.isArray(dataSOIOrdered) &&
          dataSOIOrdered[0] &&
          dataSOIOrdered.forEach((itemSOV, index) => {
            itemSOV.tableData.forEach((tableItem) => {
              const row: string[] = Array(3 * (index + 1)).fill("");
              row.push("");
              const isInData0 = data.some((d0item) => d0item[0] === tableItem.label);
              if (!isInData0) {
                row[0] = tableItem.label;
                const percentageItem =
                  dataSOIOrdered.length < 2 &&
                  advancedFilters[TabPropNames[selectedTab]].length > 0
                    ? getPercentageWithFilterSOI(
                        dataSOIOrdered[0].tableData,
                        tableItem.amount
                      )
                    : tableItem.percentage;
                row.push(percentageItem.toFixed(2));
                row.push(tableItem.prints.toString());
                const amountItem =
                  multiCountriesView && rates
                    ? tableItem.amount / rates[`USD${dataSOIOrdered[0].currency}`]
                    : tableItem.amount;
                row.push(amountItem.toString());
                for (let i = index + 1; i < dataSOIOrdered.length; i++) {
                  const otherItem = dataSOIOrdered[i].tableData.find(
                    (x) => x.id === tableItem.id
                  );
                  otherItem && row.push(otherItem.percentage.toFixed(2));
                  otherItem && row.push(otherItem.prints.toString());
                  const amountOtherItem = otherItem
                    ? multiCountriesView && rates
                      ? otherItem.amount / rates[`USD${dataSOIOrdered[i].currency}`]
                      : otherItem.amount
                    : 0;
                  otherItem && row.push(amountOtherItem.toString());
                }
                data.push(row);
              }
            });
          });
      }
      return data;
    };

    function handleRowClick(
      event: React.MouseEvent<Element, MouseEvent>,
      rowData: MultiCountryRow
    ) {
      trace("open quick filter menu", performance.now(), () => {
        setPosition({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
        setRow(rowData);
      });
    }

    function handleMenuClose() {
      trace("handle close quick filter menu", performance.now(), () => {
        setPosition(initialState);
      });
    }

    const localization = {
      body: {
        emptyDataSourceMessage: formatMessage("table.emptyDataSourceMessage"),
      },
      pagination: {
        labelDisplayedRows: formatMessage("table.labelDisplayedRows"),
        labelRowsSelect: formatMessage("table.labelRowsSelect"),
        labelRowsPerPage: formatMessage("table.labelRowsPerPage"),
        firstTooltip: formatMessage("table.firstTooltip"),
        previousTooltip: formatMessage("table.previousTooltip"),
        nextTooltip: formatMessage("table.nextTooltip"),
        lastTooltip: formatMessage("table.lastTooltip"),
      },
      toolbar: {
        searchTooltip: formatMessage("table.searchTooltip"),
        searchPlaceholder: formatMessage("table.searchPlaceholder"),
        exportTitle: formatMessage("table.exportTitle"),
        exportName: formatMessage("table.exportName"),
      },
      grouping: {
        placeholder: formatMessage("table.groupingPlaceholder"),
      },
    };

    const getTableData = useCallback(() => {
      if (props.multiCountries) {
        const rows: MultiCountryRow[] = [];
        if (selectedPage === "shareofvoice") {
          if (
            props.data &&
            (props.data as ShareVoice[])[0] &&
            Array.isArray(props.data as ShareVoice[])
          ) {
            (props.data as ShareVoice[]) &&
              (props.data as ShareVoice[]).forEach((countryData) => {
                countryData.tableData.forEach((item) => {
                  if (!rows.find((row) => row.label === item.label)) {
                    rows.push({
                      label: item.label,
                      id: item.id,
                    });
                  }
                });
              });
          }
        } else if (selectedPage === "snapshots") {
          if (
            props.data &&
            (props.data as SnapTable).items &&
            Array.isArray((props.data as SnapTable).items)
          ) {
            (props.data as SnapTable).items &&
              (props.data as SnapTable).items.forEach((countryData) => {
                countryData.table.items.forEach((item) => {
                  if (!rows.find((row) => row.label === item.label)) {
                    rows.push({
                      label: item.label,
                      id: item.id,
                    });
                  }
                });
              });
          }
        } else {
          if (props.data && Array.isArray(props.data)) {
            (props.data as Investment[]).forEach((countryData) => {
              countryData.tableData.forEach((item) => {
                if (!rows.find((row) => row.label === item.label)) {
                  rows.push({
                    label: item.label,
                    id: item.id,
                  });
                }
              });
            });
          }
        }
        return rows;
      } else {
        if (selectedPage === "shareofvoice") {
          return (
            (Array.isArray(props.data) &&
              (props.data as ShareVoice[])[0] &&
              (props.data as ShareVoice[])[0].tableData) ||
            []
          );
        } else if (selectedPage === "snapshots") {
          const rows: SnapTableItem[] =
            ((props.data as SnapTable).items &&
              (props.data as SnapTable).items[0].table.items) ||
            [];
          rows.sort((item1, item2) => {
            return item1.percentage > item2.percentage
              ? -1
              : item1.percentage < item2.percentage
              ? 1
              : 0;
          });
          return rows;
        } else {
          return (
            ((props.data as Investment[])[0] &&
              (props.data as Investment[])[0].tableData) ||
            []
          );
        }
      }
    }, [props.multiCountries, props.data, selectedPage]);

    const handleSearch = (searchText: string) => {
      if (searchText && searchText.length > 0) {
        let isEmpty = true;
        (props.data as ShareVoice[] | Investment[]).forEach(
          (item: ShareVoice | Investment) => {
            if (isEmpty) {
              isEmpty = !item.tableData.some(
                (tableItem: ShareVoiceItemTable | InvestmentItemTable) =>
                  tableItem.label.toLowerCase().includes(searchText.toLowerCase())
              );
            }
          }
        );
        setEmptyTable(isEmpty);
      } else {
        setEmptyTable(false);
      }
    };

    const columns = useMemo(() => {
      if (props.columns.length > 0) {
        return props.columns;
      }
      return [];
    }, [props.columns]);

    const tableData = useMemo(() => {
      return getTableData();
    }, [getTableData]);

    return (
      <div>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          isLoading={isDataLoading || unfilteredDataLoading}
          data={tableData}
          localization={localization}
          onChangeRowsPerPage={(pageSize: number) => {
            setPaginationSize(pageSize);
          }}
          onChangePage={(page: number) => {
            if (props.currentPage === "snapshots") {
              dispatch(setSnapTablePage(page + 1));
            }
          }}
          onSearchChange={handleSearch}
          onOrderChange={(orderByP, orderDirectionP) => {
            console.log("seteando el orden");
            console.log("OrderBy: ", orderByP);
            console.log("OrderDirection: ", orderDirectionP);
          }}
          options={{
            emptyRowsWhenPaging: false,
            exportButton: false,
            pageSize: paginationSize,
            pageSizeOptions:
              props.currentPage === "snapshots" ? [20] : [10, 20, 50, 60, 100],
            showTitle: false,
            sorting: !(
              props.currentPage === SidebarPages.snapshots || isDataLoading
            ),
            search: !(props.currentPage === SidebarPages.snapshots || isDataLoading),
          }}
          onRowClick={(event, rowData) => {
            if (props.snap !== true) {
              handleRowClick(event!, rowData!);
            }
          }}
          actions={[
            {
              icon: () =>
                isSnapTableDownloading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <SaveAlt />
                ),
              tooltip: formatMessage("table.exportName"),
              isFreeAction: true,
              onClick: () => {
                if (props.currentPage === SidebarPages.snapshots) {
                  const filters: AdvancedFilters = {
                    advertisers: [],
                    publishers: [],
                    industries: [],
                    products: [],
                    platforms: [],
                    formats: [],
                    categories: [],
                    sources: [],
                    excludedPublishers: [],
                  };

                  filters[snapType] = snapFilter !== null ? [snapFilter] : [];
                  if (filters[snapType].length > 0) {
                    const tableType =
                      snapType === "advertisers" ? "products" : "advertisers";
                    dispatch(
                      downloadSnapTableRequest(
                        {
                          advancedFilters: filters,
                          countries,
                          startDate,
                          endDate,
                        },
                        token,
                        tableType,
                        1,
                        50000
                      )
                    );
                  } else {
                    dispatch(
                      downloadSnapTableRequest(
                        {
                          advancedFilters: filters,
                          countries,
                          startDate,
                          endDate,
                        },
                        token,
                        snapType,
                        1,
                        50000
                      )
                    );
                  }
                } else {
                  const builder = new CsvBuilder(
                    `${props.currentPage} - ${props.tabType}.csv`
                  );
                  const csvData = getCsvData();
                  builder.setDelimeter(",").addRows(csvData).exportFile();
                }
              },
            },
          ]}
          components={
            props.currentPage === "snapshots"
              ? {
                  Pagination: () => (
                    <TablePaging
                      count={
                        (props.data as SnapTable).items &&
                        (props.data as SnapTable).items[0].table.totalResults
                      }
                      rowsPerPage={paginationSize}
                      currentPage={snapTablePage}
                      handleChangePage={(event: unknown, newPage: number) => {
                        if (props.currentPage === "snapshots") {
                          dispatch(setSnapTablePage(newPage));
                        }
                      }}
                      handleChangeRowsPerPage={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (props.currentPage === "snapshots") {
                          setPaginationSize(parseInt(event.target.value, 10));
                        }
                      }}
                      label={formatMessage(
                        "navigation.tablePagination.resultsPerPage"
                      )}
                      isSnap={true}
                    />
                  ),
                }
              : {
                  Toolbar: (toolbarProps) =>
                    trace("render of toolbar", performance.now(), () => (
                      <MTableToolbar {...toolbarProps} ref={toolbarRef} />
                    )),
                  Body: (bodyProps) => (
                    <MTableBody
                      {...bodyProps}
                      pageSize={emptyTable ? 1 : bodyProps.pageSize}
                      renderData={emptyTable ? [] : bodyProps.renderData}
                    />
                  ),
                }
          }
        />
        {props.snap !== true ? (
          <QuickFilterMenu
            currentPage={props.currentPage}
            position={position}
            handleClose={handleMenuClose}
            name={row!.label}
            id={row!.id}
            type={props.tabType}
          />
        ) : null}
      </div>
    );
  },
  (prevProps: ShortTableProps, nextProps: ShortTableProps) => {
    if (prevProps.currentPage !== "snapshots") {
      const prevPropData = prevProps.data as ShareVoice[] | Investment[];
      const nextPropData = nextProps.data as ShareVoice[] | Investment[];
      return (
        prevProps.multiCountries === nextProps.multiCountries &&
        prevPropData.length === nextPropData.length &&
        JSON.stringify(
          prevPropData[(prevProps.data as ShareVoice[] | Investment[]).length - 1]
        ) ===
          JSON.stringify(
            nextPropData[(nextProps.data as ShareVoice[] | Investment[]).length - 1]
          )
      );
    }
    return false;
  }
);

export default ShortTableView;
