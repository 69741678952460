import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import {
  AppBar,
  Button,
  Container,
  createStyles,
  CssBaseline,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Dashboard from "@material-ui/icons/Dashboard";
import { RootState } from "typesafe-actions";

import logo_white from "../../assets/logo-white-10.svg";
import { MainFooter } from "../../components/generic/Footer";
import { toggleDarkMode } from "../../store/general/actions";
import { WhatsApp } from "@material-ui/icons";

const marketingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      maxWidth: "100vw",
      overflowX: "hidden",
      "@media print": {
        width: `100%`,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: `100%`,
    },
    appBar: {
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.primary.main,
      "@media print": {
        display: "none",
      },
    },
    logotypeImage: {
      marginLeft: 15,
      width: 200,
    },
    grow: {
      flexGrow: 1,
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
    },
    headerIcon: {
      fontSize: 28,
      color: "rgb(255,255,255)",
    },
    iconButton: {
      backgroundColor: "#25D366",
      bottom: 20,
      height: 60,
      left: 20,
      position: "fixed",
      width: 60,
      zIndex: 3000,
      "&:focus, &:hover, &:active": {
        backgroundColor: "#128C7E",
      },
      "@media print": {
        display: "none",
      },
    },
    iconWhatsapp: {
      color: "white",
      fontSize: 35,
    },
  })
);

export const MarketingLayout: React.FC = ({ children }) => {
  const classes = marketingStyles();
  const intl = useFormatMessage();
  const { prefersDarkMode } = useSelector((state: RootState) => state.general);
  const dispatch = useDispatch();
  const history = useHistory();

  const goToDashboard = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} position={"fixed"}>
        <Toolbar>
          <img
            src={logo_white}
            className={classes.logotypeImage}
            alt={"adaCuality"}
          />
          <div className={classes.grow} />
          <Tooltip
            title={intl(
              prefersDarkMode
                ? "navigation.buttons.themeButtonDay"
                : "navigation.buttons.themeButtonNight"
            )}
          >
            <IconButton
              onClick={() => dispatch(toggleDarkMode())}
              color="inherit"
              className={classes.headerMenuButton}
            >
              {prefersDarkMode ? <BrightnessHighIcon /> : <Brightness4Icon />}
            </IconButton>
          </Tooltip>
          <Button
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={goToDashboard}
            endIcon={<Dashboard classes={{ root: classes.headerIcon }} />}
          >
            Dashboard
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Container fixed>{children || ""}</Container>
      </main>
      <MainFooter />
      <Tooltip title={intl("navigation.sidebar.whatsapp")}>
        <IconButton
          aria-label="Whatsapp Button"
          className={classes.iconButton}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=543484385372&text&app_absent=0",
              "_blank"
            )
          }
        >
          <WhatsApp className={classes.iconWhatsapp} />
        </IconButton>
      </Tooltip>
    </div>
  );
};
