import { createStyles, makeStyles, Theme } from "@material-ui/core"

const loginPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0,
    },
    formControl: {
      minWidth: 40,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    logotypeContainer: {
      backgroundColor: theme.palette.primary.main,
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    logotypeImage: {
      width: 450,
      marginBottom: theme.spacing(4),
    },
    logotypeText: {
      color: "white",
      fontWeight: 400,
      fontSize: 38,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    formContainer: {
      width: "40%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
    },
    form: {
      width: 320,
    },
    tab: {
      fontWeight: 400,
      fontSize: 18,
    },
    greeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(4),
      color: theme.palette.primary.main,
    },
    subGreeting: {
      fontWeight: 500,
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
    creatingButtonContainer: {
      marginTop: theme.spacing(2.5),
      height: 46,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    createAccountButton: {
      height: 46,
      textTransform: "none",
    },
    formDividerContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: "flex",
      alignItems: "center",
    },
    formDividerWord: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    formDivider: {
      flexGrow: 1,
      height: 1,
      backgroundColor: theme.palette.text.hint + "40",
    },
    errorMessage: {
      textAlign: "center",
    },
    textFieldUnderline: {
      "&:before": {
        borderBottomColor: theme.palette.primary.light,
      },
      "&:after": {
        borderBottomColor: theme.palette.primary.main,
      },
      "&:hover:before": {
        borderBottomColor: `${theme.palette.primary.light} !important`,
      },
    },
    textField: {
      borderBottomColor: theme.palette.background.default,
    },
    formButtons: {
      width: "100%",
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    forgetButton: {
      textTransform: "none",
      fontWeight: 400,
    },
    loginLoader: {
      marginLeft: theme.spacing(4),
    },
    copyright: {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(4),
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        bottom: theme.spacing(2),
      },
    },
    languageContainer: {
      width: "100%",
      height: "10%",
      "text-align": "right",
    },
    customer: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    }
  })
)

export default loginPageStyles
