import React, { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "./theme.css";
import {
  Button,
  Popover,
  Tooltip,
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  useTheme,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { set_date_range } from "../../../../store/search/actions";
import { es, enUS } from "date-fns/locale";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { startOfYesterday, endOfMonth, startOfYear, endOfYear } from "date-fns/esm";
import {
  subDays,
  startOfToday,
  startOfMonth,
  subMonths,
  format,
  differenceInDays,
  subYears,
  differenceInMonths,
} from "date-fns";
import { setNotification } from "../../../../store/navigation/actions";

const PERIODS = {
  lastTwoWeeks: "LAST_2_WEEKS",
  actualMonth: "ACTUAL_MONTH",
  lastMonth: "LAST_MONTH",
  yearToDate: "YEAR_TO_DATE",
  lastYear: "LAST_YEAR",
  custom: "CUSTOM",
};

const startDemo = new Date(2023, 6, 1);
const endDemo = new Date(2023, 11, 31);

const DateRangePicker = () => {
  const styles = useStyles();

  const { roles } = useSelector((state: RootState) => state.login);

  const formatMessage = useFormatMessage();
  const { startDate, endDate } = useSelector((state: RootState) => {
    if (
      typeof state.search.startDate === "string" ||
      typeof state.search.endDate === "string"
    ) {
      const startDate = new Date(state.search.startDate);
      const endDate = new Date(state.search.endDate);
      return { startDate: startDate, endDate: endDate };
    }
    return state.search;
  });
  const { language } = useSelector((state: RootState) => state.general);
  const { selectedPage } = useSelector((state: RootState) => state.navigation);
  const [range, setRange] = useState<{ startDate: Date; endDate: Date }>({
    startDate,
    endDate,
  });
  const [period, setPeriod] = useState(PERIODS.lastTwoWeeks);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const roleDemo = useMemo(
    () => roles.some((item) => item === "ROLE_FEATURE_DEMO"),
    [roles]
  );

  useEffect(() => {
    if (
      roleDemo &&
      (range.endDate < startDemo ||
        range.endDate > endDemo ||
        range.startDate < startDemo ||
        range.startDate > endDemo)
    ) {
      dispatch(set_date_range(startDemo, endDemo));
    }
  }, [dispatch, range.endDate, range.startDate, roleDemo]);

  const handleSubmit = () => {
    setAnchorEl(null);
    const diff = differenceInDays(range.endDate, range.startDate);
    if (
      (selectedPage === "snapshots" || selectedPage === "investment") &&
      diff < 14
    ) {
      dispatch(
        setNotification(
          "warning",
          formatMessage("errors.daterange.periodInvestment")
        )
      );
      return;
    }
    const diff2 = differenceInMonths(range.endDate, range.startDate);
    if (
      !roles.some((item) => item === "ROLE_SUPER_ADMIN") &&
      selectedPage === "snapshots" &&
      diff2 > 3
    ) {
      dispatch(
        setNotification("warning", formatMessage("errors.daterange.periodSnaps"))
      );
      return;
    }
    if (diff2 > 12) {
      dispatch(
        setNotification(
          "warning",
          formatMessage("errors.daterange.periodTotalMonths")
        )
      );
      return;
    }
    if (
      roleDemo &&
      (range.endDate < startDemo ||
        range.endDate > endDemo ||
        range.startDate < startDemo ||
        range.startDate > endDemo)
    ) {
      dispatch(
        setNotification(
          "warning",
          formatMessage("errors.daterange.periodTotalMonths")
        )
      );
      return;
    }
    dispatch(set_date_range(range.startDate, range.endDate));
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();

  const handleSelectRange = (range: any) => {
    const startDate: Date = range.range1.startDate;
    const endDate: Date = range.range1.endDate;
    setRange({ startDate, endDate });
  };

  const handleSelectPeriod = (period: string) => {
    switch (period) {
      case PERIODS.lastTwoWeeks: {
        setRange({
          startDate: subDays(startOfYesterday(), 15),
          endDate: startOfYesterday(),
        });
        break;
      }
      case PERIODS.actualMonth: {
        setRange({
          startDate: subDays(startOfYesterday(), 30),
          endDate: startOfYesterday(),
        });
        break;
      }
      case PERIODS.lastMonth: {
        const endDate = endOfMonth(subMonths(startOfToday(), 1));
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);
        setRange({
          startDate: startOfMonth(subMonths(startOfToday(), 1)),
          endDate: endDate,
        });
        break;
      }
      case PERIODS.yearToDate: {
        setRange({
          startDate: startOfYear(startOfToday()),
          endDate: startOfYesterday(),
        });
        break;
      }
      case PERIODS.lastYear: {
        setRange({
          startDate: startOfYear(subYears(new Date(), 1)),
          endDate: endOfYear(subYears(new Date(), 1)),
        });
        break;
      }
      default:
        break;
    }
    setPeriod(period);
  };

  return (
    <div>
      <Tooltip title={formatMessage("navigation.header.dateRange")}>
        <Button
          onClick={handleClick}
          startIcon={<DateRangeIcon />}
          variant="text"
          color="primary"
          className={styles.button}
        >
          {`${format(startDate, "dd/MM/yyyy")} → ${format(endDate, "dd/MM/yyyy")}`}
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={styles.PeriodDate}>
          <div className={styles.sidebarLeft}>
            <span className={styles.sidebarLeftTitle}>
              <FormattedMessage id="navigation.datepicker.period" />
            </span>
            <List component="nav" aria-label="secondary mailbox folder">
              {!roleDemo ? (
                <>
                  <ListItem
                    button
                    selected={period === PERIODS.lastTwoWeeks}
                    onClick={() => handleSelectPeriod(PERIODS.lastTwoWeeks)}
                  >
                    <FormattedMessage id="navigation.datepicker.last2weeks" />
                  </ListItem>
                  <ListItem
                    button
                    selected={period === PERIODS.actualMonth}
                    onClick={() => handleSelectPeriod(PERIODS.actualMonth)}
                  >
                    <FormattedMessage id="navigation.datepicker.actualMonth" />
                  </ListItem>
                  <ListItem
                    button
                    selected={period === PERIODS.lastMonth}
                    onClick={() => handleSelectPeriod(PERIODS.lastMonth)}
                  >
                    <FormattedMessage id="navigation.datepicker.lastMonth" />
                  </ListItem>
                </>
              ) : null}
              {roles.some((item) => item === "ROLE_SUPER_ADMIN") ||
              selectedPage !== "snapshots" ? (
                <>
                  <ListItem
                    button
                    selected={period === PERIODS.yearToDate}
                    onClick={() => handleSelectPeriod(PERIODS.yearToDate)}
                  >
                    <FormattedMessage id="navigation.datepicker.yearToDate" />
                  </ListItem>
                  <ListItem
                    button
                    selected={period === PERIODS.lastYear}
                    onClick={() => handleSelectPeriod(PERIODS.lastYear)}
                  >
                    <FormattedMessage id="navigation.datepicker.lastYear" />
                  </ListItem>
                </>
              ) : null}
              <ListItem
                button
                selected={period === PERIODS.custom}
                onClick={() => handleSelectPeriod(PERIODS.custom)}
              >
                <FormattedMessage id="navigation.datepicker.custom" />
              </ListItem>
            </List>
          </div>

          <div className={styles.sidebarRight}>
            <div className={styles.rdrMonth}>
              <DateRange
                locale={language === "es" ? es : enUS}
                ranges={[
                  {
                    startDate: range.startDate,
                    endDate: range.endDate,
                    color: theme.palette.primary.main,
                  },
                ]}
                onChange={handleSelectRange}
                maxDate={roleDemo ? new Date("2023-12-31") : new Date()}
                minDate={roleDemo ? new Date("2023-07-01") : new Date("2017-01-02")}
                dragSelectionEnabled={false}
                scroll={{ enabled: true }}
                className={classNames(
                  styles.rdrMonth,
                  styles.rdrDays,
                  styles.rdrDayNumber
                )}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setAnchorEl(null)}
                size="small"
              >
                <FormattedMessage id="navigation.buttons.cancel" />
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => handleSubmit()}
                size="small"
              >
                <FormattedMessage id="navigation.buttons.apply" />
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    PeriodDate: {
      display: "grid",
      gridTemplateColumns: "192px 360px",
      boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.16)",
      borderRadius: "4px",
    },
    sidebarLeft: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.paper,
    },
    sidebarLeftTitle: {
      fontSize: 18,
      lineHeight: "23px",
      fontWeight: "bold",
      color: theme.palette.text.primary,
      marginTop: 24,
      marginBottom: 16,
      paddingLeft: 16,
    },
    sidebarLeftButton: {
      display: "flex",
      justifyContent: "space-between",
      background: "none",
      border: "none",
      padding: 10,
      color: theme.palette.text.primary,
      "&:hover": {
        background: "rgb(173, 181, 202)",
      },
    },
    sidebarLeftList: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    sidebarLeftButtonValue: {
      fontSize: 14,
      lineHeight: "18px",
      letterSpacing: "0.02em",
      color: "inherit",
      paddingLeft: 8,
    },
    activeLabel: {
      background: "rgb(173, 181, 202)",
      fontWeight: 600,
    },
    sidebarRight: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.default,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
    },
    rdrMonth: {
      width: "100%",
      padding: 0,
      backgroundColor: "transparent",
    },
    rdrDays: {
      width: "100%",
    },
    pickerButton: {
      background: "none",
      width: "250px",
      height: "42px",
      borderRadius: "4px",
      border: "2px solid #e0e0e0",
      transition: "border 0.2s",
      textAlign: "left",
      outline: "none",
    },
    button: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
    rdrDayNumber: {
      color: theme.palette.text.primary,
    },
  })
);

export default DateRangePicker;
