import { Dispatch } from "redux"
import { forgotPassword, loginRequest } from "../../../store/login/actions"
import { RootAction, RootState } from "typesafe-actions"
import { connect } from "react-redux"
import LoginFormView from "./LoginFormView"
import { RouteComponentProps, withRouter } from "react-router-dom"
import * as H from "history"
import { injectIntl, InjectedIntlProps } from "react-intl"

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  general_state: state.general,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    login_request: (email: string, password: string, history: H.History) =>
      dispatch(loginRequest(email, password, history)),
    forgot_password: () => dispatch(forgotPassword()),
  }
}

export type LoginProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  InjectedIntlProps

export default withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginFormView))
)
