/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { CsvBuilder } from "filefy";

import { ColumnSeriesInterface, LineOptions } from "../../utils/ChartOptions";
import Widget from "../dashboard/Widget";
import { RootState } from "typesafe-actions";
import { COLORS, darkCOLORS } from "../../utils/colors";
import { CSVObject } from "../../store/data/types";

interface TimeChartProps {
  title: string;
  series: ColumnSeriesInterface[];
  categories: string[];
  tooltipFormatter: (arg0: number) => string;
  loading: boolean;
  type: "stacked" | "column" | "line";
  snap?: boolean;
}

const TimeChart = React.memo(
  (props: TimeChartProps) => {
    const { prefersDarkMode } = useSelector((state: RootState) => state.general);
    const theme = useTheme();
    const formatMessage = useFormatMessage();
    const print = useMediaQuery("print");

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      props.categories.forEach((val) => {
        headers.push(val);
      });
      data.push(headers);

      props.series.forEach((val) => {
        data.push([
          val.name,
          ...val.data.map((item) => props.tooltipFormatter(item)),
        ]);
      });

      return data;
    };

    const exportTime = () => {
      const builder = new CsvBuilder(
        `Time Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Widget title={props.title} snap={props.snap} handleDownloadCSV={exportTime}>
        <Chart
          options={{
            ...LineOptions,
            colors: theme.palette.type === "dark" ? darkCOLORS : COLORS,
            yaxis: {
              labels: {
                formatter: (value: string) => {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
            },
            xaxis: {
              categories: props.categories,
            },
            tooltip: {
              y: {
                formatter: props.tooltipFormatter,
              },
            },
            theme: {
              mode: prefersDarkMode ? "dark" : "light",
            },
            animations: {
              enabled: false,
            },
          }}
          type={"line"}
          series={props.series}
          height={print ? 600 : 250}
        />
      </Widget>
    );
  },
  (prevProps: TimeChartProps, nextProps: TimeChartProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default TimeChart;
