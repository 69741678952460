import { ReduxProps, toolStyles } from "./ToolContainer"
import { IconButton } from "@material-ui/core"
import Badge from "@material-ui/core/Badge"
import { Public as CountriesIcon } from "@material-ui/icons"
import Tooltip from "@material-ui/core/Tooltip"
import React from "react"

const CountryPickerView = (props: ReduxProps) => {
  const classes = toolStyles()
  return (
    <Tooltip
      title={props.intl.formatMessage({ id: "navigation.header.countryButton" })}
    >
      <IconButton
        aria-haspopup="true"
        id={"Country selector icon"}
        color="inherit"
        aria-controls="profile-menu"
        className={classes.headerMenuButton}
        onClick={() => props.toggleCountryPicker()}
      >
        <Badge
          color={"secondary"}
          badgeContent={
            props.search_state.countries.length === 1
              ? props.search_state.countries[0].toUpperCase()
              : props.search_state.countries.length
          }
        >
          <CountriesIcon classes={{ root: classes.headerIcon }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default CountryPickerView
