import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import MaterialTable, { Column } from "material-table";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import EditIcon from "@material-ui/icons/Edit";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { deleteAlertRequest, setAlertToEdit } from "../../store/data/actions";
import { AlertsInterface } from "../../store/search/types";
import { toggle_alertsDialog } from "../../store/navigation/actions";

const SavedReports = () => {
  const { userAlerts } = useSelector((state: RootState) => state.data);
  const { token } = useSelector((state: RootState) => state.login);
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const localization = {
    body: {
      emptyDataSourceMessage: formatMessage(
        "alerts.configDialog.emptyDataSourceMessage"
      ),
    },
    pagination: {
      labelDisplayedRows: "{from}-{to} of {count}",
      labelRowsSelect: formatMessage("table.labelRowsSelect"),
      labelRowsPerPage: formatMessage("table.labelRowsPerPage"),
      firstTooltip: formatMessage("table.firstTooltip"),
      previousTooltip: formatMessage("table.previousTooltip"),
      nextTooltip: formatMessage("table.nextTooltip"),
      lastTooltip: formatMessage("table.lastTooltip"),
    },
    toolbar: {
      searchTooltip: formatMessage("table.searchTooltip"),
      searchPlaceholder: formatMessage("table.searchPlaceholder"),
      exportTitle: formatMessage("table.exportTitle"),
      exportName: formatMessage("table.exportName"),
    },
    grouping: {
      placeholder: formatMessage("table.groupingPlaceholder"),
    },
    header: {
      actions: formatMessage("table.column.actions"),
    },
  };
  const columns: Column<AlertsInterface>[] = [
    { field: "name", title: formatMessage("alerts.configSettings.name") },
    {
      field: "advertisers",
      title: formatMessage("alerts.configSettings.advertisers"),
      render: (data: AlertsInterface) => {
        return data.advertisers.map((advertiser) => advertiser.label).join(", ");
      },
    },
  ];
  return (
    <>
      <MaterialTable
        data={userAlerts}
        columns={columns}
        localization={localization}
        title={formatMessage("alerts.configSettings.title")}
        actions={[
          {
            icon: function EditIconFunc() {
              return <EditIcon />;
            },
            tooltip: "Editar reporte (Coming soon)",
            onClick: (_event, rowData) => {
              const reportToSelect = Array.isArray(rowData) ? rowData[0] : rowData;
              dispatch(setAlertToEdit(reportToSelect));
              dispatch(toggle_alertsDialog());
            },
          },
          {
            icon: function DeleteIconFunc() {
              return <DeleteIcon />;
            },
            tooltip: "Borrar",
            onClick: (event, rowData) => {
              const alertToSelect = Array.isArray(rowData) ? rowData[0] : rowData;
              dispatch(deleteAlertRequest(token, alertToSelect.id));
            },
          },
        ]}
      />
    </>
  );
};

export default SavedReports;
