import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { reportMailRequest } from "../../store/data/actions";
import { RootState } from "typesafe-actions";
import { FiltersDownload } from "../../store/search/types";
import { parseISO } from "date-fns"

const styles = makeStyles({
    "grid": {
        height: "100%",
        textAlign: "center"
    },
    "icon": {
        fontSize: "4em"
    }
});

const DownloadsReports = () => {
    const { type, format } = useParams<{
        type: 'campaign' | 'share-of-voice' | 'investment' | 'campaign-rts';
        format: 'pdf' | 'excel';
    }>();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.login.token);
    const isDataLoading = useSelector((state: RootState) => state.data.isDataLoading);
    
    useEffect(() => {
        const getParams = new URLSearchParams(window.location.search);

        const country = getParams.get('country');
        const from = getParams.get('since');
        const to = getParams.get('until');

        if (from && to && country) {
            const advancedFilters: FiltersDownload = {
              advertisers: [],
              publishers: [],
              industries: [],
              products: [],
              platforms: [],
              formats: [],
              categories: [],
              sources: [],
              excludedPublishers: [],
            };

            getParams.forEach((val, key) => {
                if(/advertisers/.exec(key)) {
                    advancedFilters.advertisers.push(parseInt(val, 10))
                } else if(/publishers/.exec(key)) {
                    advancedFilters.publishers.push(parseInt(val, 10))
                } else if(/industries/.exec(key)) {
                    advancedFilters.industries.push(parseInt(val, 10))
                } else if(/products/.exec(key)) {
                    advancedFilters.products.push(parseInt(val, 10))
                } else if(/platforms/.exec(key)) {
                    advancedFilters.platforms.push(parseInt(val, 10))
                } else if(/formats/.exec(key)) {
                    advancedFilters.formats.push(parseInt(val, 10))
                } else if(/categories/.exec(key)) {
                    advancedFilters.categories.push(parseInt(val, 10))
                } else if(/sources/.exec(key)) {
                    advancedFilters.sources.push(parseInt(val, 10))
                } else if(/excludedPublishers/.exec(key)) {
                    advancedFilters.excludedPublishers.push(parseInt(val, 10))
                }
            });

            dispatch(reportMailRequest({
                filters: {
                    advancedFilters,
                    countries: [country],
                    endDate: parseISO(to),
                    startDate: parseISO(from)
                },
                reportFormat: format,
                type
            }, token));
        }
    }, [token, format, type, dispatch]);

    const classes = styles();
    return <Grid container justify="center" alignItems="center" className={classes.grid}>
        <Grid item xs={12}>
            {
                isDataLoading ?
                    <>
                        <CircularProgress className={classes.icon} />
                        <Typography variant="h3">
                            Por favor espere mientras se descargan los reportes.
                        </Typography>
                    </>
                    :
                    <>
                        <CheckCircle className={classes.icon} color="primary" />
                        <Typography variant="h3">
                            Ya puede cerrar esta pestaña.
                        </Typography>
                    </>
            }
        </Grid>
    </Grid>
};

export default DownloadsReports;
