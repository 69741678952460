import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { HelpDialogProps } from "./HelpDialogContainer"
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import countries from "../../../utils/Countries"
import helpMessageTypes from "../../../utils/HelpMessageTypes"
import { FormattedMessage } from "react-intl"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { publishEvent } from "../../../services/GoogleAnalytics"
import { HelpFormDataInterface } from "../../../store/general/types"

// TODO: Refactorizar o eliminar esta vista si ya no se necesita

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formSelects: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(2.5),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      display: "none",
    },
    button: {
      marginTop: theme.spacing(2.5),
    },
  })
)

export default function HelpDialogView(props: HelpDialogProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()
  const dialogSchema = Yup.object().shape({
    email: Yup.string()
      .email(props.intl.formatMessage({ id: "YUP.invalidEmail" }))
      .required(props.intl.formatMessage({ id: "YUP.dialog.required" })),
    message: Yup.string().required(
      props.intl.formatMessage({ id: "YUP.dialog.required" })
    ),
    title: Yup.string().required(
      props.intl.formatMessage({ id: "YUP.dialog.required" })
    ),
    name: Yup.string().required(
      props.intl.formatMessage({ id: "YUP.dialog.required" })
    ),
  })

  function handleSubmit(data: HelpFormDataInterface) {
    publishEvent("Usuario", "Emisión de ticket de soporte")
    props.helpTicketRequest(data)
  }

  return (
        <Dialog
          open={false}
          onClose={() => props.toggleDialog()}
          aria-labelledby="form-dialog-title"
          fullScreen={fullScreen}
        >
          <DialogTitle id="form-dialog-title">
            <FormattedMessage id={"navigation.sidebar.help"} />
          </DialogTitle>
          <Formik
            onSubmit={(values) =>
              handleSubmit({
                email: values.email,
                title: values.title,
                message: values.message,
                messageType: values.messageType,
                company: values.company,
                country: values.country,
                file: values.file,
                name: values.fullname,
              })
            }
            initialValues={{
              email: "",
              title: "",
              message: "",
              company: "",
              country: "",
              messageType: "",
              fullname: "",
              file: null,
            }}
            validationSchema={dialogSchema}
          >
            {({ handleChange, errors, touched }) => (
              <Form>
                <DialogContent>
                  <DialogContentText>
                    <FormattedMessage id={"help.dialog.message"} />
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    id="email"
                    label={
                      props.intl.formatMessage({ id: "loginPage.emailField" }) + " *"
                    }
                    type="email"
                    error={(errors.email && touched.email) as boolean}
                    fullWidth
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? <div>{errors.email}</div> : null}
                  <TextField
                    margin="dense"
                    id="title"
                    label={
                      props.intl.formatMessage({ id: "help.dialog.title" }) + " *"
                    }
                    fullWidth
                    onChange={handleChange}
                    error={(errors.title && touched.title) as boolean}
                  />
                  {errors.title && touched.title ? <div>{errors.title}</div> : null}
                  <TextField
                    id="message"
                    label={
                      props.intl.formatMessage({ id: "help.dialog.messagelabel" }) +
                      " *"
                    }
                    multiline
                    rowsMax="4"
                    margin="dense"
                    fullWidth
                    onChange={handleChange}
                    error={(errors.message && touched.message) as boolean}
                  />
                  {errors.message && touched.message ? (
                    <div>{errors.message}</div>
                  ) : null}

                  <TextField
                    margin="dense"
                    id="fullname"
                    label={props.intl.formatMessage({ id: "help.dialog.name" })}
                    fullWidth
                    onChange={handleChange}
                  />

                  <TextField
                    margin="dense"
                    id="company"
                    label={props.intl.formatMessage({ id: "help.dialog.company" })}
                    fullWidth
                    onChange={handleChange}
                  />
                  <div className={classes.formSelects}>
                    <FormControl>
                      <InputLabel htmlFor="country-native-simple">
                        <FormattedMessage id={"help.dialog.country"} /> *
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        native
                        id={"country"}
                        error={(errors.country && touched.country) as boolean}
                      >
                        <option value={""} />
                        {countries.map((country) => (
                          <option value={country.name} key={country.name}>
                            {country.emoji +
                              " " +
                              props.intl.formatMessage({ id: country.name })}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <InputLabel htmlFor="messageType-native-simple">
                        <FormattedMessage id={"help.dialog.messageType"} /> *
                      </InputLabel>
                      <Select
                        native
                        id={"messageType"}
                        onChange={handleChange}
                        error={
                          (errors.messageType && touched.messageType) as boolean
                        }
                      >
                        <option value={""} />
                        {helpMessageTypes.map((type) => (
                          <option value={type.name} key={type.code}>
                            {props.intl.formatMessage({ id: type.code })}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {errors.messageType && touched.messageType ? (
                    <div>{errors.messageType}</div>
                  ) : null}

                  {errors.country && touched.country ? (
                    <div>{errors.country}</div>
                  ) : null}

                  <input
                    className={classes.input}
                    accept="*"
                    id="file"
                    type="file"
                    onChange={handleChange}
                  />

                  <label htmlFor="file">
                    <Button component="span" className={classes.button}>
                      <FormattedMessage id={"help.dialog.uploadButton"} />
                    </Button>
                  </label>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => props.toggleDialog()} color="primary">
                    <FormattedMessage id={"navigation.buttons.cancel"} />
                  </Button>
                  <Button type={"submit"} color="primary">
                    <FormattedMessage id={"navigation.buttons.send"} />
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
  )
}
