import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { COLORS, darkCOLORS, greyScale } from "../../utils/colors";
import { PieChartSeries } from "./PieChart";
import { AudiencesDonutChartSeries } from "./AudiencesDonutChart";

export interface PieLegendProps {
  labels: string[];
  country: number;
  pieData: PieChartSeries[];
  setPieData:
    | React.Dispatch<React.SetStateAction<PieChartSeries[]>>
    | React.Dispatch<React.SetStateAction<AudiencesDonutChartSeries[]>>;
  greyColors?: boolean;
}

const useStyles = makeStyles({
  labels: {
    fontSize: "13px",
    fontFamily: "Helvetica, Arial, sans-serif",
    marginRight: "5px",
    position: "relative",
  },
  labelSelected: {
    color: "#A8ABAB",
  },
  legend: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  icon: {
    marginRight: "3px",
    display: "inline-block",
  },
  group: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
});

const PieLegend = (props: PieLegendProps) => {
  const styles = useStyles();
  const theme = useTheme();

  const handleClickSeries = (id: number) => {
    const newData = [...props.pieData];
    newData[id].hidden = !newData[id].hidden;
    props.setPieData(newData);
  };

  const getColors = (index: number) => {
    if (props.greyColors) {
      return greyScale[index];
    }
    return props.pieData[index].hidden
      ? "#A8ABAB"
      : theme.palette.type === "dark"
      ? darkCOLORS[index]
      : COLORS[index];
  };

  return (
    <div className={styles.legend}>
      {props.labels.map((label: string, index: number) => {
        return (
          <div
            className={styles.group}
            onClick={() => handleClickSeries(index)}
            key={index}
          >
            <span className={styles.icon}>
              <svg width="12" height="12">
                <rect
                  width="12"
                  height="12"
                  style={{
                    fill: getColors(index),
                    strokeWidth: "3",
                    stroke: getColors(index),
                  }}
                />
              </svg>
            </span>
            <span
              className={clsx(
                styles.labels,
                props.pieData[index].hidden && styles.labelSelected
              )}
            >
              {label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default PieLegend;
