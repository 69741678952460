import { SearchActionTypes, SearchState } from "./types"
import { ActionType } from "typesafe-actions"
import * as navigationActions from "./actions"
import { subDays, startOfToday, startOfYesterday } from "date-fns"

type SearchAction = ActionType<typeof navigationActions>

export const initial_state: SearchState = {
  advancedFilters: {
    advertisers: [],
    publishers: [],
    industries: [],
    products: [],
    platforms: [],
    formats: [],
    categories: [],
    sources: [],
    excludedPublishers: []
  },
  paginationParameters: {
    requestedPageNumber: 1,
    requestedResults: 15,
  },
  parameterQueue: {
    advertisers: [],
    publishers: [],
    industries: [],
    products: [],
    platforms: [],
    formats: [],
    categories: [],
    sources: [],
    excludedPublishers: []
  },
  snapType: 'industries',
  snapFilter: null,
  startDate: subDays(startOfToday(), 15),
  endDate: startOfYesterday(),
  countries: []
}

function reducer(state = initial_state, action: SearchAction) {
  switch (action.type) {
    case SearchActionTypes.SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      }
    case SearchActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries,
      }
    case SearchActionTypes.SET_ADVANCED_FILTERS:
      return {
        ...state,
        advancedFilters: action.payload.filters
      }
    case SearchActionTypes.SET_SNAP_TYPE:
      return {
        ...state,
        snapType: action.payload.snapType
      }
    case SearchActionTypes.SET_SNAP_FILTER:
      return {
        ...state,
        snapFilter: action.payload.filter
      }
    case SearchActionTypes.SET_PAGINATION_PARAMETERS:
      return { ...state, paginationParameters: action.payload.parameters }
    case SearchActionTypes.SET_ALL:
      // if (window.location.search.length > 0) {
      //   window.history.pushState({}, document.title, window.location.pathname);
      // }
      return { 
        ...state,
        countries: action.payload.countries,
        advancedFilters: action.payload.advancedFilters,
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
      }
    case SearchActionTypes.QUEUE_PARARM:
      return { ...state, parameterQueue: action.payload.parameters }
    case SearchActionTypes.CLEAR_ALL:
      return { ...initial_state }
    default:
      return { ...state }
  }
}

export { reducer as searchReducer }
