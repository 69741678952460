import React from "react";
import LayoutStyles from "../../styles/LayoutStyles";
import { CssBaseline } from "@material-ui/core";
import HeaderView from "./header/HeaderContainer";
import { Route, Switch } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import HelpDialogView from "./help/HelpDialogContainer";
import HomeView from "../../pages/home/HomeView";
import GalleryView from "../../pages/spots/GalleryContainer";
import AdvancedSearch from "./search/AdvancedSearchContainer";
import SpotsListView from "../../pages/spots-list/SpotsListContainer";
import DrawerView from "./sidebar/DrawerContainer";
import SessionExpiredDialog from "./dialogs/SessionExpired/DialogContainer";
import NotFoundPage from "../../pages/NotFoundPage";
import ProfilePage from "../account/ProfilePage";
import CountryPickerDialog from "./search/CountryPickerDialog";
import NotificationBanner from "../NotificationBanner";
import ShareVoicePage from "../../pages/share";
import InvestmentPage from "../../pages/investment";
import CustomerIframeView from "./customer/CustomerIframeContainer";
import ErrorBoundary from "../errors/ErrorBoundary";
import SnapshotsPage from "../../pages/snapshots";
import WelcomeDialog from "./dialogs/WelcomeDialog";
import DownloadsReports from "../account/DownloadReports";
import AlertsDialogContainer from "./alerts/AlertsDialogContainer";

const AppLayout = () => {
  const classes = LayoutStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter>
        <React.Fragment>
          <HeaderView />
          <DrawerView />
          <ErrorBoundary>
            <div className={classes.content}>
              <div className={classes.fakeToolbar} id={"Fake Toolbar"} />
              <Switch>
                <Route path={"/dashboard/account"} component={ProfilePage} />
                <Route
                  path={"/dashboard/shareofvoice/:reportId"}
                  component={ShareVoicePage}
                />
                <Route path={"/dashboard/shareofvoice"} component={ShareVoicePage} />
                <Route
                  path={"/dashboard/investment/:reportId"}
                  component={InvestmentPage}
                />
                <Route path={"/dashboard/investment"} component={InvestmentPage} />
                <Route
                  path={"/dashboard/spotslist/:reportId"}
                  component={SpotsListView}
                />
                <Route path={"/dashboard/spotslist"} component={SpotsListView} />
                <Route
                  path={"/dashboard/campaigns/:reportId"}
                  component={GalleryView}
                />
                <Route path={"/dashboard/campaigns"} component={GalleryView} />
                <Route path={"/dashboard/snapshots"} component={SnapshotsPage} />
                <Route path={"/dashboard/home"} component={HomeView} />
                <Route
                  path={"/dashboard/export/:type/:format"}
                  component={DownloadsReports}
                />
                <Route path={"/404"} component={NotFoundPage} />
                <Redirect to={"/404"} />
              </Switch>
            </div>
          </ErrorBoundary>
          <AdvancedSearch />
          <AlertsDialogContainer />
          <CountryPickerDialog />
          <HelpDialogView />
          <SessionExpiredDialog />
          <NotificationBanner />
          <CustomerIframeView />
          <WelcomeDialog />
        </React.Fragment>
      </BrowserRouter>
    </div>
  );
};

export default AppLayout;
