export enum DataType {
  "industry" = "INDUSTRIES",
  "advertiser" = "ADVERTISERS",
  "product" = "PRODUCTS",
  "category" = "CATEGORIES",
  "format" = "FORMATS",
  "publisher" = "PUBLISHERS",
  "platform" = "PLATFORMS",
  "source" = "SOURCES",
  "excludedPublishers" = "EXCLUDEDPUBLISHERS"
}

export const Tabs: string[] = [
  DataType.industry,
  DataType.advertiser,
  DataType.product,
  DataType.category,
  DataType.format,
  DataType.publisher,
  DataType.platform,
  DataType.source,
]

export const TabPropNames: string[] = [
  'industries',
  'advertisers',
  'products',
  'categories',
  'formats',
  'publishers',
  'platforms',
  'sources',
]

export const TABS: string[] = [
  DataType.industry,
  DataType.advertiser,
  DataType.product,
  DataType.category,
  DataType.format,
  DataType.publisher,
  DataType.platform,
  DataType.source,
]
