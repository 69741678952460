import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "typesafe-actions"
import { setNotificationOff } from "../store/navigation/actions"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const NotificationBanner = () => {
  const { isSnackbarOpen, notificationMessage, notificationSeverity } = useSelector(
    (state: RootState) => state.navigation
  )
  const dispatch = useDispatch()
  const formatMessage = useFormatMessage();

  return (
    <Snackbar
      open={isSnackbarOpen}
      autoHideDuration={6000}
      onClose={() => dispatch(setNotificationOff())}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={() => dispatch(setNotificationOff())}
        severity={notificationSeverity}
      >
        { 
          typeof notificationMessage === 'string' ?
            notificationMessage === "No data available. Try with other criteria." ?
              formatMessage("errors.data.noDataAvailable")
            : notificationMessage
          : ''
        }
      </Alert>
    </Snackbar>
  )
}

export default NotificationBanner
