import React from "react"
import { useSelector } from "react-redux"
import { ReduxProps } from "./ToolContainer"
import { Button, makeStyles, createStyles, Theme } from "@material-ui/core"
import Badge from "@material-ui/core/Badge"
import { FilterList as FilterIcon } from "@material-ui/icons"
import { getBadge } from "../../header/HeaderView"
import { RootState } from "typesafe-actions"

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.main
  }
}));

const AdvancedFilterPickerView = (props: ReduxProps) => {
  const { isDataLoading } = useSelector((state: RootState) => state.data);
  const styles = useStyles();
  return (
    <Badge
      color={"secondary"}
      badgeContent={getBadge(props.search_state.advancedFilters)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Button
        aria-haspopup="true"
        id={"Advanced search selector icon"}
        aria-controls="profile-menu"
        onClick={props.toggleAdvancedSearch}
        startIcon={<FilterIcon />}
        color="primary"
        variant="text"
        className={styles.button}
        disabled={isDataLoading}
      >
        {props.intl.formatMessage({ id: "navigation.header.advancedSearch" })}
      </Button>
    </Badge>
  )
}

export default AdvancedFilterPickerView
