import { Dispatch } from "redux"
import {
  cancelResetPassword,
  resetPasswordRequest,
} from "../../../store/login/actions"
import { RootAction, RootState } from "typesafe-actions"
import { connect } from "react-redux"
import PasswordResetDialogView from "./PasswordResetDialogView"

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  general_state: state.general,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    handle_cancel: () => dispatch(cancelResetPassword()),
    handle_reset: (email: string) => dispatch(resetPasswordRequest(email)),
  }
}

export type PasswordResetDialogProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetDialogView)
