import React, { ReactElement } from "react"
import { List, ListItemIcon, ListItemText } from "@material-ui/core"
import ListItem from "@material-ui/core/ListItem"

interface DataListItem {
  text: ReactElement;
  icon: ReactElement;
  id: string;
}

interface DataListProps {
  items: DataListItem[];
}

export const CampaignDataList = (props: DataListProps) => {
  return (
    <List dense={true}>
      {props.items.map((item) => (
        <ListItem key={item.id + Math.random().toString()}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.text}</ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
