import React from "react";

interface SvgProps {
  width: string;
  height: string;
}

export const ArgentinaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#338AF3" }}
        d="M256,0C154.506,0,66.81,59.065,25.402,144.696h461.195C445.19,59.065,357.493,0,256,0z"
      />
      <path
        style={{ fill: "#338AF3" }}
        d="M256,512c101.493,0,189.19-59.065,230.598-144.696H25.402C66.81,452.935,154.506,512,256,512z"
      />
    </g>
    <polygon
      style={{ fill: "#FFDA44" }}
      points="332.515,256 301.25,270.707 317.899,300.986 283.949,294.491 279.647,328.787 256,303.563 
   232.352,328.787 228.051,294.491 194.101,300.985 210.749,270.706 179.485,256 210.75,241.293 194.101,211.015 228.05,217.509 
   232.353,183.213 256,208.437 279.648,183.213 283.949,217.509 317.9,211.015 301.251,241.294 "
    />
  </svg>
);

export const BrazilBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#6DA544" }} cx="256" cy="256" r="256" />
    <polygon
      style={{ fill: "#FFDA44" }}
      points="256,100.174 467.478,256 256,411.826 44.522,256 "
    />
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="89.043" />
    <g>
      <path
        style={{ fill: "#0052B4" }}
        d="M211.478,250.435c-15.484,0-30.427,2.355-44.493,6.725c0.623,48.64,40.227,87.884,89.015,87.884
		c30.168,0,56.812-15.017,72.919-37.968C301.362,272.579,258.961,250.435,211.478,250.435z"
      />
      <path
        style={{ fill: "#0052B4" }}
        d="M343.393,273.06c1.072-5.524,1.651-11.223,1.651-17.06c0-49.178-39.866-89.043-89.043-89.043
		c-36.694,0-68.194,22.201-81.826,53.899c12.05-2.497,24.526-3.812,37.305-3.812C263.197,217.043,309.983,238.541,343.393,273.06z"
      />
    </g>
  </svg>
);

export const ChileBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#D80027" }}
      d="M512,256c0,141.384-114.616,256-256,256S0,397.384,0,256s256,0,256,0S449.761,256,512,256z"
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M0,256C0,114.616,114.616,0,256,0c0,97.355,0,256,0,256S89.043,256,0,256z"
    />
    <polygon
      style={{ fill: "#F0F0F0" }}
      points="152.389,89.043 168.966,140.061 222.609,140.061 179.211,171.591 195.787,222.609 
	        152.389,191.078 108.991,222.609 125.567,171.591 82.169,140.061 135.812,140.061 "
    />
  </svg>
);

export const ColombiaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <path
      style={{ fill: "#FFDA44" }}
      d="M0,256C0,114.616,114.616,0,256,0s256,114.616,256,256l-256,22.261L0,256z"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M34.256,384C78.521,460.516,161.245,512,256,512s177.479-51.484,221.744-128L256,367.304L34.256,384z
            "
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M477.744,384C499.526,346.346,512,302.631,512,256H0c0,46.631,12.474,90.346,34.256,128H477.744z"
    />
  </svg>
);

export const CostaRicaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#D80027" }}
      d="M496.077,166.957H15.923C5.633,194.69,0,224.686,0,256s5.633,61.31,15.923,89.043h480.155
	        C506.368,317.31,512,287.314,512,256S506.368,194.69,496.077,166.957z"
    />
    <g>
      <path
        style={{ fill: "#0052B4" }}
        d="M256,0C178.409,0,108.886,34.524,61.939,89.043H450.06C403.114,34.524,333.591,0,256,0z"
      />
      <path
        style={{ fill: "#0052B4" }}
        d="M450.061,422.957H61.939C108.886,477.476,178.409,512,256,512S403.114,477.476,450.061,422.957z"
      />
    </g>
  </svg>
);

export const DominicanaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#D80027" }}
      d="M8.819,322.783c23.638,87.703,92.695,156.761,180.399,180.399V322.783H8.819z"
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M189.217,8.819C101.514,32.456,32.456,101.514,8.819,189.217h180.399V8.819z"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M503.181,189.217C479.544,101.514,410.486,32.456,322.783,8.819v180.399H503.181z"
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M322.783,503.181c87.703-23.638,156.761-92.695,180.399-180.399H322.783V503.181z"
    />
    <path
      style={{ fill: "#496E2D" }}
      d="M322.783,256c0,36.884-29.899,66.783-66.783,66.783c-36.883,0-66.783-29.899-66.783-66.783
            S256,189.217,256,189.217S322.783,219.116,322.783,256z"
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M189.217,256c0-36.884,29.9-66.783,66.783-66.783c36.884,0,66.783,29.899,66.783,66.783"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M218.435,222.609v41.739c0,20.747,16.818,37.565,37.565,37.565s37.565-16.818,37.565-37.565v-41.739
            H218.435z"
    />
  </svg>
);

export const USABall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#D80027" }}
        d="M244.87,256H512c0-23.106-3.08-45.49-8.819-66.783H244.87V256z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M244.87,122.435h229.556c-15.671-25.572-35.708-48.175-59.07-66.783H244.87V122.435z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M256,512c60.249,0,115.626-20.824,159.356-55.652H96.644C140.374,491.176,195.751,512,256,512z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M37.574,389.565h436.852c12.581-20.529,22.338-42.969,28.755-66.783H8.819
                C15.236,346.596,24.993,369.036,37.574,389.565z"
      />
    </g>
    <path
      style={{ fill: "#0052B4" }}
      d="M118.584,39.978h23.329l-21.7,15.765l8.289,25.509l-21.699-15.765L85.104,81.252l7.16-22.037
            C73.158,75.13,56.412,93.776,42.612,114.552h7.475l-13.813,10.035c-2.152,3.59-4.216,7.237-6.194,10.938l6.596,20.301l-12.306-8.941
            c-3.059,6.481-5.857,13.108-8.372,19.873l7.267,22.368h26.822l-21.7,15.765l8.289,25.509l-21.699-15.765l-12.998,9.444
            C0.678,234.537,0,245.189,0,256h256c0-141.384,0-158.052,0-256C205.428,0,158.285,14.67,118.584,39.978z M128.502,230.4
            l-21.699-15.765L85.104,230.4l8.289-25.509l-21.7-15.765h26.822l8.288-25.509l8.288,25.509h26.822l-21.7,15.765L128.502,230.4z
            M120.213,130.317l8.289,25.509l-21.699-15.765l-21.699,15.765l8.289-25.509l-21.7-15.765h26.822l8.288-25.509l8.288,25.509h26.822
            L120.213,130.317z M220.328,230.4l-21.699-15.765L176.93,230.4l8.289-25.509l-21.7-15.765h26.822l8.288-25.509l8.288,25.509h26.822
            l-21.7,15.765L220.328,230.4z M212.039,130.317l8.289,25.509l-21.699-15.765l-21.699,15.765l8.289-25.509l-21.7-15.765h26.822
            l8.288-25.509l8.288,25.509h26.822L212.039,130.317z M212.039,55.743l8.289,25.509l-21.699-15.765L176.93,81.252l8.289-25.509
            l-21.7-15.765h26.822l8.288-25.509l8.288,25.509h26.822L212.039,55.743z"
    />
  </svg>
);

export const EcuadorBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <path
      style={{ fill: "#FFDA44" }}
      d="M0,256C0,114.616,114.616,0,256,0s256,114.616,256,256l-256,22.261L0,256z"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M34.256,384C78.521,460.516,161.245,512,256,512s177.479-51.484,221.744-128L256,367.304L34.256,384z
            "
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M477.744,384C499.526,346.346,512,302.631,512,256H0c0,46.631,12.474,90.346,34.256,128H477.744z"
    />
    <circle style={{ fill: "#FFDA44" }} cx="256" cy="256" r="89.043" />
    <path
      style={{ fill: "#338AF3" }}
      d="M256,311.652c-30.687,0-55.652-24.966-55.652-55.652v-33.391c0-30.687,24.966-55.652,55.652-55.652
            s55.652,24.966,55.652,55.652V256C311.652,286.687,286.687,311.652,256,311.652z"
    />
    <path
      d="M345.043,122.435h-66.783c0-12.294-9.967-22.261-22.261-22.261s-22.261,9.967-22.261,22.261h-66.783
            c0,12.295,10.709,22.261,23.002,22.261h-0.741c0,12.295,9.966,22.261,22.261,22.261c0,12.295,9.966,22.261,22.261,22.261h44.522
            c12.295,0,22.261-9.966,22.261-22.261c12.295,0,22.261-9.966,22.261-22.261h-0.741C334.335,144.696,345.043,134.729,345.043,122.435
            z"
    />
  </svg>
);

export const SalvadorBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#0052B4" }}
        d="M256,0C154.506,0,66.81,59.065,25.402,144.696h461.195C445.19,59.065,357.493,0,256,0z"
      />
      <path
        style={{ fill: "#0052B4" }}
        d="M256,512c101.493,0,189.19-59.065,230.598-144.696H25.402C66.81,452.935,154.506,512,256,512z"
      />
    </g>
    <polygon
      style={{ fill: "#FFDA44" }}
      points="204.59,267.13 256,178.087 307.41,267.13 "
    />
    <polygon
      style={{ fill: "#6DA544" }}
      points="322.783,296.531 256,329.923 189.217,296.531 189.217,252.01 322.783,252.01 "
    />
    <path
      style={{ fill: "#FFDA44" }}
      d="M318.963,181.907l-23.611,23.611c10.071,10.071,16.301,23.984,16.301,39.352
            c0,30.736-24.917,55.652-55.652,55.652s-55.652-24.917-55.652-55.652c0-15.368,6.23-29.281,16.301-39.352l-23.611-23.611
            c-16.115,16.112-26.081,38.373-26.081,62.963c0,49.178,39.866,89.043,89.043,89.043s89.043-39.866,89.043-89.043
            C345.043,220.28,335.077,198.019,318.963,181.907z"
    />
  </svg>
);

export const GuatemalaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#338AF3" }}
        d="M512,256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935,445.19,512,357.493,512,256z"
      />
      <path
        style={{ fill: "#338AF3" }}
        d="M0,256c0,101.493,59.065,189.19,144.696,230.598V25.402C59.065,66.81,0,154.506,0,256z"
      />
    </g>
    <polygon
      style={{ fill: "#ACABB1" }}
      points="322.898,299.288 279.612,256.002 320.815,214.797 318.948,193.035 307.152,181.237 
            256,232.389 204.848,181.237 193.052,193.035 191.185,214.797 232.388,256.002 189.101,299.288 212.713,322.898 256,279.613 
            299.287,322.898 "
    />
    <path
      style={{ fill: "#6DA544" }}
      d="M318.963,193.037l-23.611,23.611c10.071,10.071,16.301,23.984,16.301,39.352
            c0,30.736-24.917,55.652-55.652,55.652S200.348,286.736,200.348,256c0-15.368,6.23-29.281,16.301-39.352l-23.611-23.611
            c-16.115,16.112-26.081,38.373-26.081,62.963c0,49.178,39.866,89.043,89.043,89.043s89.043-39.866,89.043-89.043
            C345.043,231.411,335.077,209.15,318.963,193.037z"
    />
  </svg>
);

export const HondurasBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#338AF3" }}
        d="M256,512c101.493,0,189.19-59.065,230.598-144.696H25.402C66.81,452.935,154.506,512,256,512z"
      />
      <path
        style={{ fill: "#338AF3" }}
        d="M256,0C154.506,0,66.81,59.065,25.402,144.696h461.195C445.19,59.065,357.493,0,256,0z"
      />
      <polygon
        style={{ fill: "#338AF3" }}
        points="157.545,166.957 165.833,192.466 192.654,192.466 170.956,208.23 179.245,233.739 
            157.545,217.974 135.845,233.739 144.134,208.23 122.435,192.466 149.256,192.466 	"
      />
      <polygon
        style={{ fill: "#338AF3" }}
        points="157.545,278.261 165.833,303.771 192.654,303.771 170.956,319.535 179.245,345.043 
            157.545,329.278 135.845,345.043 144.134,319.535 122.435,303.771 149.256,303.771 	"
      />
      <polygon
        style={{ fill: "#338AF3" }}
        points="354.455,166.957 362.744,192.466 389.565,192.466 367.866,208.23 376.155,233.739 
            354.455,217.974 332.755,233.739 341.044,208.23 319.345,192.466 346.167,192.466 	"
      />
      <polygon
        style={{ fill: "#338AF3" }}
        points="354.455,278.261 362.744,303.771 389.565,303.771 367.866,319.535 376.155,345.043 
            354.455,329.278 332.755,345.043 341.044,319.535 319.345,303.771 346.167,303.771 	"
      />
      <polygon
        style={{ fill: "#338AF3" }}
        points="256,222.609 264.289,248.119 291.11,248.119 269.411,263.883 277.7,289.391 256,273.626 
            234.3,289.391 242.589,263.883 220.89,248.119 247.711,248.119 	"
      />
    </g>
  </svg>
);

export const MexicoBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#D80027" }}
      d="M512,256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935,445.19,512,357.494,512,256z"
    />
    <g>
      <path
        style={{ fill: "#6DA544" }}
        d="M0,256c0,101.494,59.065,189.19,144.696,230.598V25.402C59.065,66.81,0,154.506,0,256z"
      />
      <path
        style={{ fill: "#6DA544" }}
        d="M189.217,256c0,36.883,29.9,66.783,66.783,66.783s66.783-29.9,66.783-66.783v-22.261H189.217V256z"
      />
    </g>
    <path
      style={{ fill: "#FF9811" }}
      d="M345.043,211.478h-66.783c0-12.294-9.967-22.261-22.261-22.261s-22.261,9.967-22.261,22.261h-66.783
            c0,12.295,10.709,22.261,23.002,22.261h-0.741c0,12.295,9.966,22.261,22.261,22.261c0,12.295,9.966,22.261,22.261,22.261h44.522
            c12.295,0,22.261-9.966,22.261-22.261c12.295,0,22.261-9.966,22.261-22.261h-0.742C334.335,233.739,345.043,223.773,345.043,211.478
            z"
    />
  </svg>
);

export const PanamaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#0052B4" }}
      d="M0,256c0,141.384,114.616,256,256,256c0-97.355,0-256,0-256S89.043,256,0,256z"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M256,0c141.384,0,256,114.616,256,256c-97.355,0-256,0-256,0S256,89.043,256,0z"
    />
    <polygon
      style={{ fill: "#0052B4" }}
      points="152.389,89.043 168.966,140.061 222.609,140.061 179.211,171.591 195.787,222.609 
            152.389,191.078 108.991,222.609 125.567,171.591 82.169,140.061 135.812,140.061 "
    />
    <polygon
      style={{ fill: "#D80027" }}
      points="359.611,289.391 376.188,340.409 429.831,340.409 386.432,371.939 403.009,422.957 
            359.611,391.426 316.213,422.957 332.789,371.939 289.391,340.409 343.034,340.409 "
    />
  </svg>
);

export const ParaguayBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#D80027" }}
      d="M256,0C154.506,0,66.81,59.065,25.402,144.696h461.195C445.19,59.065,357.493,0,256,0z"
    />
    <path
      style={{ fill: "#0052B4" }}
      d="M256,512c101.493,0,189.19-59.065,230.598-144.696H25.402C66.81,452.935,154.506,512,256,512z"
    />
    <path
      style={{ fill: "#6DA544" }}
      d="M318.963,181.907l-23.611,23.611c10.071,10.071,16.301,23.984,16.301,39.352
            c0,30.736-24.917,55.652-55.652,55.652s-55.652-24.917-55.652-55.652c0-15.368,6.23-29.281,16.301-39.352l-23.611-23.611
            c-16.115,16.112-26.081,38.373-26.081,62.963c0,49.178,39.866,89.043,89.043,89.043s89.043-39.866,89.043-89.043
            C345.043,220.28,335.077,198.019,318.963,181.907z"
    />
    <polygon
      style={{ fill: "#FFDA44" }}
      points="256,211.478 264.289,236.988 291.11,236.988 269.411,252.752 277.7,278.261 256,262.496 
            234.3,278.261 242.589,252.752 220.89,236.988 247.711,236.988 "
    />
  </svg>
);

export const PeruBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#D80027" }}
        d="M512,256c0-110.07-69.472-203.906-166.957-240.076v480.155C442.528,459.906,512,366.072,512,256z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M0,256c0,110.072,69.472,203.906,166.957,240.078V15.924C69.472,52.094,0,145.93,0,256z"
      />
    </g>
  </svg>
);

export const PuertoRicoBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <g>
      <path
        style={{ fill: "#D80027" }}
        d="M256,0C172.248,0,97.893,40.223,51.189,102.4h409.622C414.107,40.223,339.752,0,256,0z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M256,512c83.752,0,158.107-40.223,204.811-102.4H51.189C97.893,471.777,172.248,512,256,512z"
      />
      <path
        style={{ fill: "#D80027" }}
        d="M0,256c0,17.535,1.768,34.657,5.127,51.2h501.748C510.234,290.657,512,273.535,512,256
                s-1.768-34.657-5.126-51.2H5.127C1.768,221.343,0,238.465,0,256z"
      />
    </g>
    <path
      style={{ fill: "#0052B4" }}
      d="M74.98,74.98c-99.974,99.974-99.974,262.065,0,362.04C116.293,395.707,156.026,355.974,256,256
            L74.98,74.98z"
    />
    <polygon
      style={{ fill: "#F0F0F0" }}
      points="103.61,189.217 120.185,240.233 173.831,240.233 130.433,271.765 147.007,322.783 
            103.61,291.252 60.211,322.783 76.789,271.765 33.391,240.233 87.033,240.233 "
    />
  </svg>
);

export const SpainBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <mask id="a">
      <circle cx="256" cy="256" r="256" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path fill="#ffda44" d="m0 128 256-32 256 32v256l-256 32L0 384Z" />
      <path fill="#d80027" d="M0 0h512v128H0zm0 384h512v128H0z" />
      <g fill="#eee">
        <path d="M144 304h-16v-80h16zm128 0h16v-80h-16z" />
        <ellipse cx="208" cy="296" rx="48" ry="32" />
      </g>
      <g fill="#d80027">
        <rect width="16" height="24" x="128" y="192" rx="8" />
        <rect width="16" height="24" x="272" y="192" rx="8" />
        <path d="M208 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z" />
      </g>
      <rect width="32" height="16" x="120" y="208" fill="#ff9811" ry="8" />
      <rect width="32" height="16" x="264" y="208" fill="#ff9811" ry="8" />
      <rect width="32" height="16" x="120" y="304" fill="#ff9811" rx="8" />
      <rect width="32" height="16" x="264" y="304" fill="#ff9811" rx="8" />
      <path
        fill="#ff9811"
        d="M160 272v24c0 8 4 14 9 19l5-6 5 10a21 21 0 0 0 10 0l5-10 5 6c6-5 9-11 9-19v-24h-9l-5 8-5-8h-10l-5 8-5-8z"
      />
      <path d="M122 252h172m-172 24h28m116 0h28" />
      <path
        fill="#d80027"
        d="M122 248a4 4 0 0 0-4 4 4 4 0 0 0 4 4h172a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 24a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm144 0a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4z"
      />
      <path
        fill="#eee"
        d="M196 168c-7 0-13 5-15 11l-5-1c-9 0-16 7-16 16s7 16 16 16c7 0 13-4 15-11a16 16 0 0 0 17-4 16 16 0 0 0 17 4 16 16 0 1 0 10-20 16 16 0 0 0-27-5c-3-4-7-6-12-6zm0 8c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm24 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm-44 10 4 1 4 8c0 4-4 7-8 7s-8-3-8-8c0-4 4-8 8-8zm64 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-7l4-8z"
      />
      <path fill="none" d="M220 284v12c0 7 5 12 12 12s12-5 12-12v-12z" />
      <path fill="#ff9811" d="M200 160h16v32h-16z" />
      <path fill="#eee" d="M208 224h48v48h-48z" />
      <path
        fill="#d80027"
        d="m248 208-8 8h-64l-8-8c0-13 18-24 40-24s40 11 40 24zm-88 16h48v48h-48z"
      />
      <rect width="20" height="32" x="222" y="232" fill="#d80027" rx="10" ry="10" />
      <path
        fill="#ff9811"
        d="M168 232v8h8v16h-8v8h32v-8h-8v-16h8v-8zm8-16h64v8h-64z"
      />
      <g fill="#ffda44">
        <circle cx="186" cy="202" r="6" />
        <circle cx="208" cy="202" r="6" />
        <circle cx="230" cy="202" r="6" />
      </g>
      <path
        fill="#d80027"
        d="M169 272v43a24 24 0 0 0 10 4v-47h-10zm20 0v47a24 24 0 0 0 10-4v-43h-10z"
      />
      <g fill="#338af3">
        <circle cx="208" cy="272" r="16" />
        <rect width="32" height="16" x="264" y="320" ry="8" />
        <rect width="32" height="16" x="120" y="320" ry="8" />
      </g>
    </g>
  </svg>
);

export const UruguayBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <circle style={{ fill: "#F0F0F0" }} cx="256" cy="256" r="256" />
    <path
      id="SVGCleanerId_0"
      style={{ fill: "#338AF3" }}
      d="M256,189.217h247.181c-6.419-23.814-16.175-46.255-28.755-66.783H256V189.217z"
    />
    <g>
      <path
        style={{ fill: "#338AF3" }}
        d="M96.643,456.348h318.713c23.363-18.608,43.399-41.21,59.069-66.783H37.574
                C53.245,415.137,73.281,437.74,96.643,456.348z"
      />
      <path
        style={{ fill: "#338AF3" }}
        d="M256,0c0,22.261,0,55.652,0,55.652h159.357C371.627,20.824,316.249,0,256,0z"
      />
    </g>
    <g>
      <path
        id="SVGCleanerId_0_1_"
        style={{ fill: "#338AF3" }}
        d="M256,189.217h247.181c-6.419-23.814-16.175-46.255-28.755-66.783H256
                V189.217z"
      />
    </g>
    <path
      style={{ fill: "#338AF3" }}
      d="M0,256c0,23.107,3.08,45.489,8.819,66.783h494.363C508.92,301.489,512,279.107,512,256H0z"
    />
    <polygon
      style={{ fill: "#FFDA44" }}
      points="222.609,149.821 191.343,164.528 207.992,194.808 174.042,188.314 169.74,222.609 
            146.094,197.385 122.446,222.609 118.145,188.314 84.195,194.806 100.843,164.527 69.579,149.821 100.844,135.116 84.195,104.836 
            118.144,111.33 122.447,77.035 146.094,102.259 169.741,77.035 174.042,111.33 207.993,104.836 191.344,135.117 "
    />
  </svg>
);

export const VenezuelaBall = (props: SvgProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    style={{ background: "new 0 0 512 512" }}
  >
    <path
      style={{ fill: "#0052B4" }}
      d="M486.598,367.304C502.873,333.648,512,295.891,512,256s-9.127-77.648-25.402-111.304L256,122.435
            L25.402,144.696C9.127,178.352,0,216.109,0,256s9.127,77.648,25.402,111.304L256,389.565L486.598,367.304z"
    />
    <path
      style={{ fill: "#D80027" }}
      d="M256,512c101.493,0,189.19-59.065,230.598-144.696H25.402C66.81,452.935,154.506,512,256,512z"
    />
    <g>
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="443.367,306.252 429.846,316.818 435.714,332.939 421.487,323.346 407.965,333.91 
                412.693,317.415 398.468,307.819 415.617,307.221 420.349,290.726 426.219,306.85 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="408.741,246.282 399.65,260.835 410.681,273.977 394.03,269.831 384.936,284.38 
                383.737,267.263 367.088,263.11 383,256.681 381.804,239.563 392.833,252.71 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="355.694,201.769 352.128,218.554 366.988,227.134 349.923,228.933 346.355,245.713 
                339.375,230.039 322.308,231.83 335.062,220.348 328.083,204.674 342.943,213.252 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="290.622,178.087 293.013,195.08 309.91,198.058 294.488,205.584 296.877,222.576 
                284.957,210.232 269.531,217.754 277.589,202.601 265.668,190.259 282.568,193.238 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="221.377,178.087 229.434,193.238 246.33,190.257 234.413,202.604 242.466,217.756 
                227.044,210.232 215.122,222.573 217.513,205.584 202.09,198.062 218.989,195.08 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="156.305,201.769 169.059,213.252 183.916,204.674 176.939,220.354 189.69,231.834 
                172.625,230.039 165.643,245.713 162.08,228.93 145.014,227.138 159.874,218.556 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="103.258,246.282 119.171,252.71 130.197,239.566 129.004,256.687 144.912,263.116 
                128.263,267.265 127.064,284.38 117.974,269.831 101.323,273.98 112.353,260.835 	"
      />
      <polygon
        style={{ fill: "#F0F0F0" }}
        points="68.634,306.252 85.786,306.85 91.652,290.728 96.387,307.224 113.534,307.823 
                99.308,317.419 104.036,333.913 90.516,323.349 76.289,332.942 82.158,316.818 	"
      />
    </g>
    <path
      style={{ fill: "#FFDA44" }}
      d="M486.598,144.696C445.19,59.065,357.493,0,256,0C154.506,0,66.81,59.065,25.402,144.696H486.598z"
    />
  </svg>
);
