interface MessageTypesInterface {
  id: number
  code: string
  name: string
}

const helpMessageTypes: MessageTypesInterface[] = [
  { id: 0, code: "help-messageType-add-sites", name: "Agregar sitios al monitoreo" },
  {
    id: 1,
    code: "help-messageType-abm",
    name: "Alta, baja y/o modificación de usuarios",
  },
  { id: 2, code: "help-messageType-campus", name: "Campus" },
  { id: 3, code: "help-messageType-courses", name: "Capacitación" },
  { id: 4, code: "help-messageType-comercial-contact", name: "Contrato comercial" },
  { id: 5, code: "help-messageType-classPauta", name: "Clasificación de pauta" },
  { id: 6, code: "help-messageType-API", name: "Integración API" },
  { id: 7, code: "help-messageType-method", name: "Metodología y estadística" },
  { id: 8, code: "help-messageType-reports", name: "Reportes" },
  {
    id: 9,
    code: "help-messageType-cant-access",
    name: "No puedo acceder a adCuality",
  },
  { id: 10, code: "help-messageType-RTS", name: "RTS - inversión" },
  { id: 11, code: "help-messageType-other", name: "Otro" },
]

export default helpMessageTypes
