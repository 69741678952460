import { createBrowserHistory } from "history";
import { LoginState } from "./login/types";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import LogRocket from "logrocket";

import {
  persistStore,
  persistReducer,
  Persistor,
  createTransform,
  PersistConfig,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { GeneralState } from "./general/types";
import { CustomReportParams, DataState } from "./data/types";
import { SearchState } from "./search/types";
import { RootState } from "typesafe-actions";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

export const history = createBrowserHistory();

const getStateTransliterated = (state: DataState): DataState => {
  return {
    ...state,
    savedReports: [
      ...state.savedReports.map<CustomReportParams>((item) => {
        item.dateCreated = new Date(item.dateCreated);
        item.filters.endDate = new Date(item.filters.endDate);
        item.filters.startDate = new Date(item.filters.startDate);
        return item;
      }),
    ],
  };
};

const getSearchStateTransliterated = (state: SearchState): SearchState => {
  return {
    ...state,
    startDate: new Date(`${state.startDate}T04:00:00`),
    endDate: new Date(`${state.endDate}T04:00:00`),
  };
};

const DateTransform = createTransform(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (inbound: DataState, key): DataState => {
    return getStateTransliterated(inbound);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (outbound: DataState, key) => {
    return getStateTransliterated(outbound);
  },
  { whitelist: ["data"] }
);

const SearchDateTransform = createTransform(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (inbound: SearchState, key): SearchState => {
    return getSearchStateTransliterated(inbound);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (outbound: SearchState, key) => {
    return getSearchStateTransliterated(outbound);
  },
  { whitelist: ["search"] }
);

/**
 * Interfaz de estado global de la app. Combinacion de todos los estados.
 */
export interface ApplicationState {
  loginState: LoginState;
  generalState: GeneralState;
}

/**
 * Configuración para persist-redux. Reducers en whitelist se preservan, reducers en blacklist, se rehidratan con
 * initial state en cada refresh.
 */
const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  whitelist: ["login", "navigation", "search"],
  blacklist: ["login.userMessages", "search.countries"],
  transforms: [DateTransform, SearchDateTransform],
  version: 10.2,
};

/**
 * Saga middleware para llamadas asíncronas.
 */
const sagaMiddleware = createSagaMiddleware();

/**
 * Estado inicial. Se le asigna a los estados blacklisteados por redux-persist en cada refresh.
 */
const initialState = {};

export default function configureStore() {
  /**
   * Reducers wrapeados en persistReducer para que funcione redux-persist
   */
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  /**
   * Instancia de la store de redux aplicando el middleware y redux-persist.
   */
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        LogRocket.reduxMiddleware()
      )
    )
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./index.ts", () => {
      store.replaceReducer(persistedReducer);
    });
  }

  return store;
}

export const store = configureStore();

/**
 * Objeto persitor que permite guardar el estado. Hay que pasarlo a PersistGate en App.tsx
 */
export const persistor: Persistor = persistStore(store);

/**
 * Run del middleware.
 */
sagaMiddleware.run(rootSaga);
