import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  DialogContent,
  Typography,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles, createStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import ImageCarousel, {
  ElementCarouselItem,
} from "../../dashboard/data/ImageCarousel";
import {
  FacebookPostStyles,
  FacebookStoryStyles,
  TwitterStyles,
} from "../../../store/data/types";
import { CampaignMediaTypes } from "../../../utils/CampaignMediaTypes";
import { FacebookPost } from "../FacebookPostView";
import { FacebookStory } from "../FacebookStoryView";
import { TwitterStyle } from "../TwitterStyleView";
import { MediaInterface } from "./Creativity";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

interface GalleryProps {
  isOpen: boolean;
  toggle: () => void;
  media: MediaInterface;
  title: string;
}

export const CreativityGallery = (props: GalleryProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  let carouselData: ElementCarouselItem[] = [];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const closeDialog = () => {
    setActiveStep(0);
    props.toggle();
  };

  if (props.media !== undefined) {
    switch (props.media.style) {
      case CampaignMediaTypes.youtube:
        carouselData = [
          {
            label: "Screenshot",
            component: (
              <img
                className={classes.img}
                src={props.media.screenshot}
                alt={props.media.advertiser}
              />
            ),
          },
          {
            label: "Creativity",
            component: (
              <div className={classes.youtube}>
                {props.media.creativity.includes("https://youtube.com/embed/") ? (
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${
                      props.media.creativity.split("https://youtube.com/embed/")[1]
                    }`}
                    width={"100%"}
                    height={"100%"}
                  />
                ) : (
                  <img
                    className={classes.img}
                    src={props.media.creativity}
                    alt={props.media.advertiser}
                  />
                )}
              </div>
            ),
          },
        ];
        break;
      case CampaignMediaTypes.facebookPost:
        carouselData = [
          {
            label: "",
            component: (
              <FacebookPost
                facebookPostAttributes={
                  props.media.styleAttributes as FacebookPostStyles
                }
                mediaSource={props.media.screenshot}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.facebookStory:
        carouselData = [
          {
            label: "",
            component: (
              <FacebookStory
                facebookStoryAttributes={
                  props.media.styleAttributes as FacebookStoryStyles
                }
                advertiser={props.media.advertiser}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.video:
        carouselData = [
          {
            label: "Screenshot",
            component: (
              <img
                className={classes.img}
                src={props.media.screenshot}
                alt={props.media.advertiser}
              />
            ),
          },
          {
            label: "",
            component: (
              <FacebookStory
                facebookStoryAttributes={
                  props.media.styleAttributes as FacebookStoryStyles
                }
                advertiser={props.media.advertiser}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.twitter:
        carouselData = [
          {
            label: "",
            component: (
              <TwitterStyle
                twitterAttributes={props.media.styleAttributes as TwitterStyles}
                advertiser={props.media.advertiser}
              />
            ),
          },
        ];
        break;
      default:
        if (props.media.screenshot !== "") {
          carouselData = [
            {
              label: "Screenshot",
              component: (
                <img
                  className={classes.img}
                  src={props.media.screenshot}
                  alt={props.media.advertiser}
                />
              ),
            },
          ];
        }
        carouselData.push({
          label: "Creativity",
          component: (
            <img
              className={classes.img}
              src={props.media.creativity}
              alt={props.media.advertiser}
            />
          ),
        });
    }
    const maxSteps = carouselData.length;

    return (
      <Dialog
        open={props.isOpen}
        onClose={closeDialog}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent
          style={{
            padding: "0 24px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={10}>
              <ImageCarousel data={carouselData} activeStep={activeStep} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <strong>{formatMessage("spotslistPage.column.date")}:</strong>{" "}
                {props.media.date}
              </Typography>
              <Typography>
                <strong>{formatMessage("spotslistPage.column.platform")}:</strong>{" "}
                {props.media.platform}
              </Typography>
              <Typography>
                <strong>{formatMessage("spotslistPage.column.category")}:</strong>{" "}
                {props.media.category}
              </Typography>
              <Typography>
                <strong>{formatMessage("spotslistPage.column.format")}:</strong>{" "}
                {props.media.format}
              </Typography>
              <Typography>
                <strong>{formatMessage("spotslistPage.column.industry")}:</strong>{" "}
                {props.media.industry}
              </Typography>
              <Typography>
                <strong>pViewability:</strong>{" "}
                {props.media.pViewability ? props.media.pViewability : "N/A"}
              </Typography>
              <Typography variant={"body2"} component="small" color="textSecondary">
                *{formatMessage("campaignsPage.moreInfo.gmtZone")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing>
          <div className={classes.growActions}>
            <Button
              size="small"
              color="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
            <span>
              {activeStep + 1} / {maxSteps}
            </span>
            <Button
              size="small"
              color="secondary"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </div>
          <Button className={classes.growButton} onClick={closeDialog}>
            <FormattedMessage id={"navigation.actions.close"} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      height: "auto",
      maxWidth: "100%",
    },
    youtube: {
      height: "100%",
      position: "relative",
      width: "100%",
      "@media(min-width: 457px)": {
        height: "65vh",
        width: "100%",
      },
      "@media(min-width: 992px)": {
        width: "60vw",
      },
      "@media(min-width: 1440px)": {
        width: "50vw",
      },
    },
    growActions: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
    },
    growButton: {
      marginLeft: "10em",
      marginRight: "1em",
    },
  })
);
