import { Dispatch } from "redux"
import { set_sidebar_page, toggle_sidebar } from "../../../store/navigation/actions"
import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import HeaderView from "./HeaderView"
import { injectIntl, InjectedIntlProps } from "react-intl"
import { logout } from "../../../store/login/actions"
import { clearAllParameters } from "../../../store/search/actions"

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  login_state: state.login,
  token: state.login.token,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar()),
    log_out: () => {
      dispatch(logout())
      dispatch(clearAllParameters())
    },
    set_sidebar_page: (page: string) => dispatch(set_sidebar_page(page)),
  }
}

export type HeaderProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HeaderView))
