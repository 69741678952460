import React from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Divider,
  List,
} from "@material-ui/core"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"
import cx from "clsx"
import Message from "./Message"
import { format } from "date-fns"
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 4,
      minWidth: 256,
      textAlign: "center",
    },
    heading: {
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      // color: palette.grey[500],
      marginBottom: "0.875em",
      textAlign: "start",
      fontWeight: "normal",
    },
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflowY: "scroll",
      maxHeight: "435px",
    },
    inline: {
      display: "inline",
    },
  })
)

const MessagesCard = () => {
  const styles = useStyles()
  const formatMessage = useFormatMessage()
  const messages = useSelector((state: RootState) => state.login.userMessages);

  return (
    <Card className={cx(styles.card)}>
      <CardContent>
        <h3 className={styles.heading}>
          {formatMessage("accountPage.tabs.messages")}
        </h3>
        <List className={styles.root}>
          {
            messages.length > 0 ?
            messages.map((message, i) => (
              <React.Fragment key={`fragment-${i}`}>
                <Message
                  key={`message-${i}`}
                  title={message.name}
                  date={message.createdAt}
                  message={message.html}
                />
                <Divider key={`divider-${i}`} variant="inset" component="li" />
              </React.Fragment>
            )):
            (
              <>
                <Message
                  title={"Cargando mensajes"}
                  date={format(new Date(), "dd/MM/yyyy")}
                  message={" — Cargando mensajes nuevos."}
                />
                <Divider variant="inset" component="li" />
              </>
            )
          }
        </List>
      </CardContent>
    </Card>
  )
}

export default MessagesCard
