import TablePagination from "@material-ui/core/TablePagination";
import React from "react";
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
  TextField,
  Button,
} from "@material-ui/core";
import LastPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { isMobile } from "react-device-detect";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
    pages: {
      display: "inline-block",
      padding: "1em 2em",
      width: "auto",
      verticalAlign: "middle",
    },
    formControl: {
      margin: theme.spacing(1),
      width: 50,
      verticalAlign: "middle",
    },
    data: {
      marginBottom: theme.spacing(6),
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const intl = useFormatMessage();
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const [newPage, setNewPage] = React.useState(page);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onChangePage(event, 1);
    setNewPage(1);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newPageAction = parseInt(page.toString(), 10) - 1;
    onChangePage(event, newPageAction);
    setNewPage(newPageAction);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newPageAction = parseInt(page.toString(), 10) + 1;
    onChangePage(event, newPageAction);
    setNewPage(newPageAction);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const lastPage = Math.max(0, Math.ceil(count / rowsPerPage));
    onChangePage(event, lastPage);
    setNewPage(lastPage);
  };

  const handleChangePage = (newPageAction: number) => {
    if (page !== newPageAction) {
      const lastPage = Math.max(0, Math.ceil(count / rowsPerPage));
      if (newPageAction > lastPage) {
        onChangePage(null, lastPage);
        setNewPage(lastPage);
      } else {
        onChangePage(null, newPageAction);
      }
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewPage(event.target.value as number);
  };

  return (
    <div className={classes.root}>
      {isMobile ? (
        <div>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={parseInt(page.toString(), 10) === 1}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={
              parseInt(page.toString(), 10) >= Math.ceil(count / rowsPerPage) - 1
            }
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </div>
      ) : (
        <div>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={parseInt(page.toString(), 10) === 1}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={parseInt(page.toString(), 10) === 1}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <div className={classes.pages}>
            <span>{intl("navigation.tablePagination.page")} </span>
            <TextField
              className={classes.formControl}
              id="page-select"
              value={newPage}
              onChange={handleChangeInput}
            />
            <span>
              {" "}
              {intl("navigation.tablePagination.of")}{" "}
              {Math.max(0, Math.ceil(count / rowsPerPage))}{" "}
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleChangePage(newPage)}
              size="small"
            >
              {intl("navigation.tablePagination.go")}
            </Button>
          </div>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={
              parseInt(page.toString(), 10) >= Math.ceil(count / rowsPerPage)
            }
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={
              parseInt(page.toString(), 10) >= Math.ceil(count / rowsPerPage)
            }
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      )}
    </div>
  );
}

interface TablePagingProps {
  count: number;
  rowsPerPage: number;
  currentPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  isSnap?: boolean;
}

const TablePaging = (props: TablePagingProps) => {
  const intl = useFormatMessage();
  const classes = useStyles1();

  return (
    <TablePagination
      className={isMobile ? classes.data : undefined}
      rowsPerPageOptions={props.isSnap ? [20] : [15, 20, 50]}
      component="div"
      count={props.count}
      rowsPerPage={props.rowsPerPage}
      labelDisplayedRows={() => {
        const from =
          props.count === 0 ? 0 : (props.currentPage - 1) * props.rowsPerPage + 1;
        const to =
          props.count !== -1
            ? Math.min(props.count, props.currentPage * props.rowsPerPage)
            : props.currentPage * props.rowsPerPage;
        return `${from}-${to} ${intl("navigation.tablePagination.of")} ${
          props.count !== -1
            ? props.count
            : `${intl("navigation.tablePagination.moreThan")} ${to}`
        }`;
      }}
      labelRowsPerPage={props.label}
      page={props.currentPage}
      backIconButtonProps={{
        "aria-label": "previous page",
      }}
      nextIconButtonProps={{
        "aria-label": "next page",
      }}
      SelectProps={{
        inputProps: { "aria-label": "campaigns per page" },
        native: isMobile,
      }}
      onChangePage={props.handleChangePage}
      onChangeRowsPerPage={props.handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default TablePaging;
