import React, { useState } from "react"
import { RootState } from "typesafe-actions"
import { useSelector, useDispatch } from "react-redux"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  createStyles,
  RadioGroup,
  Radio,
} from "@material-ui/core"
import { toggle_countryPicker } from "../../../store/navigation/actions"
import countries from "../../../utils/Countries"
import { set_countries } from "../../../store/search/actions"

interface CountrySelectorProps {
  countries: string[]
  setCountries: React.Dispatch<React.SetStateAction<string[]>>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(1),
    },
  })
)

const CountryCheckboxes = (props: CountrySelectorProps) => {
  const styles = useStyles()
  const formatMessage = useFormatMessage()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newCountries = props.countries
    if (event.target.checked) {
      newCountries.push(event.target.name)
    } else {
      newCountries = props.countries.filter(
        (country) => country !== event.target.name
      )
    }
    props.setCountries(newCountries)
  }
  return (
    <div className={styles.root}>
      <FormControl className={styles.formControl}>
        <FormGroup>
          {countries.slice(0, countries.length / 2).map((country) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={props.countries.includes(country.name)}
                  name={country.name}
                  onChange={handleChange}
                />
              }
              label={`${country.emoji} ${formatMessage(country.name)}`}
              key={`country-${formatMessage(country.name)}-form-control`}
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormControl className={styles.formControl}>
        <FormGroup>
          {countries
            .slice(countries.length / 2 + 1, countries.length)
            .map((country) => (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={props.countries.includes(country.name)}
                    name={country.name}
                    onChange={handleChange}
                  />
                }
                label={`${country.emoji} ${formatMessage(country.name)}`}
                key={`country-${formatMessage(country.name)}-form-control-second`}
              />
            ))}
        </FormGroup>
      </FormControl>
    </div>
  )
}

const CountryRadios = (props: CountrySelectorProps) => {
  const formatMessage = useFormatMessage()
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <FormControl>
        <RadioGroup
          name={"countries"}
          value={props.countries[0]}
          onChange={(event, value) => props.setCountries([value])}
        >
          {countries.slice(0, countries.length / 2).map((country) => (
            <FormControlLabel
              key={country.name}
              control={<Radio />}
              label={`${country.emoji} ${formatMessage(country.name)}`}
              value={country.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <RadioGroup
          name={"countries"}
          value={props.countries[0]}
          onChange={(event, value) => props.setCountries([value])}
        >
          {countries
            .slice(countries.length / 2 + 1, countries.length)
            .map((country) => (
              <FormControlLabel
                key={country.name}
                control={<Radio />}
                label={`${country.emoji} ${formatMessage(country.name)}`}
                value={country.name}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

const CountryPickerDialog = () => {
  const { countries: selectedCountries } = useSelector(
    (state: RootState) => state.search
  )
  const { isCountryPickerOpen } = useSelector((state: RootState) => state.navigation)
  const formatMessage = useFormatMessage()
  const [newCountries, setNewCountries] = useState<string[]>(selectedCountries)
  const { selectedPage } = useSelector((state: RootState) => state.navigation)
  const dispatch = useDispatch()

  const handleOk = () => {
    dispatch(set_countries(newCountries))
    dispatch(toggle_countryPicker())
  }

  return (
    <Dialog
      open={isCountryPickerOpen}
      onClose={() => dispatch(toggle_countryPicker())}
    >
      <DialogTitle id="form-dialog-title">
        {formatMessage("search.dialog.countryPicker.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage("search.dialog.countryPicker.description")}
        </DialogContentText>
        {["campaigns", "spotlist", "snapshots"].includes(selectedPage) ? (
          <CountryRadios countries={newCountries} setCountries={setNewCountries} />
        ) : (
          <CountryCheckboxes
            countries={newCountries}
            setCountries={setNewCountries}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOk()} color={"primary"}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CountryPickerDialog
