import { action } from "typesafe-actions"
import { LoginActionTypes, TermsAcceptedAtInterface, UserMessagesInterface } from "./types"
import * as H from "history"

//action creators

export const loginRequest = (email: string, password: string, history: H.History) =>
  action(LoginActionTypes.LOGIN_REQUEST, { email, password, history })

export const loginSuccess = (token: string) =>
  action(LoginActionTypes.LOGIN_SUCCESS, { token })

export const loginFailure = () => action(LoginActionTypes.LOGIN_FAILURE)

export const logout = () => action(LoginActionTypes.LOGOUT)

export const forgotPassword = () => action(LoginActionTypes.FORGOT_PASSWORD)

export const cancelResetPassword = () => action(LoginActionTypes.CANCEL_RESET)

export const resetPasswordRequest = (email: string) =>
  action(LoginActionTypes.RESET_PASSWORD_REQUEST, { email })

export const resetPasswordSuccess = () =>
  action(LoginActionTypes.RESET_PASSWORD_SUCCESS)

export const changeUsernameAndEmailRequest = (name: string, surname: string, message: string) =>
  action(LoginActionTypes.CHANGE_USERNAME_EMAIL_REQUEST, { name, surname, message })

export const changeUsernameAndEmailSuccess = (
  name: string,
  surname: string
) =>
  action(LoginActionTypes.CHANGE_USERNAME_EMAIL_SUCCESS, { name, surname })

export const change_password_request = (
  currentPassword: string,
  newPassword: string
) =>
  action(LoginActionTypes.CHANGE_PASSWORD_REQUEST, { currentPassword, newPassword })

export const changePasswordSuccess = (newPassword: string) =>
  action(LoginActionTypes.CHANGE_PASSWORD_SUCCESS, { newPassword })

export const setAccountExpired = (message: UserMessagesInterface) =>
  action(LoginActionTypes.SET_EXPIRED, { message })

export const setTokenExpired = () => action(LoginActionTypes.SESSION_EXPIRED)

export const setRoles = (
  roles: Array<string>,
  name: string,
  surname: string,
  termsAccepted: boolean,
  termsAcceptedAt: TermsAcceptedAtInterface
) => action(LoginActionTypes.SET_ROLES, { roles, name, surname, termsAccepted, termsAcceptedAt })
export const setCountries = (countries: Array<string>) => action(LoginActionTypes.SET_COUNTRIES, { countries })

export const acceptTermsRequest = () => action(LoginActionTypes.ACCEPT_TERMS_REQUEST);
export const acceptTermsSuccess = () => action(LoginActionTypes.ACCEPT_TERMS_SUCCESS);

export const getSystemMessages = (token: string) => action(LoginActionTypes.GET_USER_MESSAGES, { token })
export const setSystemMessages = (messages: UserMessagesInterface[]) =>
  action(LoginActionTypes.SET_USER_MESSAGES, { messages })
