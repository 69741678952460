import Airtable, { Attachment, Records, FieldSet } from "airtable";
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  createStyles,
  Grid,
  Link as MuiLink,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { Link } from "react-router-dom";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_TOKEN || "",
}).base(process.env.REACT_APP_AIRTABLE_DB_ID || "");

const reportStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(10, 0, 6),
    },
    large: {
      width: theme.spacing(40),
      height: theme.spacing(40),
    },
  })
);

export const ReportList = () => {
  const classes = reportStyles();
  const intl = useFormatMessage();
  const [reports, setReports] = useState<Records<FieldSet>>([]);

  const readData = (records: Records<FieldSet>) => {
    console.log(records);
    setReports((prev) => [...prev, ...records]);
  };

  useEffect(() => {
    async function fetchReports() {
      const data = await base("Reports")
        .select({
          view: "Grid view",
        })
        .all();

      readData(data);
    }

    fetchReports();
  }, []);

  return (
    <Grid container className={classes.container} justify="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{intl("marketing.report_list.title")}</Typography>
      </Grid>
      {reports.map((report, i) => (
        <Grid key={`${report.id}-${i}`} item xs={12} sm={6} md={4}>
          <Box display="flex" justifyContent="center" mt={5} mb={5}>
            <Link to={`/marketing/report/${report.id}`} component={MuiLink}>
              <Avatar alt={report.get("Name")?.toString()} src={(report.get("Flag Image") as Attachment[])?.[0].url || ""} className={classes.large} />
              <Box display="flex" mt={2} />
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textPrimary"
                align="center"
              >
                {report.get("Name")?.toString()}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" align="center">
                {report.get("Description")?.toString()}
              </Typography>
            </Link>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
