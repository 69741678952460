import React, { useEffect, useState } from "react";
import { HeaderProps } from "./HeaderContainer";
import {
  AppBar,
  createStyles,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircle as AccountIcon,
  ArrowBackIos as SignOutIcon,
  SettingsApplications,
} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import logo_white from "../../../assets/logo-white-10.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "react-router-dom";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { toggleDarkMode } from "../../../store/general/actions";
import { AdvancedFilters } from "../../../store/search/types";

const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    logotypeImage: {
      marginLeft: 15,
      width: 200,
    },
    appBar: {
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.primary.main,
      "@media print": {
        display: "none",
      },
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    headerMenu: {
      marginTop: theme.spacing(7),
    },
    headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
    },
    headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
    },
    headerIcon: {
      fontSize: 28,
      color: "rgb(255,255,255)",
    },
    headerIconCollapse: {
      color: "white",
    },
    profileMenu: {
      minWidth: 265,
    },
    profileMenuUser: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    profileMenuItem: {
      color: theme.palette.text.hint,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
    },
    profileMenuLink: {
      fontSize: 16,
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

export function getBadge(parameters: AdvancedFilters) {
  let badge = 0;
  if (parameters.formats.length > 0) {
    badge++;
  }
  if (parameters.advertisers.length > 0) {
    badge++;
  }
  if (parameters.platforms.length > 0) {
    badge++;
  }
  if (parameters.products.length > 0) {
    badge++;
  }
  if (parameters.publishers.length > 0) {
    badge++;
  }
  if (parameters.categories.length > 0) {
    badge++;
  }
  if (parameters.industries.length > 0) {
    badge++;
  }
  return badge;
}

const HeaderView = (props: HeaderProps) => {
  const classes = headerStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>();
  const { prefersDarkMode } = useSelector((state: RootState) => state.general);
  const { roles, name, surname } = useSelector((state: RootState) => state.login);
  const { log_out } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!roles || !Array.isArray(roles)) {
      log_out();
    }
  }, [roles, log_out]);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    log_out();
  }

  return (
    <AppBar className={classes.appBar} position={"fixed"}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => props.toggle_sidebar()}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo_white} className={classes.logotypeImage} alt={"adaCuality"} />
        <div className={classes.grow} />

        <Tooltip
          title={props.intl.formatMessage({
            id: prefersDarkMode
              ? "navigation.buttons.themeButtonDay"
              : "navigation.buttons.themeButtonNight",
          })}
        >
          <IconButton
            onClick={() => dispatch(toggleDarkMode())}
            color="inherit"
            className={classes.headerMenuButton}
          >
            {prefersDarkMode ? <BrightnessHighIcon /> : <Brightness4Icon />}
          </IconButton>
        </Tooltip>

        <Tooltip
          title={props.intl.formatMessage({ id: "navigation.sidebar.account" })}
        >
          <Button
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={handleClick}
            endIcon={<AccountIcon classes={{ root: classes.headerIcon }} />}
          >
            {`${name} ${surname}`}
          </Button>
        </Tooltip>
        <Menu
          id="profile-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.headerMenu}
          disableAutoFocusItem
          disableScrollLock={true}
        >
          <MenuItem component={Link} to={"/dashboard/account"} onClick={handleClose}>
            <SettingsIcon className={classes.profileMenuIcon} />{" "}
            {props.intl.formatMessage({
              id: "navigation.sidebar.accountSettings",
            })}
          </MenuItem>
          {roles && roles.some((item) => item === "ROLE_SUPER_ADMIN") ? (
            <MenuItem
              onClick={() =>
                window.open("https://www.adcuality.com/admin/dashboard", "_blank")
              }
            >
              <SettingsApplications className={classes.profileMenuIcon} /> Admin
              Dashboard
            </MenuItem>
          ) : (
            ""
          )}
          <MenuItem onClick={handleLogout}>
            <SignOutIcon className={classes.profileMenuIcon} />{" "}
            {props.intl.formatMessage({ id: "navigation.sidebar.logout" })}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderView;
