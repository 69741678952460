import { Dispatch } from "redux";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RootAction, RootState } from "typesafe-actions";

import { toggle_alertsDialog } from "../../../store/navigation/actions";
import AlertsDialog from "./AlertsDialogView";
import { AlertsInterface } from "../../../store/search/types";
import { createAlertRequest, editAlertRequest } from "../../../store/data/actions";

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  token: state.login.token,
  alerts: state.data.userAlerts,
  isUserAlerstLoading: state.data.isUserAlertsLoading,
  isUserAlertCreated: state.data.isUserAlertCreated,
  userAlertToEdit: state.data.userAlertToEdit,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  toggle_dialog: () => {
    dispatch(toggle_alertsDialog());
  },
  createAlert: (token: string, alert: AlertsInterface) => {
    dispatch(createAlertRequest(token, alert));
  },
  edit_alert: (token: string, alert: AlertsInterface) => {
    dispatch(editAlertRequest(token, alert.id, alert));
  },
});

export type AlertsReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps;

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AlertsDialog)
);
