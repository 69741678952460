import { LanguageSelectorProps } from "./LanguageSelectorContainer"
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Theme,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import Select from "@material-ui/core/Select"
import React from "react"
// import { isMobile } from "react-device-detect"
// import { COUNTRIES_BALLS } from "../../../utils/Countries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    ball: {
      marginRight: theme.spacing(1),
    },
  })
)

const LanguageSelectorView = (props: LanguageSelectorProps) => {
  const classes = useStyles()
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="outlined-age-native-simple">
        <FormattedMessage id={"loginPage.languageSelectorLabel"} />
      </InputLabel>
      <Select
        value={props.general_state.language}
        onChange={props.handle_language_selection}
      >
        <MenuItem value={"es"}>
          {/* <div className={classes.ball}>{COUNTRIES_BALLS['AR']}</div> */}
          {props.intl.formatMessage({ id: "loginPage.languageSelectorSpanish" })}
        </MenuItem>
        <MenuItem value={"en"}>
          {/* <div className={classes.ball}>{COUNTRIES_BALLS['US']}</div> */}
          {props.intl.formatMessage({ id: "loginPage.languageSelectorEnglish" })}
        </MenuItem>
        <MenuItem value={"pt"}>
            {/* <div className={classes.ball}>{COUNTRIES_BALLS['BR']}</div> */}
            {props.intl.formatMessage({ id: "loginPage.languageSelectorPortuguese" })}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default LanguageSelectorView
