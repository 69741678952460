import { call, put, takeEvery, fork, all, select } from "redux-saga/effects"
import {
  changePasswordSuccess,
  changeUsernameAndEmailSuccess,
  loginFailure,
  loginSuccess,
  resetPasswordSuccess,
  loginRequest,
  change_password_request,
  changeUsernameAndEmailRequest,
  getSystemMessages,
  setSystemMessages,
  acceptTermsSuccess,
} from "./actions"
import { LoginActionTypes, UserMessagesInterface } from "./types"
import {
  acceptTermsAPI,
  changeCurrentPassword,
  changeUsernameAndEmail,
  fetchSystemMessagesAPIv2,
  fetchUserToken,
  sendResetPasswordEmail,
} from "../../services/api"
import { RootAction, RootState } from "typesafe-actions"
import { setNotification } from "../navigation/actions"

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const token = yield call(
      fetchUserToken,
      action.payload.email,
      action.payload.password
    )
    yield put(loginSuccess(token))
    action.payload.history.push("/dashboard/shareofvoice")
  } catch (e) {
    yield put(loginFailure())
    console.log(e)
  }
}

function* handleReset(action: RootAction) {
  try {
    // @ts-expect-error
    const status = yield call(sendResetPasswordEmail, action.payload.email)
    console.log(status)
    yield put(resetPasswordSuccess())
  } catch (e) {
    console.log("ERROR ON RESET " + e)
  }
}

function* handleChangePassword(action: ReturnType<typeof change_password_request>) {
  try {
    const getToken = (state: RootState) => state.login.token
    const token = yield select(getToken)
    const status = yield call(
      changeCurrentPassword,
      action.payload.currentPassword,
      action.payload.newPassword,
      token
    )
    console.log(status)
    yield put(changePasswordSuccess(action.payload.newPassword))
  } catch (e) {
    console.log(e)
  }
}

function* handleChangeUsernameAndEmail(
  action: ReturnType<typeof changeUsernameAndEmailRequest>
) {
  try {
    const getToken = (state: RootState) => state.login.token
    const token = yield select(getToken)
    const getPassword = (state: RootState) => state.login.password
    const password = yield select(getPassword)
    const status = yield call(
      changeUsernameAndEmail,
      action.payload.name,
      action.payload.surname,
      password,
      token
    )
    console.log(status)
    yield put(
      changeUsernameAndEmailSuccess(action.payload.name, action.payload.surname)
    )
    yield put(setNotification("success", action.payload.message));
  } catch (e) {
    console.log(e)
  }
}

function* handleFetchSystemMessages(
  action: ReturnType<typeof getSystemMessages>
) {
  try {
    const messages = yield call(
      fetchSystemMessagesAPIv2,
      action.payload.token
    )
    yield put(setSystemMessages(messages as UserMessagesInterface[]));
  } catch (e) {
    console.log(e)
  }
}

function* handleAcceptSLATerms() {
  try {
    const getToken = (state: RootState) => state.login.token
    const token = yield select(getToken)
    yield call(
      acceptTermsAPI,
      token
    )
    yield put(acceptTermsSuccess());
    yield put(setNotification("success", "Terms accepted!"));
  } catch (e) {
    console.log(e)
    yield put(setNotification("error", "Error at accept terms, try later!"));
  }
}

function* watchLoginRequest() {
  yield takeEvery(LoginActionTypes.LOGIN_REQUEST, handleLogin)
}

function* watchResetPasswordRequest() {
  yield takeEvery(LoginActionTypes.RESET_PASSWORD_REQUEST, handleReset)
}

function* watchPasswordChangeRequest() {
  yield takeEvery(LoginActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword)
}

function* watchUsernameAndEmailChangeRequest() {
  yield takeEvery(
    LoginActionTypes.CHANGE_USERNAME_EMAIL_REQUEST,
    handleChangeUsernameAndEmail
  )
}

function* watchfetchSystemMessages() {
  yield takeEvery(LoginActionTypes.GET_USER_MESSAGES, handleFetchSystemMessages)
}

function* watchAcceptSLATerms() {
  yield takeEvery(LoginActionTypes.ACCEPT_TERMS_REQUEST, handleAcceptSLATerms)
}

function* loginSaga() {
  yield all([
    fork(watchLoginRequest),
    fork(watchResetPasswordRequest),
    fork(watchPasswordChangeRequest),
    fork(watchUsernameAndEmailChangeRequest),
    fork(watchfetchSystemMessages),
    fork(watchAcceptSLATerms),
  ])
}

export default loginSaga
