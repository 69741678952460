import React from "react";
import clsx from "clsx";
import { Paper, Theme, IconButton, Menu, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import html2canvas from "html2canvas";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { toggle_investment_view } from "../../store/navigation/actions";
import { DataPages } from "../../utils/constants";

interface WidgetProps {
  header?: string;
  title: string | JSX.Element;
  children: any;
  snap?: boolean;
  handleDownloadCSV?: () => void;
}

type WidgetPropsType = WidgetProps & InjectedIntlProps;

const Widget = (props: WidgetPropsType) => {
  const amountView = useSelector((state: RootState) => state.navigation.amountView);
  const actualPage = useSelector(
    (state: RootState) => state.navigation.selectedPage
  );
  const dispatch = useDispatch();

  const handleChangeView = () => {
    handleCloseMenu();
    dispatch(toggle_investment_view());
  };

  function handleCloseMenu() {
    setAnchorEl(null);
    setMoreMenu(false);
  }

  function handleOpenMenu(anchorEl: HTMLButtonElement) {
    setAnchorEl(anchorEl);
    setMoreMenu(true);
  }

  function handleDownloadGraph() {
    window.scrollTo(0, 0);
    const input: HTMLElement | null = document.getElementById(
      props.title + "-printable"
    );
    if (input !== null) {
      html2canvas(input, {
        allowTaint: true,
        useCORS: true,
        backgroundColor: "rgba(0,0,0,0)",
        removeContainer: true,
        scale: 2,
      }).then((canvas: any) => {
        const screenshot = document.createElement("a");
        screenshot.href = canvas.toDataURL("image/png");
        screenshot.download = props.title + ".png";
        screenshot.click();
      });
    }
    handleCloseMenu();
  }

  function handlePrintGraph() {
    const newWindow = window.open("", "PRINT", "height=400,width=600");
    newWindow!.document.write(
      document.getElementById(props.title + "-printable")!.innerHTML
    );
    newWindow!.document.close();
    newWindow!.focus();
    newWindow!.print();
    newWindow!.close();
    handleCloseMenu();
  }

  function handleExportCSV() {
    if (props.handleDownloadCSV) {
      props.handleDownloadCSV();
    } else {
      console.log("This function to export graph is doesn't implemented yet!");
    }
  }

  const classes = useStyles();
  const [isMoreMenuOpen, setMoreMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  return (
    <div className={classes.widgetWrapper} id={props.title + "-printable"}>
      {props.snap ? (
        <div className={clsx(classes.paper)}>
          <div className={classes.widgetHeader}>
            {props.header ? (
              props.header
            ) : (
              <React.Fragment>
                <Typography variant={"subtitle1"} color="textSecondary">
                  {props.title}
                </Typography>
                <IconButton
                  color={"primary"}
                  className={classes.moreButton}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handleOpenMenu(event.currentTarget)
                  }
                >
                  <MoreIcon />
                </IconButton>
              </React.Fragment>
            )}
          </div>
          <div className={classes.widgetBody}>{props.children}</div>
        </div>
      ) : (
        <Paper className={classes.paper} classes={{ root: classes.widgetRoot }}>
          <div className={classes.widgetHeader}>
            {props.header ? (
              props.header
            ) : (
              <React.Fragment>
                <Typography variant={"subtitle1"} color="textSecondary">
                  {props.title}
                </Typography>
                <IconButton
                  color={"primary"}
                  className={classes.moreButton}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handleOpenMenu(event.currentTarget)
                  }
                >
                  <MoreIcon />
                </IconButton>
              </React.Fragment>
            )}
          </div>
          <div className={classes.widgetBody}>{props.children}</div>
        </Paper>
      )}
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={anchorEl}
        onClose={() => handleCloseMenu()}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => handleDownloadGraph()}>
          <Typography>
            {props.intl.formatMessage({ id: "navigation.actions.download" })}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handlePrintGraph()}>
          <Typography>
            {props.intl.formatMessage({ id: "navigation.actions.print" })}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleExportCSV()}>
          <Typography>
            {props.intl.formatMessage({ id: "navigation.actions.export" })}
          </Typography>
        </MenuItem>
        {actualPage === DataPages.investmentPage ? (
          <MenuItem onClick={handleChangeView}>
            <Typography>
              {props.intl.formatMessage(
                amountView
                  ? { id: "navigation.widget.actions.prints" }
                  : { id: "navigation.widget.actions.amount" }
              )}
            </Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  widgetWrapper: {
    background: "transparent",
    display: "flex",
    minHeight: "100%",
    width: "100%",
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widgetRoot: {
    //boxShadow: theme.customShadows.widget
  },
  widgetBody: {
    background: "transparent",
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  moreButton: {
    padding: 0,
    width: 40,
    height: 40,
  },
}));

export default injectIntl(Widget);
