import { RootAction, RootState } from "typesafe-actions"
import { Dispatch } from "redux"
import { toggle_customer } from "../../../store/navigation/actions"
import { connect } from "react-redux"
import CustomerIframeView from "./CustomerIframeView"
import { injectIntl, InjectedIntlProps } from "react-intl"

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
})

const dispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggleCustomer: () => dispatch(toggle_customer()),
  }
}

export type CustomerIframeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof dispatchToProps> &
  InjectedIntlProps

export default injectIntl(connect(mapStateToProps, dispatchToProps)(CustomerIframeView))
