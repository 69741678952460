import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import loginPageStyles from "../styles/LoginPageStyles";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import LoginContainer from "../components/login/login_form/LoginFormContainer";
import LanguageSelectorContainer from "../components/login/language_selector/LanguageSelectorContainer";
import loginFormStyles from "../styles/LoginFormStyles";
import { Grid } from "@material-ui/core";
import logo_white from "../assets/logo-white-10.svg";
import logo_blue from "../assets/logo-blue-10.svg";
import PasswordResetContainer from "../components/login/password_reset/PasswordResetContainer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { useHistory } from "react-router-dom";
import {
  setNotificationOff,
  set_sidebar_page,
  toggleWelcomeDialog,
} from "../store/navigation/actions";

import { getSystemMessages } from "../store/login/actions";

const LoginPage = () => {
  const { userIsLoggedIn, token } = useSelector((state: RootState) => state.login);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = loginPageStyles();
  const formClasses = loginFormStyles();

  useEffect(() => {
    if (userIsLoggedIn) {
      if (token) {
        dispatch(getSystemMessages(token));
        dispatch(toggleWelcomeDialog());
        dispatch(setNotificationOff());
      }
      const pathToView = localStorage.getItem("pathToView");
      if (pathToView !== null) {
        localStorage.removeItem("pathToView");
        history.push(pathToView);
      } else {
        history.push("/dashboard/snapshots");
        dispatch(set_sidebar_page("snapshots"));
      }
    }
  }, [userIsLoggedIn, history, token, dispatch]);

  return (
    <Grid className={formClasses.container}>
      <div className={formClasses.logotypeContainer}>
        <img
          src={logo_white}
          className={formClasses.logotypeImage}
          alt={"adCuality"}
        />
        <Typography className={classes.logotypeText}>
          <FormattedMessage id={"loginPage.slogan"} />
        </Typography>
      </div>
      <div className={formClasses.formContainer}>
        <div className={classes.languageContainer}>
          <LanguageSelectorContainer />
        </div>
        <div className={formClasses.formContainer}>
          {isMobile ? (
            <img
              src={logo_blue}
              className={formClasses.logotypeImage}
              alt={"adCuality"}
            />
          ) : null}
          <div className={formClasses.form}>
            <Typography variant="h3" className={classes.greeting}>
              <FormattedMessage id={"loginPage.greeting"} />
            </Typography>
            <LoginContainer />
            <PasswordResetContainer />
            <Typography variant="body1">
              <a
                className={classes.customer}
                href="https://customer.adcuality.com/support/solutions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer Center
              </a>
            </Typography>
          </div>
          <Typography className={classes.copyright}>
            <FormattedMessage id={"loginPage.copyright"} />
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default LoginPage;
