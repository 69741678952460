import {
  AppBar,
  Button,
  ButtonGroup,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  IconButton,
  Tooltip,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { AdvancedFilterPicker, AlertsPicker } from "./tools/ToolContainer";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ShareReportIcon from "@material-ui/icons/Share";
import DownloadGraphCaptureIcon from "@material-ui/icons/Print";
import Switch from "@material-ui/core/Switch";
import { format, startOfYesterday, subDays } from "date-fns";
import { uk } from "date-fns/locale";
import { RootState } from "typesafe-actions";
import { ReduxProps } from "./SearchToolbarContainer";
import { SidebarPages } from "../../../store/navigation/types";
import { Tabs } from "../../../utils/TabCodes";
import {
  PaginationParametersInterface,
  AdvancedFilters,
  SearchParameters,
  AdvancedSearchInterface,
} from "../../../store/search/types";
import { initial_state } from "../../../store/search/reducer";
import { DataPages } from "../../../utils/constants";
import CountriesSelector from "./CountriesSelector";
import DateRangePicker from "./date-range/DateRangePicker";
import ExportReport from "../../ExportReport";
import AsyncSelector from "./AutocompleteMultiple";
import { downloadGalleryRequest, reportRequest } from "../../../store/data/actions";

const SearchToolbarView = React.memo(
  (props: ReduxProps) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isDataLoading, isGalleryDownloading } = useSelector(
      (state: RootState) => state.data
    );
    const { countries, startDate, endDate } = useSelector(
      (state: RootState) => state.search
    );
    const {
      countries: userCountries,
      token,
      roles,
    } = useSelector((state: RootState) => state.login);
    const snapType = useSelector((state: RootState) => {
      return state.search.snapType;
    });

    const [snapFilter, setSnapFilter] = useState<AdvancedSearchInterface | null>(
      props.search_state.snapFilter
    );

    useEffect(() => {
      setSnapFilter(props.search_state.snapFilter);
    }, [props.search_state.snapFilter]);

    const roleDemo = useMemo(
      () => roles.some((item) => item === "ROLE_FEATURE_DEMO"),
      [roles]
    );

    const handleQuickSearchSelectChange = (
      event: ChangeEvent<{ value: unknown }>
    ) => {
      props.setSnapType(
        event.target.value as "industries" | "advertisers" | "publishers"
      );
      props.setSnapFilter(null);
      props.resetSnapTablePage();
    };

    const noFiltersApplied =
      props.search_state.parameterQueue.advertisers.length === 0 &&
      props.search_state.parameterQueue.industries.length === 0 &&
      props.search_state.parameterQueue.publishers.length === 0 &&
      props.search_state.parameterQueue.products.length === 0 &&
      props.search_state.parameterQueue.sources.length === 0 &&
      props.search_state.parameterQueue.platforms.length === 0 &&
      props.search_state.parameterQueue.formats.length === 0 &&
      props.search_state.parameterQueue.categories.length === 0 &&
      props.search_state.parameterQueue.excludedPublishers.length === 0;

    function performSearch(
      searchParameters: SearchParameters,
      paginationParameters: PaginationParametersInterface
    ) {
      const filters: AdvancedFilters = {
        advertisers: [
          ...searchParameters.advancedFilters.advertisers,
          ...props.search_state.parameterQueue.advertisers,
        ],
        publishers: [
          ...searchParameters.advancedFilters.publishers,
          ...props.search_state.parameterQueue.publishers,
        ],
        industries: [
          ...searchParameters.advancedFilters.industries,
          ...props.search_state.parameterQueue.industries,
        ],
        products: [
          ...searchParameters.advancedFilters.products,
          ...props.search_state.parameterQueue.products,
        ],
        platforms: [
          ...searchParameters.advancedFilters.platforms,
          ...props.search_state.parameterQueue.platforms,
        ],
        formats: [
          ...searchParameters.advancedFilters.formats,
          ...props.search_state.parameterQueue.formats,
        ],
        categories: [
          ...searchParameters.advancedFilters.categories,
          ...props.search_state.parameterQueue.categories,
        ],
        sources: [
          ...searchParameters.advancedFilters.sources,
          ...props.search_state.parameterQueue.sources,
        ],
        excludedPublishers: [
          ...searchParameters.advancedFilters.excludedPublishers,
          ...props.search_state.parameterQueue.excludedPublishers,
        ],
      };
      props.setFilters({ ...searchParameters, ...filters });
      switch (props.navigation_state.selectedPage) {
        case SidebarPages.spotslist:
          history.push("/dashboard/spotslist");
          props.fetchListedSpots(
            { ...searchParameters, ...filters },
            paginationParameters,
            props.token
          );
          break;
        case SidebarPages.spotspreview:
          history.push("/dashboard/campaigns");
          props.fetchCampaigns(
            { ...searchParameters, ...filters },
            paginationParameters,
            props.token
          );
          break;
        case SidebarPages.shareofvoice:
          history.push("/dashboard/shareofvoice");
          props.fetchShareOfVoice(
            { ...searchParameters, ...filters },
            paginationParameters,
            props.token,
            Tabs[props.navigation_state.selectedTab]
          );
          break;
        case SidebarPages.investment:
          history.push("/dashboard/investment");
          props.fetchInvestment(
            { ...searchParameters, ...filters },
            paginationParameters,
            props.token,
            Tabs[props.navigation_state.selectedTab]
          );
      }
      props.updateQueue({
        advertisers: [],
        publishers: [],
        industries: [],
        products: [],
        platforms: [],
        formats: [],
        categories: [],
        sources: [],
        excludedPublishers: [],
      });
    }

    function clearFilters() {
      props.clearAllParameters();
      performSearch(
        {
          startDate: subDays(startOfYesterday(), 15),
          endDate: startOfYesterday(),
          countries: [userCountries[0]],
          advancedFilters: {
            advertisers: [],
            publishers: [],
            industries: [],
            products: [],
            platforms: [],
            formats: [],
            categories: [],
            sources: [],
            excludedPublishers: [],
          },
        },
        initial_state.paginationParameters
      );
      props.goToIndustriesOnClear();
    }

    function handleSearchClick(newTab: boolean) {
      const filters: AdvancedFilters = {
        advertisers: [
          ...props.search_state.advancedFilters.advertisers,
          ...props.search_state.parameterQueue.advertisers,
        ],
        publishers: [
          ...props.search_state.advancedFilters.publishers,
          ...props.search_state.parameterQueue.publishers,
        ],
        industries: [
          ...props.search_state.advancedFilters.industries,
          ...props.search_state.parameterQueue.industries,
        ],
        products: [
          ...props.search_state.advancedFilters.products,
          ...props.search_state.parameterQueue.products,
        ],
        platforms: [
          ...props.search_state.advancedFilters.platforms,
          ...props.search_state.parameterQueue.platforms,
        ],
        formats: [
          ...props.search_state.advancedFilters.formats,
          ...props.search_state.parameterQueue.formats,
        ],
        categories: [
          ...props.search_state.advancedFilters.categories,
          ...props.search_state.parameterQueue.categories,
        ],
        sources: [
          ...props.search_state.advancedFilters.sources,
          ...props.search_state.parameterQueue.sources,
        ],
        excludedPublishers: [
          ...props.search_state.advancedFilters.excludedPublishers,
          ...props.search_state.parameterQueue.excludedPublishers,
        ],
      };

      if (newTab) {
        localStorage.setItem(
          "sharedSearchParameters",
          JSON.stringify({ ...props.search_state, advancedFilters: { ...filters } })
        );
        window.open("/dashboard/" + props.navigation_state.selectedPage, "_blank");
      } else {
        performSearch(
          {
            countries: props.search_state.countries,
            startDate: props.search_state.startDate,
            endDate: props.search_state.endDate,
            advancedFilters: filters,
          },
          props.search_state.paginationParameters
        );
      }
    }

    function handleSwitch() {
      if (!props.navigation_state.isSwitchToggled) {
        props.fetchCampaigns(
          {
            countries: props.search_state.countries,
            startDate: props.search_state.startDate,
            endDate: props.search_state.endDate,
            advancedFilters: props.search_state.advancedFilters,
          },
          props.search_state.paginationParameters,
          props.token
        );
      } else {
        props.fetchListedSpots(
          {
            countries: props.search_state.countries,
            startDate: props.search_state.startDate,
            endDate: props.search_state.endDate,
            advancedFilters: props.search_state.advancedFilters,
          },
          props.search_state.paginationParameters,
          props.token
        );
      }
      props.toggleSwitch();
    }

    const mapToParams = (params: AdvancedSearchInterface[]) => {
      const newParsed = params.map((item) => {
        return JSON.stringify(item);
      });

      return newParsed.join(",,");
    };

    function handleShareReport() {
      const params = new URLSearchParams();
      params.append("countries", props.search_state.countries.join(",,"));
      params.append(
        "from",
        format(props.search_state.startDate, "yyyy-MM-dd", {
          locale: uk,
        })
      );
      params.append(
        "to",
        format(props.search_state.endDate, "yyyy-MM-dd", {
          locale: uk,
        })
      );

      if (props.navigation_state.selectedPage !== DataPages.snapshotsPage) {
        params.append("selected", `${props.navigation_state.selectedTab}`);
        params.append(
          "advertisers",
          mapToParams(props.search_state.advancedFilters.advertisers)
        );
        params.append(
          "formats",
          mapToParams(props.search_state.advancedFilters.formats)
        );
        params.append(
          "industries",
          mapToParams(props.search_state.advancedFilters.industries)
        );
        params.append(
          "publishers",
          mapToParams(props.search_state.advancedFilters.publishers)
        );
        params.append(
          "platforms",
          mapToParams(props.search_state.advancedFilters.platforms)
        );
        params.append(
          "products",
          mapToParams(props.search_state.advancedFilters.products)
        );
        params.append(
          "categories",
          mapToParams(props.search_state.advancedFilters.categories)
        );
        params.append(
          "sources",
          mapToParams(props.search_state.advancedFilters.sources)
        );
        params.append(
          "excludedPublishers",
          mapToParams(props.search_state.advancedFilters.excludedPublishers)
        );
      } else {
        params.append("snapType", `${snapType}`);
        params.append("snapFilter", `${JSON.stringify(snapFilter)}`);
      }

      const urlstr =
        window.location.hostname === "localhost"
          ? `${window.location.host}${window.location.pathname}`
          : `https://${window.location.host}${window.location.pathname}`;

      const urlToShare = new URL(urlstr);
      urlToShare.search = params.toString();

      const el = document.createElement("textarea");
      el.value = urlToShare.toString();
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      props.setNotificationForShare(
        "¡Se ha copiado el link para compartir al portapapeles!",
        "info"
      );
    }

    async function handleDownloadGraphs() {
      const advancedFilters: AdvancedFilters = {
        advertisers: [],
        publishers: [],
        industries: [],
        products: [],
        platforms: [],
        formats: [],
        categories: [],
        sources: [],
        excludedPublishers: [],
      };
      advancedFilters[snapType] = snapFilter !== null ? [snapFilter] : [];
      dispatch(
        reportRequest(
          {
            type: "snapshots",
            filters: { countries, startDate, endDate, advancedFilters },
            reportFormat: "pdf",
            snapType,
          },
          token
        )
      );
    }

    const handleGalleryReportRequest = () => {
      dispatch(
        downloadGalleryRequest(
          {
            countries,
            startDate,
            endDate,
            advancedFilters: props.search_state.advancedFilters,
          },
          props.navigation_state.pagination.currentPage,
          props.navigation_state.pagination.resultsPerPage,
          1,
          token
        )
      );
    };

    return (
      <div className={classes.root} id="snap-print-navbar">
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            {props.navigation_state.selectedPage === DataPages.snapshotsPage ? (
              <>
                <FormControl className={classes.formControl}>
                  <InputLabel id="quick-search-select-label">
                    {props.intl.formatMessage({
                      id: "search.dialog.quickSearch.title",
                    })}
                  </InputLabel>
                  <Select
                    labelId="quick-search-select-label"
                    id="quick-search-select"
                    value={snapType}
                    onChange={handleQuickSearchSelectChange}
                  >
                    <MenuItem value={"industries"}>
                      {props.intl.formatMessage({
                        id: "navigation.tabs.industries",
                      })}
                    </MenuItem>
                    <MenuItem value={"advertisers"}>
                      {props.intl.formatMessage({
                        id: "navigation.tabs.advertisers",
                      })}
                    </MenuItem>
                    <MenuItem value={"publishers"}>
                      {props.intl.formatMessage({
                        id: "navigation.tabs.publishers",
                      })}
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.asyncAuto}>
                  <AsyncSelector
                    isMulti={false}
                    selectedValue={snapFilter}
                    token={props.token}
                    type={props.search_state.snapType}
                    placeholder={props.intl.formatMessage({
                      id: `search.advancedSearch.label.${props.search_state.snapType}.placeholder`,
                    })}
                    handleChangeSingle={(value) => {
                      if (value !== undefined) {
                        const result: AdvancedSearchInterface | null =
                          value !== null
                            ? { id: value.id, label: value.label, value: value.id }
                            : value;
                        props.setSnapFilter(result);
                      } else {
                        props.setSnapFilter(null);
                      }
                    }}
                  />
                </div>
              </>
            ) : null}
            <ButtonGroup variant="text" aria-label="contained primary button group">
              {props.navigation_state.selectedPage !== DataPages.snapshotsPage ? (
                <AdvancedFilterPicker />
              ) : null}
              <DateRangePicker />
            </ButtonGroup>
            <CountriesSelector />
            <AlertsPicker />
            {props.navigation_state.selectedPage === DataPages.spotlistPage ? (
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    checked={props.navigation_state.isSwitchToggled}
                    onChange={() => handleSwitch()}
                    value={props.navigation_state.isSwitchToggled}
                    color="primary"
                  />
                }
                label={
                  props.navigation_state.isSwitchToggled
                    ? props.intl.formatMessage({
                        id: "navigation.switch.groupedSpotsOn",
                      })
                    : props.intl.formatMessage({
                        id: "navigation.switch.groupedSpotsOff",
                      })
                }
                color={"#ffffff"}
              />
            ) : null}
            <div className={classes.grow} />

            {props.navigation_state.selectedPage !== DataPages.snapshotsPage ? (
              <>
                <Button
                  color={"secondary"}
                  onClick={clearFilters}
                  className={classes.secondaryButton}
                  disabled={isDataLoading}
                >
                  <ClearAllIcon />
                  {props.intl.formatMessage({
                    id: "navigation.buttons.clearFilters",
                  })}
                </Button>
                {props.navigation_state.selectedPage !== DataPages.campaignsPage ? (
                  <>
                    <ExportReport />
                    <Tooltip
                      title={props.intl.formatMessage({
                        id: "navigation.buttons.searchNewTab",
                      })}
                    >
                      <IconButton
                        size={"medium"}
                        onClick={() => handleSearchClick(true)}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}
                <Button
                  onClick={() => handleSearchClick(false)}
                  disabled={noFiltersApplied}
                  color="primary"
                  className={classes.button}
                >
                  <SearchIcon />
                  {props.intl.formatMessage({ id: "navigation.buttons.apply" })}
                </Button>
              </>
            ) : (
              <Tooltip
                title={props.intl.formatMessage({
                  id: "navigation.buttons.downloadSnap",
                })}
              >
                <IconButton
                  size={"medium"}
                  onClick={() => handleDownloadGraphs()}
                  disabled={isDataLoading || roleDemo}
                >
                  {!isDataLoading ? (
                    <DownloadGraphCaptureIcon height={24} width={24} />
                  ) : (
                    <CircularProgress color="secondary" size="1em" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {props.navigation_state.selectedPage === DataPages.campaignsPage ? (
              <Tooltip
                title={props.intl.formatMessage({
                  id: "navigation.buttons.downloadCampaigns",
                })}
              >
                <IconButton
                  size={"medium"}
                  onClick={() => handleGalleryReportRequest()}
                  disabled={isGalleryDownloading || roleDemo}
                >
                  {!isGalleryDownloading ? (
                    <DownloadGraphCaptureIcon height={24} width={24} />
                  ) : (
                    <CircularProgress color="secondary" size="1em" />
                  )}
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip
              title={props.intl.formatMessage({ id: "navigation.buttons.share" })}
            >
              <IconButton size={"medium"} onClick={() => handleShareReport()}>
                <ShareReportIcon height={24} width={24} />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </div>
    );
  },
  (prevProps: ReduxProps, nextProps: ReduxProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "@media print": {
        display: "none",
      },
    },
    toolbar: {
      flexWrap: "wrap",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      background: theme.palette.background.paper,
    },
    grow: {
      "@media(min-width: 1440px)": {
        flexGrow: 1,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    asyncAuto: {
      minWidth: 200,
    },
    inputControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    button: {
      margin: 10,
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
    secondaryButton: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.main,
    },
    switch: {
      marginLeft: 3,
      color: theme.palette.type === "dark" ? "#F1FAEE" : "#000",
    },
  })
);

export default SearchToolbarView;
