import React, { useEffect } from "react";
import jwt from "jwt-decode";
import { Redirect, Route, withRouter } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import LogRocket from "logrocket";
import Smartlook from "smartlook-client";

import { PrivateRouteProps } from "./PrivateRouteContainer";
import { AdvancedFilters, AdvancedSearchInterface } from "../../store/search/types";
import countriesAll, { INVESTMENT_UNAVAILABLE } from "../Countries";
import { TermsAcceptedAtInterface } from "../../store/login/types";

interface JWTPayload {
  iat: number;
  exp: number;
  roles: Array<string>;
  countries: Array<string>;
  username: string;
  name: string;
  surname: string;
  termsAccepted: boolean;
  termsAcceptedAt: TermsAcceptedAtInterface;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    component: Component,
    logoutUser,
    setUserRoles,
    setUserCountries,
    setCountry,
    history,
    setParameters,
    setSeletedTab,
    setSidebarPage,
    setCountries,
    setSnapType,
    setSnapFilter,
    ...rest
  } = props;
  const { userIsLoggedIn, token, roles } = props.login_state;
  const { advancedFilters: filters, countries: reduxCountries } = props.search_state;
  const { selectedTab: dashTab, selectedPage } = props.navigation_state;

  if (
    window.location.search !== "" &&
    window.location.pathname !== "/login" &&
    !userIsLoggedIn
  ) {
    localStorage.setItem(
      "pathToView",
      `${window.location.pathname}${window.location.search}`
    );
  }

  useEffect(() => {
    const getParams = new URLSearchParams(window.location.search);
    const countries = getParams.getAll("countries");
    const from = getParams.get("from");
    const to = getParams.get("to");

    // Query params for SOV, RTS, Campaigns and Spotlist
    const selectedTab = getParams.get("selected");
    const advertisers = getParams.getAll("advertisers");
    const formats = getParams.getAll("formats");
    const industries = getParams.getAll("industries");
    const publishers = getParams.getAll("publishers");
    const platforms = getParams.getAll("platforms");
    const products = getParams.getAll("products");
    const categories = getParams.getAll("categories");
    const sources = getParams.getAll("sources");
    const excludedPublishers = getParams.getAll("excludedPublishers");

    // Query params for snapshots
    const snapType = getParams.get("snapType");
    const snapFilter = getParams.get("snapFilter");

    const mapToParse = (params: string[]): AdvancedSearchInterface[] => {
      if (params.length > 0 && params[0] !== "") {
        const newParsed = params[0].split(",,");
        return newParsed.map((item) => {
          return JSON.parse(item);
        });
      }
      return [];
    };

    if (userIsLoggedIn && token && !localStorage.getItem("sharedSearchParameters")) {
      const decoded = jwt(token) as JWTPayload;

      if (decoded) {
        console.log("Mi token expira el: ", new Date(decoded.exp * 1000));
        if (Date.now() > decoded.exp * 1000) {
          logoutUser();
        } else {
          if (Array.isArray(decoded.roles) && decoded.roles.length > 0) {
            setUserRoles(
              decoded.roles,
              decoded.name,
              decoded.surname,
              decoded.termsAccepted,
              decoded.termsAcceptedAt
            );
          }
          if (Array.isArray(decoded.countries) && decoded.countries.length > 0) {
            setUserCountries(decoded.countries);
            if (reduxCountries.length === 0) {
              setCountry(decoded.countries[0]);
            }
          } else {
            const userCountries = countriesAll.map((country) => country.name);
            setUserCountries(userCountries);
          }
        }

        const userInfoToIdentify = {
          name: `${decoded.name} ${decoded.surname}`,
          email: decoded.username,

          roles:
            Array.isArray(decoded.roles) && decoded.roles.length > 0
              ? decoded.roles.join(", ")
              : "",
        };

        LogRocket.identify(decoded.username, userInfoToIdentify);
        Hotjar.identify(decoded.username, userInfoToIdentify);
        Smartlook.identify(decoded.username, userInfoToIdentify);
      }

      if (from && to) {
        console.log("seteando parametros del link");
        if (snapType) {
          const filters: AdvancedFilters = {
            advertisers: [],
            publishers: [],
            industries: [],
            products: [],
            platforms: [],
            formats: [],
            categories: [],
            sources: [],
            excludedPublishers: [],
          };
          setSnapType(snapType as "industries" | "advertisers" | "publishers");

          setParameters(countries[0].split(",,"), from, to, filters);
          setSnapFilter(JSON.parse(snapFilter ?? "null"));
        } else {
          const filters: AdvancedFilters = {
            advertisers: mapToParse(advertisers),
            publishers: mapToParse(publishers),
            industries: mapToParse(industries),
            products: mapToParse(products),
            platforms: mapToParse(platforms),
            formats: mapToParse(formats),
            categories: mapToParse(categories),
            sources: mapToParse(sources),
            excludedPublishers: mapToParse(excludedPublishers),
          };

          setParameters(countries[0].split(",,"), from, to, filters);
          selectedTab && setSeletedTab(parseInt(selectedTab, 10));
        }
        history.push(`${history.location.pathname}`);
        setSidebarPage(`${history.location.pathname.split("/")[2]}`);
      } else {
        if (
          filters.advertisers.length === 0 &&
          filters.products.length === 0 &&
          dashTab === 2
        ) {
          setSeletedTab(0);
        }
      }
    }
  }, [
    userIsLoggedIn,
    token,
    history,
    logoutUser,
    filters,
    dashTab,
    reduxCountries,
    setUserRoles,
    setParameters,
    setSeletedTab,
    setUserCountries,
    setCountry,
    setSidebarPage,
    setSnapType,
    setSnapFilter,
  ]);

  useEffect(() => {
    if (!localStorage.getItem("sharedSearchParameters") && token) {
      const decoded = jwt(token) as JWTPayload;
      if (
        reduxCountries.some(
          (country) =>
            INVESTMENT_UNAVAILABLE.includes(country) &&
            (selectedPage === "investment" || selectedPage === "snapshots")
        )
      ) {
        if (reduxCountries.length > 1) {
          const countryIndex = reduxCountries.findIndex((country) =>
            INVESTMENT_UNAVAILABLE.includes(country)
          );
          const newCountries = reduxCountries.filter(
            (country, i) => i !== countryIndex
          );
          setCountries(newCountries);
        } else {
          setCountry(decoded.countries[0]);
        }
      }
    }
  }, [selectedPage, reduxCountries, token, setCountries, setCountry]);

  if (
    userIsLoggedIn &&
    ((Array.isArray(roles) && roles.length === 0) || roles === undefined)
  ) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return userIsLoggedIn ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default withRouter(PrivateRoute);
