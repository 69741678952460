import React from "react"
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NotificationsIcon from "@material-ui/icons/Notifications"

interface MessageProps {
  title: string
  date: string
  message: string
}

const useStyles = makeStyles({
  inline: {
    display: "inline",
  },
})

const Message = (props: MessageProps) => {
  const styles = useStyles()
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar>
          <NotificationsIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={props.title}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={styles.inline}
              color="textPrimary"
            >
              {props.date}
            </Typography>
            <span dangerouslySetInnerHTML={{ __html: props.message }} />
          </React.Fragment>
        }
      />
    </ListItem>
  )
}

export default Message
