import { ActionType } from "typesafe-actions";
import * as generalActions from "./actions";
import { GeneralActionTypes, GeneralState } from "./types";

import { LANGUAGES_LIST } from "../../utils/Countries";

type GeneralAction = ActionType<typeof generalActions>;

const navigatorLanguage = navigator.language.split(/[-_]/)[0];

const selectLanguage = LANGUAGES_LIST.find(
  (language) => language === navigatorLanguage
);

//Para el estado inicial toma el idioma del navegador del usuario.
const initial_state: GeneralState = {
  language: selectLanguage ?? LANGUAGES_LIST[0],
  helpFormData: {
    email: "",
    title: "",
    message: "",
    name: "",
    messageType: "",
    company: "",
    country: "",
    file: null,
  },
  prefersDarkMode:
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches,
};

function reducer(state = initial_state, action: GeneralAction) {
  switch (action.type) {
    case GeneralActionTypes.SELECT_LANGUAGE:
      return { ...state, language: action.payload.language };
    case GeneralActionTypes.HELP_TICKET_REQUEST:
      return { ...state, helpFormData: action.payload.helpFormData };
    case GeneralActionTypes.HELP_TICKET_SUCCESS:
      return {
        ...state,
        helpFormData: {
          email: "",
          title: "",
          message: "",
          messageType: "",
          company: "",
          country: "",
          name: "",
          file: null,
        },
      };
    case GeneralActionTypes.TOGGLE_DARK_MODE:
      return {
        ...state,
        prefersDarkMode: !state.prefersDarkMode,
      };
    default:
      return state;
  }
}

export { reducer as generalReducer };
