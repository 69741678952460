import { PieChartSeries } from "../../components/graphs/PieChart";

const AudienceData: PieChartSeries[] = [
    {
        label: 'Persons: 15+',
        hidden: false,
        extra: 32,
        value: 9480,
    },
    {
        label: 'Persons: 18+',
        hidden: false,
        extra: 31,
        value: 9292,
    },
    {
        label: 'Persons: 25+',
        hidden: false,
        extra: 25,
        value: 7642,
    },
    {
        label: 'Persons: 6-14',
        hidden: false,
        extra: 2,
        value: 310,
    },
    {
        label: 'Persons: 15-24',
        hidden: false,
        extra: 6,
        value: 1839,
    },
    {
        label: 'Persons: 25-34',
        hidden: false,
        extra: 6,
        value: 1821,
    },
    {
        label: 'Persons: 35+',
        hidden: false,
        extra: 19,
        value: 1821,
    },
    /* {
        label: 'Males: 15+',
        hidden: false,
        extra: 16,
        value: 4493,
    },
    {
        label: 'Males: 18+',
        hidden: false,
        extra: 16,
        value: 4428,
    },
    {
        label: 'Males: 25+',
        hidden: false,
        extra: 13,
        value: 3756,
    },
    {
        label: 'Males: 6-14',
        hidden: false,
        extra: 1,
        value: 152,
    },
    {
        label: 'Males: 15-24',
        hidden: false,
        extra: 3,
        value: 737,
    },
    {
        label: 'Males: 25-34',
        hidden: false,
        extra: 4,
        value: 850,
    },
    {
        label: 'Males: 35+',
        hidden: false,
        extra: 10,
        value: 2906,
    },
    {
        label: 'Females: 15+',
        hidden: false,
        extra: 16,
        value: 4987,
    },
    {
        label: 'Females: 18+',
        hidden: false,
        extra: 15,
        value: 4863,
    },
    {
        label: 'Females: 25+',
        hidden: false,
        extra: 12,
        value: 3886,
    },
    {
        label: 'Females: 6-14',
        hidden: false,
        extra: 1,
        value: 158,
    },
    {
        label: 'Females: 15-24',
        hidden: false,
        extra: 4,
        value: 1102,
    },
    {
        label: 'Females: 25-34',
        hidden: false,
        extra: 2,
        value: 971,
    },
    {
        label: 'Females: 35+',
        hidden: false,
        extra: 9,
        value: 2915,
    }, */
];

export default AudienceData;
