/* eslint-disable react/display-name */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { Grid, useTheme } from "@material-ui/core";
import { CsvBuilder } from "filefy";

import Widget from "../dashboard/Widget";
import { RootState } from "typesafe-actions";
import { ColumnSeriesInterface, StackedBarOptions } from "../../utils/ChartOptions";
import { COLORS, darkCOLORS } from "../../utils/colors";
import { CSVObject } from "../../store/data/types";

interface PercentajeStackedBarProps {
  title: string;
  tooltipFormatter: (arg0: number) => string;
  data: ColumnSeriesInterface[];
}

const PercentajeStackedBar = React.memo(
  (props: PercentajeStackedBarProps) => {
    const { countries } = useSelector((state: RootState) => state.search);
    const { prefersDarkMode } = useSelector((state: RootState) => state.general);
    const intl = useFormatMessage();

    const CountriesLabels: string[] = [...countries.map((country) => intl(country))];
    const theme = useTheme();
    const chartRef = useRef<Chart>(null);
    const formatMessage = useFormatMessage();

    if (props.data.length === 0) {
      return null;
    }

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      CountriesLabels.forEach((val) => {
        headers.push(
          `${formatMessage(
            "shareofvoicePage.table.column.percentage"
          )} - ${formatMessage(val)}`
        );
      });
      data.push(headers);

      props.data.forEach((val) => {
        data.push([
          val.name,
          ...val.data.map((item) => props.tooltipFormatter(item)),
        ]);
      });

      return data;
    };

    const exportStacked = () => {
      const builder = new CsvBuilder(
        `Percentage Stacked Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Grid item xs={12} md={6}>
        <Widget title={props.title} handleDownloadCSV={exportStacked}>
          <Chart
            ref={chartRef}
            options={{
              ...StackedBarOptions,
              chart: {
                id: `stacked-chart-${props.title.split(" ").join("-")}`,
                ...StackedBarOptions.chart,
              },
              colors: theme.palette.type === "dark" ? darkCOLORS : COLORS,
              xaxis: {
                categories: CountriesLabels,
              },
              tooltip: {
                y: {
                  formatter: props.tooltipFormatter,
                },
              },
              dataLabels: {
                enabled: true,
                textAnchor: "start",
                formatter: (val: number) => {
                  return `${val.toFixed(2)}%`;
                },
              },
              theme: {
                mode: prefersDarkMode ? "dark" : "light",
              },
              animations: {
                enabled: false,
              },
            }}
            type={"bar"}
            series={props.data}
            height={400}
          />
        </Widget>
      </Grid>
    );
  },
  (prevProps: PercentajeStackedBarProps, nextProps: PercentajeStackedBarProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default PercentajeStackedBar;
