import React from "react"
import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import clsx from "clsx"
import logo_blue from "../assets/logo-blue.svg"
import { injectIntl, InjectedIntlProps } from "react-intl"

const NotFoundPage = (props: InjectedIntlProps) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo_blue} alt="logo" />
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={clsx(classes.textRow, classes.errorCode)}
        >
          <span role={"img"} aria-label={"emoji haciendo una mueca"}>
            😬
          </span>
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          {props.intl.formatMessage({ id: "notFoundPage.text1" })}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          className={clsx(classes.textRow, classes.safetyText)}
        >
          {props.intl.formatMessage({ id: "notFoundPage.text2" })}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard/shareofvoice"
          size="large"
          className={classes.backButton}
        >
          {props.intl.formatMessage({ id: "notFoundPage.backButton" })}
        </Button>
      </Paper>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#FAFAFA",
    top: 0,
    left: 0,
  },
  logotype: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logotypeText: {
    fontWeight: 500,
    marginLeft: theme.spacing(2),
  },
  logotypeIcon: {
    width: 400,
    marginRight: theme.spacing(2),
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    maxWidth: 404,
  },
  textRow: {
    marginBottom: theme.spacing(10),
    textAlign: "center",
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    color: theme.palette.text.hint,
  },
  backButton: {
    textTransform: "none",
    fontSize: 22,
  },
}))

export default injectIntl(NotFoundPage)
