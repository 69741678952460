/* eslint-disable react/display-name */
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Grid, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import {
  LocationCity as IndustriesIcon,
  Work as AdIcon,
  RecordVoiceOver as PublisherIcon,
  Timer,
} from "@material-ui/icons";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ImageIcon from "@material-ui/icons/Image";
import WebIcon from "@material-ui/icons/Web";
import LastSeen from "@material-ui/icons/Visibility";
import DevicesIcon from "@material-ui/icons/Devices";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import Slider from "react-slick";
import MaterialTable, { MTablePagination } from "material-table";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";

import { CampaignDataList } from "../spots/CampaignDataList";
import MediaPlanChart from "../../components/graphs/MediaPlanChart";
import { CustomCardMedia } from "../spots/CustomCardMedia";
import { CreativityGallery } from "../../components/creativity/image-carousel/CreativityGalleryView";
import { MediaInterface } from "../../components/creativity/image-carousel/Creativity";
import {
  getTrendingMediaPlan,
  getDatesStats,
  numberWithCommas,
} from "../../utils/functions";
import AudiencesDonutChart from "../../components/graphs/AudiencesDonutChart";
import DonutChart from "../../components/graphs/DonutChart";
import PieChart from "../../components/graphs/PieChart";
import TimeChart from "../../components/graphs/TimeChart";
import { AdvancedFilters } from "../../store/search/types";
import { CampaignsItem } from "../../store/data/types";
import AudienceData from "./AudiencePieDemo";

const useStyles = makeStyles((theme) => ({
  hidePrint: {
    "@media print": {
      display: "none",
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    padding: "3em 1em",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 1em",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardProgress: {
    flexGrow: 1,
    textAlign: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
  divider: {
    marginBottom: 5,
  },
  iconTitle: {
    verticalAlign: "sub",
  },
  allHeight: {
    height: "100%",
  },
  stats: {
    display: "flex",
    flexFlow: "row",
    padding: "0 2em",
    justifyContent: "center",
  },
  statColumn: {
    display: "flex",
    flexFlow: "column",
  },
  spanStat: {
    textAlign: "center",
    "@media print": {
      display: "none",
    },
  },
  iconStat: {
    color: theme.palette.text.secondary,
    height: "2.5em",
    width: "2.5em",
  },
  textStat: {
    marginLeft: "1em",
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
}));

interface SliderSnapProps {
  campaigns: CampaignsItem[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCampaign: React.Dispatch<
    React.SetStateAction<MediaInterface | undefined>
  >;
}

const SliderSnapComponent = React.memo(
  (props: SliderSnapProps) => {
    const classes = useStyles();
    const formatMessage = useFormatMessage();

    const { campaigns, setOpen, setTitle, setSelectedCampaign } = props;
    function handleViewCreativity(data: MediaInterface, title: string) {
      setSelectedCampaign(data);
      setOpen(true);
      setTitle(title);
    }

    return (
      <Slider
        className={classes.noPrint}
        dots={true}
        infinite={campaigns.length > 3}
        speed={6000}
        slidesToShow={4}
        slidesToScroll={campaigns.length > 3 ? 4 : 1}
      >
        {campaigns.map((campaign, i) => (
          <Card className={classes.card} key={i}>
            <div
              onClick={() =>
                handleViewCreativity(
                  {
                    date: campaign.lastSeenDate,
                    advertiser: campaign.advertiser,
                    style: campaign.style,
                    styleAttributes: campaign.styleAttributes,
                    creativity: campaign.creativity,
                    platform: campaign.platform,
                    format: campaign.format,
                    category: campaign.category,
                    industry: campaign.industry,
                    pViewability: campaign.pViewability,
                    screenshot: campaign.lastSeenScreenshot,
                  },
                  campaign.product + " @ " + campaign.lastSeen
                )
              }
            >
              <CustomCardMedia campaign={campaign} />
            </div>
            <CardContent className={classes.cardContent}>
              <Tooltip title={campaign.advertiser}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  style={{ cursor: "context-menu" }}
                  color="textSecondary"
                >
                  <AdIcon className={classes.iconTitle} />{" "}
                  {campaign.advertiser.length > 20
                    ? `${campaign.advertiser.substring(0, 15)}...`
                    : campaign.advertiser}
                </Typography>
              </Tooltip>
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                color="textSecondary"
              >
                {formatMessage("campaignsPage.moreInfo.lastSeen") +
                  ": " +
                  campaign.lastSeenDate +
                  " @ "}{" "}
                <a href={campaign.lastSeenUrl} target={"_blank"}>
                  {campaign.lastSeen}
                </a>
              </Typography>
              <Divider variant={"middle"} className={classes.divider} />
              <CampaignDataList
                items={[
                  {
                    id: "industry-listed-filter",
                    text: (
                      <Tooltip title={campaign.industry}>
                        <Typography
                          variant={"body2"}
                          component="p"
                          style={{ cursor: "context-menu" }}
                          color="textSecondary"
                        >
                          {campaign.industry.length > 20
                            ? `${campaign.industry.substring(0, 15)}...`
                            : campaign.industry}
                        </Typography>
                      </Tooltip>
                    ),
                    icon: <IndustriesIcon />,
                  },
                  {
                    id: "product-listed-filter",
                    text: (
                      <Tooltip title={campaign.product}>
                        <Typography
                          variant={"body2"}
                          component="p"
                          style={{ cursor: "context-menu" }}
                          color="textSecondary"
                        >
                          {campaign.product.length > 20
                            ? `${campaign.product.substring(0, 15)}...`
                            : campaign.product}
                        </Typography>
                      </Tooltip>
                    ),
                    icon: <ShoppingCartIcon />,
                  },
                  {
                    id: "platform-listed-filter",
                    text: (
                      <Typography
                        variant={"body1"}
                        component="p"
                        style={{ cursor: "context-menu" }}
                        color="textSecondary"
                      >
                        {campaign.platform}
                      </Typography>
                    ),
                    icon: <DevicesIcon />,
                  },
                  {
                    id: "format-listed-filter",
                    text: (
                      <Typography
                        variant={"body1"}
                        component="p"
                        style={{ cursor: "context-menu" }}
                        color="textSecondary"
                      >
                        {campaign.format}
                      </Typography>
                    ),
                    icon: <ArtTrackIcon />,
                  },
                  {
                    id: "spots-listed-filter",
                    text: (
                      <Typography
                        variant={"body2"}
                        component="p"
                        color="textSecondary"
                      >
                        {campaign.spots} spots
                      </Typography>
                    ),
                    icon: <ImageIcon />,
                  },
                  {
                    id: "pview-listed-filter",
                    text: (
                      <Typography
                        variant={"body2"}
                        component="p"
                        color="textSecondary"
                      >
                        pViewability{" "}
                        {campaign.pViewability !== null
                          ? campaign.pViewability
                          : "-"}
                      </Typography>
                    ),
                    icon: <LastSeen />,
                  },
                  {
                    id: "last-seen-listed-filter",
                    text: (
                      <Typography
                        variant={"body2"}
                        component="p"
                        color="textSecondary"
                      >
                        {campaign.lastSeen}
                      </Typography>
                    ),
                    icon: <WebIcon />,
                  },
                  {
                    id: "gmt-zone-listed-filter",
                    text: (
                      <Typography
                        variant={"caption"}
                        component="small"
                        color="textSecondary"
                      >
                        *{formatMessage("campaignsPage.moreInfo.gmtZone")}
                      </Typography>
                    ),
                    icon: <Timer />,
                  },
                ]}
              />
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  handleViewCreativity(
                    {
                      date: campaign.lastSeenDate,
                      advertiser: campaign.advertiser,
                      style: campaign.style,
                      styleAttributes: campaign.styleAttributes,
                      creativity: campaign.creativity,
                      platform: campaign.platform,
                      format: campaign.format,
                      category: campaign.category,
                      industry: campaign.industry,
                      pViewability: campaign.pViewability,
                      screenshot: campaign.lastSeenScreenshot,
                    },
                    campaign.product + " @ " + campaign.lastSeen
                  )
                }
              >
                {formatMessage("spotlistPage.dialog.screenshotTooltip")}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Slider>
    );
  },
  (prevProps: SliderSnapProps, nextProps: SliderSnapProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

interface SingleProps {
  countries: string[];
  filters: AdvancedFilters;
  snapType: string;
}

const SingleCountryCharts = (props: SingleProps) => {
  const {
    isAudiencesLoading,
    isSnapSOVLoading,
    isSnapSOILoading,
    snapPlatforms,
    snapSources,
    snapPublishers,
    snapPublishersSOI,
    snapTable,
    snapStats,
    campaigns,
    audiences,
    hasRequestFailedAudiences,
    errorMessageAudiences,
  } = useSelector((state: RootState) => state.data);
  const filtered = useSelector((state: RootState) => state.data.shareOfVoice);
  const filteredSOI = useSelector((state: RootState) => state.data.investment);
  const formatNumber = useFormatNumber();
  const { isDataLoading } = useSelector((state: RootState) => state.data);
  const intl = useFormatMessage();
  const { countries, filters, snapType } = props;
  const classes = useStyles();

  const [title, setTitle] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const [selectedCampaign, setSelectedCampaign] = React.useState<
    MediaInterface | undefined
  >(undefined);

  const afterSlider = useRef<HTMLDivElement | null>(null);

  const amountFormatter = (value: number): string => {
    return `${formatNumber(value, {
      style: "currency",
      currency: filteredSOI[0] ? filteredSOI[0].currency : "ARS",
    })}`;
  };

  const onClickMore = () => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: (afterSlider.current?.offsetTop ?? 0) + (window.screen.availHeight - 50),
    });
  };

  const getTopGraphTitle = useCallback(() => {
    if (filters[snapType].length > 0) {
      if (snapType === "advertisers") {
        return `TOP ${intl("navigation.tabs.products")} SOV & SOI`;
      }
      return `TOP ${intl("navigation.tabs.advertisers")} SOV & SOI`;
    }
    switch (snapType) {
      case "industries":
        return `TOP ${intl("navigation.tabs.industries")} SOV & SOI`;
      case "advertisers":
        return `TOP ${intl("navigation.tabs.advertisers")} SOV & SOI`;
      case "publishers":
        return `TOP ${intl("navigation.tabs.publishers")} SOV & SOI`;
    }
  }, [filters, intl, snapType]);

  return (
    <>
      <Grid container id="snap-export-first-page">
        <Grid item xs={12} md={4} className={classes.allHeight} id="sov-snap-pie">
          <PieChart
            title="Share of Voice (SOV)"
            country={0}
            items={filtered[0] && !isSnapSOVLoading ? filtered[0].pieData : []}
            snap={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.allHeight} id="soi-snap-pie">
          <PieChart
            title="Share of Investment (SOI)"
            country={0}
            items={filteredSOI[0] && !isSnapSOILoading ? filteredSOI[0].pieData : []}
            snap={true}
            investment={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={classes.allHeight}
          id="snap-table-preview"
        >
          <MaterialTable
            columns={[
              {
                title: "Name",
                field: "name",
                headerStyle: {
                  width: "70%",
                },
                cellStyle: {
                  width: "70%",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
              {
                title: "SOV (%)",
                field: "percentage",
                headerStyle: {
                  width: "3rem",
                },
                cellStyle: {
                  width: "3rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
              {
                title: "SOI ($)",
                field: "amount",
                headerStyle: {
                  width: "5rem",
                },
                cellStyle: {
                  width: "5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            ]}
            data={
              snapTable.items[0]
                ? snapTable.items[0].table.items.slice(0, 10).map((item) => {
                    return {
                      name:
                        item.label.length > 27
                          ? `${item.label.substring(0, 27)}...`
                          : item.label,
                      percentage: formatNumber(item.percentage, {
                        maximumFractionDigits: 2,
                      }),
                      amount: numberWithCommas(parseInt(item.amount.toFixed(0))),
                    };
                  })
                : []
            }
            title={getTopGraphTitle()}
            options={{
              pageSize: 10,
              pageSizeOptions: [10],
              search: false,
              sorting: false,
            }}
            components={{
              Pagination: (pagProps) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { classes, ...propsPag } = pagProps;
                return (
                  <div
                    style={{
                      display: "flex",
                    }}
                    className={classes.hidePrint}
                  >
                    <MTablePagination {...propsPag} />
                    <Button variant="text" onClick={onClickMore}>
                      View all...
                    </Button>
                  </div>
                );
              },
            }}
          />
        </Grid>
        <Grid item xs={12} container className={classes.allHeight}>
          <Grid item xs={12} md={6} className={classes.allHeight} id="trending-snap">
            <MediaPlanChart
              title="Trending SOV & SOI"
              series={
                filtered[0] &&
                filteredSOI[0] &&
                !isSnapSOVLoading &&
                !isSnapSOILoading
                  ? getTrendingMediaPlan(
                      filtered[0].trendingSoV,
                      filteredSOI[0].trendingSoI
                    )
                  : []
              }
              categories={filtered[0] ? filtered[0].mediaCategories : []}
              tooltipFormatter={(value: number) => {
                return `${formatNumber(value)}`;
              }}
              yTitleFormatter={(seriesName: string) => {
                return `${seriesName}`;
              }}
              titleYAxis="Spots"
              isDoubleAxis={true}
              yAxis={[
                {
                  seriesName: "SOV",
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                  },
                  title: {
                    text: "Spots",
                    style: {
                      color: "#78909C",
                    },
                  },
                  labels: {
                    formatter: (value: string) => {
                      return (
                        value &&
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    },
                  },
                },
                {
                  seriesName: `SOI`,
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                  },
                  title: {
                    text: "Amounts ($)",
                    style: {
                      color: "#78909C",
                    },
                  },
                  labels: {
                    formatter: (value: string) => {
                      return (
                        value &&
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    },
                  },
                },
              ]}
              loading={isDataLoading}
              type="line"
              snap={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.allHeight}
            id="platforms-snap-donut"
          >
            <DonutChart
              title="Platforms"
              country={0}
              items={snapPlatforms[0] ? snapPlatforms[0].pieData : []}
              snap={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.allHeight}
            id="sources-snap-donut"
          >
            <DonutChart
              title="Sources"
              country={0}
              items={snapSources[0] ? snapSources[0].pieData : []}
              snap={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.allHeight}
        >
          {snapStats.items[0] ? (
            <>
              <Grid
                item
                xs={12}
                md={4}
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                className={classes.allHeight}
                id="stats-snap"
              >
                <div className={classes.stats}>
                  <div className={classes.statColumn}>
                    <span className={classes.spanStat}>
                      {snapType !== "publishers" ? (
                        <PublisherIcon
                          className={classes.iconStat}
                          height={60}
                          width={60}
                        />
                      ) : (
                        <AdIcon
                          className={classes.iconStat}
                          height={60}
                          width={60}
                        />
                      )}
                    </span>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="textSecondary"
                    >
                      {snapType !== "publishers" ? "Publishers" : "Advertisers"}
                    </Typography>
                  </div>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.textStat}
                    color="textSecondary"
                  >
                    {snapStats.items[0].totalStat}
                  </Typography>
                </div>
                <div className={classes.stats}>
                  <div className={classes.statColumn}>
                    <span className={classes.spanStat}>
                      <LastSeen
                        className={classes.iconStat}
                        height={60}
                        width={60}
                      />
                    </span>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      color="textSecondary"
                    >
                      pViewability
                    </Typography>
                  </div>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.textStat}
                    color="textSecondary"
                  >
                    {snapStats.items[0].pViewability.toFixed(1)}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                container
                className={classes.allHeight}
                id="time-snap-rts"
              >
                <TimeChart
                  title={`Real-Time Spend (RTS) ${amountFormatter(
                    snapStats.items[0].adSpend.total
                  )}`}
                  series={[
                    {
                      name: "Total",
                      data: snapStats.items[0]
                        ? snapStats.items[0].adSpend.histogram.map(
                            (spend) => spend.amount
                          )
                        : [],
                    },
                  ]}
                  categories={
                    snapStats.items[0]
                      ? getDatesStats(snapStats.items[0].adSpend.histogram)
                      : []
                  }
                  tooltipFormatter={amountFormatter}
                  loading={isDataLoading}
                  type="line"
                  snap={true}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={4} className={classes.allHeight} id="sov-ap-snap-pie">
          <PieChart
            title={`${
              snapType === "publishers" ? "Advertisers" : "Publishers"
            } (SOV)`}
            country={0}
            items={snapPublishers[0] ? snapPublishers[0].pieData : []}
            snap={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.allHeight} id="soi-ap-snap-pie">
          <PieChart
            title={`${
              snapType === "publishers" ? "Advertisers" : "Publishers"
            } (SOI)`}
            country={0}
            items={snapPublishersSOI[0] ? snapPublishersSOI[0].pieData : []}
            snap={true}
            investment={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.allHeight}>
          <AudiencesDonutChart
            title={`Audiences${
              ["MX", "AR", "CO", "PE", "CL", "BR"].includes(countries[0])
                ? ""
                : " (Coming soon)"
            }`}
            country={0}
            items={
              audiences &&
              audiences.audiences &&
              ["MX", "AR", "CO", "PE", "CL", "BR"].includes(countries[0])
                ? audiences.audiences
                : AudienceData
            }
            snap={true}
            hasRequestFailed={hasRequestFailedAudiences}
            errorMessage={errorMessageAudiences}
            loading={isAudiencesLoading}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.allHeight} id="sov-snap-bar">
          <MediaPlanChart
            title="Trending Share of Voice"
            series={filtered[0] ? filtered[0].mediaData : []}
            categories={filtered[0] ? filtered[0].mediaCategories : []}
            tooltipFormatter={(value: number) => {
              return `${formatNumber(value)} spots`;
            }}
            titleYAxis="Spots"
            loading={isDataLoading}
            type="stacked"
            snap={true}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.allHeight} id="soi-snap-bar">
          <MediaPlanChart
            title="Trending Share of Investment"
            series={filteredSOI[0] ? filteredSOI[0].mediaDataAmount : []}
            categories={filteredSOI[0] ? filteredSOI[0].mediaCategories : []}
            tooltipFormatter={amountFormatter}
            loading={isDataLoading}
            type={"stacked"}
            titleYAxis={"Amount"}
            snap={true}
            investment={true}
          />
        </Grid>
      </Grid>
      <Grid container id="snap-export-second-page">
        <Grid item xs={12} className={classes.cardGrid}>
          <SliderSnapComponent
            campaigns={campaigns}
            setTitle={setTitle}
            setOpen={setOpen}
            setSelectedCampaign={setSelectedCampaign}
          />
        </Grid>
      </Grid>
      <CreativityGallery
        isOpen={open}
        toggle={() => setOpen(false)}
        media={selectedCampaign!}
        title={title}
      />
      <div ref={afterSlider} />
    </>
  );
};

export default SingleCountryCharts;
