import React from "react";
import { useSelector } from "react-redux";
import { ReduxProps } from "./ToolContainer";
import { Button, makeStyles, createStyles, Theme } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { Notifications } from "@material-ui/icons";
import { getBadge } from "../../header/HeaderView";
import { RootState } from "typesafe-actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1.5),
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
  })
);

const AlertsPickerView = (props: ReduxProps) => {
  const { isDataLoading } = useSelector((state: RootState) => state.data);
  const styles = useStyles();
  return (
    <Badge
      color={"secondary"}
      badgeContent={getBadge(props.search_state.advancedFilters)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Button
        aria-haspopup="true"
        id={"Alerts selector icon"}
        aria-controls="profile-menu"
        onClick={props.toggleAlertsDialog}
        startIcon={<Notifications />}
        color="primary"
        variant="text"
        className={styles.button}
        disabled={isDataLoading}
      >
        {props.intl.formatMessage({ id: "navigation.header.alertsDialog" })}
      </Button>
    </Badge>
  );
};

export default AlertsPickerView;
