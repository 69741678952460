import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import MaterialTable, { Column } from 'material-table';
import { ReportInterface } from '../../store/data/types';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import EditIcon from '@material-ui/icons/Edit';
import NewReportDialog from '../dashboard/dialogs/SessionExpired/NewReport';
import {
  Delete as DeleteIcon,
  ToggleOn,
  ToggleOff
} from '@material-ui/icons';
import { deleteReportRequest, toggleReportRequest } from '../../store/data/actions';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      color: theme.palette.grey[400],
    },
  }),
);

const SavedReports = () => {
    const { userReports } = useSelector((state: RootState) => state.data);
    const { token } = useSelector((state: RootState) => state.login);
    const formatMessage = useFormatMessage();
    const [newReportOpen, setNewReport] = React.useState(false);
    const [reportSelected, selectReport] = React.useState<ReportInterface | undefined>(undefined);
    const dispatch = useDispatch();
    const styles = useStyles();

    const localization = {
        body: {
          emptyDataSourceMessage: formatMessage("table.emptyDataSourceMessage"),
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} of {count}",
          labelRowsSelect: formatMessage("table.labelRowsSelect"),
          labelRowsPerPage: formatMessage("table.labelRowsPerPage"),
          firstTooltip: formatMessage("table.firstTooltip"),
          previousTooltip: formatMessage("table.previousTooltip"),
          nextTooltip: formatMessage("table.nextTooltip"),
          lastTooltip: formatMessage("table.lastTooltip"),
        },
        toolbar: {
          searchTooltip: formatMessage("table.searchTooltip"),
          searchPlaceholder: formatMessage("table.searchPlaceholder"),
          exportTitle: formatMessage("table.exportTitle"),
          exportName: formatMessage("table.exportName"),
        },
        grouping: {
          placeholder: formatMessage("table.groupingPlaceholder"),
        },
        header: {
            actions: formatMessage("table.column.actions"),
        },
      }
    const columns: Column<ReportInterface>[] = [
        { field: 'name', title: formatMessage('newReport.name') },
        { field: 'frecuency', title: formatMessage('newReport.frequency.title'), render: (data: ReportInterface) => {return formatMessage(`newReport.frequency.${data.frequency}`)} },
        { field: 'formats', title: formatMessage('newReport.format.title'), render: (data: ReportInterface) => (data.formats.some(format => format.includes('pdf')) ? 'PDF' : 'Excel') },
        { field: 'enabled', title: formatMessage('newReport.enabled'), type: 'boolean' },
    ]
    return (
      <>
        <MaterialTable
            data={userReports}
            columns={columns}
            localization={localization}
            title={formatMessage('accountPage.myreports.title')}
            actions={[
              {
                icon: function EditIconFunc() { return <EditIcon /> },
                tooltip: 'Editar reporte',
                onClick: (event, rowData) => {
                  const reportToSelect = Array.isArray(rowData) ? rowData[0] : rowData;
                  selectReport(reportToSelect);
                  setNewReport(true);
                }
              },
              {
                icon: function DeleteIconFunc() { return <DeleteIcon /> },
                tooltip: 'Borrar',
                onClick: (event, rowData) => {
                  const reportToSelect = Array.isArray(rowData) ? rowData[0] : rowData;
                  dispatch(deleteReportRequest(
                    token,
                    reportToSelect.id
                  ))
                }
              },
              (rowData: ReportInterface) => ({
                icon: function ToggleIconFunc() { return (rowData.enabled ? <ToggleOn className={rowData.frequency === "never" ? styles.disabled : ''} /> : <ToggleOff className={rowData.frequency === "never" ? styles.disabled : ''} />) },
                tooltip: rowData.enabled ? 'Deshabilitar' : 'Habilitar',
                onClick: (event, rowDataClick) => {
                  const reportToSelect = Array.isArray(rowDataClick) ? rowDataClick[0] : rowDataClick;
                  if (reportToSelect.frequency === 'never') {
                    return;
                  }
                  dispatch(toggleReportRequest(
                    token,
                    reportToSelect.id,
                    reportToSelect.enabled
                  ))
                }
              })
            ]}
        />
        
        <NewReportDialog open={newReportOpen} setOpen={setNewReport} report={reportSelected} />
      </>
    )
}

export default SavedReports;