import { LoginActionTypes, LoginState } from "./types"
import { ActionType } from "typesafe-actions"
import * as loginActions from "./actions"

type LoginAction = ActionType<typeof loginActions>

const initial_state: LoginState = {
  email: "",
  password: "",
  token: "",
  loading: false,
  error: false,
  resetPassword: false,
  userIsLoggedIn: false,
  username: "",
  newPassword: "",
  isAccountExpired: false,
  userMessages: [],
  hasTokenExpired: false,
  roles: [],
  countries: [],
  name: '',
  surname: '',
  termsAccepted: false,
  termsAcceptedAt: null
}

function reducer(state = initial_state, action: LoginAction) {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        email: action.payload.email,
        password: action.payload.password,
      }
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        error: false,
        userIsLoggedIn: true,
        hasTokenExpired: false,
      }
    case LoginActionTypes.LOGIN_FAILURE:
      return { ...state, loading: false, error: true, token: "" }
    case LoginActionTypes.LOGOUT:
      return {
        ...state,
        email: "",
        password: "",
        token: "",
        loading: false,
        error: false,
        userIsLoggedIn: false,
        hasTokenExpired: false,
        roles: [],
        countries: []
      }
    case LoginActionTypes.FORGOT_PASSWORD:
      return { ...state, resetPassword: true }
    case LoginActionTypes.CANCEL_RESET:
      return { ...state, resetPassword: false }
    case LoginActionTypes.RESET_PASSWORD_REQUEST:
      return { ...state, resetPassword: true }
    case LoginActionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: false }
    case LoginActionTypes.CHANGE_USERNAME_EMAIL_REQUEST:
      return { ...state, loading: true }
    case LoginActionTypes.CHANGE_USERNAME_EMAIL_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        surname: action.payload.surname,
        loading: false,
      }
    case LoginActionTypes.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case LoginActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        password: action.payload.newPassword,
        userIsLoggedIn: false,
        loading: false,
        token: ''
      }
    case LoginActionTypes.SET_EXPIRED:
      return {
        ...state,
        isAccountExpired: !state.isAccountExpired,
        userMessages: [action.payload.message],
      }
    case LoginActionTypes.SESSION_EXPIRED:
      return { ...state, hasTokenExpired: true }
    case LoginActionTypes.SET_ROLES:
      return {
        ...state,
        roles: [...action.payload.roles],
        name: action.payload.name,
        surname: action.payload.surname,
        termsAccepted: action.payload.termsAccepted,
        termsAcceptedAt: action.payload.termsAcceptedAt
      }
    case LoginActionTypes.SET_COUNTRIES:
      return { ...state, countries: [...action.payload.countries] }
    case LoginActionTypes.SET_USER_MESSAGES:
      return { ...state, userMessages: [...action.payload.messages] }
    case LoginActionTypes.ACCEPT_TERMS_SUCCESS:
      return { ...state, termsAccepted: true }
    default:
      return state
  }
}

export { reducer as loginReducer }
