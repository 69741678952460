import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import countries, { COUNTRIES_BALLS } from "../../utils/Countries";
import reportImage from "../../assets/report.svg";

const reportStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(10, 0, 6),
    },
    reportImage: {
      width: "50%",
    },
    textField: {
      outlineColor: "#293E76",
      color: "#293E76",
    },
    form: {
      position: "relative",
    },
    formControl: {
      color: "#293E76",
      marginTop: theme.spacing(2),
      outlineColor: "#293E76",
      width: "100%",
    },
    menuItem: {
      marginLeft: theme.spacing(1),
    },
    circularProgress: {
      color: theme.palette.primary.main,
    },
    submitButton: {
      display: "block",
      marginTop: theme.spacing(2),
      fontWeight: "bold",
      fontSize: "1.1rem",
      position: "relative",
    },
  })
);

export const ReportSignUp: React.FC = () => {
  const classes = reportStyles();
  const intl = useFormatMessage();

  const reportSchema = Yup.object().shape({
    EMAIL: Yup.string()
      .email("Invalid email")
      .required(intl("marketing.signup_errors.email")),
    FNAME: Yup.string().required(intl("marketing.signup_errors.fullName")),
    RUBRO: Yup.string().required(intl("marketing.signup_errors.rubro")),
    COMPANY: Yup.string().required(intl("marketing.signup_errors.company")),
    COUNTRY: Yup.string().required(intl("marketing.signup_errors.country")),
  });

  const createHiddenInput = (name: string, value: string) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;

    return input;
  };

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      EMAIL: "",
      FNAME: "",
      RUBRO: "",
      COMPANY: "",
      COUNTRY: "",
    },
    onSubmit: (values) => {
      const url_action = process.env.REACT_APP_MAILCHIMP_ACTION || "";
      const form = document.createElement("form");
      form.action = url_action;
      form.method = "POST";

      for (const entry of Object.entries(values)) {
        form.appendChild(createHiddenInput(entry[0], entry[1]));
      }

      document.body.appendChild(form);
      form.submit();
    },
    validationSchema: reportSchema,
  });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h3">{intl("marketing.titleSignup")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" mt={4} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" mt={4} />
        <Typography variant="h5" gutterBottom>
          {intl("marketing.subTitleSignup")}
        </Typography>
        <Box display="flex" justifyContent="center" mt={6}>
          <img
            src={reportImage}
            alt="Report image"
            className={classes.reportImage}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <form
          id={"marketing-form"}
          name={"marketing-form"}
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                error={errors.EMAIL !== undefined && touched.EMAIL}
                id="outlined-email-input"
                label={<FormattedMessage id={"marketing.email"} />}
                type="email"
                name="EMAIL"
                autoComplete="email"
                margin="normal"
                onChange={handleChange}
                fullWidth
              />
              {errors.EMAIL && touched.EMAIL ? <div>{errors.EMAIL}</div> : null}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textField}
                error={errors.FNAME !== undefined && touched.FNAME}
                id="outlined-first-name-input"
                label={<FormattedMessage id={"marketing.firstName"} />}
                type="text"
                name="FNAME"
                autoComplete="firstName"
                margin="normal"
                onChange={handleChange}
                fullWidth
              />
              {errors.FNAME && touched.FNAME ? <div>{errors.FNAME}</div> : null}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textField}
                error={errors.COMPANY !== undefined && touched.COMPANY}
                id="outlined-company-input"
                label={<FormattedMessage id={"marketing.company"} />}
                type="text"
                name="COMPANY"
                autoComplete="company"
                margin="normal"
                onChange={handleChange}
                fullWidth
              />
              {errors.COMPANY && touched.COMPANY ? (
                <div>{errors.COMPANY}</div>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="outlined-country-label">
                  <FormattedMessage id={"marketing.rubro"} />
                </InputLabel>
                <Select
                  labelId="outlined-rubro-label"
                  id="outlined-rubro-input"
                  error={errors.RUBRO !== undefined && touched.RUBRO}
                  value={values.RUBRO}
                  onChange={handleChange}
                  name="RUBRO"
                  autoComplete="rubro"
                  input={<Input />}
                  variant="outlined"
                >
                  <MenuItem value={"media"}>
                    <ListItemText
                      primary={intl("marketing.rubro.media")}
                      className={classes.menuItem}
                    />
                  </MenuItem>
                  <MenuItem value={"agency"}>
                    <ListItemText
                      primary={intl("marketing.rubro.agency")}
                      className={classes.menuItem}
                    />
                  </MenuItem>
                  <MenuItem value={"advertiser"}>
                    <ListItemText
                      primary={intl("marketing.rubro.advertiser")}
                      className={classes.menuItem}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              {errors.RUBRO && touched.RUBRO ? <div>{errors.RUBRO}</div> : null}
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="outlined-country-label">
                  <FormattedMessage id={"marketing.country"} />
                </InputLabel>
                <Select
                  labelId="outlined-country-label"
                  id="outlined-country-input"
                  error={errors.COUNTRY !== undefined && touched.COUNTRY}
                  value={values.COUNTRY}
                  onChange={handleChange}
                  name="COUNTRY"
                  autoComplete="company"
                  input={<Input />}
                  variant="outlined"
                >
                  {countries.map((country) => (
                    <MenuItem key={country.name} value={country.name}>
                      <Box display="flex">
                        {COUNTRIES_BALLS[country.name]}
                        <ListItemText
                          primary={intl(country.name)}
                          className={classes.menuItem}
                        />
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.COUNTRY && touched.COUNTRY ? (
                <div>{errors.COUNTRY}</div>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.submitButton}
                  type="submit"
                  color="primary"
                  disabled={!isValid || isSubmitting}
                  form={"marketing-form"}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={24.5}
                    />
                  ) : (
                    intl("marketing.ctaButton")
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
