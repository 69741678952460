import axios, { AxiosResponse, CancelToken } from "axios";
import { CreateReportInterface, EditReportInterface } from "../store/data/types";
import { format } from "date-fns";
import {
  // AdvancedSearchInterface,
  PaginationParametersInterface,
  ReportMailParameters,
  SearchParameters,
  AlertsInterface,
} from "../store/search/types";
import { OptionsList } from "react-select-async-paginate";
import { getSnapTableCSV } from "../utils/functions";
import LogRocket from "logrocket";

const getIds = (filters: { id: number; label: string }[]): string[] => {
  const ids: string[] = [];
  filters.forEach((filter) => ids.push(filter.id.toString()));
  return ids;
};

export const exportCampaign = async (
  filters: SearchParameters,
  type: "campaign" | "share-of-voice" | "investment" | "campaign-rts" | "snapshots",
  reportFormat: "pdf" | "excel",
  token: string,
  cancelToken: CancelToken,
  snapType?: string
) => {
  const params = {
    country: filters.countries[0],
    // force: '1',
    since: format(filters.startDate, "yyyy/MM/dd"),
    until: format(filters.endDate, "yyyy/MM/dd"),
    industries: getIds(filters.advancedFilters.industries),
    advertisers: getIds(filters.advancedFilters.advertisers),
    publishers: getIds(filters.advancedFilters.publishers),
    excludedPublishers: getIds(filters.advancedFilters.excludedPublishers),
    sources: getIds(filters.advancedFilters.sources),
    categories: getIds(filters.advancedFilters.categories),
    products: getIds(filters.advancedFilters.products),
    formats: getIds(filters.advancedFilters.formats),
    platforms: getIds(filters.advancedFilters.platforms),
  };

  let url = `${process.env.REACT_APP_API_ENDPOINT}/${type}/export/${reportFormat}`;

  if (type === "snapshots" && snapType) {
    url += `?type=${snapType}&force=1`;
  }

  LogRocket.track("ExportCampaign", {
    type,
    url,
    reportFormat,
    ...params,
  });

  return axios
    .get(url, {
      params,
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((response: AxiosResponse<{ _links: { self: { href: string } } }>) => {
      const data = response.data;
      if (data._links && data._links.self && data._links.self.href) {
        window.open(
          `${process.env.REACT_APP_MAIL_ENDPOINT}${data._links.self.href}`,
          "_blank"
        );
      }
    });
};

export const exportGallery = async (
  filters: SearchParameters,
  page: number,
  limit: number,
  unique: number,
  token: string,
  cancelToken: CancelToken
) => {
  const params = {
    country: filters.countries[0],
    // force: '1',
    since: format(filters.startDate, "yyyy/MM/dd"),
    until: format(filters.endDate, "yyyy/MM/dd"),
    industries: getIds(filters.advancedFilters.industries),
    advertisers: getIds(filters.advancedFilters.advertisers),
    publishers: getIds(filters.advancedFilters.publishers),
    excludedPublishers: getIds(filters.advancedFilters.excludedPublishers),
    sources: getIds(filters.advancedFilters.sources),
    categories: getIds(filters.advancedFilters.categories),
    products: getIds(filters.advancedFilters.products),
    formats: getIds(filters.advancedFilters.formats),
    platforms: getIds(filters.advancedFilters.platforms),
    page,
    limit,
    unique,
  };

  const url = `${process.env.REACT_APP_API_ENDPOINT}/gallery/export/pdf`;

  LogRocket.track("ExportGallery", {
    url,
    ...params,
  });

  return axios
    .get(url, {
      params,
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((response: AxiosResponse<{ _links: { self: { href: string } } }>) => {
      const data = response.data;
      if (data._links && data._links.self && data._links.self.href) {
        window.open(
          `${process.env.REACT_APP_MAIL_ENDPOINT}${data._links.self.href}`,
          "_blank"
        );
      }
    });
};

export const exportMailCampaign = async (
  filters: ReportMailParameters,
  type: "campaign" | "share-of-voice" | "investment" | "campaign-rts",
  reportFormat: "pdf" | "excel",
  token: string,
  cancelToken: CancelToken
) => {
  const params = {
    country: filters.countries[0],
    // force: '1',
    since: format(filters.startDate, "yyyy/MM/dd"),
    until: format(filters.endDate, "yyyy/MM/dd"),
    industries: filters.advancedFilters.industries,
    advertisers: filters.advancedFilters.advertisers,
    publishers: filters.advancedFilters.publishers,
    excludedPublishers: filters.advancedFilters.excludedPublishers,
    sources: filters.advancedFilters.sources,
    categories: filters.advancedFilters.categories,
    products: filters.advancedFilters.products,
    formats: filters.advancedFilters.formats,
    platforms: filters.advancedFilters.platforms,
  };

  const url = `${process.env.REACT_APP_API_ENDPOINT}/${type}/export/${reportFormat}`;

  LogRocket.track("ExportMailCampaign", {
    url,
    type,
    reportFormat,
    ...params,
  });

  return axios
    .get(url, {
      params,
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((response: AxiosResponse<{ _links: { self: { href: string } } }>) => {
      const data = response.data;
      if (data._links && data._links.self && data._links.self.href) {
        window.open(`https://adcuality.com${data._links.self.href}`, "_blank");
      }

      return data;
    });
};

export const fetchUserToken = async (email: string, password: string) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
      username: email,
      password: password,
    })
    .then((result) => {
      return result.data.token;
    });
};

export const sendResetPasswordEmail = async (email: string) => {
  const formData = new FormData();
  formData.set("username", email);
  return axios
    .post(`${process.env.REACT_APP_MAIL_ENDPOINT}/v2/account/send_email`, {
      username: email,
    })
    .then((result) => {
      return result.status;
    })
    .catch((reason) => {
      console.log("Motivo del error " + reason);
      return reason.status;
    });
};

export const changeUsernameAndEmail = async (
  name: string,
  surname: string,
  currentPassword: string,
  token: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/account/edit`,
      {
        payload: {
          name,
          surname,
        },
        current_password: currentPassword,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      return result.status;
    })
    .catch((reason) => {
      return reason.status;
    });
};

export const acceptTermsAPI = async (token: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/account/terms/accept`,
      {
        accept: true,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      return result.status;
    })
    .catch((reason) => {
      return reason.status;
    });
};

export const changeCurrentPassword = async (
  currentPassword: string,
  newPassword: string,
  token: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/account/password`,
      {
        payload: {
          plainPassword: newPassword,
        },
        current_password: currentPassword,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      return result.status;
    })
    .catch((reason) => {
      console.log("error" + reason);
      return reason.status;
    });
};

export const changeLocale = async (
  currentPassword: string,
  locale: string,
  token: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/account/locale`,
      {
        payload: {
          locale,
        },
        current_password: currentPassword,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      return result.status;
    })
    .catch((reason) => {
      console.log("error" + reason);
      return reason.status;
    });
};

export const fetchSystemMessagesAPIv2 = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/v2/messages/all`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const sendHelpTicket = async () => {
  //todo: no se como se comunica esta data con el server.
  return 200;
};

export const GetExchangeRate = async (currencies: string[], token: CancelToken) => {
  try {
    const currencyToken = "6249a1c30f1520491162a54113d2e87b";

    const rates = await axios.get(
      `https://api.currencylayer.com/live?access_key=${currencyToken}&currencies=${currencies.join(
        ","
      )}`,
      { cancelToken: token }
    );
    if (rates.data.success) {
      const rate: {
        [param: string]: number;
      } = rates.data.quotes;
      return rate;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const getQueryVariables = (searchParameters: SearchParameters) => {
  return {
    since: format(searchParameters.startDate, "yyyy-MM-dd"),
    until: format(searchParameters.endDate, "yyyy-MM-dd"),
    advertisers: getIds(searchParameters.advancedFilters.advertisers),
    formats: getIds(searchParameters.advancedFilters.formats),
    industries: getIds(searchParameters.advancedFilters.industries),
    publishers: getIds(searchParameters.advancedFilters.publishers),
    excludedPublishers: getIds(searchParameters.advancedFilters.excludedPublishers),
    platforms: getIds(searchParameters.advancedFilters.platforms),
    products: getIds(searchParameters.advancedFilters.products),
    categories: getIds(searchParameters.advancedFilters.categories),
    sources: getIds(searchParameters.advancedFilters.sources),
  };
};

export const fetchShareOfVoiceDataAPIv2 = async (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  cancelToken: CancelToken,
  snap = false,
  lang = "en"
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });

  const url = `${
    process.env.REACT_APP_API_ENDPOINT
  }/v2/sov/${type.toLowerCase()}?snap=${snap ? 1 : 0}&lang=${lang}`;

  postBody.forEach((item) => {
    LogRocket.track(`ShareOfVoice-${item.country}`, {
      url,
      type,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      return result.data;
    });
};

export const fetchInvestmentDataAPIv2 = async (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  cancelToken: CancelToken,
  snap = false,
  lang = "en"
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });

  const url = `${
    process.env.REACT_APP_API_ENDPOINT
  }/v2/soi/${type.toLowerCase()}?snap=${snap ? 1 : 0}&lang=${lang}`;

  postBody.forEach((item) => {
    LogRocket.track(`RealTimeSpend-${item.country}`, {
      url,
      type,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      const items = result.data;
      return items;
    });
};

export const fetchCampaignsDataAPIv2 = async (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  unique: 0 | 1,
  lang: string,
  cancelToken: CancelToken
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });
  const params = {
    page: paginationParameters.requestedPageNumber,
    limit: paginationParameters.requestedResults,
  };

  const url = `${process.env.REACT_APP_API_ENDPOINT}/v2/gallery/${params.page}/${params.limit}?unique=${unique}&lang=${lang}`;

  postBody.forEach((item) => {
    LogRocket.track(`Campaigns-${item.country}`, {
      url,
      ...params,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      return Array.isArray(result.data) ? result.data[0] : result.data;
    });
};

export const fetchSnapStatsDataAPIv2 = async (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  cancelToken: CancelToken
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });

  const url = `${
    process.env.REACT_APP_API_ENDPOINT
  }/v2/snapshots/stats/${type.toLowerCase()}`;

  postBody.forEach((item) => {
    LogRocket.track(`SnapStats-${item.country}`, {
      url,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      const items = result.data;
      const obj = {
        items: items,
      };
      return obj;
    });
};

export const fetchSnapTableDataAPIv2 = async (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  page: number,
  cancelToken: CancelToken,
  lang: string,
  limits?: number,
  downloadCsv?: boolean
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });

  let url = `${
    process.env.REACT_APP_API_ENDPOINT
  }/v2/snapshots/table/${type.toLowerCase()}/${page}`;

  if (downloadCsv && limits) {
    url += `/${limits}?lang=${lang}`;
  } else {
    url += `/20?lang=${lang}`;
  }

  postBody.forEach((item) => {
    LogRocket.track(`SnapTable-${item.country}`, {
      url,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      const items = result.data;

      if (downloadCsv && items) {
        getSnapTableCSV(items);
      }

      const obj = {
        items: items,
      };
      return obj;
    });
};

export const fetchSnapAudiencesAPI = async (
  searchParameters: SearchParameters,
  token: string,
  cancelToken: CancelToken
) => {
  const queryVariables = getQueryVariables(searchParameters);

  const postBody = searchParameters.countries.map((country) => {
    return {
      country: country,
      ...queryVariables,
    };
  });

  const url = `${process.env.REACT_APP_API_ENDPOINT}/v2/audiences`;

  postBody.forEach((item) => {
    LogRocket.track(`SnapAudiences-${item.country}`, {
      url,
      ...item,
    });
  });

  return axios
    .post(url, postBody, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      const items = result.data;
      return items;
    });
};

export const fetchAutocompleteAPIv2 = async (
  term: string,
  token: string,
  type: string,
  page: number,
  per_page: number
): Promise<{
  options: OptionsList<sel>;
  hasMore: boolean;
  additional: {
    page: number;
  };
}> => {
  const query = {
    q: term,
    country: "",
    page,
    per_page,
  };

  return axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/autocomplete/${type.toLowerCase()}`,
      {
        params: query,
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      const values: sel[] = [];
      const auto = result.data;
      auto.results.forEach((item: AutocompleteInterface) =>
        values.push({ value: item.text, label: item.text, id: item.id })
      );
      return {
        options: values,
        hasMore: auto.pagination?.more,
        additional: {
          page: page + 1,
        },
      };
    })
    .catch(() => {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    });
};

interface AutocompleteInterface {
  id: number;
  text: string;
}

interface sel {
  value: string;
  label: string;
  id: number;
}

export const fetchIndustriesListsAPIv2 = async (
  token: string,
  cancelToken: CancelToken
): Promise<
  Array<{
    id: number;
    name: string;
  }>
> => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/v2/list/industries`, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken,
    })
    .then((result) => {
      return result.data;
    });
};

export const fetchReportsAPIv2 = async (token: string, cancelToken: CancelToken) => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/v2/reports`, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken: cancelToken,
    })
    .then((result) => {
      return result.data;
    });
};

export const createReportAPIv2 = async (
  token: string,
  report: CreateReportInterface
) => {
  LogRocket.track("CreateReport", {
    url: `${process.env.REACT_APP_API_ENDPOINT}/v2/reports`,
    reportName: report.name,
    reportFormats: report.formats,
    reportFrequency: report.frequency,
    ...report.parameters,
  });

  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/v2/reports`, report, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const editReportAPIv2 = async (
  token: string,
  report: EditReportInterface
) => {
  const id = report.id;
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/v2/reports/${id}/edit`, report, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const toggleReportAPIv2 = async (
  token: string,
  id: string,
  enabled: boolean
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/reports/${id}/toggle`,
      {
        enabled: !enabled,
      },
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
    .then((result) => {
      return result.data;
    });
};

export const deleteReportAPIv2 = async (token: string, id: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/v2/reports/${id}`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const fetchAlertsAPI = async (token: string, cancelToken: CancelToken) => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/v2/alerts`, {
      headers: {
        authorization: "Bearer " + token,
      },
      cancelToken,
    })
    .then((result) => {
      return result.data as AlertsInterface[];
    });
};

export const createAlertAPI = async (token: string, alert: AlertsInterface) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/v2/alerts`, alert, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const editAlertAPI = async (token: string, alert: AlertsInterface) => {
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/v2/alerts/${alert.id}`, alert, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const deleteAlertAPI = async (token: string, id: number) => {
  return axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/v2/alerts/${id}`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export const ddsWithoutCaptureAPIv2 = async (
  token: string,
  country: string,
  since: Date,
  until: Date,
  cancelToken: CancelToken
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/v2/ddsWithoutCapture`,
      [
        {
          country,
          since: format(since, "yyyy-MM-dd"),
          until: format(until, "yyyy-MM-dd"),
        },
      ],
      {
        headers: {
          authorization: "Bearer " + token,
        },
        cancelToken,
      }
    )
    .then((result) => {
      return result.data;
    });
};
