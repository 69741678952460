import { Form, Formik } from "formik"
import * as React from "react"
import * as Yup from "yup"
import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { ProfileFormReduxProps } from "./ProfileFormContainer"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0),
    },
    input: {
      display: "none",
    },
    textField: {
      outlineColor: "#293E76",
      color: "#293E76",
    },
    formButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    },
    form: {
      width: "100%",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
)

const ProfileFormView = (props: ProfileFormReduxProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Enter your name"),
    surname: Yup.string()
      .required("Enter your surname"),
  })

  return (
    <Formik
      onSubmit={(values) => {
        props.submit_form(values.name, values.surname, formatMessage("accountPage.profile.message"))
        props.toggle_dialog()
      }}
      initialValues={{ name: props.login_state.name, surname: props.login_state.surname }}
      validationSchema={validationSchema}
    >
      {({ handleChange, errors, touched }) => (
        <Form className={classes.form}>
          <TextField
            className={classes.textField}
            margin={"normal"}
            id={"name-textfield"}
            name={"name"}
            defaultValue={props.login_state.name}
            onChange={handleChange}
            fullWidth
            label={<FormattedMessage id={"accountPage.tabs.profile.name"} />}
            disabled={!props.navigation_state.canEditForm}
            helperText={<FormattedMessage id={"accountPage.helperText.name"} />}
            error={errors.name && touched.name ? true : false}
          />

          {errors.name && touched.name ? <div>{errors.name}</div> : null}

          <TextField
            className={classes.textField}
            id={"surname-textfield"}
            name={"surname"}
            defaultValue={props.login_state.surname}
            onChange={handleChange}
            fullWidth
            label={<FormattedMessage id={"accountPage.tabs.profile.surname"} />}
            disabled={!props.navigation_state.canEditForm}
            helperText={<FormattedMessage id={"accountPage.helperText.surname"} />}
            error={errors.surname && touched.surname ? true : false}
          />

          {errors.surname && touched.surname ? <div>{errors.surname}</div> : null}

          <div className={classes.formButtons}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={props.edit_form}
            >
              {props.navigation_state.canEditForm ? (
                <FormattedMessage id={"navigation.buttons.cancel"} />
              ) : (
                <FormattedMessage id={"navigation.buttons.edit"} />
              )}
            </Button>
            <Button
              variant="outlined"
              type={"submit"}
              color="primary"
              className={classes.button}
              disabled={!props.navigation_state.canEditForm}
            >
              {props.login_state.loading ? (
                <CircularProgress size={24.5} />
              ) : (
                <FormattedMessage id={"navigation.buttons.submit"} />
              )}
            </Button>
          </div>
          <Divider />
        </Form>
      )}
    </Formik>
  )
}

export default ProfileFormView
