import {
  CampaignsItem,
  FacebookStoryStyles,
  TwitterStyles,
} from "../../store/data/types";
import { CardMedia, makeStyles } from "@material-ui/core";
import React from "react";
import YouTube from "react-youtube-embed";
import { CampaignMediaTypes } from "../../utils/CampaignMediaTypes";
import { FacebookStoryAdTypes } from "../../utils/constants";

interface CustomCardProps {
  campaign: CampaignsItem;
}

export const CustomCardMedia = (props: CustomCardProps) => {
  const classes = useStyles();

  const attributes =
    props.campaign.style === CampaignMediaTypes.twitter
      ? (props.campaign.styleAttributes as TwitterStyles)
      : null;
  const twitterImage = attributes?.img_profile?.src ?? attributes?.header?.img?.src;

  switch (props.campaign.style) {
    case CampaignMediaTypes.default:
      return (
        <CardMedia
          className={classes.cardMedia}
          image={props.campaign.creativity}
          title={props.campaign.advertiser}
        />
      );
    case CampaignMediaTypes.youtube:
      return props.campaign.creativity.includes("https://youtube.com/embed/") ? (
        <YouTube
          id={props.campaign.creativity.split("https://youtube.com/embed/")[1]}
        />
      ) : (
        <CardMedia
          className={classes.cardMedia}
          image={props.campaign.creativity}
          title={props.campaign.advertiser}
        />
      );
    case CampaignMediaTypes.facebookStory:
      if (
        props.campaign.styleAttributes &&
        (props.campaign.styleAttributes as FacebookStoryStyles).body.media[0]
          .format === "image"
      ) {
        return (
          <CardMedia
            className={classes.cardMedia}
            image={props.campaign.creativity}
            title={props.campaign.advertiser}
          />
        );
      }
      return (
        <CardMedia
          className={classes.videoFacebook}
          component={
            (props.campaign.styleAttributes as FacebookStoryStyles).type ===
              FacebookStoryAdTypes.video ||
            (props.campaign.styleAttributes as FacebookStoryStyles).type ===
              FacebookStoryAdTypes.videoMP4
              ? "video"
              : "img"
          }
          src={props.campaign.creativity}
          title={props.campaign.advertiser}
          height={300}
        />
      );
    case CampaignMediaTypes.facebookPost:
      return (
        <CardMedia
          className={classes.cardMedia}
          image={props.campaign.creativity}
          title={props.campaign.advertiser}
        />
      );
    case CampaignMediaTypes.video:
      return (
        <CardMedia
          className={classes.videoFacebook}
          component={"video"}
          src={props.campaign.creativity}
          title={props.campaign.advertiser}
          height={300}
        />
      );
    case CampaignMediaTypes.twitter:
      return (
        <CardMedia
          className={classes.cardMedia}
          image={
            twitterImage ??
            "https://via.placeholder.com/350x200?text=Twitter+Promoted+Trend"
          }
          title={props.campaign.advertiser}
        />
      );
    default:
      return (
        <span className={classes.cardMedia} role={"img"} aria-label="Error!">
          😬
        </span>
      );
  }
};

const useStyles = makeStyles(() => ({
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  videoFacebook: {
    objectFit: "cover",
  },
}));
