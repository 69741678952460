/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import TabLayout from "../../components/TabLayout";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  shareOfVoiceDataRequest,
  // unfilteredShareOfVoiceRequest,
} from "../../store/data/actions";
import { TABS, TabPropNames } from "../../utils/TabCodes";
import { Grid, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import {
  SignalCellularNull,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
} from "@material-ui/icons";
import SingleCountryCharts from "./SingleCountryCharts";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";
import ShortTableView, {
  Column,
  MultiCountryRow,
} from "../../components/dashboard/table/ShortTable";
import { ShareVoice, ShareVoiceItemTable } from "../../store/data/types";
import PercentageBar from "../../components/dashboard/PercentageBar";
import ChipValues, { ChipProps } from "../../components/dashboard/table/ChipValues";
// import { initial_state } from "../../store/search/reducer"
import MultiCountryCharts from "./MultiCountryCharts";
import {
  clearAllParameters,
  queueParameter,
  setAllParameters,
} from "../../store/search/actions";

import { SearchParameters } from "../../store/search/types";
import { logout } from "../../store/login/actions";
import { getDateFromReports } from "../../utils/functions";
import { SidebarPages } from "../../store/navigation/types";
import { choose_tab, set_sidebar_page } from "../../store/navigation/actions";

const useStyles = makeStyles((theme) => ({
  percentage: {
    display: "inline-block",
    height: 15,
    width: "70%",
  },
  amountWrapper: {
    display: "inline-block",
    width: "100%",
  },
  amountValue: {
    marginLeft: "10px",
    width: "20%",
  },
  redSpots: {
    color: theme.palette.error.main,
  },
  orangeSpots: {
    color: theme.palette.warning.main,
  },
  yellowSpots: {
    color: "#FFD447",
  },
  greenSpots: {
    color: theme.palette.success.main,
  },
}));

const LinearMemo = React.memo(
  (props: {
    row: ShareVoiceItemTable;
    filtered: ShareVoice[];
    isLoading: boolean;
  }) => {
    const styles = useStyles();
    const { row, filtered } = props;
    const { advancedFilters } = useSelector((state: RootState) => state.search);
    const { selectedTab } = useSelector((state: RootState) => state.navigation);
    const formatNumber = useFormatNumber();

    const getPercentageWithFilter = (
      total: Array<ShareVoiceItemTable>,
      totalItem: number
    ) => {
      let totalItems = 0;
      total.forEach((item) => {
        totalItems += item.total;
      });

      return (totalItem * 100) / totalItems;
    };

    const amountValue =
      advancedFilters[TabPropNames[selectedTab]].length > 0
        ? getPercentageWithFilter(filtered[0].tableData, row.total)
        : row.percentage;
    const twoDigits = parseFloat(amountValue.toFixed(2)) === 0;
    const lengthDigits = amountValue.toString().split(".")[1]
      ? amountValue.toString().split(".")[1].length > 5
        ? 5
        : amountValue.toString().split(".")[1].length
      : 0;

    return (
      <div className={styles.amountWrapper}>
        <LinearProgress
          className={styles.percentage}
          variant={"determinate"}
          value={row && row.percentage ? amountValue : 0}
        />
        <span className={styles.amountValue}>{`${formatNumber(
          row && row.percentage ? amountValue : 0,
          { maximumFractionDigits: twoDigits ? lengthDigits : 2 }
        )}%`}</span>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row) &&
      prevProps.isLoading === nextProps.isLoading &&
      prevProps.filtered[0].tableData.length ===
        nextProps.filtered[0].tableData.length
    );
  }
);

const SignalMemo = React.memo(
  (props: { row: ShareVoiceItemTable }) => {
    const styles = useStyles();
    const { row } = props;
    const { selectedTab } = useSelector((state: RootState) => state.navigation);
    const { advancedFilters } = useSelector((state: RootState) => state.search);
    const formatNumber = useFormatNumber();

    const filtersAndIsntCurrentTab = () => {
      const tabName = TabPropNames[selectedTab];

      const excludeCurrent = TabPropNames.filter((tab) => tab !== tabName);
      const isSomeFilterActivated = excludeCurrent.some(
        (tab) => advancedFilters[tab].length > 0
      );
      return isSomeFilterActivated;
    };

    const isFiltered = filtersAndIsntCurrentTab();
    const spots = row.total;

    return (
      <div>
        <Tooltip title={`${formatNumber(spots)} Spots`}>
          {(isFiltered && row.percentage > 2.0) ||
          ((selectedTab === 1 || selectedTab === 2) && row.percentage > 0.01) ||
          row.percentage > 0.4 ? (
            (isFiltered && row.percentage > 18.0) ||
            ((selectedTab === 1 || selectedTab === 2) && row.percentage > 0.07) ||
            row.percentage > 5.0 ? (
              <SignalCellular4Bar className={styles.greenSpots} />
            ) : (isFiltered && row.percentage > 6.0) ||
              ((selectedTab === 1 || selectedTab === 2) && row.percentage > 0.02) ||
              row.percentage > 2.0 ? (
              <SignalCellular3Bar className={styles.yellowSpots} />
            ) : (
              <SignalCellular2Bar className={styles.orangeSpots} />
            )
          ) : row.total === 0 ? (
            <SignalCellularNull className={styles.redSpots} />
          ) : (
            <SignalCellular1Bar
              className={
                selectedTab !== 1 && selectedTab !== 2
                  ? styles.redSpots
                  : (isFiltered && row.percentage > 0.4) || row.percentage > 0.005
                  ? styles.orangeSpots
                  : styles.redSpots
              }
            />
          )}
        </Tooltip>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row);
  }
);

const ShareVoicePage = () => {
  const { hasRequestFailed, isDataLoading } = useSelector(
    (state: RootState) => state.data
  );
  const filtered = useSelector((state: RootState) => state.data.shareOfVoice);
  /* const unfiltered = useSelector(
    (state: RootState) => state.data.unfilteredShareOfVoice
  ); */
  const { userReports } = useSelector((state: RootState) => state.data);
  const {
    countries,
    startDate,
    endDate,
    advancedFilters,
    paginationParameters,
  } = useSelector((state: RootState) => state.search);
  const { selectedTab, selectedPage } = useSelector(
    (state: RootState) => state.navigation
  );
  const { token } = useSelector((state: RootState) => state.login);
  const multiCountriesView = countries.length > 1;
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();
  const { reportId } = useParams<{
    reportId: string;
  }>();
  const { roles } = useSelector((state: RootState) => state.login);
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(roles)) {
      const isAllowed = roles.some((item) => item === "ROLE_FEATURE_SHARE_OF_VOICE");
      if (!isAllowed) {
        history.push("/404");
      }
    } else {
      dispatch(logout());
      dispatch(clearAllParameters());
    }
  }, [roles, history, dispatch]);

  /* useEffect(() => {
    if(TabPropNames[selectedTab] !== 'products') {
      !localStorage.getItem('sharedSearchParameters') &&
      dispatch(unfilteredShareOfVoiceRequest({...initial_state, startDate, endDate, countries}, paginationParameters, token, TABS[selectedTab]))
    }
  }, [countries, startDate, endDate, paginationParameters, token, selectedTab, dispatch]); */

  useEffect(() => {
    !localStorage.getItem("sharedSearchParameters") &&
      dispatch(
        shareOfVoiceDataRequest(
          { countries, startDate, endDate, advancedFilters },
          paginationParameters,
          token,
          TABS[selectedTab]
        )
      );
  }, [
    countries,
    startDate,
    endDate,
    advancedFilters,
    paginationParameters,
    token,
    selectedTab,
    dispatch,
  ]);

  useEffect(() => {
    const report = reportId && userReports.find((r) => r.id === reportId);
    if (report) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { country, page, countries, ...params } = report.parameters;
      const advanced = {
        advertisers: [],
        formats: [],
        platforms: [],
        publishers: [],
        excludedPublishers: [],
        categories: [],
        industries: [],
        products: [],
        sources: [],
      };
      dispatch(queueParameter(advanced));
      const newAdvanced = {
        ...advanced,
        ...params,
      };

      if (newAdvanced.products.length > 0) {
        dispatch(choose_tab(2));
      } else if (newAdvanced.advertisers.length > 0) {
        dispatch(choose_tab(1));
      }

      const dates = getDateFromReports(report.frequency, false);

      dispatch(
        setAllParameters(countries ?? [country], dates[1], dates[0], {
          ...newAdvanced,
        })
      );
      history.push(`/dashboard/${SidebarPages.shareofvoice}`);
      dispatch(set_sidebar_page(SidebarPages.shareofvoice));
    }
  }, [startDate, endDate, reportId, userReports, history, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("sharedSearchParameters") !== null) {
      const sharedParameters: SearchParameters = JSON.parse(
        localStorage.getItem("sharedSearchParameters") ?? "{}"
      );
      const startDate = new Date(sharedParameters.startDate.toString());
      const endDate = new Date(sharedParameters.endDate.toString());
      const newCountries = sharedParameters.countries;
      localStorage.removeItem("sharedSearchParameters");
      setTimeout(() => {
        dispatch(
          setAllParameters(
            newCountries,
            startDate,
            endDate,
            sharedParameters.advancedFilters
          )
        );
      }, 500);
    }
  }, [dispatch]);

  const getPviewavility = (index: number, filter: string) => {
    const item = filtered[index].tableData.find((item) => item.label === filter);
    return (item && item.viewability) || "0";
  };

  const getSortedValues = (filter: string) => {
    const chips: ChipProps[] = [];
    filtered.forEach((countryData, index) => {
      const pview = getPviewavility(index, filter);
      chips.push({
        label: countryData.country,
        value:
          pview === "0" || pview === "0.0" ? "N/A" : formatNumber(parseFloat(pview)),
      });
    });
    return chips;
  };

  const getMCspots = (id: number) => {
    const chips: ChipProps[] = [];
    filtered.forEach((countryData, index) => {
      const item = filtered[index].tableData.find((x) => x.id === id);
      const spots = item ? item.total : 0;
      chips.push({
        label: countryData.country,
        value: formatNumber(spots),
        percentage: item ? item.percentage : 0.0,
        spots: true,
      });
    });
    return chips;
  };

  const tableColumns: Column[] = [
    {
      field: "label",
      title: formatMessage("shareofvoicePage.table.column.name"),
      defaultSort: multiCountriesView ? "asc" : undefined,
      defaultGroupOrder: multiCountriesView ? 0 : undefined,
      headerStyle: {
        width: "40%",
      },
      cellStyle: {
        width: "40%",
      },
      customSort: (rowa: ShareVoiceItemTable, rowb: ShareVoiceItemTable) => {
        if (rowa.label.toLowerCase() < rowb.label.toLowerCase()) {
          return -1;
        }
        if (rowa.label.toLowerCase() > rowb.label.toLowerCase()) {
          return 1;
        }
        return 0;
      },
    },
    {
      field: "viewability",
      title: "pViewability",
      headerStyle: {
        width: "10%",
      },
      cellStyle: {
        width: "10%",
      },
      grouping: false,
    },
    {
      field: "percentage",
      title: "Share (%)",
      grouping: false,
      headerStyle: {
        width: "40%",
      },
      cellStyle: {
        color: "#000",
        width: "40%",
      },
      render: (row: ShareVoiceItemTable) => {
        return (
          <LinearMemo row={row} filtered={filtered} isLoading={isDataLoading} />
        );
      },
    },
    {
      field: "spots",
      title: "Spots",
      headerStyle: {
        width: "10%",
      },
      cellStyle: {
        width: "10%",
      },
      render: (row: ShareVoiceItemTable) => {
        return <SignalMemo row={row} />;
      },
    },
  ];

  const multiCountriesTableColumns: Column[] = [
    {
      field: "label",
      title: formatMessage("shareofvoicePage.table.column.name"),
    },
    {
      field: "viewability",
      title: "pViewability",
      render: (row: MultiCountryRow) => (
        <ChipValues data={getSortedValues(row.label)} />
      ),
    },
    {
      field: "percentage",
      title: formatMessage("shareofvoicePage.table.column.percentage"),
      render: (row: MultiCountryRow) => (
        <PercentageBar filter={row.label} data={filtered} />
      ),
    },
    {
      field: "spots",
      title: "Spots",
      render: (row: MultiCountryRow) => <ChipValues data={getMCspots(row.id)} />,
    },
  ];

  if (
    TabPropNames[selectedTab] === "sources" ||
    TabPropNames[selectedTab] === "platforms"
  ) {
    filtered.forEach((item: ShareVoice) => {
      item.tableData.forEach((shareItem: ShareVoiceItemTable) => {
        const label = shareItem.label;
        shareItem.label =
          label === "Medios"
            ? formatMessage("graphs.label.desktop")
            : label === "Google"
            ? formatMessage("graphs.label.programmatic")
            : label === "Otros"
            ? formatMessage("graphs.label.direct")
            : label;
      });
    });
  }

  return (
    <TabLayout>
      <Grid container spacing={2}>
        {multiCountriesView ? <MultiCountryCharts /> : <SingleCountryCharts />}
        <Grid item xs={12}>
          <ShortTableView
            columns={multiCountriesView ? multiCountriesTableColumns : tableColumns}
            data={filtered[0] && !hasRequestFailed ? filtered : []}
            tabType={TABS[selectedTab].toLowerCase()}
            currentPage={selectedPage}
            multiCountries={multiCountriesView}
          />
        </Grid>
      </Grid>
    </TabLayout>
  );
};

export default ShareVoicePage;
