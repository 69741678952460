export enum FacebookStoryAdTypes {
  card = "card",
  carousel = "carousel",
  image = "image",
  video = "video",
  videoMP4 = "video/mp4",
}

export enum DataFilter {
  "industry" = "INDUSTRY",
  "advertiser" = "ADVERTISER",
  "product" = "PRODUCT",
  "category" = "CATEGORY",
  "format" = "FORMAT",
  "publisher" = "PUBLISHER",
  "platform" = "PLATFORM",
  "source" = "SOURCE",
}

export enum DataPages {
  "spotlistPage" = "spotslist",
  "campaignsPage" = "campaigns",
  "shareVoicePage" = "shareofvoice",
  "investmentPage" = "investment",
  "snapshotsPage" = "snapshots",
}

export const DataFilterNames: string[] = [
  "industries",
  "advertisers",
  "products",
  "categories",
  "formats",
  "publishers",
  "platforms",
  "sources",
];
