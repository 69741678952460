import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { acceptTermsRequest } from "../../../store/login/actions";
import { toggleWelcomeDialog } from "../../../store/navigation/actions";

const useStylesTerms = makeStyles({
  body: {
    maxHeight: '400px',
    overflow: 'auto',
    '& p': {
      '& span': {
        fontSize: 'x-small'
      }
    }
  },
  spanBold: {
    fontWeight: 'bold',
    fontSize: 'medium'
  },
  spanLine: {
    fontSize: '10px',
    lineHeight: '13px'
  }
});

const UserTerms: React.FC<{}> = () => {
  const classes = useStylesTerms();
  return <>
    <div>
      <h3>TERMINOS Y CONDICIONES DE USO Y CONTRATACION DE ADCUALITY</h3>
    </div>
    <Divider light />
    <div className={classes.body}>
      <p><span><span className={classes.spanBold}>TERMINOS GENERALES DE CONTRATACION Y USO DEL SERVICIO</span><br /></span></p>
      <div><span>&nbsp;VER 0215 - FEB. 2015</span></div>
      <p><br /></p>
      <p>1) DESCRIPCION GENERAL<br /></p>
      <p><span>adCuality es una herramienta de investigaci&oacute;n de mercado que monitorea la publicidad online para los pa&iacute;ses bajo cobertura, generando m&uacute;ltiples reportes para los usuarios que poseen la suscripci&oacute;n. Toda la documentacion sobre las metodolog&iacute;as para el funcionamiento de adCuality se encuentran disponibles en nuestro Customer Center en</span> <a href="https://customer.adcuality.com/" target="_blank"  rel="noopener noreferrer">adCuality Customer Center</a><span>. El acceso a la documentaci&oacute;n se encuentra completa para todos los usuarios de adCuality que se hayan registrado en el Customer Center, cuya registracion es gratuita disponiendo de una suscripci&oacute;n.</span></p>
      <p><span>Los presentes terminos de contrataci&oacute;n y uso del servicio rigen la utilizaci&oacute;n del servicio. Los presentes &ldquo;T&eacute;rminos Generales de Contrataci&oacute;n y Uso del Servicio&rdquo; se refieren al servicio brindado por adCuality para ingresar en la herramienta y visualizar y/o descargar los reportes que all&iacute; se encuentran disponibles, incluyendo todas las caracter&iacute;sticas y funcionalidades que &eacute;sta ofrece, como la interfaz web, servicio de alertas, reportes descargables y todo software asociado a nuestro servicio.</span></p>
      <p>2) TERMINOS DE USO</p>
      <p><span>a) Estos t&eacute;rminos generales de contrataci&oacute;n y uso del servicio rigen el uso del servicio de adCuality. Al utilizar, visitar o navegar por el servicio de adCuality, acuerda y acepta&nbsp; los T&eacute;rminos generales de contrataci&oacute;n y uso. Si no acepta estos T&eacute;rminos, no utilice el servicio adCuality, cerrando el navegador inmediatamente.</span></p>
      <p><span>b) &nbsp;Modificaciones de los t&eacute;rminos de uso. adCuality puede cambiar los terminos y condiciones y dichos cambios ser&aacute;n efectivos inmediatamente al momento de haberlos publicado en el sitio web. Usted acepta expresamente que quedar&aacute; sujeto a dichas modificaciones o enmiendas</span></p>
      <p><span>c) adCuality emplea el uso de cookies para entender como los usuarios hacen uso de la plataforma, para posteriormente mejorar constantemente la experiencia de uso.</span></p>
      <p><span>d) Toda la informaci&oacute;n personal de los usuarios es mantenida por adCuality de manera confidencial y todos nuestros servicios se encuentran bajo certificados SSL.</span></p>
      <p><span>e) Preferencias en la comunicaci&oacute;n. Al utilizar el servicio adCuality, acepta recibir comunicaciones enviadas por adCuality acerca de su cuenta y noticias generales sobre lo relacionado con el servicio como mejoras, y notificaciones generales. adCuality enviar&aacute; las comunicaciones digitales a las direcciones de email declaradas para las altas de las cuentas.</span></p>
      <p><span>f) Al aceptar estos t&eacute;rminos, acepta que la informaci&oacute;n entregada por adCuality es generada bajo nuestra metodolog&iacute;a, siendo &eacute;sta s&oacute;lo una forma no absoluta de observar una realidad, cuyas estad&iacute;sticas son generadas en base a una muestra de anuncios que forman parte de un universo total que no ofrece adCuality.</span></p>
      <p><span>f1) adCuality se reserva el derecho a realizar modificaciones y/o actualizaciones tanto de software como de metodolog&iacute;as y de cualquier software asociado al producto sin previo aviso y el cliente entiende y acepta dichos cambios.</span></p>
      <p><span>g) La &uacute;nica v&iacute;a v&aacute;lida para la solicitud de atenci&oacute;n al cliente, requerimiento sobre el uso del sistema, capacitaciones o cualquier otra necesidad sobre adCuality, deber&aacute; ser remitida a trav&eacute;s del sistema de Tickets en </span><a href="https://customer.adcuality.com/" target="_blank"  rel="noopener noreferrer">adCuality Customer Center</a><span> que se encuentra regido por nuestro Acuerdo SLA que se encuentra disponible aqu&iacute;:</span> <a href="https://customer.adcuality.com/support/solutions/articles/1000149195-acuerdo-sla" target="_blank"  rel="noopener noreferrer">Acuerdo SLA</a></p>
      <p><span>h) El uso del servicio es personal e intransferible. La detecci&oacute;n por parte de adCuality de la distribucion a un tercero de las credenciales de acceso as&iacute; como tambi&eacute;n la informaci&oacute;n a terceros no autorizados o competidores, implicar&aacute; la potestad de adCuality por cobrar dicho servicio, basandose en la tabla de precios de lista vigentes al momento, con penalidades que podr&aacute;n ser hasta 5 veces dicha tarifa.</span></p>
      <p><span>h1) El cliente es responsable por la confidencialidad de los datos de acceso en su cuenta. En caso que los mismos se hayen expuestos a terceros, deber&aacute; informar inmediatamente a adCuality y proceder al cambio de credenciales.</span></p>
      <p><span>i) Queda prohibido el lucro y/o la reventa de la informaci&oacute;n expuesta en adCuality en todas sus versiones, tanto en la informaci&oacute;n web, los reportes descargables o la informaci&oacute;n que es enviada a trav&eacute;s de email procedente de las alertas automatizadas.</span></p>
      <p><span>i1) El contenido de adCuality se provee en el estado en que se encuentra y no podr&aacute; licenciar, comerciarlizar ni vender cualquier conrtenido obtenido de la herramienta sin el consentimiento por escrito de adCuality.</span></p>
      <p><span>j) adCuality no es responsable por las decisiones que tome el cliente en funci&oacute;n de los reportes o informaci&oacute;n que se expone en la herramienta. Toda decisi&oacute;n que el cliente tome o acci&oacute;n que desee ejecutar ser&aacute; pura y exclusiva responsabilidad de &eacute;ste.</span></p>
      <p><span>k) adCuality no es responsable por el contenido de los anuncios y capturas de pantalla expuestos en los reportes, ya que es simplemente una captura de la informaci&oacute;n publica disponible en la web por terceros. adCuality tampoco es responsable por la utilidad, exactitud, seguridad, ni derechos de propiedad intelectual de los anuncios y capturas de pantalla disponibles en la plataforma.</span></p>
      <p><span>k1) El cliente entiende y acepta que podr&aacute; estar expuesto a contenido que puede ser impreciso, ofensivo, indecente u objetable y se obliga a renunciar a cualquier derecho o defensa legal ya sea en dinero o de cualquier otra forma que tenga o que pueda tener contra adCuality.</span></p>
      <p><span>k2) La informaci&oacute;n y el material ofrecido por adCuality deber&aacute; ser utilizado unicamente con fines l&iacute;citos.</span></p>
      <p><span>l) El cliente responde por el buen uso de la informaci&oacute;n, asimismo el cliente declara conocer y poseer todos los conocimientos necesarios para emplear la plataforma.</span></p>
      <p><span>m) El cliente es el &uacute;nico responsable por la calidad y velocidad de su conexion a Internet, eximiendo a adCuality de cualquier responsabilidad sobre ello.</span></p>
      <p><span>m1) El cliente es el responsable de disponer una computadora en condiciones &oacute;ptimas para el uso de la plataforma, con funcionamiento correcto y libre de virus que puedan afectar el desempeño de nuestra plataforma. El cliente entiende y acepta los requerimientos t&eacute;cnicos para el uso de adcuality expuestos en nuestro </span><a href="https://customer.adcuality.com/" target="_blank"  rel="noopener noreferrer">Customer Center</a><span>.</span></p>
      <p><span>n) El cliente se encuentra obligado a poner en aviso a adCuality cuando un empleado culmine su relacion laboral con la empresa que contrata el servicio de adCuality. Cualquier uso de la plataforma por parte de dicho usuario posterior a su desvinculaci&oacute;n de la empresa que contrata el servicio, ser&aacute; facturada a dicho cliente al precio de lista vigente.</span></p>
      <p><span className={classes.spanLine}>o) El cliente est&aacute; obligado a dar aviso a adCuality a trav&eacute;s del Customer Center cuando un empleado que dispone de una cuenta de suscripci&oacute;n deje de formar parte de la empresa, debiendo el cliente responder economicamente por el uso del servicio que el ex-empleado haya generado.</span></p>
      <p>3) TERMINOS DE CONTRATACION</p>
      <p><span>a) El ciclo de facturaci&oacute;n comienza cuando adCuality env&iacute;a al cliente las credenciales de acceso, las cuales son enviadas por email a &eacute;l o los responsables de la contrataci&oacute;n del servicio. Una vez que adCuality ha enviado las mismas, es responsabilidad del cliente la recepci&oacute;n y lectura del email con las credenciales de acceso.</span></p>
      <p><span>b) La suscripci&oacute;n se encuentra siempre activa una vez que adCuality ha enviado las credenciales de acceso. Hacer uso o no de las mismas, es una responsabilidad exclusiva del cliente, as&iacute; como tambi&eacute;n poner en aviso mediante el Customer Center si es que &eacute;ste nunca ha recibido el correspondiente email. De no recibir ninguna comunicaci&oacute;n por parte del cliente, adCuality da por hecho que &eacute;ste ya ha recibido dichas credenciales de acceso.</span></p>
      <p><span>c) La suscripci&oacute;n es por un per&iacute;odo m&iacute;nimo de 12 (doce) meses desde el momento que son enviadas las credenciales de acceso a la plataforma. En caso que el cliente no ejecute el pedido de baja con 30 (treinta) d&iacute;as de anticipaci&oacute;n (ver cap&iacute;tulo &quot;baja del servicio&quot;) la suscripci&oacute;n se renueva instant&aacute;nea y autom&aacute;ticamente por igual per&iacute;odo de tiempo que la suscripci&oacute;n inicial.</span></p>
      <p><span>d) La prestaci&oacute;n de la suscripci&oacute;n por parte de adCuality es al cliente y no a su solicitante en caracter personal. En caso que el solicitante ya no se encuentre desempeñando funciones en el cliente, la suscripci&oacute;n seguir&aacute; vigente.</span></p>
      <p>4) FACTURACION</p>
      <p><span>a) El cliente se obliga al pago mensual de la suscripci&oacute;n al servicio y por el monto acordado en la orden de suscripci&oacute;n de servicios.</span></p>
      <p><span>b) Las v&iacute;as de pago v&aacute;lidas son con cheques y/o transferencias bancarias.</span></p>
      <p><span>c) adCuality se reserva el derecho a ejecutar el cobro de una mora de hasta el 10% mensual sobre el valor total de la factura para todos los casos en donde el pago se encuentre con m&aacute;s de 30 d&iacute;as de atraso.</span></p>
      <p><span>d) La cuota del servicio se facturar&aacute; desde el comienzo del ciclo de la suscripci&oacute;n (ver 4.a) y se facturar&aacute; todos los meses desde entonces. Para la suscripci&oacute;n del servicio en Argentina, el ciclo de facturaci&oacute;n es a mes vencido. Para el resto de los pa&iacute;ses, el servicio se cobra a mes por adelantado. adCuality se reserva el derecho de cambiar los tiempos de facturaci&oacute;n.</span></p>
      <p>5) BAJAS</p>
      <p><span>a) Por parte del cliente, la baja del servicio deber&aacute; ser solicitada&nbsp;</span><span>&nbsp;</span><span>con 30 d&iacute;as de antelaci&oacute;n&nbsp;</span><span>a trav&eacute;s de nuestro&nbsp;</span><a href="https://customer.adcuality.com/" target="_blank"  rel="noopener noreferrer">Customer Center</a><span>&nbsp;por el responsable que ha contratado el servicio, siendo &eacute;sta la &uacute;nica v&iacute;a v&aacute;lida para dicho procedimiento. En caso que la suscripci&oacute;n&nbsp;</span><span>se encuentre dentro de los 12 meses de contrataci&oacute;n m&iacute;nima, el cliente deber&aacute; abonar la diferencia entre el precio pactado y el precio de lista vigente al d&iacute;a de la baja en la misma moneda en la cual fu&eacute; contratado el servicio adicionalmente al equivalente de 1 mes de penalidad.</span></p>
      <p><span>b) Por parte de adCuality, se reserva el derecho de dar de baja la suscripcion en caso que el cliente presente 3 o m&aacute;s facturas impagas, lo cual representa la p&eacute;rdida de los descuentos oportunamente obtenidos y el inicio de acciones legales para el cobro de lo adeudado adicionalmente a la diferencia entre el precio pactado y el precio de lista del servicio al momento de las acciones legales as&iacute; como tambi&eacute;n los costos del departamento de legales, intereses y otros gastos asociados.</span></p>
      <p>6) VIOLACIONES</p>
      <p><span>a) Las violaciones a la seguridad del sistema o el mal uso del mismo est&aacute;n prohibidas como por ejemplo todas acciones que puedan:</span></p>
      <p><span>&nbsp; &nbsp; - dañar</span></p>
      <p><span>&nbsp; &nbsp; - sobrecargar</span></p>
      <p><span>&nbsp; &nbsp; - deteriorar</span></p>
      <p><span>&nbsp; &nbsp; - interferir</span></p>
      <p><span>Estas acciones sobre nuestra plataforma pueden originar una medida legal por parte de adCuality contra sus autores.</span></p>
      <p><span><strong>Links citados:</strong></span></p>
      <p><span><strong>CUSTOMER CENTER: https://customer.adCuality.com/</strong></span></p>
    </div>
  </>;
};

const WelcomeDialog = () => {
    const userMessage = useSelector((state: RootState) => state.login.userMessages.find(msg => msg.enabled === true));
    const termsAccepted = useSelector((state: RootState) => state.login.termsAccepted);
    const isOpen = useSelector((state: RootState) => state.navigation.isWelcomeDialogOpen);
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [
      formatMessage("account.welcomeDialog.sla"),
      formatMessage("account.welcomeDialog.message"),
    ];

    function getStepContent(stepIndex: number) {
      switch (stepIndex) {
        case 0:
          return <UserTerms />;
        case 1:
          return <>
            <Typography gutterBottom variant="h5">
              {userMessage?.name}
            </Typography>
            <span dangerouslySetInnerHTML={{ __html: userMessage?.html || '' }} />
          </>;
        default:
          return 'Unknown stepIndex';
      }
    }

    const handleNext = () => {
      if (activeStep === 0) {
        dispatch(acceptTermsRequest());
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if(activeStep === 1) {
        dispatch(toggleWelcomeDialog());
        setActiveStep(0);
      }
    };

    function handleClick() {
      dispatch(toggleWelcomeDialog());
    }

    return (
      <Dialog open={isOpen && (!termsAccepted || userMessage !== undefined)} style={{
        zIndex: 9999
      }}>
        {
          termsAccepted ?
            <>
              <DialogTitle>
                {userMessage?.name}
              </DialogTitle>
              <DialogContent>
                <span dangerouslySetInnerHTML={{ __html: userMessage?.html || '' }} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClick}>
                  Ok
                </Button>
              </DialogActions>
            </> :
            <>
              <DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {
                    steps.map(label => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))
                  }
                </Stepper>
              </DialogTitle>
              <DialogContent>
                {getStepContent(activeStep)}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleNext} variant="contained" color="primary">
                  {activeStep === 0 ? formatMessage("account.welcomeDialog.buttonTerms") : 'Ok'}
                </Button>
              </DialogActions>
            </>
        }
      </Dialog>
    )
  }

export default WelcomeDialog;