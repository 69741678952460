import {
  BrazilBall,
  ArgentinaBall,
  ChileBall,
  ColombiaBall,
  CostaRicaBall,
  DominicanaBall,
  USABall,
  EcuadorBall,
  SalvadorBall,
  GuatemalaBall,
  HondurasBall,
  MexicoBall,
  PanamaBall,
  ParaguayBall,
  PeruBall,
  PuertoRicoBall,
  SpainBall,
  UruguayBall,
  VenezuelaBall,
} from "../assets/CountryBalls";
import React from "react";

export interface CountriesInterface {
  id: number;
  name: string;
  emoji: string;
}

const countries: CountriesInterface[] = [
  { id: 0, name: "AR", emoji: "🇦🇷" },
  { id: 1, name: "BR", emoji: "🇧🇷" },
  { id: 2, name: "CL", emoji: "🇨🇱" },
  { id: 3, name: "CO", emoji: "🇨🇴" },
  { id: 4, name: "CR", emoji: "🇨🇷" },
  { id: 5, name: "DO", emoji: "🇩🇴" },
  { id: 6, name: "US", emoji: "🇺🇸" },
  { id: 7, name: "EC", emoji: "🇪🇨" },
  { id: 8, name: "ES", emoji: "🇪🇸" },
  { id: 9, name: "SV", emoji: "🇸🇻" },
  { id: 10, name: "GT", emoji: "🇬🇹" },
  { id: 11, name: "HN", emoji: "🇭🇳" },
  { id: 12, name: "MX", emoji: "🇲🇽" },
  { id: 13, name: "PA", emoji: "🇵🇦" },
  { id: 14, name: "PY", emoji: "🇵🇾" },
  { id: 15, name: "PE", emoji: "🇵🇪" },
  { id: 16, name: "PR", emoji: "🇵🇷" },
  { id: 17, name: "UY", emoji: "🇺🇾" },
  { id: 18, name: "VE", emoji: "🇻🇪" },
];

interface EmojisInterface {
  [key: string]: string;
}

export const COUNTRIES_EMOJIS: EmojisInterface = {
  AR: "🇦🇷",
  BR: "🇧🇷",
  CL: "🇨🇱",
  CO: "🇨🇴",
  CR: "🇨🇷",
  DO: "🇩🇴",
  US: "🇺🇸",
  EC: "🇪🇨",
  ES: "🇪🇸",
  SV: "🇸🇻",
  GT: "🇬🇹",
  HN: "🇭🇳",
  MX: "🇲🇽",
  PA: "🇵🇦",
  PY: "🇵🇾",
  PE: "🇵🇪",
  PR: "🇵🇷",
  UY: "🇺🇾",
  VE: "🇻🇪",
};

interface CountryBallMapper {
  [key: string]: JSX.Element | string;
}

export const COUNTRIES_BALLS: CountryBallMapper = {
  AR: <ArgentinaBall width={"24px"} height={"24px"} />,
  BR: <BrazilBall width={"24px"} height={"24px"} />,
  CL: <ChileBall width={"24px"} height={"24px"} />,
  CO: <ColombiaBall width={"24px"} height={"24px"} />,
  CR: <CostaRicaBall width={"24px"} height={"24px"} />,
  DO: <DominicanaBall width={"24px"} height={"24px"} />,
  US: <USABall width={"24px"} height={"24px"} />,
  EC: <EcuadorBall width={"24px"} height={"24px"} />,
  ES: <SpainBall width={"24px"} height={"24px"} />,
  SV: <SalvadorBall width={"24px"} height={"24px"} />,
  GT: <GuatemalaBall width={"24px"} height={"24px"} />,
  HN: <HondurasBall width={"24px"} height={"24px"} />,
  MX: <MexicoBall width={"24px"} height={"24px"} />,
  PA: <PanamaBall width={"24px"} height={"24px"} />,
  PY: <ParaguayBall width={"24px"} height={"24px"} />,
  PE: <PeruBall width={"24px"} height={"24px"} />,
  PR: <PuertoRicoBall width={"24px"} height={"24px"} />,
  UY: <UruguayBall width={"24px"} height={"24px"} />,
  VE: <VenezuelaBall width={"24px"} height={"24px"} />,
};

export const INVESTMENT_UNAVAILABLE = ["VE", "US"];

export const LANGUAGES_LIST = ["en", "es"];

export default countries;
