/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import {
  shareOfVoiceDataRequest,
  investmentDataRequest,
  campaignsDataFetchRequest,
  snapPlatformsDataRequest,
  snapPublishersDataRequest,
  snapSourcesDataRequest,
  snapTableDataRequest,
  snapStatsDataRequest,
  snapPublishersSOIDataRequest,
  setSnapTablePage,
  fetchAudiencesRequest,
} from "../../store/data/actions";
import {
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SingleCountryCharts from "./SingleCountryCharts";
/*
import ShortTableView, {
  Column,
  MultiCountryRow,
} from "../../components/dashboard/table/ShortTable"
*/
// import MultiCountryCharts from "./MultiCountryCharts"
import {
  clearAllParameters,
  setAllParameters,
  set_date_range,
} from "../../store/search/actions";
import { SearchParameters, AdvancedFilters } from "../../store/search/types";
import { logout } from "../../store/login/actions";
import Paper from "@material-ui/core/Paper";
import { SearchToolbar } from "../../components/dashboard/search/SearchToolbarContainer";
import ShortTableView, { Column } from "../../components/dashboard/table/ShortTable";
import { SnapTableItem } from "../../store/data/types";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";
import {
  differenceInMonths,
  startOfToday,
  startOfYesterday,
  subDays,
} from "date-fns";
import LoadingDialog from "../../components/dashboard/dialogs/LoadingDialog";
import { numberWithCommas } from "../../utils/functions";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
  percentage: {
    display: "inline-block",
    height: 15,
    width: "80%",
  },
  amountWrapper: {
    display: "inline-block",
    width: "100%",
  },
  amountValue: {
    marginLeft: "10px",
    width: "10%",
  },
  dividerError: {
    marginTop: 15,
    marginBottom: 15,
  },
  noPrint: {
    "@media print": {
      display: "none",
    },
  },
}));

const initialFilters: AdvancedFilters = {
  advertisers: [],
  publishers: [],
  industries: [],
  products: [],
  platforms: [],
  formats: [],
  categories: [],
  sources: [],
  excludedPublishers: [],
};

const SnapshotsPage = () => {
  //const filtered = useSelector((state: RootState) => state.data.shareOfVoice)
  //const filteredSOI = useSelector((state: RootState) => state.data.investment)
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();
  const {
    hasRequestFailed,
    snapTable,
    snapTablePage,
    errorMessage,
    isDataLoading,
    unfilteredDataLoading,
    isSnapSOILoading,
    isSnapSOVLoading,
    isSnapTableLoading,
    isSnapCasting,
  } = useSelector((state: RootState) => state.data);
  const {
    countries,
    startDate,
    endDate,
    paginationParameters,
    snapType,
    snapFilter,
  } = useSelector((state: RootState) => state.search);
  const { token } = useSelector((state: RootState) => state.login);
  // const multiCountriesView = countries.length > 1
  const dispatch = useDispatch();
  const { roles } = useSelector((state: RootState) => state.login);
  const history = useHistory();
  // const theme = useTheme();
  const styles = useStyles();
  const { selectedPage } = useSelector((state: RootState) => state.navigation);

  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({
    advertisers: [],
    publishers: [],
    industries: [],
    products: [],
    platforms: [],
    formats: [],
    categories: [],
    sources: [],
    excludedPublishers: [],
  });

  useEffect(() => {
    if (Array.isArray(roles)) {
      const isAllowed = roles.some((item) => item === "ROLE_FEATURE_SHARE_OF_VOICE");
      if (!isAllowed) {
        history.push("/404");
      }
    } else {
      dispatch(clearAllParameters());
      dispatch(logout());
    }
  }, [roles, history, dispatch]);

  useEffect(() => {
    if (snapType) {
      setAdvancedFilters({
        ...initialFilters,
        [snapType]: snapFilter !== null ? [snapFilter] : [],
      });
      dispatch(setSnapTablePage(1)); // Reset table pagination
    }
  }, [snapType, snapFilter, setAdvancedFilters, dispatch]);

  useEffect(() => {
    const diffDates = differenceInMonths(endDate, startDate);
    if (diffDates > 3 && !roles.some((item) => item === "ROLE_SUPER_ADMIN")) {
      dispatch(set_date_range(subDays(startOfToday(), 15), startOfYesterday()));
    } else {
      if (!localStorage.getItem("sharedSearchParameters")) {
        if (advancedFilters[snapType].length > 0) {
          const type =
            snapType === "industries" || snapType === "publishers"
              ? "advertisers"
              : "products";
          dispatch(
            shareOfVoiceDataRequest(
              { countries, startDate, endDate, advancedFilters },
              paginationParameters,
              token,
              type,
              true
            )
          );
          dispatch(
            investmentDataRequest(
              { countries, startDate, endDate, advancedFilters },
              paginationParameters,
              token,
              type,
              true
            )
          );
        } else {
          dispatch(
            shareOfVoiceDataRequest(
              { countries, startDate, endDate, advancedFilters },
              paginationParameters,
              token,
              snapType
            )
          );
          dispatch(
            investmentDataRequest(
              { countries, startDate, endDate, advancedFilters },
              paginationParameters,
              token,
              snapType
            )
          );
        }
        dispatch(
          campaignsDataFetchRequest(
            { countries, advancedFilters, startDate, endDate },
            {
              requestedPageNumber: 1,
              requestedResults: 10,
            },
            token
          )
        );

        dispatch(
          snapPublishersDataRequest(
            { countries, startDate, endDate, advancedFilters },
            paginationParameters,
            token,
            snapType === "publishers"
          )
        );
        dispatch(
          snapPublishersSOIDataRequest(
            { countries, startDate, endDate, advancedFilters },
            paginationParameters,
            token,
            snapType === "publishers"
          )
        );
        dispatch(
          snapPlatformsDataRequest(
            { countries, startDate, endDate, advancedFilters },
            paginationParameters,
            token
          )
        );
        dispatch(
          snapSourcesDataRequest(
            { countries, startDate, endDate, advancedFilters },
            paginationParameters,
            token
          )
        );
        dispatch(
          snapStatsDataRequest(
            { countries, startDate, endDate, advancedFilters },
            paginationParameters,
            token,
            snapType
          )
        );
        dispatch(
          fetchAudiencesRequest(
            { countries, startDate, endDate, advancedFilters },
            token
          )
        );
      }
    }
  }, [
    countries,
    startDate,
    endDate,
    paginationParameters,
    token,
    advancedFilters,
    snapType,
    dispatch,
    roles,
  ]);

  useEffect(() => {
    if (!localStorage.getItem("sharedSearchParameters")) {
      if (advancedFilters[snapType].length > 0) {
        const tableType = snapType === "advertisers" ? "products" : "advertisers";
        dispatch(
          snapTableDataRequest(
            { countries, startDate, endDate, advancedFilters },
            token,
            tableType,
            snapTablePage
          )
        );
      } else {
        dispatch(
          snapTableDataRequest(
            { countries, startDate, endDate, advancedFilters },
            token,
            snapType,
            snapTablePage
          )
        );
      }
    }
  }, [
    countries,
    startDate,
    endDate,
    paginationParameters,
    token,
    advancedFilters,
    snapType,
    snapTablePage,
    roles,
    dispatch,
  ]);

  useEffect(() => {
    if (localStorage.getItem("sharedSearchParameters") !== null) {
      const sharedParameters: SearchParameters = JSON.parse(
        localStorage.getItem("sharedSearchParameters") ?? "{}"
      );
      const startDate = new Date(sharedParameters.startDate.toString());
      const endDate = new Date(sharedParameters.endDate.toString());
      dispatch(
        setAllParameters(
          [sharedParameters.countries[0]],
          startDate,
          endDate,
          sharedParameters.advancedFilters
        )
      );
      localStorage.removeItem("sharedSearchParameters");
    }
  }, [dispatch]);

  const getPercentageWithFilter = (
    total: Array<SnapTableItem>,
    totalItem: number
  ) => {
    let totalItems = 0;
    total.forEach((item) => {
      totalItems += item.total;
    });

    return (totalItem * 100) / totalItems;
  };

  const tableColumns: Column[] = [
    {
      field: "label",
      title: formatMessage("shareofvoicePage.table.column.name"),
      headerStyle: {
        width: "30%",
      },
      cellStyle: {
        width: "30%",
      },
    },
    {
      field: "viewability",
      title: "pViewability",
      headerStyle: {
        width: "5rem",
      },
      cellStyle: {
        width: "5rem",
      },
      grouping: false,
    },
    {
      field: "percentage",
      title: "SOV (%)",
      grouping: false,
      headerStyle: {
        width: "30rem",
      },
      cellStyle: {
        color: "#000",
        width: "30rem",
      },
      render: (row: SnapTableItem) => {
        const item =
          snapTable.items[0] &&
          snapTable.items[0].table.items.find((item) => item.id === row.id);
        return (
          <div className={styles.amountWrapper}>
            <LinearProgress
              className={styles.percentage}
              variant={"determinate"}
              value={
                item && item.percentage
                  ? advancedFilters[snapType].length > 0
                    ? getPercentageWithFilter(
                        snapTable.items[0].table.items,
                        item.total
                      )
                    : item.percentage
                  : 0
              }
            />
            <span className={styles.amountValue}>{`${formatNumber(
              item && item.percentage
                ? advancedFilters[snapType].length > 0
                  ? getPercentageWithFilter(
                      snapTable.items[0].table.items,
                      item.total
                    )
                  : item.percentage
                : 0,
              { maximumFractionDigits: 2 }
            )}%`}</span>
          </div>
        );
      },
    },
    {
      field: "amount",
      title: "SOI ($)",
      grouping: false,
      headerStyle: {
        width: "15rem",
      },
      cellStyle: {
        color: "#000",
        width: "15rem",
      },
      render: (row: SnapTableItem) => {
        return (
          <div className={styles.amountWrapper}>
            <span className={styles.amountValue}>
              {numberWithCommas(row.amount)}{" "}
              {snapTable.items[0] && snapTable.items[0].table.currency}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <SearchToolbar />
      <Collapse
        in={
          isDataLoading ||
          unfilteredDataLoading ||
          isSnapSOILoading ||
          isSnapSOVLoading ||
          isSnapTableLoading
        }
        mountOnEnter
        unmountOnExit
      >
        <LinearProgress />
      </Collapse>
      {hasRequestFailed ? (
        <>
          <Divider className={styles.dividerError} />
          <Alert severity="warning">
            {errorMessage === "No data available. Try with other criteria."
              ? formatMessage("errors.data.noDataAvailableSnaps")
              : errorMessage}
          </Alert>
        </>
      ) : (
        <Paper className={styles.paper} id="capture">
          <SingleCountryCharts
            countries={countries}
            filters={advancedFilters}
            snapType={snapType}
          />
          {/* multiCountriesView ? <MultiCountryCharts /> : <SingleCountryCharts /> */}
          <Grid container id="snap-export-third-page">
            <Grid item xs={12} className={styles.noPrint}>
              <ShortTableView
                columns={tableColumns}
                data={snapTable.items[0] && !hasRequestFailed ? snapTable : []}
                tabType={snapType}
                currentPage={selectedPage}
                multiCountries={false}
                snap={true}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      <LoadingDialog
        isLoading={isSnapTableLoading || isSnapCasting}
        title={isSnapCasting ? "Exporting" : formatMessage("snapshots.loading")}
      />
    </>
  );
};

export default SnapshotsPage;
