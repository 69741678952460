export enum CampaignMediaTypes {
  "default" = "default",
  "youtube" = "youtube-pre-roll",
  "facebookPost" = "facebook",
  "facebookStory" = "facebook-story-ad",
  "pauteFacil" = "paute-facil",
  "peruRed" = "peru-red",
  "twitter" = "twitter",
  "video" = "video",
}
