import React, { useEffect } from "react";
import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Theme,
} from "@material-ui/core";
import { unstable_trace as trace } from "scheduler/tracing";
import {
  AdvancedSearchInterface,
  AlertsInterface,
} from "../../../store/search/types";
import { isMobile } from "react-device-detect";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import AsyncSelector from "../search/AutocompleteMultiple";
import { publishEvent } from "../../../services/GoogleAnalytics";
import { AlertsReduxProps } from "./AlertsDialogContainer";

export interface SelectedValuesInterface {
  value: string;
  label: string;
  id: number;
}

const AlertsDialog = (props: AlertsReduxProps) => {
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [parameters, setParameters] = React.useState<AlertsInterface>({
    id: 0,
    name: "",
    advertisers: [],
  });

  const classes = useStyles();

  useEffect(() => {
    if (props.isUserAlertCreated) {
      setParameters({
        id: 0,
        name: "",
        advertisers: [],
      });
    }
  }, [props.isUserAlertCreated]);

  useEffect(() => {
    if (props.userAlertToEdit !== null) {
      setParameters(props.userAlertToEdit);
    } else {
      setParameters({
        id: 0,
        name: "",
        advertisers: [],
      });
    }
  }, [props.userAlertToEdit]);

  function applyParameters() {
    if (parameters.id === 0) {
      props.createAlert(props.token, parameters);
    } else {
      props.edit_alert(props.token, parameters);
    }
    setSnackOpen(true);
  }

  function handleSearchSubmission() {
    publishEvent(
      "Guardar",
      "Actualizar configuración de alertas",
      parameters.toString()
    );
    applyParameters();
    props.toggle_dialog();
  }

  return (
    <>
      <Dialog
        open={props.navigation_state.isAlertsDialogOpen}
        onClose={props.toggle_dialog}
        fullWidth
        maxWidth={"md"}
        fullScreen={isMobile}
        scroll="body"
        disableEnforceFocus
      >
        {isMobile ? (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.toggle_dialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {props.intl.formatMessage({
                  id: "navigation.header.alertsDialog",
                })}
              </Typography>
              <Button color="inherit" onClick={handleSearchSubmission}>
                {props.intl.formatMessage({ id: "navigation.buttons.submit" })}
              </Button>
            </Toolbar>
          </AppBar>
        ) : (
          <DialogTitle id="form-dialog-title">
            {props.intl.formatMessage({ id: "navigation.header.alertsDialog" })}
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText>
            {props.intl.formatMessage({ id: "alerts.configDialog.dialogText" })}
          </DialogContentText>
          <div className={classes.root}>
            <Grid container spacing={3}>
              {props.alerts.length > 2 && props.userAlertToEdit === null ? (
                <Grid item xs={12}>
                  <Typography variant="body1" color="error">
                    {props.intl.formatMessage({
                      id: "alerts.configDialog.alertsLimit",
                    })}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  label={props.intl.formatMessage({
                    id: "alerts.configDialog.alertName",
                  })}
                  fullWidth
                  onChange={(event) => {
                    setParameters((prevState) => ({
                      ...prevState,
                      name: event.target.value,
                    }));
                  }}
                  value={parameters.name}
                />
              </Grid>
              <Grid item xs={12}>
                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.advertisers}
                  values={parameters.advertisers}
                  token={props.token}
                  type={"advertisers"}
                  placeholder={props.intl.formatMessage({
                    id: "alerts.configDialog.advertisers",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null) {
                      if (values.length > 4) return;
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    }
                    setParameters((prevState) => ({
                      ...prevState,
                      advertisers: result,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        {!isMobile ? (
          <DialogActions>
            <Button
              onClick={() =>
                trace("close alerts config dialog", performance.now(), () =>
                  props.toggle_dialog()
                )
              }
              color="secondary"
              size="large"
              disabled={props.isUserAlerstLoading}
            >
              {props.intl.formatMessage({ id: "navigation.buttons.cancel" })}
            </Button>
            <Button
              onClick={handleSearchSubmission}
              color="primary"
              className={classes.button}
              size="large"
              disabled={
                props.isUserAlerstLoading ||
                (props.alerts.length > 2 && props.userAlertToEdit === null)
              }
            >
              {props.intl.formatMessage({ id: "navigation.buttons.submit" })}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen && props.isUserAlertCreated}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        message={props.intl.formatMessage({
          id: "alerts.configDialog.dialogConfirmMsg",
        })}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      flexGrow: 1,
      height: 300,
      maxHeight: 300,
    },
    container: {
      flexGrow: 1,
      position: "relative",
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: "wrap",
    },
    inputInput: {
      width: "auto",
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
    button: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
  })
);

export default AlertsDialog;
