import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { TwitterStyles } from "../../store/data/types";

const PromotedIcon: React.FC<{ className: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" aria-hidden="true">
    <g>
      <path d="M20.75 2H3.25C2.007 2 1 3.007 1 4.25v15.5C1 20.993 2.007 22 3.25 22h17.5c1.243 0 2.25-1.007 2.25-2.25V4.25C23 3.007 21.993 2 20.75 2zM17.5 13.504c0 .483-.392.875-.875.875s-.875-.393-.875-.876V9.967l-7.547 7.546c-.17.17-.395.256-.62.256s-.447-.086-.618-.257c-.342-.342-.342-.896 0-1.237l7.547-7.547h-3.54c-.482 0-.874-.393-.874-.876s.392-.875.875-.875h5.65c.483 0 .875.39.875.874v5.65z"></path>
    </g>
  </svg>
);

interface TwitterStyleProps {
  twitterAttributes: TwitterStyles;
  advertiser: string;
}

export const TwitterStyle = (props: TwitterStyleProps) => {
  const classes = useStyles();
  const { twitterAttributes: attributes } = props;
  const formatMessage = useFormatMessage();

  return (
    <div className={classes.twitterPost}>
      {attributes.type === "trends" ? (
        <Box textAlign="left">
          <a
            href={`https://twitter.com/hashtag/${attributes.hashtag?.replace(
              "#",
              ""
            )}`}
            className={classes.title}
            target="_blank"
          >
            {attributes.hashtag}
          </a>
          <p className={classes.body}>{attributes.body.text}</p>
          <a
            href={`https://twitter.com/hashtag/${attributes.hashtag?.replace(
              "#",
              ""
            )}`}
            className={classes.promoted}
            target="_blank"
          >
            <PromotedIcon className={classes.promotedIcon} />{" "}
            {attributes.body.subtitle}
          </a>
        </Box>
      ) : null}
      {attributes.type === "profiles" ? (
        <Grid container justify="center" spacing={2}>
          <Grid item xs={2}>
            <img className={classes.profileImg} src={attributes.img_profile?.src} />
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="left">
              <a
                href={attributes.body.account_url}
                className={classes.title}
                target="_blank"
              >
                {attributes.body.account}
              </a>
              <br />
              <a
                href={attributes.body.account_url}
                className={classes.account}
                target="_blank"
              >
                @{attributes.body.account_url?.replace("https://", "").split("/")[1]}
              </a>
              <br />
              <a
                href={attributes.body.account_url}
                className={classes.promoted}
                target="_blank"
              >
                <PromotedIcon className={classes.promotedIcon} /> Promocionado
              </a>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.profileButton}
            >
              {formatMessage("campaignsPage.followButton")}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {attributes.type === "simple" ? (
        <Box textAlign="left">
          <Grid container justify="center" spacing={2}>
            <Grid item xs={2}>
              <img
                className={classes.profileImg}
                src={attributes.header?.img?.src}
              />
            </Grid>
            <Grid item xs={8}>
              <a
                href={attributes.header?.href}
                className={classes.title}
                target="_blank"
              >
                {attributes.header?.text}
              </a>
              <br />
              <a
                href={attributes.header?.href}
                className={classes.account}
                target="_blank"
              >
                @{attributes.header?.href.replace("https://", "").split("/")[1]}
              </a>
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <p className={classes.body}>{attributes.body.text}</p>
            </Grid>
            <Grid item xs={12}>
              <a
                href={attributes.header?.href}
                className={classes.promoted}
                target="_blank"
              >
                <PromotedIcon className={classes.promotedIcon} /> Promocionado por{" "}
                {attributes.header?.text}
              </a>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    twitterPost: {
      width: 300,
      fontSize: 14,
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
      margin: "auto",
    },
    title: {
      color: "#252525",
      fontSize: 15,
      fontWeight: "bold",
      lineHeight: 1.5,
      textAlign: "left",
      wordBreak: "break-word",
    },
    account: {
      color: "#646464",
      fontSize: 14,
      textAlign: "left",
    },
    profileImg: {
      borderRadius: "100%",
      height: 40,
      marginRight: 8,
      width: 40,
    },
    profileButton: {
      backgroundColor: "#000",
      borderRadius: "25px",
      color: "#fff",
      fontWeight: "bold",
    },
    body: {
      color: "#333333",
      fontSize: 14,
      textAlign: "left",
    },
    promoted: {
      color: "#333333",
      fontSize: 12,
      textAlign: "left",
    },
    promotedIcon: {
      height: 12,
      width: 12,
    },
  })
);
