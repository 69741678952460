import { connect } from "react-redux"
import { RootState, RootAction } from "typesafe-actions"
import { Dispatch } from "redux"
import {
  SearchParameters,
  PaginationParametersInterface,
  AdvancedFilters,
} from "../../../store/search/types"
import {
  campaignsDataFetchRequest,
  spotsListDataRequest,
  shareOfVoiceDataRequest,
  investmentDataRequest,
} from "../../../store/data/actions"
import { ActiveFiltersToolbarView } from "./ActiveFiltersToolbarView"
import {
  queueParameter, setAdvancedFilters,
} from "../../../store/search/actions"
import { choose_tab } from "../../../store/navigation/actions"

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  token: state.login.token,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    fetchCampaigns: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(campaignsDataFetchRequest(search, pagination, token)),
    fetchListedSpots: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(spotsListDataRequest(search, pagination, token)),
    fetchShareOfVoice: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(shareOfVoiceDataRequest(search, pagination, token, type)),
    fetchInvestment: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(investmentDataRequest(search, pagination, token, type)),
    setFilters: (filters: AdvancedFilters) =>
      dispatch(setAdvancedFilters(filters)),
    updateQueue: (parameters: AdvancedFilters) =>
      dispatch(queueParameter(parameters)),
    goToIndustriesOnClear: () => dispatch(choose_tab(0))
  }
}

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const ActiveFiltersToolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveFiltersToolbarView)
