import React from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  Chip,
  Avatar,
  Tooltip,
} from "@material-ui/core"
import { SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar, SignalCellularNull } from "@material-ui/icons"
import { COUNTRIES_BALLS } from "../../../utils/Countries"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import { TabPropNames } from "../../../utils/TabCodes"

interface ChipValuesProps {
  data: ChipProps[]
}

export interface ChipProps {
  label: string
  value: string
  spots?: boolean
  percentage?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    redSpots: {
      color: theme.palette.error.main
    },
    orangeSpots: {
      color: theme.palette.warning.main
    },
    yellowSpots: {
      color: '#FFD447'
    },
    greenSpots: {
      color: theme.palette.success.main
    },
  })
)

const ChipValues = (props: ChipValuesProps) => {
  const styles = useStyles()
  const formatMessage = useFormatMessage()
  const { selectedTab } = useSelector(
    (state: RootState) => state.navigation
  )
  const { advancedFilters } = useSelector(
    (state: RootState) => state.search
  )

  const filtersAndIsntCurrentTab = () => {
    const tabName = TabPropNames[selectedTab];

    const excludeCurrent = TabPropNames.filter(tab => tab !== tabName);
    const isSomeFilterActivated = excludeCurrent.some(tab => advancedFilters[tab].length > 0);
    return isSomeFilterActivated;
  };
  const isFiltered = filtersAndIsntCurrentTab();

  return (
    <div className={styles.root}>
      {props.data.map((item) => {
        return (
          <Tooltip title={item.spots ? `${formatMessage(item.label)} - ${item.value} Spots` : formatMessage(item.label)} key={item.label}>
            {
              item.spots && item.percentage !== undefined ? 
                (
                  ((isFiltered && item.percentage > 2.0) || (((selectedTab === 1 || selectedTab === 2) && item.percentage > 0.01) || item.percentage > 0.4)) ?
                  (
                    <Chip
                      avatar={<Avatar>{COUNTRIES_BALLS[item.label]}</Avatar>}
                      classes={{
                        label: (isFiltered && item.percentage > 18.0) || (((selectedTab === 1 || selectedTab === 2) && item.percentage > 0.07) || item.percentage > 5.0) ?
                            styles.greenSpots :
                            (isFiltered && item.percentage > 6.0) || (((selectedTab === 1 || selectedTab === 2) && item.percentage > 0.02) || item.percentage > 2.0) ?
                              styles.yellowSpots :
                              styles.orangeSpots
                      }}
                      label={(isFiltered && item.percentage > 18.0) || (((selectedTab === 1 || selectedTab === 2) && item.percentage > 0.07) || item.percentage > 5.0) ?
                        <SignalCellular4Bar /> :
                        (isFiltered && item.percentage > 6.0) || (((selectedTab === 1 || selectedTab === 2) && item.percentage > 0.02) || item.percentage > 2.0) ?
                        <SignalCellular3Bar /> :
                        <SignalCellular2Bar />}
                      key={item.label}
                    />
                  ) : (
                    <Chip
                      avatar={<Avatar>{COUNTRIES_BALLS[item.label]}</Avatar>}
                      classes={{
                        label: (selectedTab !== 1 && selectedTab !== 2) ? styles.redSpots : (isFiltered && item.percentage > 0.4) || item.percentage > 0.005 ? styles.orangeSpots : styles.redSpots
                      }}
                      label={
                        item.percentage === 0 ?
                          <SignalCellularNull /> :
                          <SignalCellular1Bar />
                        }
                      key={item.label}
                    />
                  )
                )
              : (
                <Chip
                  avatar={<Avatar>{COUNTRIES_BALLS[item.label]}</Avatar>}
                  label={item.value}
                  key={item.label}
                />
              )
            }
          </Tooltip>
        )
      })}
    </div>
  )
}

export default ChipValues
