import { all, fork } from "redux-saga/effects";
import loginSaga from "./login/sagas";
import generalSaga from "./general/sagas";
import dataSaga from "./data/sagas";
import searchSaga from "./search/sagas";

/**
 * Exporto todas las sagas juntas.
 */
export default function* rootSaga() {
  return yield all([
    fork(loginSaga),
    fork(generalSaga),
    fork(dataSaga),
    fork(searchSaga),
  ]);
}
