import React from "react"
import { unstable_trace as trace } from "scheduler/tracing";
import { Menu, MenuItem } from "@material-ui/core"

export interface MenuActionsInterface {
  actionName: string
  action: () => void
}

interface ContextualMenuProps {
  actions: MenuActionsInterface[]
  position: {
    mouseX: null | number
    mouseY: null | number
  }
  handleClose: () => void
}

export const ContextualMenu = (props: ContextualMenuProps) => {
  function handleActionClick(action: () => void) {
    props.handleClose()
    action()
  }

  return trace("render of contextual menu", performance.now(), () => (
    <React.Fragment>
      <Menu
        keepMounted
        open={props.position.mouseY !== null}
        onClose={props.handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          props.position.mouseY !== null && props.position.mouseX !== null
            ? { top: props.position.mouseY, left: props.position.mouseX }
            : undefined
        }
      >
        {props.actions.map((action) => (
          <MenuItem
            key={action.actionName}
            onClick={() => handleActionClick(action.action)}
          >
            {action.actionName}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  ));
}
