import { Dispatch } from "redux"
import { ChangeEvent } from "react"
import { changeLanguage } from "../../../store/general/actions"
import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import LanguageSelectorView from "./LanguageSelectorView"
import { injectIntl, InjectedIntlProps } from "react-intl"

const mapStateToProps = (state: RootState) => ({
  general_state: state.general,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    handle_language_selection: (event: ChangeEvent<{ value: unknown }>) =>
      dispatch(changeLanguage(String(event.target.value))),
  }
}

export type LanguageSelectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorView)
)
