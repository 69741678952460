//Action types
export enum LoginActionTypes {
  LOGIN_REQUEST = "@login/LOGIN_REQUEST",
  LOGIN_SUCCESS = "@login/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@login/LOGIN_FAILURE",
  LOGOUT = "@login/LOGOUT",
  FORGOT_PASSWORD = "@login/FORGOT_PASSWORD",
  CANCEL_RESET = "@login/PASSWORD_RESET_CANCELED",
  RESET_PASSWORD_REQUEST = "@login/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "@login/RESET_PASSWORD_SUCCESS",
  CHANGE_USERNAME_EMAIL_REQUEST = "@navigation/CHANGE_USERNAME_EMAIL_REQUEST",
  CHANGE_USERNAME_EMAIL_SUCCESS = "@navigation/CHANGE_USERNAME_EMAIL_SUCCESS",
  CHANGE_PASSWORD_REQUEST = "@navigation/CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS = "@navigation/CHANGE_PASSWORD_SUCCESS",
  SET_EXPIRED = "@user/SET_ACCOUNT_EXPIRATION",
  SESSION_EXPIRED = "@user/TOKEN_HAS_EXPIRED",
  SET_ROLES = "@user/SET_TOKEN_ROLES",
  SET_COUNTRIES = "@user/SET_TOKEN_COUNTRIES",
  GET_USER_MESSAGES = "@user/GET_USER_MESSAGES",
  SET_USER_MESSAGES = "@user/SET_USER_MESSAGES",
  ACCEPT_TERMS_REQUEST = "@user/ACCEPT_TERMS_REQUEST",
  ACCEPT_TERMS_SUCCESS = "@user/ACCEPT_TERMS_SUCCESS",
}

export interface UserMessagesInterface {
  id: number;
  createdAt: string;
  enabled: boolean;
  html: string;
  name: string;
  type: string;
}

export interface TermsAcceptedAtInterface {
  date: string;
  timezone_type: number;
  timezone: string;
}

//State type

export interface LoginState {
  readonly email: string;
  readonly password: string;
  readonly token: string;
  readonly loading: boolean;
  readonly error: boolean;
  readonly resetPassword: boolean;
  readonly userIsLoggedIn: boolean;
  readonly username: string;
  readonly newPassword: string;
  readonly isAccountExpired: boolean;
  readonly userMessages: UserMessagesInterface[];
  readonly hasTokenExpired: boolean;
  readonly roles: Array<string>;
  readonly countries: Array<string>;
  readonly name: string;
  readonly surname: string;
  readonly termsAccepted: boolean;
  readonly termsAcceptedAt: TermsAcceptedAtInterface | null;
}
