import * as Yup from "yup"
import Dialog from "@material-ui/core/Dialog"
import { Form, Formik } from "formik"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import TextField from "@material-ui/core/TextField"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import React from "react"
import { PasswordResetDialogProps } from "./PasswordResetContainer"
import { publishEvent } from "../../../services/GoogleAnalytics"

const PasswordResetDialogView = (props: PasswordResetDialogProps) => {
  const dialogSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Enter a valid email address"),
  })

  function handleSubmit(email: string) {
    publishEvent("Usuario", "Restablecer contraseña")
    props.handle_reset(email)
  }

  return (
      <Dialog
        open={props.login_state.resetPassword}
        onClose={props.handle_cancel}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            handleSubmit(values.email)
          }}
          validationSchema={dialogSchema}
        >
          {({ handleChange, errors, touched }) => (
            <Form id={"password-reset-form"} name={"password-reset-form"}>
              <DialogTitle id="password-reset-dialog-title">
                <FormattedMessage id={"loginPage.resetPasswordDialog.title"} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <FormattedMessage id={"loginPage.resetPasswordDialog.message"} />
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="outlined-email-input"
                  label={<FormattedMessage id={"loginPage.emailField"} />}
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  fullWidth
                />
                {errors.email && touched.email ? <div>{errors.email}</div> : null}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handle_cancel} color="primary">
                  <FormattedMessage
                    id={"loginPage.resetPasswordDialog.cancelButton"}
                  />
                </Button>
                <Button
                  type={"submit"}
                  form={"password-reset-form"}
                  color="primary"
                  id={"password-reset-button"}
                >
                  <FormattedMessage
                    id={"loginPage.resetPasswordDialog.resetButton"}
                  />
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
  )
}

export default PasswordResetDialogView
