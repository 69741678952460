import React, { useMemo } from "react";
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Tooltip,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { FormattedMessage } from "react-intl";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useDispatch, useSelector } from "react-redux";
import { fetchDdsWithoutCaptureRequest, reportRequest } from "../store/data/actions";
import { RootState } from "typesafe-actions";
import { differenceInMonths, differenceInDays } from "date-fns";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    root: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
    tooltip: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      fontSize: "1em",
      padding: theme.spacing(2),
    },
  })
);

const ExportReport = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { countries, startDate, endDate, advancedFilters } = useSelector(
    (state: RootState) => state.search
  );
  const { token, roles } = useSelector((state: RootState) => state.login);
  const { selectedPage } = useSelector((state: RootState) => state.navigation);
  const { isDdsWithoutCaptureLoading, errorMessage } = useSelector(
    (state: RootState) => state.data
  );
  const dispatch = useDispatch();

  const formatMessage = useFormatMessage();

  const roleDemo = useMemo(
    () => roles.some((item) => item === "ROLE_FEATURE_DEMO"),
    [roles]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportRequest = (
    type: "campaign" | "share-of-voice" | "investment" | "campaign-rts",
    format: "pdf" | "excel"
  ) => {
    dispatch(
      reportRequest(
        {
          type: type,
          filters: { countries, startDate, endDate, advancedFilters },
          reportFormat: format,
        },
        token
      )
    );
    handleClose();
  };

  const handleFetchDdsWithoutCapture = () => {
    dispatch(fetchDdsWithoutCaptureRequest(token, countries[0], startDate, endDate));
    handleClose();
  };

  React.useEffect(() => {
    if (isDdsWithoutCaptureLoading) {
      setOpenSnackbar(true);
    }
  }, [isDdsWithoutCaptureLoading]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="text"
        startIcon={<CloudDownloadIcon />}
        onClick={handleClick}
        className={classes.button}
        disabled={roleDemo}
      >
        <FormattedMessage id="navigation.header.exportReport" />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          component="nav"
          subheader={
            <ListSubheader component="div" id="base-research-subheader">
              Base Research - Single Country
            </ListSubheader>
          }
          dense
        >
          <ListItem
            button
            disabled={roleDemo}
            onClick={() => {
              handleReportRequest("share-of-voice", "pdf");
            }}
          >
            <ListItemIcon>
              <PictureAsPdfIcon />
            </ListItemIcon>
            <ListItemText>Share of Voice (PDF)</ListItemText>
          </ListItem>
          <ListItem
            button
            disabled={roleDemo}
            onClick={() => {
              handleReportRequest("share-of-voice", "excel");
            }}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText>Share of Voice (Excel)</ListItemText>
          </ListItem>
          <Tooltip
            title={formatMessage("exportReport.campaignsDetailsTooltip")}
            disableFocusListener={
              differenceInDays(endDate, startDate) <= 31 || roleDemo
            }
            disableHoverListener={
              differenceInDays(endDate, startDate) <= 31 || roleDemo
            }
            disableTouchListener={
              differenceInDays(endDate, startDate) <= 31 || roleDemo
            }
            placement="right"
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <div>
              <ListItem
                button
                onClick={() => {
                  handleReportRequest("campaign", "excel");
                }}
                disabled={differenceInDays(endDate, startDate) > 31 || roleDemo}
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText>Campaign Details (Excel)</ListItemText>
              </ListItem>
            </div>
          </Tooltip>
        </List>
        <Tooltip
          title={formatMessage("exportReport.RTSTooltip")}
          disableFocusListener={selectedPage === "investment" || roleDemo}
          disableHoverListener={selectedPage === "investment" || roleDemo}
          disableTouchListener={selectedPage === "investment" || roleDemo}
          placement="right"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <List
            dense
            component="nav"
            subheader={
              <ListSubheader component="div" id="ad-spend-subheader">
                Real-Time Spend (RTS) - Single Country
              </ListSubheader>
            }
          >
            <ListItem
              button
              disabled={
                selectedPage !== "investment" ||
                roleDemo ||
                differenceInDays(endDate, startDate) < 15
              }
              onClick={() => {
                handleReportRequest("investment", "pdf");
              }}
            >
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText>Investment (PDF)</ListItemText>
            </ListItem>
            <ListItem
              button
              disabled={
                selectedPage !== "investment" ||
                roleDemo ||
                differenceInDays(endDate, startDate) < 15
              }
              onClick={() => {
                handleReportRequest("investment", "excel");
              }}
            >
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText>Investment (Excel)</ListItemText>
            </ListItem>
            <Tooltip
              title={formatMessage("exportReport.campaignsDetailsRTSTooltip")}
              disableFocusListener={
                selectedPage !== "investment" ||
                roleDemo ||
                differenceInMonths(endDate, startDate) < 1
              }
              disableHoverListener={
                selectedPage !== "investment" ||
                roleDemo ||
                differenceInMonths(endDate, startDate) < 1
              }
              disableTouchListener={
                selectedPage !== "investment" ||
                roleDemo ||
                differenceInMonths(endDate, startDate) < 1
              }
              placement="right"
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <div>
                <ListItem
                  button
                  disabled={
                    selectedPage !== "investment" ||
                    roleDemo ||
                    differenceInDays(endDate, startDate) < 15 ||
                    differenceInMonths(endDate, startDate) > 0
                  }
                  onClick={() => {
                    handleReportRequest("campaign-rts", "excel");
                  }}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText>Campaign Details RTS (Excel)</ListItemText>
                </ListItem>
              </div>
            </Tooltip>
            {roles && roles.some((item) => item === "ROLE_SUPER_ADMIN") ? (
              <ListItem
                button
                onClick={() => {
                  handleFetchDdsWithoutCapture();
                }}
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText>Dds Without Capture (CSV)</ListItemText>
              </ListItem>
            ) : null}
          </List>
        </Tooltip>
      </Popover>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        color={errorMessage ? "error" : "success"}
        message={
          errorMessage
            ? errorMessage
            : "Reporte en proceso de generación, se le notificará al email cuando esté listo."
        }
      />
    </div>
  );
};

export default ExportReport;
