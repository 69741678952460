import React, { ChangeEvent, useEffect, useState } from "react";
import { RootState } from "typesafe-actions";
import { ReduxProps } from "./SpotsListContainer";
import TablePaging from "../../components/dashboard/table/TablePagination";
import { publishPageView } from "../../services/GoogleAnalytics";
import PageTitle from "../../components/dashboard/PageTitle";
import {
  PaginationParametersInterface,
  SearchParameters,
} from "../../store/search/types";
import { SearchToolbar } from "../../components/dashboard/search/SearchToolbarContainer";
import { Divider, LinearProgress, TableCell } from "@material-ui/core";
import { ActiveFiltersToolbar } from "../../components/dashboard/search/ActiveFiltersToolbarContainer";
import MaterialTable from "material-table";
import { CreativityGallery } from "../../components/creativity/image-carousel/CreativityGalleryView";
import { MediaInterface } from "../../components/creativity/image-carousel/Creativity";
import { ListedSpotInterface } from "../../store/data/types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { queueParameter, setAllParameters } from "../../store/search/actions";
import { getDateFromReports } from "../../utils/functions";
import { SidebarPages } from "../../store/navigation/types";
import { choose_tab, set_sidebar_page } from "../../store/navigation/actions";
import Alert from "@material-ui/lab/Alert";
import LoadingDialog from "../../components/dashboard/dialogs/LoadingDialog";

const excludedColumns: string[] = ["date", "pViewability"];

const SpotsListView = (props: ReduxProps) => {
  const dispatch = useDispatch();
  const { userReports, hasRequestFailedCreativities, errorMessage } =
    props.data_state;
  const { countries, advancedFilters, startDate, endDate, paginationParameters } =
    props.search_state;
  const { token, logoutUser } = props;
  const { setAllSearchParams, campaignsRequest, listRequest } = props;
  const { isSwitchToggled } = props.navigation_state;
  const [isGalleryOpen, setGallery] = React.useState(false);
  const [mediaAttributes, setMediaAttributes] = useState<MediaInterface | undefined>(
    undefined
  );
  const [title, setTitle] = React.useState("");

  const { roles } = useSelector((state: RootState) => state.login);
  const history = useHistory();
  const { reportId } = useParams<{
    reportId: string;
  }>();

  useEffect(() => {
    if (Array.isArray(roles)) {
      const isAllowed = roles.some((item) => item === "ROLE_FEATURE_CAMPAIGN");
      if (!isAllowed) {
        history.push("/404");
      }
    } else {
      logoutUser();
    }
  }, [roles, history, logoutUser]);

  const localization = {
    header: {
      actions: props.intl.formatMessage({ id: "table.column.actions" }),
    },
    body: {
      emptyDataSourceMessage: props.intl.formatMessage({
        id: "table.emptyDataSourceMessage",
      }),
    },
    pagination: {
      labelDisplayedRows: props.intl.formatMessage({
        id: "table.labelDisplayedRows",
      }),
      labelRowsSelect: props.intl.formatMessage({ id: "table.labelRowsSelect" }),
      labelRowsPerPage: props.intl.formatMessage({ id: "table.labelRowsPerPage" }),
      firstTooltip: props.intl.formatMessage({ id: "table.firstTooltip" }),
      previousTooltip: props.intl.formatMessage({ id: "table.previousTooltip" }),
      nextTooltip: props.intl.formatMessage({ id: "table.nextTooltip" }),
      lastTooltip: props.intl.formatMessage({ id: "table.lastTooltip" }),
    },
    toolbar: {
      searchTooltip: props.intl.formatMessage({ id: "table.searchTooltip" }),
      searchPlaceholder: props.intl.formatMessage({ id: "table.searchPlaceholder" }),
      exportTitle: props.intl.formatMessage({ id: "table.exportTitle" }),
      exportName: props.intl.formatMessage({ id: "table.exportName" }),
    },
  };

  const ungroupedColumns = [
    {
      field: "date",
      title: props.intl.formatMessage({ id: "spotslistPage.column.date" }),
      type: "date",
    },
    {
      field: "advertiser",
      title: props.intl.formatMessage({ id: "spotslistPage.column.advertiser" }),
      type: "string",
    },
    {
      field: "platform",
      title: props.intl.formatMessage({ id: "spotslistPage.column.platform" }),
      type: "string",
    },
    {
      field: "publisher",
      title: props.intl.formatMessage({ id: "spotslistPage.column.publisher" }),
      type: "string",
    },
    {
      field: "category",
      title: props.intl.formatMessage({ id: "spotslistPage.column.category" }),
      type: "string",
    },
    {
      field: "format",
      title: props.intl.formatMessage({ id: "spotslistPage.column.format" }),
      type: "string",
    },
    {
      field: "product",
      title: props.intl.formatMessage({ id: "spotslistPage.column.product" }),
      type: "string",
    },
    {
      field: "industry",
      title: props.intl.formatMessage({ id: "spotslistPage.column.industry" }),
      type: "string",
    },
    { field: "pViewability", title: "pViewability", type: "string" },
  ];

  const groupedColumns = [
    {
      field: "lastSeenDate",
      title: props.intl.formatMessage({ id: "spotslistPage.column.date" }),
      type: "date",
    },
    {
      field: "advertiser",
      title: props.intl.formatMessage({ id: "spotslistPage.column.advertiser" }),
      type: "string",
    },
    // {field: "platform", title: props.intl.formatMessage({id: "spotslistPage.column.platform"}), type: "string"},
    {
      field: "lastSeen",
      title: props.intl.formatMessage({ id: "spotslistPage.column.publisher" }),
      type: "string",
    },
    // {field: "category", title: props.intl.formatMessage({id: "spotslistPage.column.category"}), type: "string"},
    // {field: "format", title: props.intl.formatMessage({id: "spotslistPage.column.format"}), type: "string"},
    {
      field: "product",
      title: props.intl.formatMessage({ id: "spotslistPage.column.product" }),
      type: "string",
    },
    {
      field: "industry",
      title: props.intl.formatMessage({ id: "spotslistPage.column.industry" }),
      type: "string",
    },
    {
      field: "spots",
      title: props.intl.formatMessage({ id: "spotslistPage.column.spots" }),
      type: "number",
    },
    { field: "pViewability", title: "pViewability", type: "string" },
  ];

  const fetchData = (
    searchParameters: SearchParameters,
    token: string,
    pagination?: PaginationParametersInterface
  ) => {
    if (props.navigation_state.isSwitchToggled) {
      props.campaignsRequest(searchParameters, token, pagination!);
    } else {
      props.listRequest(searchParameters, token, pagination);
    }
  };

  function handleChangeNumberOfRows(event: ChangeEvent<HTMLInputElement>) {
    fetchData({ countries, startDate, endDate, advancedFilters }, props.token, {
      ...props.search_state.paginationParameters,
      requestedResults: +event.target.value,
    });
    props.setNumberOfRows(+event.target.value);
    props.setCurrentPage(1);
  }
  function handleChangePage(event: unknown, currentPage: number) {
    fetchData({ countries, startDate, endDate, advancedFilters }, props.token, {
      ...props.search_state.paginationParameters,
      requestedPageNumber: currentPage,
    });
    props.setCurrentPage(currentPage);
  }

  function handleViewCreativity(data: MediaInterface, title: string) {
    setMediaAttributes(data);
    setTitle(title);
    setGallery(true);
  }

  useEffect(() => {
    const fetchData = (
      searchParameters: SearchParameters,
      token: string,
      pagination?: PaginationParametersInterface
    ) => {
      if (isSwitchToggled) {
        campaignsRequest(searchParameters, token, pagination!);
      } else {
        listRequest(searchParameters, token, pagination);
      }
    };

    if (localStorage.getItem("sharedSearchParameters") === null) {
      fetchData(
        { countries, startDate, endDate, advancedFilters },
        token,
        paginationParameters
      );
    } else {
      const parameters: SearchParameters = JSON.parse(
        localStorage.getItem("sharedSearchParameters")!
      );
      const startDate = new Date(parameters.startDate.toString());
      const endDate = new Date(parameters.endDate.toString());
      setAllSearchParams(
        parameters.countries,
        startDate,
        endDate,
        parameters.advancedFilters
      );
      fetchData({ ...parameters, startDate, endDate }, token, paginationParameters);
      localStorage.removeItem("sharedSearchParameters");
    }
  }, [
    paginationParameters,
    advancedFilters,
    startDate,
    endDate,
    countries,
    token,
    setAllSearchParams,
    isSwitchToggled,
    campaignsRequest,
    listRequest,
  ]);

  useEffect(() => {
    const report = reportId && userReports.find((r) => r.id === reportId);
    if (report) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { country, page, countries, ...params } = report.parameters;
      const advanced = {
        advertisers: [],
        formats: [],
        platforms: [],
        publishers: [],
        excludedPublishers: [],
        categories: [],
        industries: [],
        products: [],
        sources: [],
      };
      dispatch(queueParameter(advanced));
      const newAdvanced = {
        ...advanced,
        ...params,
      };

      if (newAdvanced.products.length > 0) {
        dispatch(choose_tab(2));
      } else if (newAdvanced.advertisers.length > 0) {
        dispatch(choose_tab(1));
      }

      const dates = getDateFromReports(report.frequency, false);

      dispatch(
        setAllParameters(countries ?? [country], dates[1], dates[0], {
          ...newAdvanced,
        })
      );
      history.push(`/dashboard/${SidebarPages.shareofvoice}`);
      dispatch(set_sidebar_page(SidebarPages.shareofvoice));
    }
  }, [startDate, endDate, reportId, userReports, history, dispatch]);

  publishPageView("/dashboard/spotslist");

  return (
    <>
      <SearchToolbar />
      <LinearProgress hidden={!props.data_state.isDataLoading} />
      <ActiveFiltersToolbar />
      <PageTitle
        title={props.intl.formatMessage({ id: "navigation.sidebar.spots_list" })}
      />
      {hasRequestFailedCreativities ? (
        <>
          <Divider
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          />
          <Alert severity="warning">
            {errorMessage === "No data available. Try with other criteria."
              ? props.intl.formatMessage({ id: "errors.data.noDataAvailable" })
              : errorMessage}
          </Alert>
        </>
      ) : (
        <>
          <MaterialTable
            //@ts-expect-error
            columns={
              props.navigation_state.isSwitchToggled
                ? groupedColumns
                : ungroupedColumns
            }
            data={
              props.navigation_state.isSwitchToggled
                ? (props.data_state.campaigns as unknown as ListedSpotInterface[])
                : props.data_state.spotList
            }
            isLoading={props.data_state.isDataLoading}
            localization={localization}
            options={{
              pageSize: props.search_state.paginationParameters.requestedResults,
              emptyRowsWhenPaging: false,
              showTitle: false,
              exportButton: false,
              sorting: false,
              search: false,
            }}
            components={{
              Pagination: () => (
                <TablePaging
                  count={props.navigation_state.pagination.totalPages}
                  rowsPerPage={props.navigation_state.pagination.resultsPerPage}
                  currentPage={props.navigation_state.pagination.currentPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeNumberOfRows}
                  label={props.intl.formatMessage({
                    id: "navigation.tablePagination.resultsPerPage",
                  })}
                />
              ),
              Cell: (props) => (
                <TableCell
                  style={
                    excludedColumns.includes(props.columnDef.field)
                      ? undefined
                      : { cursor: "context-menu" }
                  }
                >
                  {props.rowData[props.columnDef.field]}
                </TableCell>
              ),
            }}
            actions={[
              {
                icon: "image",
                tooltip: props.intl.formatMessage({
                  id: "spotlistPage.dialog.creativityTooltip",
                }),
                onClick: (event, rowData) =>
                  handleViewCreativity(
                    {
                      //@ts-expect-error
                      date: rowData.lastSeenDate,
                      //@ts-expect-error
                      advertiser: rowData.advertiser,
                      //@ts-expect-error
                      style: rowData.style,
                      //@ts-expect-error
                      styleAttributes: rowData.styleAttributes,
                      //@ts-expect-error
                      creativity: rowData.creativity,
                      //@ts-expect-error
                      platform: rowData.platform,
                      //@ts-expect-error
                      category: rowData.category,
                      //@ts-expect-error
                      format: rowData.format,
                      //@ts-expect-error
                      industry: rowData.industry,
                      //@ts-expect-error
                      pViewability: rowData.pViewability,
                      screenshot: props.navigation_state.isSwitchToggled
                        ? //@ts-expect-error
                          rowData.lastSeenScreenshot
                        : //@ts-expect-error
                          rowData.screenshot,
                    },
                    //@ts-expect-error
                    rowData.product + " @ " + rowData.publisher
                  ),
              },
            ]}
          />
          <CreativityGallery
            isOpen={isGalleryOpen}
            toggle={() => setGallery(!isGalleryOpen)}
            media={mediaAttributes!}
            title={title}
          />
        </>
      )}
      <LoadingDialog isLoading={props.data_state.isDataLoading} />
    </>
  );
};

export default SpotsListView;
