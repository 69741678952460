import { createStyles, makeStyles, Theme } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import React from "react"

interface PageTitleInterface {
  title: string
}

const PageTitle = (props: PageTitleInterface) => {
  const classes = useStyles()
  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h4">
        {props.title}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(5),
    },
    typo: {
      color: theme.palette.text.hint,
    },
  })
)

export default PageTitle
