import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { createMuiTheme } from "@material-ui/core/styles"

export const adCualityTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#293E76",
    },
  },
})

const loginFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 0,
      left: 0,
    },
    textField: {
      outlineColor: "#293E76",
      color: "#293E76",
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 400,
    },

    dialogButton: {
      color: "#293E76",
    },

    forgetButton: {
      textTransform: "none",
      fontWeight: 400,
    },
    formButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(2.5),
    },
    circularProgress: {
      color: theme.palette.primary.main,
    },

    logotypeContainer: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
      width: "70%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    logotypeImage: {
      width: 390,
      marginBottom: theme.spacing(4),
    },
    logotypeText: {
      fontcolor: "#FFF",
      fontWeight: 500,
      fontSize: 84,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    creatingButtonContainer: {
      marginTop: theme.spacing(2.5),
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      width: "30%",
      height: "100%",
      display: "flex",
      backgroundColor: theme.palette.background.paper,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
    },
    formContainerMobile: {
      backgroundColor: theme.palette.background.default,
      width: "30%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
    },
    form: {
      width: 320,
    },
    greeting: {
      fontWeight: 500,
      fontSize: 84,
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
    copyright: {
      fontcolor: theme.palette.text.primary,
      marginTop: theme.spacing(4),
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        bottom: theme.spacing(2),
      },
    },
  })
)

export default loginFormStyles
