import { combineReducers } from "redux"
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import { loginReducer } from "./login/reducer"
import { generalReducer } from "./general/reducers"
import { navigationReducer } from "./navigation/reducer"
import { dataReducer } from "./data/reducer"
import { searchReducer } from "./search/reducer"

/**
 * Combinación de todos los reducers de la app.
 */
const rootReducer = (history: History) => combineReducers({
  login: loginReducer,
  general: generalReducer,
  navigation: navigationReducer,
  data: dataReducer,
  search: searchReducer,
  router: connectRouter(history)
})

export default rootReducer
