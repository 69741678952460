export const COLORS = [
  "#3a49ba",
  "#80b7e2",
  "#01c2ac",
  "#80da7f",
  "#bfe87f",
  "#fff780",
  "#fcc811",
  "#facf91",
  "#fa600f",
  "#fe9281",
  "#e893c7",
  "#b991d9",
  "#7a6dca",
  "#dcc8ec",
  "#3a49ba",
  "#80b7e2",
  "#01c2ac",
  "#80da7f",
];

export const darkCOLORS = [
  "#3a49ba",
  "#80b7e2",
  "#01c2ac",
  "#80da7f",
  "#bfe87f",
  "#fff780",
  "#fcc811",
  "#facf91",
  "#fa600f",
  "#fe9281",
  "#e893c7",
  "#b991d9",
  "#7a6dca",
  "#dcc8ec",
  "#3a49ba",
  "#80b7e2",
  "#01c2ac",
  "#80da7f",
];

export const greyScale = [
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#6c757d",
  "#495057",
  "#343a40",
  "#212529",
  "#1A1C1E",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#6c757d",
  "#495057",
  "#343a40",
  "#212529",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#6c757d",
  "#495057",
  "#343a40",
  "#212529",
  "#1A1C1E",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#6c757d",
  "#495057",
  "#343a40",
  "#212529",
];
