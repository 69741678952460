import { CancelTokenSource } from "axios";
import {
  SearchParameters,
  AdvancedSearchInterface,
  ReportMailParameters,
  AlertsInterface,
} from "../search/types";

/**
 * Tipos de acciones del Data Store.
 */
export enum DataActionTypes {
  REQUEST_SPOTSLIST = "@data/SPOTSLIST_DATA_REQUEST",
  SUCCESS_SPOTSLIST = "@data/SPOTSLIST_DATA_SUCCESS",
  FAILURE_SPOTSLIST = "@data/SPOTSLIST_DATA_FAILURE",
  REQUEST_SHAREOFVOICE = "@data/SHARE_OF_VOICE_REQUEST",
  SUCCESS_SHAREOFVOICE = "@data/SHARE_OF_VOICE_SUCCESS",
  FAILURE_SHAREOFVOICE = "@data/SHARE_OF_VOICE_FAILURE",
  REQUEST_SNAPPLATFORMS = "@data/SNAPPLATFORMS_REQUEST",
  SUCCESS_SNAPPLATFORMS = "@data/SNAPPLATFORMS_SUCCESS",
  FAILURE_SNAPPLATFORMS = "@data/SNAPPLATFORMS_FAILURE",
  REQUEST_SNAPPUBLISHERS = "@data/SNAPPUBLISHERS_REQUEST",
  SUCCESS_SNAPPUBLISHERS = "@data/SNAPPUBLISHERS_SUCCESS",
  FAILURE_SNAPPUBLISHERS = "@data/SNAPPUBLISHERS_FAILURE",
  REQUEST_SNAPPUBLISHERS_SOI = "@data/SNAPPUBLISHERS_REQUEST_SOI",
  SUCCESS_SNAPPUBLISHERS_SOI = "@data/SNAPPUBLISHERS_SUCCESS_SOI",
  FAILURE_SNAPPUBLISHERS_SOI = "@data/SNAPPUBLISHERS_FAILURE_SOI",
  REQUEST_SNAPSOURCES = "@data/SNAPSOURCES_REQUEST",
  SUCCESS_SNAPSOURCES = "@data/SNAPSOURCES_SUCCESS",
  FAILURE_SNAPSOURCES = "@data/SNAPSOURCES_FAILURE",
  SET_SNAPTABLEPAGE = "@data/SET_SNAPTABLEPAGE",
  REQUEST_SNAPTABLE = "@data/SNAPTABLE_REQUEST",
  SUCCESS_SNAPTABLE = "@data/SNAPTABLE_SUCCESS",
  FAILURE_SNAPTABLE = "@data/SNAPTABLE_FAILURE",
  FETCH_SNAP_STATS_REQUEST = "@data/FETCH_SNAP_STATS_REQUEST",
  FETCH_SNAP_STATS_SUCCESS = "@data/FETCH_SNAP_STATS_SUCCESS",
  FETCH_SNAP_STATS_FAILURE = "@data/FETCH_SNAP_STATS_FAILURE",
  REQUEST_UF_SHAREOFVOICE = "@data/UNFILTERED_SHARE_OF_VOICE_REQUEST",
  SUCCESS_UF_SHAREOFVOICE = "@data/UNFILTERED_SHARE_OF_VOICE_SUCCESS",
  FAILURE_UF_SHAREOFVOICE = "@data/UNFILTERED_SHARE_OF_VOICE_FAILURE",
  REQUEST_INVESTMENT = "@data/INVESTMENT_DATA_REQUEST",
  SUCCESS_INVESTMENT = "@data/INVESTMENT_DATA_SUCCESS",
  FAILURE_INVESTMENT = "@data/INVESTMENT_DATA_FAILURE",
  REQUEST_UF_INVESTMENT = "@data/UNFILTERED_INVESTMENT_DATA_REQUEST",
  SUCCESS_UF_INVESTMENT = "@data/UNFILTERED_INVESTMENT_DATA_SUCCESS",
  FAILURE_UF_INVESTMENT = "@data/UNFILTERED_INVESTMENT_DATA_FAILURE",
  REQUEST_CAMPAIGNS = "@data/CAMPAIGNS_DATA_REQUEST",
  SUCCESS_CAMPAINGS = "@data/CAMPAIGNS_DATA_SUCCESS",
  FAILURE_CAMPAINGS = "@data/CAMPAIGNS_DATA_FAILURE",
  REQUEST_AUDIENCES = "@data/AUDIENCES_DATA_REQUEST",
  SUCCESS_AUDIENCES = "@data/AUDIENCES_DATA_SUCCESS",
  FAILURE_AUDIENCES = "@data/AUDIENCES_DATA_FAILURE",
  SET_MOREINFO = "@data/SET_MOREINFO_CAMPAIGN",
  SET_PIE_DATA = "@data/SET_PIEDATA",
  REPORT_REQUEST = "@data/REPORT_REQUEST",
  REPORT_SUCCESS = "@data/REPORT_SUCCESS",
  REPORT_FAILURE = "@data/REPORT_FAILURE",
  REPORT_MAIL_REQUEST = "@data/REPORT_MAIL_REQUEST",
  REPORT_MAIL_SUCCESS = "@data/REPORT_MAIL_SUCCESS",
  REPORT_MAIL_FAILURE = "@data/REPORT_MAIL_FAILURE",
  SAVE_CUSTOM_REPORT = "@data/SAVE_CUSTOM_REPORT",
  SAVE_CUSTOM_REPORT_SUCCESS = "@data/SAVE_CUSTOM_REPORT_SUCCESS",
  SAVE_CUSTOM_REPORT_FAILURE = "@data/SAVE_CUSTOM_REPORT_FAILURE",
  FETCH_USER_REPORTS = "@data/FETCH_USER_REPORT",
  FETCH_USER_REPORTS_SUCCESS = "@data/FETCH_USER_REPORT_SUCCESS",
  FETCH_USER_REPORTS_FAILURE = "@data/FETCH_USER_REPORT_FAILURE",
  CREATE_USER_REPORT = "@data/CREATE_USER_REPORT",
  CREATE_USER_REPORT_SUCCESS = "@data/CREATE_USER_REPORT_SUCCESS",
  CREATE_USER_REPORT_FAILURE = "@data/CREATE_USER_REPORT_FAILURE",
  EDIT_USER_REPORT = "@data/EDIT_USER_REPORT",
  EDIT_USER_REPORT_SUCCESS = "@data/EDIT_USER_REPORT_SUCCESS",
  EDIT_USER_REPORT_FAILURE = "@data/EDIT_USER_REPORT_FAILURE",
  DELETE_USER_REPORT = "@data/DELETE_USER_REPORT",
  DELETE_USER_REPORT_SUCCESS = "@data/DELETE_USER_REPORT_SUCCESS",
  DELETE_USER_REPORT_FAILURE = "@data/DELETE_USER_REPORT_FAILURE",
  TOGGLE_USER_REPORT = "@data/TOGGLE_USER_REPORT",
  TOGGLE_USER_REPORT_SUCCESS = "@data/TOGGLE_USER_REPORT_SUCCESS",
  TOGGLE_USER_REPORT_FAILURE = "@data/TOGGLE_USER_REPORT_FAILURE",
  CREATE_ALERT_REQUEST = "@data/CREATE_ALERT_REQUEST",
  CREATE_ALERT_SUCCESS = "@data/CREATE_ALERT_SUCCESS",
  CREATE_ALERT_FAILURE = "@data/CREATE_ALERT_FAILURE",
  ALERTS_REQUEST = "@data/ALERTS_REQUEST",
  ALERTS_SUCCESS = "@data/ALERTS_SUCCESS",
  ALERTS_FAILURE = "@data/ALERTS_FAILURE",
  DELETE_ALERT_REQUEST = "@data/DELETE_ALERT_REQUEST",
  DELETE_ALERT_SUCCESS = "@data/DELETE_ALERT_SUCCESS",
  DELETE_ALERT_FAILURE = "@data/DELETE_ALERT_FAILURE",
  SET_ALERT_TO_EDIT = "@data/SET_ALERT_TO_EDIT",
  SET_ALERT_TO_EDIT_NULL = "@data/SET_ALERT_TO_EDIT_NULL",
  EDIT_ALERT_REQUEST = "@data/EDIT_ALERT_REQUEST",
  EDIT_ALERT_SUCCESS = "@data/EDIT_ALERT_SUCCESS",
  EDIT_ALERT_FAILURE = "@data/EDIT_ALERT_FAILURE",
  SET_SNAP_CASTING = "@data/SET_SNAP_CASTING",

  DOWNLOAD_SNAPSHOTS_TABLE_REQUEST = "@data/DOWNLOAD_SNAPSHOTS_TABLE_REQUEST",
  DOWNLOAD_SNAPSHOTS_TABLE_SUCCESS = "@data/DOWNLOAD_SNAPSHOTS_TABLE_SUCCESS",
  DOWNLOAD_SNAPSHOTS_TABLE_FAILURE = "@data/DOWNLOAD_SNAPSHOTS_TABLE_FAILURE",

  DOWNLOAD_GALLERY_REQUEST = "@data/DOWNLOAD_GALLERY_REQUEST",
  DOWNLOAD_GALLERY_SUCCESS = "@data/DOWNLOAD_GALLERY_SUCCESS",
  DOWNLOAD_GALLERY_FAILURE = "@data/DOWNLOAD_GALLERY_FAILURE",

  DDS_WITHOUT_CAPTURES_REQUEST = "@data/DDS_WITHOUT_CAPTURES_REQUEST",
  DDS_WITHOUT_CAPTURES_SUCCESS = "@data/DDS_WITHOUT_CAPTURES_SUCCESS",
  DDS_WITHOUT_CAPTURES_FAILURE = "@data/DDS_WITHOUT_CAPTURES_FAILURE",
}

/**
 * Interfaces referidas a los datos.
 */

/**
 * No tengo un id. El endpoint no me permite pedir reportes.
 * Debería asignar un id al reporte y asignárselo en el sagas.
 */
export interface CustomReportParams {
  id?: number;
  name: string;
  filters: SearchParameters;
  frequency: "daily" | "weekly" | "biweekly" | "monthly" | "never";
  type: "campaign" | "share-of-voice" | "investment" | "campaign-rts";
  reportFormat: "pdf" | "excel";
  dateCreated: Date;
  lastSent?: Date;
  enabled: boolean;
}

type ReportParameters = {
  country: string;
  countries?: string[];
  page?: number;
  advertisers?: AdvancedSearchInterface[];
  formats?: AdvancedSearchInterface[];
  platforms?: AdvancedSearchInterface[];
  publishers?: AdvancedSearchInterface[];
  excludedPublishers?: AdvancedSearchInterface[];
  categories?: AdvancedSearchInterface[];
  industries?: AdvancedSearchInterface[];
  products?: AdvancedSearchInterface[];
  sources?: AdvancedSearchInterface[];
};

export interface ReportInterface {
  id: string;
  name: string;
  frequency: string;
  parameters: ReportParameters;
  formats: string[];
  enabled: boolean;
}

export interface CreateReportInterface {
  name: string;
  frequency: string;
  parameters: {
    country: string;
    countries: string[];
    [T: string]: number[] | string | string[];
  };
  formats: string[];
}

export interface EditReportInterface {
  id: string;
  name: string;
  frequency: string;
  formats: string[];
}

export interface ReportRequestParams {
  type: "campaign" | "share-of-voice" | "investment" | "campaign-rts" | "snapshots";
  filters: SearchParameters;
  reportFormat: "pdf" | "excel";
  snapType?: string;
}

export interface ReportMailRequestParams {
  type: "campaign" | "share-of-voice" | "investment" | "campaign-rts";
  filters: ReportMailParameters;
  reportFormat: "pdf" | "excel";
}

/**
 * ============ Share of Voice Interfaces ==============
 */

export interface PieData {
  extra: number;
  hidden: boolean;
  label: string;
  value: number;
}

export interface MediaData {
  name: string;
  data: number[];
}

export interface TrendingSoVData {
  name: string;
  data: number[];
  type: string;
}

export interface ShareVoiceItemTable {
  label: string;
  percentage: number;
  id: number;
  viewability: string;
  total: number;
}

export interface ShareVoice {
  country: string;
  mediaCategories: string[];
  mediaData: MediaData[];
  pieData: PieData[];
  tableData: ShareVoiceItemTable[];
  total: number;
  trendingSoV: TrendingSoVData;
}

/**
 * ================ Investment Interfaces ================
 */

export interface InvestmentItemTable {
  amount: number;
  prints: number;
  label: string;
  percentage: number;
  id: number;
}

export interface Investment {
  country: string;
  mediaCategories: string[];
  mediaDataAmount: MediaData[];
  mediaDataPrints: MediaData[];
  pieData: PieData[];
  tableData: InvestmentItemTable[];
  total: number;
  trendingSoI: TrendingSoVData;
  amount: number;
  prints: number;
  currency: string;
}

/**
 *  ==== Custom Spots Interfaces ==========
 */

interface FacebookMedia {
  footer?: {
    text: string;
    href?: string;
  };
  header?: {
    text: string;
    href: string;
  };
  src: string;
  height: number;
  width: number;
  mime_type: string;
  format: string;
  link_post?: string;
}

export interface TwitterStyles {
  body: {
    account_url?: string;
    account?: string;
    text?: string;
    subtitle?: string;
    md5?: string;
    media?: FacebookMedia[];
  };
  hashtag?: string;
  img_profile?: {
    src: string;
  };
  header?: {
    text: string;
    href: string;
    img?: {
      src: string;
    };
  };
  type: string;
  footer?: {
    text: string;
    link: string;
    title: string;
    href?: string;
  };
}

export interface FacebookStoryStyles {
  body: {
    text: string;
    media: FacebookMedia[];
  };
  header: {
    text: string;
    href: string;
    img?: {
      src: string;
    };
  };
  type: string;
  footer?: {
    text: string;
    link: string;
    title: string;
    href?: string;
  };
}

export interface FacebookPostStyles {
  text: string;
  title: string;
  url?: string;
}

export type StyleAttributes =
  | FacebookStoryStyles
  | FacebookPostStyles
  | TwitterStyles;

/**
 *  ========= Campaigns Interface ===========
 */
export interface CampaignsItem {
  advertiser: string;
  advertiserId: number;
  product: string;
  productId: number;
  platform: string;
  platformId: number;
  category: string;
  format: string;
  formatId: number;
  industry: string;
  industryId: number;
  creativity: string;
  pViewability: number | null;
  spots: number;
  lastSeen: string;
  lastSeenUrl: string;
  lastSeenScreenshot: string;
  lastSeenDate: string;
  style: string;
  styleAttributes: StyleAttributes;
}

/**
 *  =========== Spot List Interface =========
 */

export interface ListedSpotInterface {
  date: Date;
  advertiser: string;
  advertiserId: number;
  product: string;
  productId: number;
  industry: string;
  industryId: number;
  publisher: string;
  publisherId: number;
  platform: string;
  platformId: number;
  category: string;
  format: string;
  formatId: number;
  source: string;
  sourceId: number;
  creativity: string;
  screenshot: string;
  country: string;
  spots: number;
  pViewability: number;
}

export interface MoreInfoProps {
  title: string;
  lastSeenOn: string;
  lastSeenDate: Date;
  pView: number | null;
  spotsNumber: number;
  advertiser: string;
  advertiserId: number;
  product: string;
  productId: number;
  industryId: number;
  industry: string;
}

/**
 * PieDataInterface
 */

export interface PieDataInterface {
  label: string;
  hidden: boolean;
  value: number;
  extra: number;
  amount: number;
}

export interface MultiPieData {
  country: string;
  data: PieDataInterface[];
}

export type CSVObject = string[][];

/**
 *  =========== Snapshots =========
 */

export interface SnapTableItem {
  amount: number;
  id: number;
  label: string;
  percentage: number;
  prints: number;
  total: number;
  viewability: string;
}

export interface SingleCountrySnapTable {
  country: string;
  table: {
    currency: string;
    items: SnapTableItem[];
    totalPages: number;
    totalResults: number;
  };
}

export interface SnapTable {
  items: SingleCountrySnapTable[];
}

export interface SnapStatsHistogram {
  date: string;
  amount: number;
}

export interface SingleSnapStats {
  pViewability: number;
  adSpend: {
    histogram: SnapStatsHistogram[];
    currency: number;
    total: number;
  };
  totalStat: number;
}

export interface SnapStats {
  items: SingleSnapStats[];
}

export interface Audience {
  label: string;
  value: number;
  hidden: boolean;
}

export interface Audiences {
  audiences: Audience[];
}

/**
 * Interface del state
 */

export interface DataState {
  readonly shareOfVoice: ShareVoice[];
  readonly snapPlatforms: ShareVoice[];
  readonly snapSources: ShareVoice[];
  readonly snapPublishers: ShareVoice[];
  readonly snapPublishersSOI: Investment[];
  readonly snapTablePage: number;
  readonly snapTable: SnapTable;
  readonly snapStats: SnapStats;
  readonly audiences: Audiences | null;
  readonly investment: Investment[];
  readonly campaigns: CampaignsItem[];
  readonly moreInfoCampaign: MoreInfoProps;
  readonly unfilteredDataLoading: boolean;
  readonly requestedPage: number;
  readonly spotList: ListedSpotInterface[];
  readonly hasReportRequestFailed: boolean;
  readonly hasRequestFailed: boolean;
  readonly hasRequestFailedCreativities: boolean;
  readonly hasRequestFailedAudiences: boolean;
  readonly errorMessage: string;
  readonly errorMessageAudiences: string;
  readonly pieData: MultiPieData[];
  readonly unfilteredShareOfVoice: ShareVoice[];
  readonly unfilteredInvestment: Investment[];
  readonly savedReports: CustomReportParams[];
  readonly userReports: ReportInterface[];
  readonly userAlerts: AlertsInterface[];
  readonly userAlertToEdit: AlertsInterface | null;
  readonly shareOfVoiceCancelToken: CancelTokenSource;
  readonly snapPlatformsCancelToken: CancelTokenSource;
  readonly snapSourcesCancelToken: CancelTokenSource;
  readonly snapPublishersCancelToken: CancelTokenSource;
  readonly snapPublishersSOICancelToken: CancelTokenSource;
  readonly snapTableCancelToken: CancelTokenSource;
  readonly snapStatsCancelToken: CancelTokenSource;
  readonly investmentCancelToken: CancelTokenSource;
  readonly campaignsCancelToken: CancelTokenSource;
  readonly spotListCancelToken: CancelTokenSource;
  readonly snapAudiencesCancelToken: CancelTokenSource;
  readonly unfilteredShareOfVoiceCancelToken: CancelTokenSource;
  readonly unfilteredInvestmentCancelToken: CancelTokenSource;
  readonly exportReportsCancelToken: CancelTokenSource;
  readonly userReportsCancelToken: CancelTokenSource;
  readonly snapTableDownloadCancelToken: CancelTokenSource;
  readonly galleryDownloadCancelToken: CancelTokenSource;

  // LOADING STATES
  readonly isDataLoading: boolean;
  readonly isSnapSOILoading: boolean;
  readonly isSnapSOVLoading: boolean;
  readonly isSnapTableLoading: boolean;
  readonly isSnapCasting: boolean;
  readonly isSnapTableDownloading: boolean;
  readonly isGalleryDownloading: boolean;
  readonly isAudiencesLoading: boolean;
  readonly isDdsWithoutCaptureLoading: boolean;
  readonly isUserAlertsLoading: boolean;
  readonly isUserAlertCreated: boolean;
}
