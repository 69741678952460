import { FacebookStoryStyles } from "../../store/data/types";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { FacebookStoryAdTypes } from "../../utils/constants";
import ReactPlayer from "react-player";
import Slider from "react-slick";

interface FacebookStoryProps {
  facebookStoryAttributes: FacebookStoryStyles;
  advertiser: string;
}

export const FacebookStory = (props: FacebookStoryProps) => {
  const classes = useStyles();

  function CustomFacebookComponent() {
    switch (props.facebookStoryAttributes.type) {
      case FacebookStoryAdTypes.carousel:
        return (
          <div className={classes.mediaBoxCarousel}>
            <Slider dots={true} slidesToShow={1} slidesToScroll={1}>
              {props.facebookStoryAttributes.body.media.map((item, i) => (
                <div className={classes.facebookCarouselItem} key={`fbsv-${i}`}>
                  <div className={classes.facebookCarouselBody}>
                    <img
                      className={classes.facebookCarouselImage}
                      src={item.src}
                      alt={props.advertiser}
                    />
                  </div>
                  {item.footer !== undefined ? (
                    <div
                      className={classes.facebookCarouselFooter}
                      key={item.footer.text}
                    >
                      {item.footer.href !== undefined ? (
                        <div className={classes.facebookCarouselFooter}>
                          {item.footer.href}
                        </div>
                      ) : null}
                      {item.footer.text !== undefined ? (
                        <div className={classes.facebookCarouselFooterBody}>
                          {item.footer.text}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ))}
            </Slider>
          </div>
        );
      case FacebookStoryAdTypes.video:
        if (props.facebookStoryAttributes.body.media[0].format === "image") {
          return (
            <a
              href={props.facebookStoryAttributes.body.media[0].link_post ?? ""}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className={classes.facebookImage}
                src={props.facebookStoryAttributes.body.media[0].src}
                alt={props.advertiser}
              />
            </a>
          );
        }
        return (
          <div className={classes.facebookVideo}>
            <ReactPlayer
              url={props.facebookStoryAttributes.body.media[0].src}
              controls
              width="100%"
            />
          </div>
        );
      case FacebookStoryAdTypes.image:
        if (props.facebookStoryAttributes.body.media[0].link_post) {
          return (
            <a
              href={props.facebookStoryAttributes.body.media[0].link_post}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className={classes.facebookImage}
                src={props.facebookStoryAttributes.body.media[0].src}
                alt={props.advertiser}
              />
            </a>
          );
        }
        return (
          <img
            className={classes.facebookImage}
            src={props.facebookStoryAttributes.body.media[0].src}
            alt={props.advertiser}
          />
        );
      case FacebookStoryAdTypes.card:
        return (
          <img
            className={classes.facebookImage}
            src={props.facebookStoryAttributes.body.media[0].src}
            alt={props.advertiser}
          />
        );
    }
  }

  return (
    <div className={classes.facebookStory}>
      <div className={classes.header}>
        {props.facebookStoryAttributes.header.img ? (
          <img
            className={classes.profileImg}
            src={props.facebookStoryAttributes.header.img.src}
            alt={props.advertiser}
          />
        ) : (
          ""
        )}
        <a
          href={props.facebookStoryAttributes.header.href}
          className={classes.title}
          target={"_blank"}
        >
          {props.facebookStoryAttributes.header.text}
        </a>
      </div>
      <p className={classes.body}>{props.facebookStoryAttributes.body.text}</p>
      {props.facebookStoryAttributes.type !== undefined ? (
        <div className={classes.mediaBox}>
          {CustomFacebookComponent()}
          {props.facebookStoryAttributes.footer !== undefined ? (
            <div className={classes.footer}>
              <div className={classes.footerTitle}>
                {props.facebookStoryAttributes.footer.title}
              </div>
              <div className={classes.footerBody}>
                {props.facebookStoryAttributes.footer.text}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={classes.mediaBox}>
          {props.facebookStoryAttributes.body.media[0].mime_type ===
          FacebookStoryAdTypes.videoMP4 ? (
            <ReactPlayer
              url={props.facebookStoryAttributes.body.media[0].src}
              controls
              width="100%"
            />
          ) : (
            <img
              src={props.facebookStoryAttributes.body.media[0].src}
              alt={props.advertiser}
            />
          )}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  facebookStory: {
    marginBottom: 20,
    maxWidth: "500px",
    textAlign: "left",
    marginLeft: 10,
    marginRight: 10,
  },
  header: {
    marginBottom: 11,
    marginLeft: 0,
    boxSizing: "border-box",
  },
  profileImg: {
    float: "left",
    height: 40,
    marginRight: 8,
    width: 40,
  },
  title: {
    color: "#3b5998",
    fontFamily: "helvetica,arial,sans-serif",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 5,
    maxHeight: 110,
    overflow: "hidden",
    wordWrap: "break-word",
    textDecoration: "none",
  },
  body: {
    color: "#141823",
    paddingTop: 3,
    fontFamily: "helvetica",
    fontSize: 14,
    textAlign: "left",
  },
  mediaBox: {
    boxShadow: "inset 0 0 0 1.5px rgba(0,0,0,.1), 0 1px 1px rgba(0,0,0,.05)",
  },
  mediaBoxCarousel: {
    position: "relative",
    whiteSpace: "nowrap",
    marginRight: 1,
  },
  facebookCarouselInner: {
    color: "#4e5665",
    padding: 1,
    position: "relative",
    listStyleType: "none",
    margin: 0,
    whiteSpace: "nowrap",
    wordWrap: "break-word",
  },
  facebookCarouselItem: {
    borderWidth: 0,
    marginRight: 6,
    paddingLeft: 0,
    verticalAlign: "top",
    whiteSpace: "normal",
  },
  facebookCarouselBody: {
    marginTop: 1,
    marginLeft: 1,
    marginRight: 0,
    marginBottom: 0,
    overflow: "hidden",
    textAlign: "center",
    width: "100%",
  },
  facebookCarouselImage: {
    objectFit: "cover",
    width: "100%",
  },
  facebookCarouselFooter: {
    height: 54,
    padding: "6 8",
  },
  facebookCarouselFooterLink: {
    color: "#3b5998",
    display: "-webkit-box",
    fontSize: 12,
    fontWeight: "bold",
    height: 36,
    lineHeight: 18,
    marginBottom: 1,
    marginTop: 1,
    overflow: "hidden",
    "-webkit-line-clamp": 2,
  },
  facebookCarouselFooterBody: {
    color: "#b2b2b2",
    fontSize: 12,
    height: 15,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  facebookVideo: {
    textAlign: "center",
    width: "100%",
  },
  facebookImage: {
    maxHeight: "60vh",
    objectFit: "cover",
    overflow: "hidden",
    width: "500px",
  },
  footer: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 12,
    marginRight: 12,
  },
  footerTitle: {
    color: "#141823",
    fontFamily: "Georgia,lucida grande,tahoma,verdana,arial,sans-serif",
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 5,
    maxHeight: 110,
    overflow: "hidden",
    wordWrap: "break-word",
  },
  footerBody: {
    color: "#141823",
    fontFamily: "helvetica",
    fontSize: 12,
    maxHeight: 80,
    wordWrap: "break-word",
  },
  facebookStoryAd: {
    textAlign: "left",
    width: 476,
  },
});
