import { action } from "typesafe-actions";
import {
  CampaignsItem,
  DataActionTypes,
  Investment,
  ListedSpotInterface,
  MoreInfoProps,
  ShareVoice,
  MultiPieData,
  ReportRequestParams,
  CustomReportParams,
  ReportInterface,
  CreateReportInterface,
  EditReportInterface,
  SnapStats,
  SnapTable,
  ReportMailRequestParams,
  Audiences,
} from "./types";
import {
  AlertsInterface,
  PaginationParametersInterface,
  SearchParameters,
} from "../search/types";
import axios from "axios";

/**
 * Action creators.
 */

export const shareOfVoiceDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  type: string,
  snapPage = false
) =>
  action(DataActionTypes.REQUEST_SHAREOFVOICE, {
    searchParameters,
    paginationParameters,
    token,
    type,
    snapPage,
    cancelToken: axios.CancelToken.source(),
  });

export const shareOfVoiceFetchSuccess = (shareOfVoice: ShareVoice[]) =>
  action(DataActionTypes.SUCCESS_SHAREOFVOICE, { shareOfVoice });

export const shareVoiceFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SHAREOFVOICE, { error });

export const snapPlatformsDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string
) =>
  action(DataActionTypes.REQUEST_SNAPPLATFORMS, {
    searchParameters,
    paginationParameters,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const snapPlatformsFetchSuccess = (shareOfVoice: ShareVoice[]) =>
  action(DataActionTypes.SUCCESS_SNAPPLATFORMS, { shareOfVoice });

export const snapPlatformsFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SNAPPLATFORMS, { error });

export const snapSourcesDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string
) =>
  action(DataActionTypes.REQUEST_SNAPSOURCES, {
    searchParameters,
    paginationParameters,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const snapSourcesFetchSuccess = (shareOfVoice: ShareVoice[]) =>
  action(DataActionTypes.SUCCESS_SNAPSOURCES, { shareOfVoice });

export const snapSourcesFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SNAPSOURCES, { error });

export const snapPublishersDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  advertisers: boolean
) =>
  action(DataActionTypes.REQUEST_SNAPPUBLISHERS, {
    searchParameters,
    paginationParameters,
    token,
    advertisers,
    cancelToken: axios.CancelToken.source(),
  });

export const snapPublishersFetchSuccess = (shareOfVoice: ShareVoice[]) =>
  action(DataActionTypes.SUCCESS_SNAPPUBLISHERS, { shareOfVoice });

export const snapPublishersFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SNAPPUBLISHERS, { error });

export const snapPublishersSOIDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  advertisers: boolean
) =>
  action(DataActionTypes.REQUEST_SNAPPUBLISHERS_SOI, {
    searchParameters,
    paginationParameters,
    token,
    advertisers,
    cancelToken: axios.CancelToken.source(),
  });

export const snapPublishersSOIFetchSuccess = (investment: Investment[]) =>
  action(DataActionTypes.SUCCESS_SNAPPUBLISHERS_SOI, { investment });

export const snapPublishersSOIFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SNAPPUBLISHERS_SOI, { error });

export const setSnapTablePage = (page: number) =>
  action(DataActionTypes.SET_SNAPTABLEPAGE, {
    page,
  });

export const snapTableDataRequest = (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  page: number
) =>
  action(DataActionTypes.REQUEST_SNAPTABLE, {
    searchParameters,
    token,
    type,
    page,
    cancelToken: axios.CancelToken.source(),
  });

export const snapTableFetchSuccess = (table: SnapTable) =>
  action(DataActionTypes.SUCCESS_SNAPTABLE, { table });

export const snapTableFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SNAPTABLE, { error });

export const snapStatsDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  type: string
) =>
  action(DataActionTypes.FETCH_SNAP_STATS_REQUEST, {
    searchParameters,
    paginationParameters,
    token,
    type,
    cancelToken: axios.CancelToken.source(),
  });

export const snapStatsFetchSuccess = (stats: SnapStats) =>
  action(DataActionTypes.FETCH_SNAP_STATS_SUCCESS, { stats });

export const snapStatsFetchFailure = (error: string) =>
  action(DataActionTypes.FETCH_SNAP_STATS_FAILURE, { error });

export const investmentDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  type: string,
  snapPage = false
) =>
  action(DataActionTypes.REQUEST_INVESTMENT, {
    searchParameters,
    paginationParameters,
    token,
    type,
    snapPage,
    cancelToken: axios.CancelToken.source(),
  });

export const investmentDataSuccess = (investment: Investment[]) =>
  action(DataActionTypes.SUCCESS_INVESTMENT, { investment });

export const investmentDataFailure = (error: string) =>
  action(DataActionTypes.FAILURE_INVESTMENT, { error });

export const campaignsDataFetchRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string
) =>
  action(DataActionTypes.REQUEST_CAMPAIGNS, {
    searchParameters,
    paginationParameters,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const fetchCampaignsDataSuccess = (campaigns: CampaignsItem[]) =>
  action(DataActionTypes.SUCCESS_CAMPAINGS, { campaigns });

export const campaignsDataFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_CAMPAINGS, { error });

export const spotsListDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string
) =>
  action(DataActionTypes.REQUEST_SPOTSLIST, {
    searchParameters,
    paginationParameters,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const spotsListFetchSuccess = (spotsList: ListedSpotInterface[]) =>
  action(DataActionTypes.SUCCESS_SPOTSLIST, { spotsList });

export const spotsListDataFailure = (error: string) =>
  action(DataActionTypes.FAILURE_SPOTSLIST, { error });

export const setMoreInfoCampaign = (info: MoreInfoProps) =>
  action(DataActionTypes.SET_MOREINFO, { info });

export const setPieData = (data: MultiPieData[]) =>
  action(DataActionTypes.SET_PIE_DATA, { data });

export const unfilteredShareOfVoiceRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  type: string
) =>
  action(DataActionTypes.REQUEST_UF_SHAREOFVOICE, {
    searchParameters,
    paginationParameters,
    token,
    type,
    cancelToken: axios.CancelToken.source(),
  });

export const unfilteredShareOfVoiceFetchSuccess = (shareOfVoice: ShareVoice[]) =>
  action(DataActionTypes.SUCCESS_UF_SHAREOFVOICE, { shareOfVoice });

export const unfilteredShareVoiceFetchFailure = (error: string) =>
  action(DataActionTypes.FAILURE_UF_SHAREOFVOICE, { error });

export const unfilteredInvestmentDataRequest = (
  searchParameters: SearchParameters,
  paginationParameters: PaginationParametersInterface,
  token: string,
  type: string
) =>
  action(DataActionTypes.REQUEST_UF_INVESTMENT, {
    searchParameters,
    paginationParameters,
    token,
    type,
    cancelToken: axios.CancelToken.source(),
  });

export const unfilteredInvestmentDataSuccess = (investment: Investment[]) =>
  action(DataActionTypes.SUCCESS_UF_INVESTMENT, { investment });

export const unfilteredInvestmentDataFailure = (error: string) =>
  action(DataActionTypes.FAILURE_UF_INVESTMENT, { error });

export const reportRequest = (params: ReportRequestParams, token: string) =>
  action(DataActionTypes.REPORT_REQUEST, {
    params,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const reportSuccess = () => action(DataActionTypes.REPORT_SUCCESS);

export const reportFailure = () => action(DataActionTypes.REPORT_FAILURE);

export const reportMailRequest = (params: ReportMailRequestParams, token: string) =>
  action(DataActionTypes.REPORT_MAIL_REQUEST, {
    params,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const reportMailSuccess = () => action(DataActionTypes.REPORT_MAIL_SUCCESS);

export const reportMailFailure = () => action(DataActionTypes.REPORT_MAIL_FAILURE);

export const saveCustomReport = (params: CustomReportParams) =>
  action(DataActionTypes.SAVE_CUSTOM_REPORT, { params });

export const fecthReportRequest = (token: string) =>
  action(DataActionTypes.FETCH_USER_REPORTS, {
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const fecthReportSuccess = (reports: ReportInterface[]) =>
  action(DataActionTypes.FETCH_USER_REPORTS_SUCCESS, { reports });

export const fecthReportFailure = () =>
  action(DataActionTypes.FETCH_USER_REPORTS_FAILURE);

export const createReportRequest = (token: string, report: CreateReportInterface) =>
  action(DataActionTypes.CREATE_USER_REPORT, { token, report });

export const createReportSuccess = () =>
  action(DataActionTypes.CREATE_USER_REPORT_SUCCESS);

export const createReportFailure = () =>
  action(DataActionTypes.CREATE_USER_REPORT_FAILURE);

export const editReportRequest = (token: string, report: EditReportInterface) =>
  action(DataActionTypes.EDIT_USER_REPORT, { token, report });

export const editReportSuccess = () =>
  action(DataActionTypes.EDIT_USER_REPORT_SUCCESS);

export const editReportFailure = () =>
  action(DataActionTypes.EDIT_USER_REPORT_FAILURE);

export const toggleReportRequest = (token: string, id: string, enabled: boolean) =>
  action(DataActionTypes.TOGGLE_USER_REPORT, { token, id, enabled });

export const toggleReportSuccess = () =>
  action(DataActionTypes.TOGGLE_USER_REPORT_SUCCESS);

export const toggleReportFailure = () =>
  action(DataActionTypes.TOGGLE_USER_REPORT_FAILURE);

export const deleteReportRequest = (token: string, id: string) =>
  action(DataActionTypes.DELETE_USER_REPORT, { token, id });

export const deleteReportSuccess = () =>
  action(DataActionTypes.DELETE_USER_REPORT_SUCCESS);

export const deleteReportFailure = () =>
  action(DataActionTypes.DELETE_USER_REPORT_FAILURE);

export const setSnapCasting = (casting: boolean) =>
  action(DataActionTypes.SET_SNAP_CASTING, { casting });

export const downloadSnapTableRequest = (
  searchParameters: SearchParameters,
  token: string,
  type: string,
  page: number,
  limit: number
) =>
  action(DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_REQUEST, {
    searchParameters,
    token,
    type,
    page,
    limit,
    cancelToken: axios.CancelToken.source(),
  });

export const downloadSnapTableSuccess = () =>
  action(DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_SUCCESS);

export const downloadSnapTableFailure = (error: string) =>
  action(DataActionTypes.DOWNLOAD_SNAPSHOTS_TABLE_FAILURE, { error });

export const downloadGalleryRequest = (
  searchParameters: SearchParameters,
  page: number,
  limit: number,
  unique: number,
  token: string
) =>
  action(DataActionTypes.DOWNLOAD_GALLERY_REQUEST, {
    searchParameters,
    page,
    limit,
    unique,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const downloadGallerySuccess = () =>
  action(DataActionTypes.DOWNLOAD_GALLERY_SUCCESS);

export const downloadGalleryFailure = (error: string) =>
  action(DataActionTypes.DOWNLOAD_GALLERY_FAILURE, { error });

export const fetchAudiencesRequest = (
  searchParameters: SearchParameters,
  token: string
) =>
  action(DataActionTypes.REQUEST_AUDIENCES, {
    searchParameters,
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const fetchAudiencesSuccess = (audiences: Audiences) =>
  action(DataActionTypes.SUCCESS_AUDIENCES, { audiences });

export const fetchAudiencesFailure = (error: string) =>
  action(DataActionTypes.FAILURE_AUDIENCES, { error });

export const fetchDdsWithoutCaptureRequest = (
  token: string,
  country: string,
  since: Date,
  until: Date
) =>
  action(DataActionTypes.DDS_WITHOUT_CAPTURES_REQUEST, {
    token,
    country,
    since,
    until,
    cancelToken: axios.CancelToken.source(),
  });

export const fetchDdsWithoutCaptureSuccess = () =>
  action(DataActionTypes.DDS_WITHOUT_CAPTURES_SUCCESS);

export const fetchDdsWithoutCaptureFailure = (error: string) =>
  action(DataActionTypes.DDS_WITHOUT_CAPTURES_FAILURE, { error });

export const createAlertRequest = (token: string, alert: AlertsInterface) =>
  action(DataActionTypes.CREATE_ALERT_REQUEST, { token, alert });

export const createAlertSuccess = () => action(DataActionTypes.CREATE_ALERT_SUCCESS);

export const createAlertFailure = () => action(DataActionTypes.CREATE_ALERT_FAILURE);

export const fetchAlertsRequest = (token: string) =>
  action(DataActionTypes.ALERTS_REQUEST, {
    token,
    cancelToken: axios.CancelToken.source(),
  });

export const fetchAlertsSuccess = (alerts: any) =>
  action(DataActionTypes.ALERTS_SUCCESS, { alerts });

export const fetchAlertsFailure = (error: string) =>
  action(DataActionTypes.ALERTS_FAILURE, { error });

export const deleteAlertRequest = (token: string, id: number) =>
  action(DataActionTypes.DELETE_ALERT_REQUEST, { token, id });

export const deleteAlertSuccess = () => action(DataActionTypes.DELETE_ALERT_SUCCESS);

export const deleteAlertFailure = () => action(DataActionTypes.DELETE_ALERT_FAILURE);

export const setAlertToEdit = (alert: AlertsInterface) =>
  action(DataActionTypes.SET_ALERT_TO_EDIT, { alert });
export const setAlertToEditNull = () =>
  action(DataActionTypes.SET_ALERT_TO_EDIT_NULL);

export const editAlertRequest = (
  token: string,
  id: number,
  alert: AlertsInterface
) => action(DataActionTypes.EDIT_ALERT_REQUEST, { token, id, alert });

export const editAlertSuccess = () => action(DataActionTypes.EDIT_ALERT_SUCCESS);

export const editAlertFailure = () => action(DataActionTypes.EDIT_ALERT_FAILURE);
