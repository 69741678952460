/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { RootState } from "typesafe-actions";
import Chart from "react-apexcharts";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { CsvBuilder } from "filefy";

import Widget from "../dashboard/Widget";
import { PieOptions } from "../../utils/ChartOptions";
import PieLegend from "./PieLegend";
import { COLORS, darkCOLORS, greyScale } from "../../utils/colors";
import { CSVObject } from "../../store/data/types";

const useStyles = makeStyles(() => ({
  blur: {
    filter: "blur(0.15rem)",
    marginTop: 20,
  },
}));

export interface AudiencesDonutChartSeries {
  label: string;
  value: number;
  hidden: boolean;
}

interface DonutChartProps {
  title: string | JSX.Element;
  country: number;
  items: AudiencesDonutChartSeries[];
  multi?: boolean;
  snap?: boolean;
  rates?: false | { [p: string]: number };
  hasRequestFailed?: boolean;
  errorMessage?: string;
  loading: boolean;
}

const AudiencesDonutChart = React.memo(
  (props: DonutChartProps) => {
    const { hasRequestFailed, errorMessage } = props;
    const { roles } = useSelector((state: RootState) => state.login);
    const { countries } = useSelector((state: RootState) => state.search);

    const [pieData, setPieData] = useState<AudiencesDonutChartSeries[]>(props.items);
    const [pieValues, setPieValues] = useState<number[]>([]);
    const [pieLabels, setPieLabels] = useState<string[]>([]);
    const formatMessage = useFormatMessage();
    const formatNumber = useFormatNumber();
    const theme = useTheme();
    const chartRef = useRef<Chart>(null);
    const classes = useStyles();

    const roleAudiences = useMemo(
      () => roles.some((item) => item === "ROLE_FEATURE_AUDIENCES"),
      [roles]
    );

    const isActive = useMemo(
      () => ["MX", "AR", "CO", "PE", "CL", "BR"].includes(countries[0]),
      [countries]
    );

    const audienceLabelFormatter = (label: string): string => {
      // const yearsMsg = formatMessage("years");
      const menMsg = formatMessage("men");
      const womenMsg = formatMessage("women");
      switch (label) {
        case "MALES: 6-14":
          return `${menMsg} 6-14 `;
        case "MALES: 15-24":
          return `${menMsg} 15-24 `;
        case "MALE_18+":
          return `${menMsg} 18+ `;
        case "MALES_18+":
          return `${menMsg} 18+ `;
        case "MALES: 25-34":
          return `${menMsg} 25-34 `;
        case "MALES: 35+":
          return `${menMsg} 35+ `;
        case "TARGET :: MALES: 6-14":
          return `${menMsg} 6-14 `;
        case "TARGET :: MALES: 6-17":
          return `${menMsg} 6-17 `;
        case "TARGET :: MALES: 15-17":
          return `${menMsg} 15-17 `;
        case "TARGET :: MALES: 15-24":
          return `${menMsg} 15-24 `;
        case "TARGET :: MALES: 18-24":
          return `${menMsg} 18-24 `;
        case "TARGET :: MALES: 25-34":
          return `${menMsg} 25-34 `;
        case "TARGET :: MALES: 35-44":
          return `${menMsg} 35-44 `;
        case "TARGET :: MALES: 45-54":
          return `${menMsg} 45-54 `;
        case "TARGET :: MALES: 55+":
          return `${menMsg} 55+ `;
        case "FEMALES: 6-14":
          return `${womenMsg} 6-14 `;
        case "FEMALES: 15-24":
          return `${womenMsg} 15-24 `;
        case "FEMALE_18+":
          return `${womenMsg} 18+ `;
        case "FEMALES_18+":
          return `${womenMsg} 18+ `;
        case "FEMALES: 25-34":
          return `${womenMsg} 25-34 `;
        case "FEMALES: 35+":
          return `${womenMsg} 35+ `;
        case "TARGET :: FEMALES: 6-14":
          return `${womenMsg} 6-14 `;
        case "TARGET :: FEMALES: 6-17":
          return `${womenMsg} 6-17 `;
        case "TARGET :: FEMALES: 15-17":
          return `${womenMsg} 15-17 `;
        case "TARGET :: FEMALES: 15-24":
          return `${womenMsg} 15-24 `;
        case "TARGET :: FEMALES: 18-24":
          return `${womenMsg} 18-24 `;
        case "TARGET :: FEMALES: 25-34":
          return `${womenMsg} 25-34 `;
        case "TARGET :: FEMALES: 35-44":
          return `${womenMsg} 35-44 `;
        case "TARGET :: FEMALES: 45-54":
          return `${womenMsg} 45-54 `;
        case "TARGET :: FEMALES: 55+":
          return `${womenMsg} 55+ `;
        default:
          return `Dummy Data 6-55+ `;
      }
    };

    const audienceFormatter = (value: number): string => {
      let total = 0;
      pieData.forEach((item) => {
        if (!item.hidden || props.snap) total += item.value;
      });
      const percentage = (value * 100) / total;
      return `${formatNumber(percentage, {
        maximumFractionDigits: 2,
      })}%`;
    };

    useEffect(() => {
      setPieData(props.items);
      const newLabels: string[] = [];
      const newValues: number[] = [];
      props.items.forEach((item) => {
        newLabels.push(item.label);
        newValues.push(item.value);
      });
      setPieLabels(newLabels);
      setPieValues(newValues);
    }, [props.items]);

    useEffect(() => {
      const newValues: number[] = [];
      pieData.forEach((item) => {
        if (!item.hidden) {
          newValues.push(item.value);
        }
      });
      setPieValues(newValues);
    }, [pieData]);

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      headers.push(`${formatMessage("shareofvoicePage.table.column.percentage")}`);
      headers.push(`Spots`);
      data.push(headers);

      pieValues.forEach((val, i) => {
        data.push([pieLabels[i], audienceFormatter(val)]);
      });

      return data;
    };

    const exportPie = () => {
      const builder = new CsvBuilder(
        `Audiences Donut Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Widget title={props.title} snap={props.snap} handleDownloadCSV={exportPie}>
        {!roleAudiences && isActive ? (
          <Alert severity="warning">{formatMessage("errors.audiences.role")}</Alert>
        ) : null}

        {!props.loading &&
        roleAudiences &&
        isActive &&
        pieData.length === 0 &&
        !hasRequestFailed ? (
          <Alert severity="warning">{formatMessage("errors.audiences.void")}</Alert>
        ) : null}

        {!props.loading &&
        roleAudiences &&
        isActive &&
        hasRequestFailed &&
        errorMessage ? (
          <Alert severity="warning">{errorMessage}</Alert>
        ) : null}

        {props.loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
            <p>{formatMessage("snapshots.loading")}</p>
          </div>
        )}

        {props.loading || pieLabels.length === 0 ? null : (
          <div className={clsx({ [classes.blur]: isActive && !roleAudiences })}>
            <Chart
              ref={chartRef}
              options={{
                ...PieOptions,
                chart: {
                  id: `donut-chart-${props.country}-${
                    props.items.length > 0
                      ? props.items[0].label.split(" ").join("_")
                      : "0"
                  }`,
                  ...PieOptions.chart,
                },
                tooltip: {
                  enabled: true,
                  shared: false,
                  intersect: true,
                  custom: function ({
                    series,
                    seriesIndex,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    dataPointIndex,
                    w,
                  }: {
                    series: any[];
                    seriesIndex: number;
                    dataPointIndex: number;
                    w: any;
                  }) {
                    const label = audienceLabelFormatter(
                      w.globals.seriesNames[seriesIndex]
                    );
                    const value = audienceFormatter(series[seriesIndex]);
                    return (
                      '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">' +
                      '<span class="apexcharts-tooltip-marker" style="background: ' +
                      w.globals.colors[seriesIndex] +
                      '; display: block;"></span>' +
                      '<div class="apexcharts-tooltip-text">' +
                      '<div class="apexcharts-tooltip-y-group">' +
                      '<span class="apexcharts-tooltip-text-label" style="color: rgba(0, 0, 0, 0.87);">' +
                      label +
                      "</span>" +
                      '<span class="apexcharts-tooltip-text-value" style="color: rgba(0, 0, 0, 0.87);">' +
                      value +
                      "</span>" +
                      "</div>" +
                      '<div class="apexcharts-tooltip-z-group">' +
                      '<span class="apexcharts-tooltip-text-z-label"></span>' +
                      '<span class="apexcharts-tooltip-text-z-value"></span>' +
                      "</div>" +
                      "</div>" +
                      "</div>"
                    );
                  },
                  fixed: {
                    enabled: true,
                  },
                },
                labels: pieLabels,
                theme: {
                  monochrome: {
                    enabled: false,
                  },
                },
                colors: [
                  // @ts-expect-error
                  function ({ value, seriesIndex }) {
                    if (value && seriesIndex) {
                      const indexToUse = seriesIndex % COLORS.length;

                      if (isActive && roleAudiences) {
                        return theme.palette.type === "dark"
                          ? darkCOLORS[indexToUse]
                          : COLORS[indexToUse];
                      }

                      return greyScale[indexToUse];
                    } else {
                      if (isActive && roleAudiences) {
                        return theme.palette.type === "dark"
                          ? darkCOLORS[0]
                          : COLORS[0];
                      }

                      return greyScale[0];
                    }
                  },
                ],
                animations: {
                  enabled: false,
                },
              }}
              type={"donut"}
              series={pieValues}
              height={isActive && !roleAudiences ? 300 : 400}
            />
            {props.loading || (!roleAudiences && isActive) ? null : (
              <PieLegend
                labels={pieLabels}
                country={props.country}
                pieData={pieData.map((item) => ({ ...item, extra: 0 }))} // Temporal fix for extra attribute
                setPieData={setPieData}
                greyColors={!isActive || !roleAudiences}
              />
            )}
          </div>
        )}
      </Widget>
    );
  },
  (prevProps: DonutChartProps, nextProps: DonutChartProps) => {
    return (
      JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items) &&
      prevProps.hasRequestFailed === nextProps.hasRequestFailed &&
      prevProps.errorMessage === nextProps.errorMessage &&
      prevProps.loading === nextProps.loading
    );
  }
);

AudiencesDonutChart.displayName = "AudiencesDonutChart";

export default AudiencesDonutChart;
