import { CsvBuilder } from "filefy";
import { startOfYesterday, subDays } from "date-fns";
import {
  CSVObject,
  Investment,
  InvestmentItemTable,
  MediaData,
  ShareVoiceItemTable,
  SingleCountrySnapTable,
  SnapStatsHistogram,
  TrendingSoVData,
} from "../store/data/types";
import { ColumnSeriesInterface } from "./ChartOptions";

export const getMCshareOfInvestment = (
  filterId: number,
  unfiltered: Investment[]
) => {
  const data: number[] = [];
  unfiltered.forEach((country) => {
    const item = country.tableData.find(
      (item) => item.id.toString() === filterId.toString()
    );
    data.push((item && item.percentage) || 0);
  });
  return data;
};

const getFilteredValues = (index: number, series: ColumnSeriesInterface[]) => {
  let filteredValues = 0;
  series.forEach((serie) => {
    filteredValues += serie.data[index];
  });
  return filteredValues;
};

export const getOthersValue = (
  series: ColumnSeriesInterface[],
  countries: string[]
) => {
  const data: number[] = [];
  countries.forEach((country, index) => {
    data.push(100 - getFilteredValues(index, series));
  });
  return data;
};

export function getTrendingMediaPlan(
  dataSOV: TrendingSoVData,
  dataSOI: TrendingSoVData
) {
  const series: ColumnSeriesInterface[] = [];
  series.push(dataSOV);

  series.push({
    name: "SOI",
    data: dataSOI.data,
    type: "line",
  });

  return series;
}

export function getDatesStats(data: SnapStatsHistogram[]) {
  const dates: string[] = [];
  if (data[0] !== undefined) {
    data.forEach((value) => {
      dates.push(value.date);
    });
  }
  return dates;
}

export function tooltipFormatter(value: number) {
  return value + " spots";
}

export function getInvestmentHistogramValues(
  dataAmount: MediaData,
  dataPrints: MediaData,
  amountView: boolean
) {
  const result: number[] = [];
  if (amountView) {
    dataAmount.data.forEach((item) => result.push(item));
  } else {
    dataPrints.data.forEach((item) => result.push(item));
  }
  return result;
}
export function getInvestmentHistogramValuesPrints(data: InvestmentItemTable[]) {
  const result: number[] = [];
  data.forEach((item) => result.push(item.prints));
  return result;
}

export const getDateFromReports = (frequency: string, investment: boolean) => {
  if (frequency === "daily" && !investment) {
    return [startOfYesterday(), startOfYesterday()];
  } else if (frequency === "weekly" && !investment) {
    return [startOfYesterday(), subDays(startOfYesterday(), 7)];
  } else if (frequency === "monthly") {
    return [startOfYesterday(), subDays(startOfYesterday(), 30)];
  } else {
    return [startOfYesterday(), subDays(startOfYesterday(), 15)];
  }
};

export const getPercentageWithFilterSOV = (
  total: Array<ShareVoiceItemTable>,
  totalItem: number
) => {
  let totalItems = 0;
  total.forEach((item) => {
    totalItems += item.total;
  });

  return (totalItem * 100) / totalItems;
};

export const getPercentageWithFilterSOI = (
  total: Array<InvestmentItemTable>,
  totalItem: number
) => {
  let totalItems = 0;
  total.forEach((item) => {
    totalItems += item.amount;
  });

  return (totalItem * 100) / totalItems;
};

export function numberWithCommas(x: number): string {
  const stringSplit = x.toString().split(".");
  stringSplit[0] = stringSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (stringSplit.length > 1) {
    stringSplit[1] = stringSplit[1].slice(0, 2);
  }
  return stringSplit.join(",");
}

export function getSnapTableCSV(items: SingleCountrySnapTable[]): void {
  const data: CSVObject = [];
  const headers = ["Name", "pViewability", "SOV (%)", "Spots", "SOI ($)"];
  data.push(headers);

  items &&
    items[0].table.items.forEach((item) => {
      try {
        const row: string[] = [];
        row.push(item.label);
        row.push(item.viewability);
        row.push(item.percentage.toFixed(2));
        row.push(item.total.toString());
        row.push(item.amount ? item.amount.toString() : "0");
        for (let i = 1; i < items.length; i++) {
          const otherItem = items[i].table.items.find((x) => x.id === item.id);
          otherItem && row.push(otherItem.viewability.toString());
          otherItem && row.push(otherItem.percentage.toFixed(2));
          otherItem && row.push(otherItem.total.toString());
          otherItem &&
            row.push(otherItem.amount ? otherItem.amount.toString() : "0");
        }
        data.push(row);
      } catch (e) {
        console.log(item);
        console.error(e);
      }
    });
  const builder = new CsvBuilder("snapshots-table.csv");
  builder.setDelimeter(",").addRows(data).exportFile();
}
