import { COLORS, darkCOLORS } from "./colors"

export const pieDatasetColor = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
]

export const pieDatasetHoverColor = [
  "#6CC5FF",
  "#6CFFCD",
  "#FFE770",
  "#FF859A",
  "#AE98FF",
  "#6CC5FF",
  "#6CFFCD",
  "#FFE770",
  "#FF859A",
  "#AE98FF",
]

export const PieOptions = {
  stroke: {
    show: false
  },
  responsive: [
    {
      breakpoint: 1000,
      options: {
        xaxis: {
          labels: {
            rotate: -45,
          },
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: false
    },
    animations: {
      enabled: false,
    }
  },
  legend: {
    show: false,
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "12px",
    onItemClick: {
      toggleDataSeries: true,
    },
    offsetY: -10,
  },
}

export const SharePieTooltip = {
  tooltip: {
    enabled: true,
    shared: false,
    intersect: true,
    y: {
      formatter: function (value: number) {
        if (value === undefined) {
          return ""
        } else {
          return "%" + value.toFixed(2)
        }
      },
    },
    fixed: {
      enabled: true,
    },
  },
}

export const StackedColumnsOptions = {
  stroke: {
    show: false
  },
  chart: {
    height: 400,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
    sparkline: {
      enabled: false
    },
    animations: {
      enabled: false,
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "12px",
    offsetY: 0,
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
  },
  lightColors: COLORS,
  darkColors: darkCOLORS,
}

export const ColumnsOptions = {
  stroke: {
    show: false
  },
  chart: {
    height: 400,
    stacked: false,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
    sparkline: {
      enabled: false
    },
    animations: {
      enabled: false,
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      endingShape: "rounded",
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "12px",
    offsetY: 0,
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
  },
  lightColors: COLORS,
  darkColors: darkCOLORS,
}

export const LineOptions = {
  chart: {
    height: 400,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: false
    },
    animations: {
      enabled: false,
    },
    stacked: false,
    type: 'line'
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "12px",
    offsetY: 0,
  },
  lightColors: COLORS,
  darkColors: darkCOLORS,
}

export const StackedBarOptions = {
  stroke: {
    show: false
  },
  chart: {
    height: 400,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: false
    },
    animations: {
      enabled: false,
    },
    stacked: true,
    stackType: "100%",
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    fontSize: "12px",
    offsetY: 0,
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  lightColors: COLORS,
  darkColors: darkCOLORS,
}

export interface ColumnSeriesInterface {
  name: string;
  data: number[];
  type?: string
}
