/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useTheme } from "@material-ui/core";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { CsvBuilder } from "filefy";

import {
  ColumnSeriesInterface,
  ColumnsOptions,
  LineOptions,
  StackedColumnsOptions,
} from "../../utils/ChartOptions";
import Widget from "../dashboard/Widget";
import { RootState } from "typesafe-actions";
import { COLORS, darkCOLORS } from "../../utils/colors";
import { CSVObject } from "../../store/data/types";

interface MediaPlanProps {
  title: string;
  series: ColumnSeriesInterface[];
  categories: string[];
  tooltipFormatter: (arg0: number) => string;
  loading: boolean;
  type: "stacked" | "column" | "line";
  currencies: string[];
  titleYAxis: string;
  prints: boolean;
  rates?: { [p: string]: number } | false;
}

const CHART_OPTIONS = {
  line: { ...LineOptions },
  stacked: { ...StackedColumnsOptions },
  column: { ...ColumnsOptions },
};

const ChartType = (type: string): "line" | "bar" => {
  switch (type) {
    case "stacked" || "column":
      return "bar";
    case "line":
      return "line";
    default:
      return "bar";
  }
};

const MediaPlanChartInvestment = React.memo(
  (props: MediaPlanProps) => {
    const { series, currencies, rates, prints } = props;
    const { prefersDarkMode } = useSelector((state: RootState) => state.general);
    const { amountView } = useSelector((state: RootState) => state.navigation);
    const theme = useTheme();
    const chartRef = useRef<Chart>(null);
    const [seriesConv, setSeriesConv] = useState<ColumnSeriesInterface[]>([]);
    const formatMessage = useFormatMessage();

    useEffect(() => {
      const getCurrencyConvertedData = (data: ColumnSeriesInterface[]) => {
        if (rates && !prints) {
          const dataForFilter: ColumnSeriesInterface[] = [];
          data.forEach((item, i) => {
            const dataNumber: number[] = [];
            item.data.forEach((num) => {
              dataNumber.push(num / rates[`USD${currencies[i]}`]);
            });
            dataForFilter.push({ name: item.name, data: dataNumber });
          });
          return dataForFilter;
        }
        return data;
      };
      setSeriesConv(getCurrencyConvertedData(series));
    }, [series, currencies, rates, prints]);

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      props.categories.forEach((val) => {
        if (amountView) {
          headers.push(`${val} (USD)`);
        } else {
          headers.push(`${val} ${formatMessage("investmentPage.prints")}`);
        }
      });
      data.push(headers);

      seriesConv.forEach((val) => {
        data.push([val.name, ...val.data.map((item) => item.toFixed(2))]);
      });

      return data;
    };

    const exportMediaInvestment = () => {
      const builder = new CsvBuilder(
        `Media Investment Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Widget title={props.title} handleDownloadCSV={exportMediaInvestment}>
        <Chart
          ref={chartRef}
          options={{
            ...CHART_OPTIONS[props.type],
            chart: {
              id: `bar-chart-${props.title.split(" ").join("-")}`,
              ...CHART_OPTIONS[props.type].chart,
            },
            colors: theme.palette.type === "dark" ? darkCOLORS : COLORS,
            yaxis: {
              title: {
                text: props.titleYAxis,
                style: {
                  color: "#78909C",
                },
              },
              labels: {
                formatter: (value: string) =>
                  parseInt(value, 10)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              },
            },
            xaxis: {
              categories: props.categories,
            },
            tooltip: {
              y: {
                formatter: props.tooltipFormatter,
              },
            },
            theme: {
              mode: prefersDarkMode ? "dark" : "light",
            },
            noData: {
              text: "Cargando...",
            },
            animations: {
              enabled: false,
            },
          }}
          type={ChartType(props.type)}
          series={seriesConv}
          height={400}
        />
      </Widget>
    );
  },
  (prevProps: MediaPlanProps, nextProps: MediaPlanProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

export default MediaPlanChartInvestment;
