import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import countries, {
  CountriesInterface,
  COUNTRIES_BALLS,
  INVESTMENT_UNAVAILABLE,
} from "../../../utils/Countries";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { setSnapFilter, set_countries } from "../../../store/search/actions";
import { Checkbox, ListItemText } from "@material-ui/core";
import { SidebarPages } from "../../../store/navigation/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "97%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    menuItem: {
      marginLeft: theme.spacing(1),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CountriesSelector = () => {
  const classes = useStyles();
  const { countries: reduxCountries } = useSelector(
    (state: RootState) => state.search
  );
  const { countries: userCountries } = useSelector(
    (state: RootState) => state.login
  );
  const { selectedPage } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();
  const intl = useFormatMessage();
  const [reduxCountriesState, setReduxCountriesState] = useState<string[]>(
    reduxCountries
  );
  const [countriesForSelect, setCountriesForSelect] = useState<CountriesInterface[]>(
    []
  );

  useEffect(() => {
    if (userCountries.length > 0) {
      const filtered = countries.filter(
        (value) => userCountries.indexOf(value.name) !== -1
      );
      setCountriesForSelect(filtered);
    }
  }, [userCountries]);

  useEffect(() => {
    setReduxCountriesState(reduxCountries);
  }, [reduxCountries]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (selectedPage === SidebarPages.snapshots) {
      dispatch(setSnapFilter(null));
    }
    dispatch(set_countries([event.target.value as string]));
  };

  const handleCheckbox = (country: string) => {
    if (selectedPage === "shareofvoice" || selectedPage === "investment") {
      if (reduxCountriesState.indexOf(country) > -1) {
        dispatch(
          set_countries(reduxCountriesState.filter((elem) => elem !== country))
        );
      } else {
        dispatch(set_countries([...reduxCountriesState, country]));
      }
    } else {
      if (selectedPage === SidebarPages.snapshots) {
        dispatch(setSnapFilter(null));
      }
      dispatch(set_countries([country]));
    }
  };

  const renderMultiple = (selected: string[]) => {
    const translated: string[] = [];
    selected.forEach((item) => translated.push(intl(item)));
    return translated.join(", ");
  };

  return (
    <div>
      <FormControl className={classes.formControl} variant="outlined">
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          defaultValue={
            selectedPage === "investment" || selectedPage === "shareofvoice"
              ? reduxCountriesState
              : reduxCountriesState[0]
          }
          value={
            selectedPage === "investment" || selectedPage === "shareofvoice"
              ? reduxCountriesState
              : reduxCountriesState[0]
          }
          onChange={handleChange}
          input={<Input />}
          variant="outlined"
          renderValue={(selected) =>
            selectedPage === "shareofvoice" || selectedPage === "investment"
              ? renderMultiple(selected as string[])
              : intl(selected as string)
          }
          MenuProps={MenuProps}
        >
          {countriesForSelect.map((country) => (
            <MenuItem
              key={country.name}
              value={country.name}
              disabled={
                INVESTMENT_UNAVAILABLE.includes(country.name) &&
                (selectedPage === "investment" || selectedPage === "snapshots")
              }
            >
              <Checkbox
                checked={reduxCountriesState.indexOf(country.name) > -1}
                onChange={(event) => {
                  event.stopPropagation();
                  handleCheckbox(country.name);
                }}
              />
              {COUNTRIES_BALLS[country.name]}
              <ListItemText
                primary={intl(country.name)}
                className={classes.menuItem}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CountriesSelector;
