import { createMuiTheme } from "@material-ui/core/styles";

export const AdCualityRootTheme = (prefersDarkMode: boolean) =>
  createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1380,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        light: "#475b97",
        main: "#2b428e",
        dark: "#202f67",
        contrastText: "#F1FAEE",
      },
      secondary: {
        light: "#3e84be",
        main: "#1671b6",
        dark: "#105688",
        contrastText: "#F1FAEE",
      },
      error: {
        light: "#ed6e78",
        main: "#E63946",
        dark: "#c81927",
        contrastText: "#F1FAEE",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "#F1FAEE",
      },
      info: {
        light: "#d5eff8",
        main: "#c7edf8",
        dark: "#8198a2",
        contrastText: "#F1FAEE",
      },
      success: {
        light: "#b5e3b2",
        main: "#96e592",
        dark: "#5f8f5d",
        contrastText: "#F1FAEE",
      },
      type: prefersDarkMode ? "dark" : "light",
    },
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        text: {
          fontSize: "0.5rem",
          "@media (min-width: 1024px)": {
            fontSize: "0.55rem",
          },
          "@media (min-width: 1200px)": {
            fontSize: "0.65rem",
          },
          "@media (min-width: 1330px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 1440px)": {
            fontSize: "0.75rem",
          },
          "@media (min-width: 1600px)": {
            fontSize: "0.875rem",
          },
          "&$disabled": {
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
        root: {
          "&$disabled": {
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
        textPrimary: {
          color: prefersDarkMode ? "#b8d6ff" : "#023e8a",
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            color: prefersDarkMode ? "#96c2ff" : "#1D3557",
          },
        },
        textColorPrimary: {
          "&$selected": {
            color: prefersDarkMode ? "#96c2ff" : "#1D3557",
          },
        },
      },
    },
  });
