import * as React from "react";
import * as ReactDOM from "react-dom";
import Hotjar from "@hotjar/browser";
import LogRocket from "logrocket";
import Smartlook from "smartlook-client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";

const HOTJAR_VERSION = 6;

Hotjar.init(Number(process.env.REACT_APP_HOTJAR_ENV || "0"), HOTJAR_VERSION);
LogRocket.init(process.env.REACT_APP_LOGROCKET_ENV || "");
Smartlook.init(process.env.REACT_APP_SMARTLOOK_ENV || "", {
  advancedNetwork: {
    allowedUrls: [new RegExp(/^https:\/\/\w+.adcuality.com/)],
  },
});
LogRocket.getSessionURL(function (sessionURL: string) {
  ReactGA.event({
    category: "LogRocket",
    action: sessionURL,
  });
});

ReactDOM.render(<App />, document.getElementById("root"));

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept("./App", () => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
