import { action } from "typesafe-actions";
import { NavigationActionTypes } from "./types";

/**
 * Action creators.
 */
export const toggle_sidebar = (isOpen?: boolean) =>
  action(NavigationActionTypes.TOGGLE_SIDEBAR, { isOpen });

export const choose_tab = (tabIndex: number) =>
  action(NavigationActionTypes.CHOOSE_TAB, { tabIndex: tabIndex });

export const set_sidebar_page = (page: string) =>
  action(NavigationActionTypes.SET_PAGE, { page: page });

export const edit_form = () => action(NavigationActionTypes.EDIT_FORM);

export const toggle_dialog = () => action(NavigationActionTypes.TOGGLE_DIALOG);

export const toggle_customer = () => action(NavigationActionTypes.TOGGLE_CUSTOMER);

export const toggle_snackbar = () => action(NavigationActionTypes.TOGGLE_SNACKBAR);

export const setNotification = (
  severity: "success" | "error" | "info" | "warning",
  message: string
) => action(NavigationActionTypes.SET_NOTIFICATION, { severity, message });

export const setNotificationOff = () =>
  action(NavigationActionTypes.SET_NOTIFICATION_OFF);

export const toggle_advSearch = () =>
  action(NavigationActionTypes.TOGGLE_ADVANCED_SEARCH);

export const toggle_alertsDialog = () =>
  action(NavigationActionTypes.TOGGLE_ALERTS_DIALOG);

export const toggle_countryPicker = () =>
  action(NavigationActionTypes.TOGGLE_COUNTRY_PICKER);

export const toggle_dateRangeDialog = () =>
  action(NavigationActionTypes.TOGGLE_DATERANGE);

export const toggle_quickSearchDialog = () =>
  action(NavigationActionTypes.TOGGLE_SEARCH);

export const toggle_MoreInfoDialog = () =>
  action(NavigationActionTypes.TOGGLE_MOREINFO);

export const set_TotalPages = (totalPages: number) =>
  action(NavigationActionTypes.SET_TOTALPAGES, { totalPages });

export const set_newPage = (newPage: number) =>
  action(NavigationActionTypes.SET_PAGE_NUMBER, { newPage });

export const set_ResultsPerPage = (numberOfResults: number) =>
  action(NavigationActionTypes.SET_RESULTS_PER_PAGE, { numberOfResults });

export const toggleSwitch = () => action(NavigationActionTypes.TOGGLE_SWITCH);

export const toggle_investment_view = () =>
  action(NavigationActionTypes.TOGGLE_INVESTMENT_VIEW);

export const toggleWelcomeDialog = () =>
  action(NavigationActionTypes.TOGGLE_WELCOME_DIALOG);
