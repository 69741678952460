import { Dispatch } from "redux"
import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import { injectIntl, InjectedIntlProps } from "react-intl"
import AdvancedSearch from "./AdvancedSearchDialogView"
import { choose_tab, toggle_advSearch } from "../../../store/navigation/actions"
import { queueParameter, setAdvancedFilters } from "../../../store/search/actions"
import {
  PaginationParametersInterface, SearchParameters, AdvancedFilters,
} from "../../../store/search/types"
import {
  campaignsDataFetchRequest,
  spotsListDataRequest,
  shareOfVoiceDataRequest,
  investmentDataRequest,
} from "../../../store/data/actions"

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  token: state.login.token,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggle_dialog: () => {
      dispatch(toggle_advSearch())
    },
    setAdvancedFilters: (newFilters: AdvancedFilters) => dispatch(setAdvancedFilters(newFilters)),
    fetchCampaigns: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(campaignsDataFetchRequest(search, pagination, token)),
    fetchListedSpots: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(spotsListDataRequest(search, pagination, token)),
    fetchShareVoice: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(shareOfVoiceDataRequest(search, pagination, token, type)),
    fetchInvestment: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(investmentDataRequest(search, pagination, token, type)),
    setQueuedParameters: (parameters: AdvancedFilters) =>
      dispatch(queueParameter(parameters)),
    setTab: (tab: number) => dispatch(choose_tab(tab))
  }
}

export type SearchReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch)
)
