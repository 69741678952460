import React, { useEffect, useMemo, useState } from "react";
import { unstable_trace as trace } from "scheduler/tracing";
import Select from "react-select";
import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import axios from "axios";
import { SearchReduxProps } from "./AdvancedSearchContainer";
import {
  AdvancedSearchInterface,
  AdvancedFilters,
} from "../../../store/search/types";
import { isMobile } from "react-device-detect";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AsyncSelector from "./AutocompleteMultiple";
import { publishEvent } from "../../../services/GoogleAnalytics";
import CountriesSelector from "./CountriesSelector";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { fetchIndustriesListsAPIv2 } from "../../../services/api";

export interface SelectedValuesInterface {
  value: string;
  label: string;
  id: number;
}

const AdvancedSearch = (props: SearchReduxProps) => {
  const formatMessage = useFormatMessage();
  let parameters: AdvancedFilters = {
    advertisers: props.search_state.advancedFilters.advertisers,
    formats: props.search_state.advancedFilters.formats,
    platforms: props.search_state.advancedFilters.platforms,
    publishers: props.search_state.advancedFilters.publishers,
    categories: props.search_state.advancedFilters.categories,
    industries: props.search_state.advancedFilters.industries,
    products: props.search_state.advancedFilters.products,
    sources: props.search_state.advancedFilters.sources,
    excludedPublishers: props.search_state.advancedFilters.excludedPublishers,
  };
  const [industries, setIndustries] = useState<AdvancedSearchInterface[]>([]);

  const SOURCES_OPTIONS: AdvancedSearchInterface[] = useMemo(
    () => [
      {
        id: 1,
        label: formatMessage("graphs.label.direct"),
        value: 1,
      },
      {
        id: 2,
        label: formatMessage("graphs.label.programmatic"),
        value: 2,
      },
      { id: 10, label: "AppNexus", value: 10 },
    ],
    [formatMessage]
  );

  const FORMATS_OPTIONS: AdvancedSearchInterface[] = useMemo(
    () => [
      { id: 1, label: "Text ADs", value: 1 },
      { id: 2, label: "Standard ADs", value: 2 },
      { id: 4, label: "Rich Media ADs", value: 4 },
      { id: 5, label: "Video ADs", value: 5 },
      { id: 6, label: "Social ADs", value: 6 },
      { id: 7, label: "Native ADs", value: 7 },
      { id: 8, label: "Native Video ADs", value: 8 },
    ],
    []
  );

  const PLATFORMS_OPTIONS: AdvancedSearchInterface[] = useMemo(
    () => [
      {
        id: 1,
        label: formatMessage("graphs.label.desktop"),
        value: 1,
      },
      { id: 3, label: "Social Media", value: 3 },
      { id: 4, label: "Web Mobile", value: 4 },
    ],
    [formatMessage]
  );

  const classes = useStyles();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchIndustriesListsAPIv2(props.token, cancelToken.token).then((response) => {
      const industriesList = response.map((industry) => ({
        id: industry.id,
        label: industry.name,
        value: industry.id,
      }));

      setIndustries(industriesList);
    });

    return () => cancelToken.cancel();
  }, [props.token]);

  function setParameters() {
    parameters = {
      advertisers: [...parameters.advertisers],
      formats: [...parameters.formats],
      platforms: [...parameters.platforms],
      publishers: [...parameters.publishers],
      categories: [...parameters.categories],
      industries: [...parameters.industries],
      products: [...parameters.products],
      sources: [...parameters.sources],
      excludedPublishers: [...parameters.excludedPublishers],
    };
    if (
      props.navigation_state.selectedTab === 2 &&
      parameters.advertisers.length === 0 &&
      parameters.products.length === 0
    ) {
      props.setTab(0);
    }

    props.setAdvancedFilters(parameters);
  }

  function handleSearchSubmission() {
    publishEvent("Búsqueda", "Cambio de filtros avanzados", parameters.toString());
    setParameters();
    props.toggle_dialog();
  }

  return (
    <Dialog
      open={props.navigation_state.isAdvSearchOpen}
      onClose={props.toggle_dialog}
      fullWidth
      maxWidth={"md"}
      fullScreen={isMobile}
      scroll="body"
      disableEnforceFocus
    >
      {isMobile ? (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.toggle_dialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.intl.formatMessage({
                id: "navigation.header.advancedSearch",
              })}
            </Typography>
            <Button color="inherit" onClick={handleSearchSubmission}>
              {props.intl.formatMessage({ id: "navigation.buttons.search" })}
            </Button>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle id="form-dialog-title">
          {props.intl.formatMessage({ id: "navigation.header.advancedSearch" })}
        </DialogTitle>
      )}

      <DialogContent>
        <DialogContentText>
          {props.intl.formatMessage({ id: "search.advancedSearch.dialogText" })}
        </DialogContentText>
        <div className={classes.root}>
          {isMobile ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.advertisers}
                  token={props.token}
                  type={"advertisers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.advertisers.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.advertisers = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.platforms}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.platforms.placeholder",
                  })}
                  options={PLATFORMS_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.platforms = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.formats}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.formats.placeholder",
                  })}
                  options={FORMATS_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.formats = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.publishers}
                  token={props.token}
                  type={"publishers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.publishers.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.publishers = result;
                  }}
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.excludedPublishers}
                  token={props.token}
                  type={"publishers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.excluded.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.excludedPublishers = result;
                  }}
                  placement="top"
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.categories}
                  token={props.token}
                  type={"categories"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.categories.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.categories = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.industries}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.industries.placeholder",
                  })}
                  options={industries}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.industries = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.products}
                  token={props.token}
                  type={"products"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.products.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.products = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.sources}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.source.placeholder",
                  })}
                  options={SOURCES_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.sources = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.advertisers}
                  token={props.token}
                  type={"advertisers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.advertisers.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.advertisers = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.platforms}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.platforms.placeholder",
                  })}
                  options={PLATFORMS_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.platforms = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.formats}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.formats.placeholder",
                  })}
                  options={FORMATS_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.formats = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.publishers}
                  token={props.token}
                  type={"publishers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.publishers.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.publishers = result;
                  }}
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.excludedPublishers}
                  token={props.token}
                  type={"publishers"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.excluded.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.excludedPublishers = result;
                  }}
                  placement="top"
                />
              </Grid>
              <Grid item xs={6}>
                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.categories}
                  token={props.token}
                  type={"categories"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.categories.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.categories = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.industries}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.industries.placeholder",
                  })}
                  options={industries}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.industries = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <div className={classes.divider} />

                <AsyncSelector
                  isMulti={true}
                  selectedValues={parameters.products}
                  token={props.token}
                  type={"products"}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.products.placeholder",
                  })}
                  handleChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (values !== undefined && values !== null)
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.products = result;
                  }}
                />

                <div className={classes.divider} />

                <Select
                  isMulti
                  defaultValue={parameters.sources}
                  placeholder={props.intl.formatMessage({
                    id: "search.advancedSearch.label.source.placeholder",
                  })}
                  options={SOURCES_OPTIONS}
                  onChange={(values) => {
                    const result: AdvancedSearchInterface[] = [];
                    if (
                      Array.isArray(values) &&
                      values !== undefined &&
                      values !== null
                    )
                      values.forEach((value) =>
                        result.push({
                          id: value.id,
                          label: value.label,
                          value: value.id,
                        })
                      );
                    parameters.sources = result;
                  }}
                  maxMenuHeight={100}
                  menuPlacement="auto"
                />

                <CountriesSelector />
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>
      {!isMobile ? (
        <DialogActions>
          <Button
            onClick={() =>
              trace("close advanced dialog", performance.now(), () =>
                props.toggle_dialog()
              )
            }
            color="secondary"
          >
            {props.intl.formatMessage({ id: "navigation.buttons.cancel" })}
          </Button>
          <Button
            onClick={handleSearchSubmission}
            color="primary"
            className={classes.button}
          >
            {props.intl.formatMessage({ id: "navigation.buttons.search" })}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      flexGrow: 1,
      height: 300,
      maxHeight: 300,
    },
    container: {
      flexGrow: 1,
      position: "relative",
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: "wrap",
    },
    inputInput: {
      width: "auto",
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
    button: {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
  })
);

export default AdvancedSearch;
