/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks";
import { useTheme } from "@material-ui/core";
import { CsvBuilder } from "filefy";

import Widget from "../dashboard/Widget";
import { PieOptions } from "../../utils/ChartOptions";
import PieLegend from "./PieLegend";
import { COLORS, darkCOLORS } from "../../utils/colors";
import { RootState } from "typesafe-actions";
import { CSVObject } from "../../store/data/types";

export interface PieChartSeries {
  label: string;
  value: number;
  extra: number;
  hidden: boolean;
}

interface PieChartProps {
  title: string | JSX.Element;
  country: number;
  items: PieChartSeries[];
  snap?: boolean;
  investment?: boolean;
  monochrome?: boolean;
  multi?: boolean;
  rates?: false | { [p: string]: number };
}

const PieChart = React.memo(
  (props: PieChartProps) => {
    const { selectedPage, amountView } = useSelector(
      (state: RootState) => state.navigation
    );
    const currency = useSelector(
      (state: RootState) =>
        state.data.investment[props.country] &&
        state.data.investment[props.country].currency
    );
    const [pieData, setPieData] = useState<PieChartSeries[]>(props.items);
    const [pieValues, setPieValues] = useState<number[]>([]);
    const [pieLabels, setPieLabels] = useState<string[]>([]);
    const [pieExtra, setPieExtra] = useState<number[]>([]);
    const formatMessage = useFormatMessage();
    const formatNumber = useFormatNumber();
    const theme = useTheme();
    const chartRef = useRef<Chart>(null);

    const shareOfVoiceFormatter = (value: number): string => {
      // const index = pieValues.indexOf(value)
      let total = 0;
      pieData.forEach((item) => {
        if (!item.hidden) total += item.value;
      });
      const percentage = (value * 100) / total;
      return `${formatNumber(percentage, { maximumFractionDigits: 1 })}%`;
    };

    const investmentFormatter = (value: number): string => {
      const index = pieValues.indexOf(value);
      let total = 0;
      pieData.forEach((item) => {
        if (!item.hidden) total += item.value;
      });

      const percentage = (value * 100) / total;
      const currencyLabel = props.multi ? "USD" : currency ? currency : "ARS";
      const extra = props.rates
        ? pieExtra[index] / props.rates[`USD${currency}`]
        : pieExtra[index];
      return `${formatNumber(percentage, {
        maximumFractionDigits: 1,
      })}% (${formatNumber(extra, {
        style: "currency",
        currency: currencyLabel,
      })})`;
    };

    useEffect(() => {
      setPieData(props.items);
      const newLabels: string[] = [];
      const newExtra: number[] = [];
      const newValues: number[] = [];
      props.items.forEach((item) => {
        newLabels.push(item.label);
        newExtra.push(item.extra);
        newValues.push(item.value);
      });
      setPieLabels(newLabels);
      setPieExtra(newExtra);
      setPieValues(newValues);
    }, [props.items]);

    useEffect(() => {
      const newValues: number[] = [];
      const newExtra: number[] = [];
      pieData.forEach((item) => {
        if (!item.hidden) {
          newValues.push(item.value);
          newExtra.push(item.extra);
        }
      });
      setPieValues(newValues);
      setPieExtra(newExtra);
    }, [pieData]);

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];

      if (selectedPage === "investment" || props.investment === true) {
        headers.push(`${formatMessage("shareofvoicePage.table.column.percentage")}`);
        if (amountView) {
          headers.push(
            `${formatMessage("investmentPage.amount")} (${
              props.multi ? "USD" : currency ? currency : "ARS"
            })`
          );
        } else {
          headers.push(`${formatMessage("investmentPage.prints")}`);
        }
        data.push(headers);

        pieValues.forEach((val, i) => {
          const index = pieValues.indexOf(val);
          const extra = props.rates
            ? pieExtra[index] / props.rates[`USD${currency}`]
            : pieExtra[index];

          data.push([pieLabels[i], val.toString(), extra.toString()]);
        });
      } else {
        headers.push(`${formatMessage("shareofvoicePage.table.column.percentage")}`);
        headers.push(`Spots`);
        data.push(headers);

        pieValues.forEach((val, i) => {
          data.push([
            pieLabels[i],
            shareOfVoiceFormatter(val),
            pieExtra[i].toString(),
          ]);
        });
      }

      return data;
    };

    const exportPie = () => {
      const builder = new CsvBuilder(
        `Pie Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Widget title={props.title} snap={props.snap} handleDownloadCSV={exportPie}>
        <Chart
          ref={chartRef}
          options={{
            ...PieOptions,
            chart: {
              id: `pie-chart-${props.country}-${props.investment ? "inv" : "sov"}-${
                props.items.length > 0
                  ? props.items[0].label.split(" ").join("_")
                  : "0"
              }`,
              ...PieOptions.chart,
            },
            tooltip: {
              enabled: true,
              shared: false,
              intersect: true,
              custom: function ({
                series,
                seriesIndex,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                dataPointIndex,
                w,
              }: {
                series: any[];
                seriesIndex: number;
                dataPointIndex: number;
                w: any;
              }) {
                const value =
                  selectedPage === "investment" || props.investment === true
                    ? investmentFormatter(series[seriesIndex])
                    : shareOfVoiceFormatter(series[seriesIndex]);
                return (
                  '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex;">' +
                  '<span class="apexcharts-tooltip-marker" style="background: ' +
                  w.globals.colors[seriesIndex] +
                  '; display: block;"></span>' +
                  '<div class="apexcharts-tooltip-text">' +
                  '<div class="apexcharts-tooltip-y-group">' +
                  '<span class="apexcharts-tooltip-text-label" style="color: rgba(0, 0, 0, 0.87);">' +
                  w.globals.seriesNames[seriesIndex] +
                  ":</span>" +
                  '<span class="apexcharts-tooltip-text-value" style="color: rgba(0, 0, 0, 0.87);">' +
                  value +
                  "</span>" +
                  "</div>" +
                  '<div class="apexcharts-tooltip-z-group">' +
                  '<span class="apexcharts-tooltip-text-z-label"></span>' +
                  '<span class="apexcharts-tooltip-text-z-value"></span>' +
                  "</div>" +
                  "</div>" +
                  "</div>"
                );
              },
              fixed: {
                enabled: true,
              },
            },
            labels: pieLabels,
            theme: {
              monochrome: {
                enabled: props.monochrome ? true : false,
              },
            },
            colors: [
              // @ts-expect-error
              function ({ value }) {
                const item = pieData.find((x) => x.value === value);
                if (item) {
                  const index = pieData.indexOf(item);
                  const indexToUse = index % COLORS.length;
                  return theme.palette.type === "dark"
                    ? darkCOLORS[indexToUse]
                    : COLORS[indexToUse];
                } else {
                  return theme.palette.type === "dark" ? darkCOLORS[0] : COLORS[0];
                }
              },
            ],
          }}
          type={"pie"}
          series={pieValues}
          height={400}
        />
        <PieLegend
          labels={pieLabels}
          country={props.country}
          pieData={pieData}
          setPieData={setPieData}
        />
      </Widget>
    );
  },
  (prevProps: PieChartProps, nextProps: PieChartProps) => {
    return (
      prevProps.items.length === nextProps.items.length &&
      JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items) &&
      prevProps.multi === nextProps.multi &&
      JSON.stringify(prevProps.rates) === JSON.stringify(nextProps.rates)
    );
  }
);

export default PieChart;
