import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Cards from "./Cards"
import { Grid } from "@material-ui/core"
import PageTitle from "../../components/dashboard/PageTitle"

const useStyles = makeStyles({
  card: {
    minWidth: 120,
    maxWidth: 600,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
  },
})

export default function HomeView() {
  const classes = useStyles()
  return (
    <>
      <PageTitle title={"Home"} />
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Grid container justify={"flex-start"} spacing={3}>
            {Cards.map((card) => (
              <Grid key={card.title} item lg={3} md={4} sm={6} xs={12}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {card.type}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {card.body}
                    </Typography>
                  </CardContent>
                  {card.buttons !== undefined ? (
                    <CardActions>
                      {card.buttons.map((button) => (
                        <Button
                          key={button.link}
                          size={"small"}
                          color={"primary"}
                          href={button.link}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </CardActions>
                  ) : null}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      </>
  )
}
