import { RootState, RootAction } from "typesafe-actions"
import { Dispatch } from "redux"
import { logout } from "../../../../store/login/actions"
import { connect } from "react-redux"
import SidebarListView from "./SidebarListView"
import {
  set_sidebar_page, toggle_customer,
  toggle_dialog,
  toggle_sidebar,
  setNotification, choose_tab
} from "../../../../store/navigation/actions"
import { clearAllParameters } from "../../../../store/search/actions"

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  navigation_state: state.navigation,
  search_state: state.search,
  data: state.data
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    log_out: () => {
      dispatch(logout())
      dispatch(clearAllParameters())
    },
    set_sidebar_page: (page: string) => dispatch(set_sidebar_page(page)),
    set_tab: (tab: number) => dispatch(choose_tab(tab)),
    setNotification: (notification: string) => dispatch(setNotification("warning", notification)),
    toggle_dialog: () => dispatch(toggle_dialog()),
    toggleCustomer: () => dispatch(toggle_customer()),
    toggleDrawer: (isOpen: boolean) => dispatch(toggle_sidebar(isOpen)),
  }
}

export type SidebarReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(SidebarListView)
