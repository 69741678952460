import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import { Grid } from "@material-ui/core"
import MediaPlanChart from "../../components/graphs/MediaPlanChart"
/* import {
  getInvestmentDates,
  getInvestmentMediaPlan,
  getInvestmentPieData,
} from "../../utils/functions" */
import PieChart from "../../components/graphs/PieChart"
import { useFormatNumber, useFormatMessage } from "@comparaonline/react-intl-hooks"
// import { AdvancedSearchInterface } from "../../store/search/types"

const SingleCountryCharts = () => {
  const filtered = useSelector((state: RootState) => state.data.investment)
  const formatNumber = useFormatNumber()
  const formatMessage = useFormatMessage()
  const { hasRequestFailed } = useSelector((state: RootState) => state.data);
  const { isDataLoading } = useSelector((state: RootState) => state.data)
  const viewPrints = useSelector((state: RootState) => !state.navigation.amountView)

  const amountFormatter = (value: number): string => {
    return `${formatNumber(value, {
      style: "currency",
      currency: filtered[0].currency,
    })}`
  }

  const printsFormatter = (value: number): string => {
    return `${formatNumber(value)} ${formatMessage(
      "navigation.chart.tooltip.prints"
    )}`
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <PieChart
          title={"Share of Investment"}
          country={0}
          items={(filtered[0] && !hasRequestFailed) ? filtered[0].pieData : []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan"
          series={
            (filtered[0] && !hasRequestFailed)
              ? (viewPrints ? filtered[0].mediaDataPrints : filtered[0].mediaDataAmount)
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={viewPrints ? printsFormatter : amountFormatter}
          loading={isDataLoading}
          type={"stacked"}
          titleYAxis={viewPrints ? 'Prints' : "Amount"}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan Bar"
          series={
            (filtered[0] && !hasRequestFailed)
              ? (viewPrints ? filtered[0].mediaDataPrints : filtered[0].mediaDataAmount)
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={viewPrints ? printsFormatter : amountFormatter}
          loading={isDataLoading}
          type={"column"}
          titleYAxis={viewPrints ? 'Prints' : "Amount"}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan Evo"
          series={
            (filtered[0] && !hasRequestFailed)
              ? (viewPrints ? filtered[0].mediaDataPrints : filtered[0].mediaDataAmount)
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={viewPrints ? printsFormatter : amountFormatter}
          loading={isDataLoading}
          type={"line"}
          titleYAxis={viewPrints ? 'Prints' : "Amount"}
        />
      </Grid>
    </>
  )
}

export default SingleCountryCharts
