import { Dispatch } from "redux";
import {
  toggle_advSearch,
  toggle_alertsDialog,
  toggle_countryPicker,
} from "../../../../store/navigation/actions";
import { set_countries, set_date_range } from "../../../../store/search/actions";
import { DateRange } from "@matharumanpreet00/react-daterange-picker/build";
import {
  PaginationParametersInterface,
  SearchParameters,
} from "../../../../store/search/types";
import {
  campaignsDataFetchRequest,
  setAlertToEditNull,
  spotsListDataRequest,
} from "../../../../store/data/actions";
import { RootState, RootAction } from "typesafe-actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, Theme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl, InjectedIntlProps } from "react-intl";
import CountryPickerView from "./CountryPicker";
import AdvancedFilterPickerView from "./AdvancedFilterPicker";
import AlertsPickerView from "./AlertsPicker";

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  token: state.login.token,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggleAdvancedSearch: () => dispatch(toggle_advSearch()),
    toggleCountryPicker: () => dispatch(toggle_countryPicker()),
    toggleAlertsDialog: () => {
      dispatch(setAlertToEditNull());
      dispatch(toggle_alertsDialog());
    },
    setDateRange: (range: DateRange) =>
      dispatch(set_date_range(range.startDate!, range.endDate!)),
    setCountries: (countries: string[]) => dispatch(set_countries(countries)),
    fetchCampaigns: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(campaignsDataFetchRequest(search, pagination, token)),
    fetchListedSpots: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(spotsListDataRequest(search, pagination, token)),
  };
};

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps;

export const CountryPicker = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CountryPickerView)
);

export const AdvancedFilterPicker = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterPickerView)
);

export const AlertsPicker = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AlertsPickerView)
);

export const toolStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      "&:before": {
        borderColor: "rgba(255,255,255,0)",
      },
      "&:after": {
        borderColor: "rgba(255,255,255,0)",
      },
      "&:hover": {
        borderColor: "rgba(255,255,255,0)",
      },
      borderColor: "rgba(255,255,255,0)",
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    countriesIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 24,
      color: "rgb(255,255,255)",
    },
    formControl: {
      color: "rgba(255,255,255,0)",
      outlineColor: "rgba(255,255,255,0)",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
    },
    logotype: {
      color: "white",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: 24,
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    logotypeImage: {
      width: 200,
    },
    appBar: {
      width: "100vw",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    hide: {
      display: "none",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    headerMenu: {
      marginTop: theme.spacing(7),
      marginLeft: theme.spacing(40),
    },
    headerMenuList: {
      display: "flex",
      flexDirection: "column",
    },
    headerMenuItem: {
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    headerMenuButton: {
      marginLeft: 0,
      padding: theme.spacing(2),
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
    },
    headerMenuButtonCollapse: {
      marginRight: theme.spacing(2),
    },
    headerIcon: {
      fontSize: 28,
      color: "#626262",
    },
    dateIcon: {
      color: "#626262",
      marginRight: 10,
    },
  })
);
