import React, { useCallback, useEffect, useState } from "react"
import axios, { Canceler } from "axios";
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks"
import { AdvancedSearchInterface } from "../../store/search/types"
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core"
import { DataFilterNames } from "../../utils/constants"
import {
  // getInvestmentDates,
  getMCshareOfInvestment,
  getOthersValue,
  /* getInvestmentPieData, */
  getInvestmentHistogramValues,
} from "../../utils/functions"
import PercentajeStackedBar from "../../components/graphs/PercentageStackedChart"
import { COUNTRIES_BALLS } from "../../utils/Countries"
import { ColumnSeriesInterface } from "../../utils/ChartOptions"
import PieChart from "../../components/graphs/PieChart"
import { Investment } from "../../store/data/types"
import MediaPlanChartInvestment from "../../components/graphs/MediaPlanChartInvestment"
import { GetExchangeRate } from "../../services/api"

interface FilterTabs {
  [key: string]: AdvancedSearchInterface[];
}

const tooltipBarFormatter = (value: number) => {
  return `${value.toFixed(2)}%`
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      justifyContent: "center",
      display: "flex",
    },
    ball: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
)

const MultiCountryCharts = () => {
  const { hasRequestFailed } = useSelector((state: RootState) => state.data)
  const filtered = useSelector((state: RootState) => state.data.investment)
  const {countries} = useSelector(
    (state: RootState) => state.search
  )
  const {
    industries,
    advertisers,
    products,
    categories,
    sources,
    publishers,
    formats,
    platforms,
  } = useSelector((state: RootState) => state.search.advancedFilters)
  const selectedTab = useSelector((state: RootState) => state.navigation.selectedTab)
  const formatMessage = useFormatMessage()
  const viewPrints = useSelector((state: RootState) => !state.navigation.amountView)
  const styles = useStyles()
  const formatNumber = useFormatNumber()
  const [rates, setRates] = useState<{ [p: string]: number } | false>(false);
  const [currencies, setCurrencies] = useState<string[]>([]);

  const fetchConverted = useCallback(async () => {
    let cancelT: Canceler;
    const cancelToken = new axios.CancelToken(cancel => cancelT = cancel);
    const currenciesEffect = (filtered[0] && !hasRequestFailed) ? filtered.map(item => item.currency) : [];
    setCurrencies(currenciesEffect);
    const getRates = await GetExchangeRate(currenciesEffect, cancelToken);
    setRates(getRates);

    return () => {
      if (cancelT) {
        cancelT();
      }
    }
  }, [filtered, hasRequestFailed])

  useEffect(() => {
    fetchConverted();
  }, [fetchConverted]);

  const amountFormatter = (value: number): string => {
    return `${formatNumber(value, {
      style: "currency",
      currency: 'USD',
    })}`
  }

  const printsFormatter = (value: number): string => {
    return `${formatNumber(value)} ${formatMessage(
      "navigation.chart.tooltip.prints"
    )}`
  }

  const tabs: FilterTabs = {
    industries: industries,
    advertisers: advertisers,
    products: products,
    categories: categories,
    sources: sources,
    publishers: publishers,
    formats: formats,
    platforms: platforms,
  }

  const getBarData = (label: string, id: number, filterType: string) => {
    const series: ColumnSeriesInterface[] = []

    series.push({
      name: label,
      data: getMCshareOfInvestment(id, filtered),
    })

    series.push({
      name: formatMessage(`labels.other.${filterType}`),
      data: getOthersValue(series, countries),
    })

    return series
  }

  const getMCinvestmentNoise = (
    label: string,
    filtered: Investment[],
    amountViewEnabled: boolean
  ) => {
    const series: ColumnSeriesInterface[] = []
    filtered.forEach((countryItem) => {
      const iAmount = countryItem.mediaDataAmount.find(item => item.name === label);
      const iPrint = countryItem.mediaDataPrints.find(item => item.name === label);
      const item: ColumnSeriesInterface = {
        name: formatMessage(countryItem.country),
        data: iAmount && iPrint
          ? getInvestmentHistogramValues(
              iAmount,
              iPrint,
              amountViewEnabled
            )
          : [],
      }
      series.push(item)
    })
    return series
  }

  if (tabs[DataFilterNames[selectedTab]].length > 0) {
    return (
      <>
        {tabs[DataFilterNames[selectedTab]].map((filter, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6}>
                <MediaPlanChartInvestment
                  rates={rates}
                  currencies={currencies}
                  categories={
                    (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
                  }
                  loading={false}
                  series={
                    (filtered[0] && !hasRequestFailed)
                      ? getMCinvestmentNoise(filter.label, filtered, !viewPrints)
                      : []
                  }
                  title={`${formatMessage("labels.widget.investment-intensity")} - ${
                    filter.label
                  }`}
                  key={`${filter.label}-LineGraph`}
                  tooltipFormatter={viewPrints ? printsFormatter : amountFormatter}
                  type={"line"}
                  prints={viewPrints}
                  titleYAxis={viewPrints ? 'Prints' : "Amount"}
                />
              </Grid>
              <PercentajeStackedBar
                title={`Share of Investment - ${filter.label}`}
                data={getBarData(
                  filter.label,
                  filter.id,
                  DataFilterNames[selectedTab]
                )}
                tooltipFormatter={tooltipBarFormatter}
                key={`${filter.label}-PercentageBar`}
              />
            </React.Fragment>
          )
        })}
      </>
    )
  } else
    return (
      <>
        {countries.map((country, index) => {
          return (
            <Grid item xs={12} md={6} key={country}>
              <PieChart
                title={
                  <div className={styles.titleWrapper}>
                    Share of Investment -{" "}
                    <div className={styles.ball}>{COUNTRIES_BALLS[country]}</div>{" "}
                    {formatMessage(country)}
                  </div>
                }
                country={index}
                items={
                  filtered[index] ? filtered[index].pieData : []
                }
                rates={rates}
                multi={true}
              />
            </Grid>
          )
        })}
      </>
    )
}

export default MultiCountryCharts
