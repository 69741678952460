import { RootAction, RootState } from "typesafe-actions";
import { connect } from "react-redux";
import { RouteProps } from "react-router";
import * as React from "react";
import PrivateRoute from "./PrivateRoute";
import { Dispatch } from "redux";
import {
  logout,
  setRoles,
  setCountries,
  getSystemMessages,
} from "../../store/login/actions";
import { RouteComponentProps } from "react-router-dom";
import {
  clearAllParameters,
  setAllParameters,
  setSnapFilter,
  setSnapType,
  set_countries,
} from "../../store/search/actions";
import { AdvancedFilters, AdvancedSearchInterface } from "../../store/search/types";
import { choose_tab, set_sidebar_page } from "../../store/navigation/actions";
import { TermsAcceptedAtInterface } from "../../store/login/types";

export interface PrivateRouteInterface extends RouteProps {
  component: React.ComponentType<any>;
}

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  search_state: state.search,
  navigation_state: state.navigation,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    logoutUser: () => {
      dispatch(logout());
      dispatch(clearAllParameters());
    },
    setUserRoles: (
      roles: Array<string>,
      name: string,
      surname: string,
      termsAccepted: boolean,
      termsAcceptedAt: TermsAcceptedAtInterface
    ) => dispatch(setRoles(roles, name, surname, termsAccepted, termsAcceptedAt)),
    setUserCountries: (countries: Array<string>) =>
      dispatch(setCountries(countries)),
    setCountry: (country: string) => dispatch(set_countries([country])),
    setCountries: (countries: string[]) => dispatch(set_countries(countries)),
    setParameters: (
      countries: string[],
      from: string,
      to: string,
      filters: AdvancedFilters
    ) =>
      dispatch(
        setAllParameters(
          countries,
          new Date(`${from}T00:00:00`),
          new Date(`${to}T00:00:00`),
          filters
        )
      ),
    setSeletedTab: (tab: number) => dispatch(choose_tab(tab)),
    setSidebarPage: (page: string) => dispatch(set_sidebar_page(page)),
    setSnapType: (snapType: "industries" | "advertisers" | "publishers") =>
      dispatch(setSnapType(snapType)),
    setSnapFilter: (filter: AdvancedSearchInterface) =>
      dispatch(setSnapFilter(filter)),
    getUserMessages: (token: string) => dispatch(getSystemMessages(token)),
  };
};

export type PrivateRouteProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps &
  PrivateRouteInterface;

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
