import { LoginProps } from "./LoginFormContainer";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import {
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import loginFormStyles from "../../../styles/LoginFormStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { publishPageView } from "../../../services/GoogleAnalytics";

interface State {
  password: string;
  showPassword: boolean;
}

const LoginFormView = (props: LoginProps) => {
  const [ownValues, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...ownValues, showPassword: !ownValues.showPassword });
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Enter a valid email address"),
    password: Yup.string().required("Enter a valid password").min(4),
  });
  const classes = loginFormStyles();
  publishPageView("/login");
  return (
    <Formik
      initialValues={{ email: "", password: "", showPassword: false }}
      onSubmit={(values) => {
        props.login_request(values.email, values.password, props.history);
      }}
      validationSchema={loginSchema}
    >
      {({ handleChange, errors, touched, values }) => (
        <Form id={"login-form"} name={"login-form"}>
          <TextField
            className={classes.textField}
            error={props.login_state.error}
            id="outlined-email-input"
            label={<FormattedMessage id={"loginPage.emailField"} />}
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            onChange={handleChange}
            fullWidth
          />
          {errors.email && touched.email ? <div>{errors.email}</div> : null}
          <FormControl className={classes.textField} fullWidth>
            <InputLabel htmlFor="adornment-password">
              {props.intl.formatMessage({ id: "loginPage.passwordField" })}
            </InputLabel>
            <Input
              id="adornment-password"
              type={ownValues.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {ownValues.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {errors.password && touched.password ? <div>{errors.password}</div> : null}

          <div className={classes.formButtons}>
            <Button
              type={"submit"}
              variant="text"
              color="primary"
              disabled={values.email.length === 0 || values.password.length < 4}
              form={"login-form"}
            >
              {props.login_state.loading ? (
                <CircularProgress className={classes.circularProgress} size={24.5} />
              ) : (
                "Login"
              )}
            </Button>
            <Button
              className={classes.forgetButton}
              variant="text"
              color="secondary"
              size="medium"
              onClick={props.forgot_password}
            >
              {<FormattedMessage id={"loginPage.resetPassword"} />}
            </Button>
          </div>
          <Fade in={props.login_state.error}>
            <Typography color={"error"}>
              <FormattedMessage id={"loginPage.serverResponse"} />
            </Typography>
          </Fade>
        </Form>
      )}
    </Formik>
  );
};

export default LoginFormView;
