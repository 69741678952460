/** @jsxRuntime classic */
import React, { useEffect, useRef } from "react";
import AsyncSelect from "react-select/async";
import { AsyncPaginate, OptionsList } from "react-select-async-paginate";
import { fetchAutocompleteAPIv2 } from "../../../services/api";
import { SelectedValuesInterface } from "./AdvancedSearchDialogView";
import { AdvancedSearchInterface } from "../../../store/search/types";
import { useTheme } from "@material-ui/core";
import { Styles } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "typesafe-actions";

interface AsyncSelectorProps {
  isMulti: boolean;
  token: string;
  type: string;
  placeholder: string;
  selectedValues?: AdvancedSearchInterface[];
  selectedValue?: AdvancedSearchInterface | null;
  handleChange?: (values: SelectedValuesInterface[]) => void;
  handleChangeSingle?: (values: SelectedValuesInterface) => void;
  placement?: "auto" | "bottom" | "top";
  values?: AdvancedSearchInterface[];
}

interface sel {
  value: string;
  label: string;
  id: number;
}

const AsyncSelector = (props: AsyncSelectorProps) => {
  const theme = useTheme();
  const { snapFilter } = useSelector((state: RootState) => state.search);
  const { shareOfVoice, isSnapTableLoading } = useSelector(
    (state: RootState) => state.data
  );
  const asyncRef = useRef<AsyncSelect<AdvancedSearchInterface>>(null);

  useEffect(() => {
    if (props.isMulti === false && asyncRef && asyncRef.current) {
      if (
        props.selectedValue === null &&
        // @ts-expect-error
        asyncRef.current.select.select.state.selectValue.length > 0
      ) {
        // @ts-expect-error
        asyncRef.current.select.select.clearValue();
      }
    }
  });

  const promiseOptions = async (inputValue: string) => {
    if (inputValue.length > 1) {
      if (props.isMulti === true || snapFilter !== null) {
        return (
          await fetchAutocompleteAPIv2(inputValue, props.token, props.type, 1, 50)
        )?.options;
      } else {
        if (!isSnapTableLoading) {
          return shareOfVoice[0].tableData
            .filter((item) => {
              const reg = new RegExp(inputValue, "gi");
              const match = item.label.match(reg);

              return match !== null && Array.isArray(match) && match.length > 0;
            })
            .map((queryItem) => ({
              value: queryItem.label,
              label: queryItem.label,
              id: queryItem.id,
            }));
        }
      }
    }
  };

  const promiseOptionsMulti = async (
    inputValue: string,
    loadedOptions: OptionsList<sel>,
    { page }: { page: number }
  ) => {
    if (inputValue.length > 1) {
      return await fetchAutocompleteAPIv2(
        inputValue,
        props.token,
        props.type,
        page,
        50
      );
    }
    return {
      options: loadedOptions,
      hasMore: true,
      additional: {
        page,
      },
    };
  };

  const customStyles: Partial<Styles> = {
    control: (provided) => ({
      ...provided,
      background: theme.palette.background.paper,
      backgroundColor: theme.palette.background.paper,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.palette.text.primary,
    }),
    input: (provided) => ({
      ...provided,
      color: theme.palette.text.primary,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.default,
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  if (props.isMulti && props.isMulti === true) {
    return (
      <AsyncPaginate
        isMulti={true}
        cacheOptions={true}
        defaultOptions
        defaultValue={props.selectedValues}
        isClearable={true}
        loadOptions={promiseOptionsMulti}
        menuPlacement={props.placement ? props.placement : "auto"}
        maxMenuHeight={100}
        placeholder={props.placeholder}
        value={props.values}
        onChange={(value) => {
          props.handleChange !== undefined && props.handleChange(value);
        }}
        additional={{
          page: 1,
        }}
      />
    );
  } else {
    return (
      <AsyncSelect
        ref={asyncRef}
        isMulti={props.isMulti !== undefined ? props.isMulti : true}
        cacheOptions={props.isMulti && props.isMulti === true ? true : false}
        defaultOptions
        defaultValue={
          props.isMulti && props.isMulti === true
            ? props.selectedValues
            : props.selectedValue
        }
        value={
          props.isMulti && props.isMulti === true
            ? props.selectedValues
            : props.selectedValue
        }
        loadOptions={promiseOptions}
        placeholder={isSnapTableLoading ? "Loading..." : props.placeholder}
        styles={customStyles}
        onChange={(value) =>
          props.isMulti && props.isMulti === true
            ? // @ts-expect-error
              props.handleChange(value)
            : // @ts-expect-error
              props.handleChangeSingle(value)
        }
        menuPlacement={props.placement ? props.placement : "auto"}
        maxMenuHeight={100}
        isClearable={true}
        isDisabled={isSnapTableLoading}
      />
    );
  }
};

export default AsyncSelector;
