import React, { useCallback, useEffect, useState } from "react"
import axios, { Canceler, CancelToken } from "axios";
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import ColorBar from "react-color-bar"
import { ShareVoice, Investment } from "../../store/data/types"
import { useFormatMessage, useFormatNumber } from "@comparaonline/react-intl-hooks"
import { COLORS, darkCOLORS } from "../../utils/colors"
import { useTheme } from "@material-ui/core"
import { GetExchangeRate } from "../../services/api"
import { numberWithCommas } from "../../utils/functions";

interface PercentageBarProps {
  filter: string;
  data: Investment[] | ShareVoice[];
}

interface BarProps {
  value: number;
  color: string;
  legendLabel: string;
  legendValue: string;
}

const PercentageBar = React.memo((props: PercentageBarProps) => {
  const selectedPage = useSelector(
    (state: RootState) => state.navigation.selectedPage
  )
  const formatMessage = useFormatMessage()
  const formatNumber = useFormatNumber()
  const theme = useTheme();

  const { data } = props;
  const [rates, setRates] = useState<{ [p: string]: number } | false>(false);

  const fetchConverted = useCallback(async (cancelToken: CancelToken) => {
    if (Array.isArray(data) && (data as Investment[])[0] && (data as Investment[])[0].tableData) {
      const currenciesEffect = (data as Investment[]).map(item => item.currency);
      const getRates = await GetExchangeRate(currenciesEffect, cancelToken);
      setRates(getRates);
    }
  }, [data]);

  useEffect(() => {
    let cancelT: Canceler;
    const cancelToken = new axios.CancelToken(cancel => cancelT = cancel);
    fetchConverted(cancelToken);

    return () => {
      if (cancelT) {
        cancelT();
      }
    }
  }, [fetchConverted]);

  const ShareVoiceData = () => {
    const dataBar: BarProps[] = []
    if (data && (data as ShareVoice[]) && Array.isArray((data as ShareVoice[]))) {
      (data as ShareVoice[]).forEach((countryData, index) => {
        const item = countryData.tableData.find(
          (item) => item.label === props.filter
        )
        dataBar.push({
          value: (item && item.percentage) || 0,
          color: theme.palette.type === 'dark' ? darkCOLORS[index] : COLORS[index],
          legendLabel: formatMessage(countryData.country),
          legendValue: (item && `${formatNumber(
            item.percentage,
            {
              maximumFractionDigits: 2 
            })}%`) || "0%",
        })
      })
    } else {
      dataBar.push({
        value: 0,
        color: theme.palette.type === 'dark' ? darkCOLORS[0] : COLORS[0],
        legendLabel: 'Loading...',
        legendValue: "0",
      },{
        value: 0,
        color: theme.palette.type === 'dark' ? darkCOLORS[1] : COLORS[1],
        legendLabel: 'Loading...',
        legendValue: "0",
      });
    }
    return dataBar
  }

  const InvestmentData = () => {
    const dataBar: BarProps[] = []
    if (data && Array.isArray(data) && rates) {
      (data as Investment[]).forEach((countryData, index) => {
        const item = countryData.tableData.find(
          (item) => item.label === props.filter
        )
        if (countryData.currency) {
          dataBar.push({
            value: (item && item.amount / rates[`USD${countryData.currency}`]) || 0,
            color: theme.palette.type === 'dark' ? darkCOLORS[index] : COLORS[index],
            legendLabel: formatMessage(countryData.country),
            legendValue:
              (item &&
                `${numberWithCommas(item.amount / rates[`USD${countryData.currency}`])} USD`) ||
              "0",
          })
        }
      })
    }
    if (dataBar.length === 0) {
      dataBar.push({
        value: 0,
        color: theme.palette.type === 'dark' ? darkCOLORS[0] : COLORS[0],
        legendLabel: 'Loading...',
        legendValue: "0",
      },{
        value: 0,
        color: theme.palette.type === 'dark' ? darkCOLORS[1] : COLORS[1],
        legendLabel: 'Loading...',
        legendValue: "0",
      });
    }
    return dataBar
  }

  return (
    <ColorBar
      data={selectedPage === "investment" ? InvestmentData() : ShareVoiceData()}
      barContainerStyle={{ height: "15px", borderRadius: 0 }}
      legendContainerStyle={{ display: "flex", justifyContent: "space-around" }}
      legendRowBodyStyle={{ marginLeft: "3px", color: theme.palette.text.primary }}
      legendRowTitleStyle={{ color: theme.palette.text.primary }}
    />
  )
}, (prevProps, nextProps) => {
  return prevProps.data.length === nextProps.data.length && JSON.stringify(prevProps.data[prevProps.data.length - 1]) === JSON.stringify(nextProps.data[nextProps.data.length - 1]);
});

PercentageBar.displayName = "PercentageBar";

export default PercentageBar
