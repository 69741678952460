import Airtable, { Attachment, Record, FieldSet } from "airtable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  createStyles,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_TOKEN || "",
}).base(process.env.REACT_APP_AIRTABLE_DB_ID || "");

const reportStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(10, 0, 6),
    },
    countryFlag: {
      position: "relative",
      width: "100%",
    },
    title: {
      margin: theme.spacing(4, 2, 2),
    },
  })
);

export const CountryReports = () => {
  const classes = reportStyles();
  const intl = useFormatMessage();
  const { countryId } = useParams<{ countryId: string }>();
  const [report, setReport] = useState<Record<FieldSet>>();

  const readData = (record: Record<FieldSet>) => {
    console.log(record);
    setReport(record);
  };

  useEffect(() => {
    async function fetchReports() {
      const data = await base("Reports").find(countryId);

      readData(data);
    }

    fetchReports();
  }, [countryId]);

  return (
    <Grid container className={classes.container} justify="center" spacing={2}>
      {report && (
        <>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h4" className={classes.title}>
              {intl("marketing.report_view.title")}
            </Typography>
            <List>
              {(report.get("Docs") as Attachment[])?.map((reportDoc, i) => (
                <ListItem key={`Report-${i}`}>
                  <ListItemText
                    primary={
                      <Link href={reportDoc.url} color="textPrimary">
                        {reportDoc.thumbnails?.small?.width && (
                          <img
                            src={reportDoc.thumbnails?.large?.url || ""}
                            alt="report"
                          />
                        )}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img
              className={classes.countryFlag}
              src={(report.get("Flag Image") as Attachment[])?.[0].url || ""}
              alt="country flag"
            />
            <Typography gutterBottom variant="h5" component="h2">
              {report.get("Name")?.toString()}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              {report.get("Description")?.toString()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" mt={5} />
            <Typography variant="h5">{intl("marketing.report_view.cta")}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
