import { call, put, takeEvery, fork, all } from "redux-saga/effects";
import { sendHelpTicket } from "../../services/api";
import { helpTicketSuccess, helpTicketRequest } from "./actions";
import { toggle_dialog } from "../navigation/actions";
import { GeneralActionTypes } from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* handleSendHelpTicketRequest(action: ReturnType<typeof helpTicketRequest>) {
  try {
    yield call(sendHelpTicket);
    yield put(helpTicketSuccess());
    yield put(toggle_dialog());
  } catch (e) {
    console.log(e);
  }
}

function* watchHelpTicketRequest() {
  yield takeEvery(
    GeneralActionTypes.HELP_TICKET_REQUEST,
    handleSendHelpTicketRequest
  );
}

function* generalSaga() {
  yield all([fork(watchHelpTicketRequest)]);
}

export default generalSaga;
