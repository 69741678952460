import React, { useEffect } from 'react';
import { useFormatMessage } from '@comparaonline/react-intl-hooks';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, FormHelperText, makeStyles, Theme, createStyles, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { createReportRequest, editReportRequest } from '../../../../store/data/actions';
import { ReportInterface } from '../../../../store/data/types';

interface NewReportDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    report?: ReportInterface;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

type FrecuencyReport = "daily" | "weekly" | "biweekly" | "monthly" | "never";

const NewReportDialog = (props: NewReportDialogProps) => {
    const { report } = props;
    const formatMessage = useFormatMessage();
    const [value, setValue] = React.useState<"daily" | "weekly" | "biweekly" | "monthly" | "never">('daily');
    const [state, setState] = React.useState({
        sovExcel: true,
        sovPdf: false,
        soiExcel: false,
        soiPdf: false,
        campaigns: false,
        campaignsRTS: false,
    });
    const styles = useStyles();
    const { sovExcel, sovPdf, soiExcel, soiPdf, campaigns, campaignsRTS } = state;
    const [name, setName] = React.useState('');
    const error = [sovExcel, sovPdf, soiExcel, soiPdf, campaigns, campaignsRTS].filter((v) => v).length < 1 && value !== 'never';
    const dispatch = useDispatch();
    const { countries, advancedFilters } = useSelector((state: RootState) => state.search);
    const { token } = useSelector((state: RootState) => state.login);

    useEffect(() => {
      if (report) {
        setValue(report.frequency as FrecuencyReport);
        setState({
          sovExcel: report.formats.some(format => format === "sov_excel"),
          sovPdf: report.formats.some(format => format === "sov_pdf"),
          soiExcel: report.formats.some(format => format === "soi_excel"),
          soiPdf: report.formats.some(format => format === "soi_pdf"),
          campaigns: report.formats.some(format => format === "campaign_details_excel"),
          campaignsRTS: report.formats.some(format => format === "campaign_rts_excel"),
        });
        setName(report.name);
      }
    }, [report]);

    
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };
    
    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
       setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value as FrecuencyReport);
    };
    const handleClose = () => {
      props.setOpen(false);
    };

    const getTypeAndFormat = (): string[] => {
      const types: string[] = [];
      if (state.sovExcel) types.push('sov_excel');
      if (state.campaignsRTS) types.push('campaign_rts_excel');
      if (state.campaigns) types.push('campaign_details_excel');
      if (state.sovPdf) types.push('sov_pdf');
      if (state.soiExcel) types.push('soi_excel');
      if (state.soiPdf) types.push('soi_pdf');

      return types;
    }

    const handleSubmit = () => {
      if (report) {
        dispatch(editReportRequest(
          token,
          {
            id: report.id,
            formats: getTypeAndFormat(),
            frequency: value,
            name: name
          }
        ));
      } else {
        const hashAdvanced: any = {};
  
        if (advancedFilters.advertisers.length > 0) {
          hashAdvanced.advertisers = advancedFilters.advertisers.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.products.length > 0) {
          hashAdvanced.products = advancedFilters.products.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.formats.length > 0) {
          hashAdvanced.formats = advancedFilters.formats.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.platforms.length > 0) {
          hashAdvanced.platforms = advancedFilters.platforms.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.publishers.length > 0) {
          hashAdvanced.publishers = advancedFilters.publishers.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.excludedPublishers.length > 0) {
          hashAdvanced.excludedPublishers = advancedFilters.excludedPublishers.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.categories.length > 0) {
          hashAdvanced.categories = advancedFilters.categories.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.industries.length > 0) {
          hashAdvanced.industries = advancedFilters.industries.map(item => parseInt((item.id as unknown) as string));
        }
        if (advancedFilters.sources.length > 0) {
          hashAdvanced.sources = advancedFilters.sources.map(item => parseInt((item.id as unknown) as string));
        }

        dispatch(createReportRequest(
          token,
          {
            name,
            frequency: value,
            parameters: { country: countries[0], page: "1", countries: countries, ...hashAdvanced },
            formats: getTypeAndFormat()
          }
        ));
      }
      handleClose();
    }

    return (
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{formatMessage('newReport.title')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {formatMessage('newReport.message')}
            </DialogContentText>
            <TextField id="standard-name" label={formatMessage('newReport.name')} value={name} onChange={handleTextChange} fullWidth error={name === ''} />
            <div className={styles.root}>
            <FormControl component="fieldset" className={styles.formControl}>
                <FormLabel component="legend">{formatMessage('newReport.frequency.title')}</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <FormControlLabel value="daily" control={<Radio />} label={formatMessage('newReport.frequency.daily')} />
                    <FormControlLabel value="weekly" control={<Radio />} label={formatMessage('newReport.frequency.weekly')} />
                    <FormControlLabel value="monthly" control={<Radio />} label={formatMessage('newReport.frequency.monthly')} />
                    <FormControlLabel value="never" control={<Radio />} label={formatMessage('newReport.frequency.never')} />
                </RadioGroup>
            </FormControl>
            <FormControl required={value !== 'never'} error={error} component="fieldset" className={styles.formControl}>
                <FormLabel component="legend">{formatMessage('newReport.format.title')}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sovPdf} onChange={handleChangeCheckbox} name="sovPdf" />}
                      label="Share of Voice (PDF)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={sovExcel} onChange={handleChangeCheckbox} name="sovExcel" />}
                      label="Share of Voice (Excel)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={campaigns} onChange={handleChangeCheckbox} name="campaigns" />}
                      label="Campaigns Details (Excel)"
                    /> 
                    {
                        value === 'daily' || value === 'weekly' ?
                          '': (
                                <>
                                  <FormControlLabel
                                    control={<Checkbox checked={soiPdf} onChange={handleChangeCheckbox} name="soiPdf" />}
                                    label="Share of Investment (PDF)"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={soiExcel} onChange={handleChangeCheckbox} name="soiExcel" />}
                                    label="Share of Investment (Excel)"
                                  />
                                </>
                            )
                    }
                    {
                        value === 'daily' || value === 'weekly' ?
                            '' : (
                              <FormControlLabel
                                control={<Checkbox checked={campaignsRTS} onChange={handleChangeCheckbox} name="campaignsRTS" />}
                                label="Campaigns Details RTS (Excel)"
                              />
                            )
                    }
                </FormGroup>
                {
                  value === 'never' ? '' : (<FormHelperText>{formatMessage('newReport.format.help')}</FormHelperText>)
                }
            </FormControl>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage('navigation.buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={error || name === ''}>
            {formatMessage('navigation.buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default NewReportDialog;
