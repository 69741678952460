import { Dispatch } from "redux"
import { connect } from "react-redux"
import { RootState, RootAction } from "typesafe-actions"
import { toggle_dialog } from "../../store/navigation/actions"
import { change_password_request } from "../../store/login/actions"
import ChangePasswordFormView from "./ChangePasswordFormView"

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  general_state: state.general,
  navigation_state: state.navigation,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggle_dialog: () => dispatch(toggle_dialog()),
    change_password: (currentPassword: string, newPassword: string) =>
      dispatch(change_password_request(currentPassword, newPassword)),
  }
}

export type ChangePasswordFormReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormView)
