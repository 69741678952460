import React from "react";
import cx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import ChangePasswordFormView from "./ChangePasswordFormContainer";
import ProfileFormView from "./ProfileFormContainer";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      borderRadius: 4,
      minWidth: 256,
      textAlign: "center",
    },
    heading: {
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      // color: palette.grey[500],
      marginBottom: "0.875em",
      textAlign: "start",
      fontWeight: "normal",
    },
  })
);

const SecurirtyCard = () => {
  const styles = useStyles();
  const intl = useFormatMessage();

  return (
    <Card className={cx(styles.card)}>
      <CardContent>
        <h3 className={styles.heading}>{intl("accountPage.tabs.security")}</h3>
        <h4 className={styles.subheader}>{intl("accountPage.general.text")}</h4>
        <ProfileFormView />
        <Divider light />
        <h4 className={styles.subheader}>{intl("accountPage.security.text")}</h4>
        <ChangePasswordFormView />
      </CardContent>
    </Card>
  );
};

export default SecurirtyCard;
