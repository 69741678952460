import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import { Dispatch } from "react"
import {
  campaignsDataFetchRequest,
  investmentDataRequest,
  shareOfVoiceDataRequest,
  spotsListDataRequest,
} from "../../../store/data/actions"
import {
  PaginationParametersInterface, SearchParameters, AdvancedFilters,
} from "../../../store/search/types"
import { injectIntl, InjectedIntlProps } from "react-intl"
import {
  queueParameter,
  setAllParameters,
  setAdvancedFilters,
} from "../../../store/search/actions"
import { QuickFilterMenuView } from "./QuickFilterMenuView"

interface OwnProps {
  name: string
  id: number
  type: string
  currentPage: string
  position: {
    mouseX: null | number
    mouseY: null | number
  }
  handleClose: () => void
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  data_state: state.data,
  navigation_state: state.navigation,
  token: state.login.token,
  search_state: state.search,
  name: ownProps.name,
  type: ownProps.type,
  id: ownProps.id,
  currentPage: ownProps.currentPage,
  position: ownProps.position,
  handleClose: ownProps.handleClose,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    fetchShareOfVoice: (
      searchParameters: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) =>
      dispatch(shareOfVoiceDataRequest(searchParameters, pagination, token, type)),
    fetchInvestment: (
      searchParameters: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(investmentDataRequest(searchParameters, pagination, token, type)),
    fetchListedSpots: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(spotsListDataRequest(search, pagination, token)),
    fetchCampaigns: (
      searchParameters: SearchParameters,
      token: string,
      pagination?: PaginationParametersInterface
    ) => dispatch(campaignsDataFetchRequest(searchParameters, pagination!, token)),
    queueParameter: (parameters: AdvancedFilters) =>
      dispatch(queueParameter(parameters)),
    setAdvancedSearchParameters: (parameters: AdvancedFilters) =>
      dispatch(setAdvancedFilters(parameters)),
    setAllSearchParams: (parameters: SearchParameters) =>
      dispatch(setAllParameters(parameters.countries, parameters.startDate, parameters.endDate, parameters.advancedFilters)),
  }
}

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export const QuickFilterMenu = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(QuickFilterMenuView)
)
