import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import { Dispatch } from "react"
import {
  spotsListDataRequest,
  campaignsDataFetchRequest,
} from "../../store/data/actions"
import {
  PaginationParametersInterface, SearchParameters, AdvancedFilters,
} from "../../store/search/types"
import { injectIntl, InjectedIntlProps } from "react-intl"
import SpotsListView from "./SpotsListView"
import { set_newPage, set_ResultsPerPage } from "../../store/navigation/actions"
import {
  setAllParameters, setAdvancedFilters, clearAllParameters,
} from "../../store/search/actions"
import { logout } from "../../store/login/actions";

const mapStateToProps = (state: RootState) => ({
  data_state: state.data,
  navigation_state: state.navigation,
  token: state.login.token,
  search_state: state.search,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    listRequest: (
      searchParameters: SearchParameters,
      token: string,
      pagination?: PaginationParametersInterface
    ) => dispatch(spotsListDataRequest(searchParameters, pagination!, token)),
    campaignsRequest: (
      searchParameters: SearchParameters,
      token: string,
      pagination: PaginationParametersInterface
    ) => dispatch(campaignsDataFetchRequest(searchParameters, pagination, token)),
    setCurrentPage: (currentPage: number) => dispatch(set_newPage(currentPage)),
    setNumberOfRows: (rows: number) => dispatch(set_ResultsPerPage(rows)),
    setAdvancedSearchParameters: (parameters: AdvancedFilters) =>
      dispatch(setAdvancedFilters(parameters)),
    setAllSearchParams: (countries: string[], startDate: Date, endDate: Date, advancedFilters: AdvancedFilters) =>
      dispatch(setAllParameters(countries, startDate, endDate, advancedFilters)),
    logoutUser: () => {
      dispatch(logout())
      dispatch(clearAllParameters())
    }
  }
}

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SpotsListView)
)
