import { ContextualMenu, MenuActionsInterface } from "../../generic/ContextualMenu"
import React, { useState } from "react"
import { unstable_trace as trace } from "scheduler/tracing";
import { ReduxProps } from "./QuickFilterMenuContainer"
import { AdvancedFilters } from "../../../store/search/types"
import { SidebarPages } from "../../../store/navigation/types"
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

export const QuickFilterMenuView = (props: ReduxProps) => {
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  function getParameters() {
    return trace("get parameters function", performance.now(), () => {
      const parameters: AdvancedFilters = {
        ...props.search_state.parameterQueue
      };
  
      const isIn = parameters[props.type].some((item) => item.id === props.id) ||
            props.search_state.advancedFilters[props.type].some(item => item.id === props.id);
  
      if (!isIn) {
        parameters[props.type].push({ id: props.id, label: props.name, value: props.id });
      } else {
        setOpenSnack(true);
      }
      return parameters
    });
  }

  function performSearch() {
    const params = getParameters();
    trace("perform search function", performance.now(), () => {
      const newParameters: AdvancedFilters = {
        platforms: [
          ...params.platforms,
          ...props.search_state.advancedFilters.platforms,
        ],
        advertisers: [
          ...params.advertisers,
          ...props.search_state.advancedFilters.advertisers,
        ],
        categories: [
          ...params.categories,
          ...props.search_state.advancedFilters.categories,
        ],
        formats: [
          ...params.formats,
          ...props.search_state.advancedFilters.formats,
        ],
        products: [
          ...params.products,
          ...props.search_state.advancedFilters.products,
        ],
        industries: [
          ...params.industries,
          ...props.search_state.advancedFilters.industries,
        ],
        publishers: [
          ...params.publishers,
          ...props.search_state.advancedFilters.publishers,
        ],
        sources: [
          ...params.sources,
          ...props.search_state.advancedFilters.sources,
        ],
        excludedPublishers: [
          ...params.excludedPublishers,
          ...props.search_state.advancedFilters.excludedPublishers
        ]
      }
  
      props.setAdvancedSearchParameters(newParameters);
      
      switch (props.navigation_state.selectedPage) {
        case SidebarPages.shareofvoice:
          props.fetchShareOfVoice(
            { ...props.search_state, advancedFilters: { ...newParameters } },
            props.search_state.paginationParameters,
            props.token,
            props.type.toUpperCase()
          )
          break
        case SidebarPages.investment:
          props.fetchInvestment(
            { ...props.search_state, advancedFilters: { ...newParameters } },
            props.search_state.paginationParameters,
            props.token,
            props.type.toUpperCase()
          )
          break
        case SidebarPages.spotspreview:
          props.fetchCampaigns(
            { ...props.search_state, advancedFilters: { ...newParameters } },
            props.token,
            props.search_state.paginationParameters
          )
          break
        case SidebarPages.spotslist:
          props.fetchListedSpots(
            { ...props.search_state, advancedFilters: { ...newParameters } },
            props.search_state.paginationParameters,
            props.token
          )
          break
      }
      props.queueParameter({
        advertisers: [],
        publishers: [],
        industries: [],
        products: [],
        platforms: [],
        formats: [],
        categories: [],
        sources: [],
        excludedPublishers: []
      })
    });
  }

  function performSearchInNewTab() {
    const newParameters: AdvancedFilters = {
      platforms: props.type === 'platforms' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.platforms],
      advertisers: props.type === 'advertisers' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.advertisers],
      categories: props.type === 'categories' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.categories],
      formats: props.type === 'formats' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.formats],
      products: props.type === 'products' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.products],
      industries: props.type === 'industries' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.industries],
      publishers: props.type === 'publishers' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.publishers],
      sources: props.type === 'sources' ? [{ id: props.id, label: props.name, value: props.id }] : [...props.search_state.advancedFilters.sources],
      excludedPublishers: [...props.search_state.advancedFilters.excludedPublishers],
    }
    const sharedJson = JSON.stringify({ ...props.search_state, advancedFilters: { ...newParameters } });
    localStorage.setItem(
      "sharedSearchParameters",
      sharedJson
    );
    window.open("/dashboard/" + props.currentPage, "_blank")
  }

  function queue() {
    const params = getParameters();
    trace("add to queue function", performance.now(), () => {
      const queued = {
        advertisers: [...params.advertisers],
        categories: [...params.categories],
        formats: [...params.formats],
        industries: [...params.industries],
        publishers: [...params.publishers],
        platforms: [...params.platforms],
        products: [...params.products],
        sources: [...params.sources],
        excludedPublishers: [...params.excludedPublishers],
      }
      props.queueParameter(queued)
    });
  }

  const actions: MenuActionsInterface[] = [
    {
      actionName: props.intl.formatMessage({ id: "navigation.buttons.queue" }),
      action: queue,
    },
    {
      actionName: props.intl.formatMessage({ id: "navigation.buttons.searchNow" }),
      action: performSearch,
    },
    {
      actionName: props.intl.formatMessage({
        id: "navigation.buttons.searchNewTab",
      }),
      action: performSearchInNewTab,
    },
  ];

  return (
    <React.Fragment>
      <ContextualMenu
        actions={actions}
        position={props.position}
        handleClose={props.handleClose}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnack} severity="error">
          {
            props.type === 'source' ?
              props.intl.formatMessage({
                id: "errors.filters.sources",
              }) :
              props.intl.formatMessage({
                id: "errors.filters",
              })
          }
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  )
}
