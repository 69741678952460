import { makeStyles, createStyles } from "@material-ui/core";
import React from "react";
import { FacebookPostStyles } from "../../store/data/types";

interface FacebookPostProps {
  facebookPostAttributes: FacebookPostStyles;
  mediaSource: string;
}

export const FacebookPost = (props: FacebookPostProps) => {
  const classes = useStyles();
  return (
    <div className={classes.facebookPost}>
      <div className={classes.title}>{props.facebookPostAttributes.title}</div>
      {props.facebookPostAttributes.url ? (
        <div className={classes.url}>{props.facebookPostAttributes.url}</div>
      ) : null}
      <div className={classes.body}>
        <div className={classes.image}>
          <img
            className={classes.img}
            src={props.mediaSource}
            alt={props.facebookPostAttributes.title}
          />
        </div>
        {props.facebookPostAttributes.text ? (
          <div className={classes.text}>{props.facebookPostAttributes.text}</div>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    facebookPost: {
      width: 244,
      fontSize: 11,
      fontFamily: "lucida grande",
      margin: "auto",
    },
    title: {
      color: "#3b5998",
      fontWeight: "bold",
    },
    url: {
      color: "#999999",
    },
    body: {
      paddingTop: 3,
    },
    image: {
      float: "left",
      marginRight: 8,
      width: 100,
    },
    img: {
      maxHeight: 72,
      maxWidth: 100,
      paddingTop: 3,
    },
    text: {
      color: "#333",
      overflow: "hidden",
    },
  })
);
