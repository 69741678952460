import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { Form, Formik } from "formik"
import { ChangePasswordFormReduxProps } from "./ChangePasswordFormContainer"
import * as Yup from "yup"
import { FormattedMessage } from "react-intl"
import * as React from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0),
    },
    input: {
      display: "none",
    },
    textField: {
      outlineColor: "#293E76",
      color: "#293E76",
    },
    formButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    },
    form: {
      width: "100%",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
)

interface State {
  password: string;
  showPassword: boolean;
}
const ChangePasswordFormView = (props: ChangePasswordFormReduxProps) => {
  const [ownValues, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  })

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setValues({ ...ownValues, showPassword: !ownValues.showPassword })
  }

  const classes = useStyles()

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Enter your password.")
      .oneOf([props.login_state.password], "This is not your current password!"),
    newPassword: Yup.string()
      .required("Enter a new password.")
      .min(4, "The new password must be at least 4 characters long."),
    newPasswordRepeat: Yup.string()
      .required("Repeat your new password.")
      .min(4)
      .oneOf([Yup.ref("newPassword")], "Passwords don't match"),
  })
  return (
    <Formik
      onSubmit={(values) =>
        {props.change_password(values.currentPassword, values.newPassword)}
      }
      initialValues={{
        currentPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
        showPassword: false,
      }}
      validationSchema={validationSchema}
    >
      {({ handleChange, touched, errors, values }) => (
        <Form className={classes.form}>
          <FormControl className={classes.textField} fullWidth>
            <InputLabel htmlFor="adornment-password">
              <FormattedMessage id={"loginPage.passwordField"} />
            </InputLabel>
            <Input
              id="currentPassword"
              type={ownValues.showPassword ? "text" : "password"}
              value={values.currentPassword}
              name="currentPassword"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {ownValues.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.currentPassword && touched.currentPassword ? true : false}
            />
          </FormControl>

          {errors.currentPassword && touched.currentPassword ? (
            <div>{errors.currentPassword}</div>
          ) : null}

          <FormControl className={classes.textField} fullWidth>
            <InputLabel htmlFor="adornment-password">
              <FormattedMessage id={"accountPage.security.newPassword"} />
            </InputLabel>
            <Input
              id="newPassword"
              type={ownValues.showPassword ? "text" : "password"}
              value={values.newPassword}
              name="newPassword"
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {ownValues.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.newPassword && touched.newPassword ? true : false}
            />
          </FormControl>

          {errors.newPassword && touched.newPassword ? (
            <div>{errors.newPassword}</div>
          ) : null}

          <FormControl className={classes.textField} fullWidth>
            <InputLabel htmlFor="adornment-password">
              <FormattedMessage id={"accountPage.security.newPasswordRepeat"} />
            </InputLabel>
            <Input
              id="newPasswordRepeat"
              type={ownValues.showPassword ? "text" : "password"}
              value={values.newPasswordRepeat}
              name="newPasswordRepeat"
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {ownValues.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={errors.newPasswordRepeat && touched.newPasswordRepeat ? true : false}
            />
          </FormControl>

          {errors.newPasswordRepeat && touched.newPasswordRepeat ? (
            <div>{errors.newPasswordRepeat}</div>
          ) : null}

          <div className={classes.formButtons}>
            <Button
              variant="outlined"
              type={"submit"}
              color="primary"
              className={classes.button}
              disabled={
                values.currentPassword.length === 0 ||
                values.newPassword.length === 0 ||
                values.newPasswordRepeat.length === 0
              }
            >
              {props.login_state.loading ? (
                <CircularProgress size={24.5} />
              ) : (
                <FormattedMessage id={"navigation.buttons.submit"} />
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ChangePasswordFormView
