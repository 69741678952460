interface CardButtonsInterface {
  label: string
  link: string
}

export interface HomeCardsInterface {
  type: string
  title: string
  body: string
  buttons?: CardButtonsInterface[]
}

const Cards: HomeCardsInterface[] = [
  {
    type: "Novedades",
    title: "¡Lanzamos pVIEWABILITY!",
    body:
      "Esta gran innovación te permitirá conocer la visibilidad de todas las campañas en los 17 mercados. Hemos activado pVIEWABILITY en tu cuenta, de cortesía por un tiempo. ¡Disfrutalo!",
    buttons: [
      {
        label: "Más Información",
        link:
          "https://customer.adcuality.com/support/solutions/articles/1000278991-viewability-new-",
      },
      {
        label: "Seminarios Web",
        link: "https://zoom.us/webinar/register/WN_wONnwpm0ROK-cMn2BpgAEQ",
      },
    ],
  },
  {
    type: "Atención",
    title: "¡Lorem ipsum dolor! 1",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    type: "Lorem ipsum",
    title: "¡Lorem ipsum dolor! 2",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    type: "Lorem ipsum",
    title: "¡Lorem ipsum dolor! 3",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    type: "Lorem ipsum",
    title: "¡Lorem ipsum dolor! 4",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    type: "Lorem ipsum",
    title: "¡Lorem ipsum dolor! 5",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
]

export default Cards
