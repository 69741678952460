import {
  Container,
  createStyles,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  WhatsApp,
} from "@material-ui/icons";
import { Box } from "reflexbox";

const footerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(5, 0, 3),
      position: "relative",
      width: "100%",
    },
    text: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    listItem: {
      padding: 0,
    },
  })
);

export const MainFooter: React.FC = () => {
  const classes = footerStyles();

  return (
    <footer className={classes.root}>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} md={5} lg={4}>
            <Typography variant="h4" className={classes.text} gutterBottom>
              Nosotros
            </Typography>
            <Typography variant="body1" className={classes.text} gutterBottom>
              adCuality es la primer y más avanzada tecnología en monitoreo de
              publicidad. Máxima compatibilidad de captura en plataformas Desktop y
              Web Mobile, y la cobertura más importante de la región, con el máximo
              nivel de información histórica.{" "}
            </Typography>

            <Box display="flex" style={{ gap: "0.5rem" }}>
              <Link href="https://www.facebook.com/adCuality" target="_blank">
                <Facebook className={classes.text} />
              </Link>
              <Link href="https://twitter.com/adCuality" target="_blank">
                <Twitter className={classes.text} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/adcuality/"
                target="_blank"
              >
                <LinkedIn className={classes.text} />
              </Link>
              <Link href="https://www.instagram.com/adcuality/" target="_blank">
                <Instagram className={classes.text} />
              </Link>
              <Link
                href="https://api.whatsapp.com/send/?phone=543484385372&amp;text&amp;app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsApp className={classes.text} />
              </Link>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
          <Grid item xs={12} md={3}>
            <Typography variant="h4" className={classes.text} gutterBottom>
              Links
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="https://adcuality.com/login#about"
                      target="_blank"
                      className={classes.text}
                    >
                      Nosotros
                    </Link>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="https://adcuality.com/login#product"
                      target="_blank"
                      className={classes.text}
                    >
                      Producto
                    </Link>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="https://adcuality.com/login#coverage"
                      target="_blank"
                      className={classes.text}
                    >
                      Cobertura
                    </Link>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="https://adcuality.com/login"
                      target="_blank"
                      className={classes.text}
                    >
                      Contacto
                    </Link>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="http://customer.adCuality.com/"
                      target="_blank"
                      className={classes.text}
                    >
                      Customer Center
                    </Link>
                  }
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText
                  primary={
                    <Link
                      href="https://customer.adcuality.com/support/solutions/articles/1000159563"
                      target="_blank"
                      className={classes.text}
                    >
                      Términos y Condiciones
                    </Link>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.text}>
              Todos los derechos reservados @ adcuality.com 2022
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
