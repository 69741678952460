import { Dispatch } from "redux"
import { toggle_sidebar } from "../../../store/navigation/actions"
import { RootState, RootAction } from "typesafe-actions"
import { connect } from "react-redux"
import DrawerView from "./DrawerView"

const mapStateToProps = (state: RootState) => ({
  isSidebarOpen: state.navigation.isSidebarOpen,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggle_sidebar: () => dispatch(toggle_sidebar()),
  }
}

export type DrawerReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(DrawerView)
