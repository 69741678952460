import { connect } from "react-redux";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { RootState, RootAction } from "typesafe-actions";
import { Dispatch } from "redux";
import {
  PaginationParametersInterface,
  SearchParameters,
  AdvancedFilters,
  AdvancedSearchInterface,
} from "../../../store/search/types";
import {
  campaignsDataFetchRequest,
  investmentDataRequest,
  setSnapCasting,
  setSnapTablePage,
  shareOfVoiceDataRequest,
  spotsListDataRequest,
} from "../../../store/data/actions";
import SearchToolbarView from "./SearchToolbar";
import {
  clearAllParameters,
  queueParameter,
  setAllParameters,
  setSnapFilter,
  setSnapType,
} from "../../../store/search/actions";
import {
  choose_tab,
  setNotification,
  toggleSwitch,
} from "../../../store/navigation/actions";

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  search_state: state.search,
  token: state.login.token,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    fetchCampaigns: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(campaignsDataFetchRequest(search, pagination, token)),
    fetchListedSpots: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string
    ) => dispatch(spotsListDataRequest(search, pagination, token)),
    fetchShareOfVoice: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(shareOfVoiceDataRequest(search, pagination, token, type)),
    fetchInvestment: (
      search: SearchParameters,
      pagination: PaginationParametersInterface,
      token: string,
      type: string
    ) => dispatch(investmentDataRequest(search, pagination, token, type)),
    clearAllParameters: () => dispatch(clearAllParameters()),
    toggleSwitch: () => dispatch(toggleSwitch()),
    updateQueue: (parameters: AdvancedFilters) =>
      dispatch(queueParameter(parameters)),
    setFilters: (filters: SearchParameters) =>
      dispatch(
        setAllParameters(
          filters.countries,
          filters.startDate,
          filters.endDate,
          filters.advancedFilters
        )
      ),
    setSnapType: (type: "industries" | "advertisers" | "publishers") =>
      dispatch(setSnapType(type)),
    setSnapFilter: (filter: AdvancedSearchInterface | null) =>
      dispatch(setSnapFilter(filter)),
    setSnapCasting: (casting: boolean) => dispatch(setSnapCasting(casting)),
    resetSnapTablePage: () => dispatch(setSnapTablePage(1)),
    setNotificationForShare: (
      text: string,
      severity: "success" | "error" | "info" | "warning"
    ) => dispatch(setNotification(severity, text)),
    goToIndustriesOnClear: () => dispatch(choose_tab(0)),
  };
};

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps;

export const SearchToolbar = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SearchToolbarView)
);
