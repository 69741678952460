import React, { useEffect, useState } from "react";
import { RootState } from "typesafe-actions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ReduxProps } from "./GalleryContainer";
import TablePaging from "../../components/dashboard/table/TablePagination";
import { publishPageView } from "../../services/GoogleAnalytics";
import PageTitle from "../../components/dashboard/PageTitle";
import { SearchToolbar } from "../../components/dashboard/search/SearchToolbarContainer";
import { LinearProgress } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { QuickFilterMenu } from "../../components/dashboard/menus/QuickFilterMenuContainer";
import {
  LocationCity as IndustriesIcon,
  Timer,
  Work as AdIcon,
} from "@material-ui/icons";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ImageIcon from "@material-ui/icons/Image";
import WebIcon from "@material-ui/icons/Web";
import LastSeen from "@material-ui/icons/Visibility";
import DevicesIcon from "@material-ui/icons/Devices";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import { CampaignDataList } from "./CampaignDataList";
import { ActiveFiltersToolbar } from "../../components/dashboard/search/ActiveFiltersToolbarContainer";
import { CustomCardMedia } from "./CustomCardMedia";
import { CreativityGallery } from "../../components/creativity/image-carousel/CreativityGalleryView";
import { MediaInterface } from "../../components/creativity/image-carousel/Creativity";
import { SearchParameters } from "../../store/search/types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { queueParameter, setAllParameters } from "../../store/search/actions";
import { getDateFromReports } from "../../utils/functions";
import { SidebarPages } from "../../store/navigation/types";
import { choose_tab, set_sidebar_page } from "../../store/navigation/actions";
import LoadingDialog from "../../components/dashboard/dialogs/LoadingDialog";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardProgress: {
    flexGrow: 1,
    textAlign: "center",
  },
  progress: {
    margin: theme.spacing(2),
  },
  divider: {
    marginBottom: 5,
  },
  iconTitle: {
    verticalAlign: "sub",
  },
  dividerError: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

export default function GaleryView(props: ReduxProps) {
  const dispatch = useDispatch();
  const initialState = {
    mouseX: null,
    mouseY: null,
  };

  const [title, setTitle] = React.useState("");

  const [state, setState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const [selectedFilter, setFilter] = React.useState<{
    name: string;
    id: number;
    type: string;
  }>({ name: "", id: 0, type: "" });

  const { roles } = useSelector((state: RootState) => state.login);
  const history = useHistory();
  const { logoutUser } = props;

  useEffect(() => {
    if (Array.isArray(roles)) {
      const isAllowed = roles.some((item) => item === "ROLE_FEATURE_CAMPAIGN");
      if (!isAllowed) {
        history.push("/404");
      }
    } else {
      logoutUser();
    }
  }, [roles, history, logoutUser]);

  const [open, setOpen] = React.useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState<
    MediaInterface | undefined
  >(undefined);

  function handleViewCreativity(data: MediaInterface, title: string) {
    setSelectedCampaign(data);
    setOpen(true);
    setTitle(title);
  }

  function handleFilterClick(
    event: React.MouseEvent<HTMLElement>,
    name: string,
    id: number,
    type: string
  ) {
    setFilter({ name, id, type });
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  const handleCloseMenu = () => {
    setState(initialState);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setNewPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setNewPage(1);
    props.setResultsPerPage(+event.target.value);
  };

  const { countries, advancedFilters, startDate, endDate } = props.search_state;

  const { currentPage, resultsPerPage, totalPages } =
    props.navigation_state.pagination;

  const { userReports, hasRequestFailedCreativities, errorMessage } =
    props.data_state;
  const { reportId } = useParams<{
    reportId: string;
  }>();
  const { token } = props.login_state;
  const { setAllSearchParams, campaignsRequest, toggleMoreInfo } = props;

  useEffect(() => {
    if (localStorage.getItem("sharedSearchParameters") === null) {
      campaignsRequest({ countries, advancedFilters, startDate, endDate }, token, {
        requestedPageNumber: currentPage,
        requestedResults: resultsPerPage,
      });
    } else {
      toggleMoreInfo();
      const parameters: SearchParameters = JSON.parse(
        localStorage.getItem("sharedSearchParameters")!
      );
      const startDate = new Date(parameters.startDate.toString());
      const endDate = new Date(parameters.endDate.toString());
      setAllSearchParams(
        parameters.countries,
        startDate,
        endDate,
        parameters.advancedFilters
      );
      campaignsRequest({ ...parameters, startDate, endDate }, token, {
        requestedPageNumber: currentPage,
        requestedResults: resultsPerPage,
      });
      localStorage.removeItem("sharedSearchParameters");
    }
  }, [
    countries,
    startDate,
    endDate,
    advancedFilters,
    currentPage,
    resultsPerPage,
    token,
    setAllSearchParams,
    campaignsRequest,
    toggleMoreInfo,
  ]);

  useEffect(() => {
    const report = reportId && userReports.find((r) => r.id === reportId);
    if (report) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { country, page, countries, ...params } = report.parameters;
      const advanced = {
        advertisers: [],
        formats: [],
        platforms: [],
        publishers: [],
        excludedPublishers: [],
        categories: [],
        industries: [],
        products: [],
        sources: [],
      };
      dispatch(queueParameter(advanced));
      const newAdvanced = {
        ...advanced,
        ...params,
      };

      if (newAdvanced.products.length > 0) {
        dispatch(choose_tab(2));
      } else if (newAdvanced.advertisers.length > 0) {
        dispatch(choose_tab(1));
      }

      const dates = getDateFromReports(report.frequency, false);

      dispatch(
        setAllParameters(countries ?? [country], dates[1], dates[0], {
          ...newAdvanced,
        })
      );
      history.push(`/dashboard/${SidebarPages.shareofvoice}`);
      dispatch(set_sidebar_page(SidebarPages.shareofvoice));
    }
  }, [startDate, endDate, reportId, userReports, history, dispatch]);

  const classes = useStyles();
  publishPageView("/dashboard/campaigns");
  return (
    <React.Fragment>
      <SearchToolbar />
      <LinearProgress hidden={!props.data_state.isDataLoading} />
      <ActiveFiltersToolbar />
      <PageTitle
        title={props.intl.formatMessage({
          id: "navigation.sidebar.spots_preview",
        })}
      />
      {hasRequestFailedCreativities ? (
        <>
          <Divider className={classes.dividerError} />
          <Alert severity="warning">
            {errorMessage === "No data available. Try with other criteria."
              ? props.intl.formatMessage({ id: "errors.data.noDataAvailable" })
              : errorMessage}
          </Alert>
        </>
      ) : (
        <>
          <CssBaseline />
          <main>
            <div>
              <Grid container spacing={4}>
                {props.data_state.campaigns.map((campaign) => (
                  <Grid item key={campaign.creativity} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <div
                        onClick={() =>
                          handleViewCreativity(
                            {
                              date: campaign.lastSeenDate,
                              advertiser: campaign.advertiser,
                              style: campaign.style,
                              styleAttributes: campaign.styleAttributes,
                              creativity: campaign.creativity,
                              platform: campaign.platform,
                              format: campaign.format,
                              category: campaign.category,
                              industry: campaign.industry,
                              pViewability: campaign.pViewability,
                              screenshot: campaign.lastSeenScreenshot,
                            },
                            campaign.product + " @ " + campaign.lastSeen
                          )
                        }
                      >
                        <CustomCardMedia campaign={campaign} />
                      </div>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="h2"
                          style={{ cursor: "context-menu" }}
                          onClick={(event: any) =>
                            handleFilterClick(
                              event,
                              campaign.advertiser,
                              campaign.advertiserId,
                              "advertisers"
                            )
                          }
                        >
                          <AdIcon className={classes.iconTitle} />{" "}
                          {campaign.advertiser}
                        </Typography>
                        <Typography variant="overline" display="block" gutterBottom>
                          {props.intl.formatMessage({
                            id: "campaignsPage.moreInfo.lastSeen",
                          }) +
                            ": " +
                            campaign.lastSeenDate +
                            " @ "}{" "}
                          <a href={campaign.lastSeenUrl} target={"_blank"}>
                            {campaign.lastSeen}
                          </a>
                        </Typography>
                        <Divider variant={"middle"} className={classes.divider} />
                        <CampaignDataList
                          items={[
                            {
                              id: "industry-listed-filter",
                              text: (
                                <Typography
                                  variant={"body1"}
                                  component="p"
                                  style={{ cursor: "context-menu" }}
                                  onClick={(event: any) =>
                                    handleFilterClick(
                                      event,
                                      campaign.industry,
                                      campaign.industryId,
                                      "industries"
                                    )
                                  }
                                >
                                  {campaign.industry}
                                </Typography>
                              ),
                              icon: <IndustriesIcon />,
                            },
                            {
                              id: "product-listed-filter",
                              text: (
                                <Typography
                                  variant={"body1"}
                                  component="p"
                                  style={{ cursor: "context-menu" }}
                                  onClick={(event: any) =>
                                    handleFilterClick(
                                      event,
                                      campaign.product,
                                      campaign.productId,
                                      "products"
                                    )
                                  }
                                >
                                  {campaign.product}
                                </Typography>
                              ),
                              icon: <ShoppingCartIcon />,
                            },
                            {
                              id: "platform-listed-filter",
                              text: (
                                <Typography
                                  variant={"body1"}
                                  component="p"
                                  style={{ cursor: "context-menu" }}
                                  onClick={(event: any) =>
                                    handleFilterClick(
                                      event,
                                      campaign.platform,
                                      campaign.platformId,
                                      "platforms"
                                    )
                                  }
                                >
                                  {campaign.platform}
                                </Typography>
                              ),
                              icon: <DevicesIcon />,
                            },
                            {
                              id: "format-listed-filter",
                              text: (
                                <Typography
                                  variant={"body1"}
                                  component="p"
                                  style={{ cursor: "context-menu" }}
                                  onClick={(event: any) =>
                                    handleFilterClick(
                                      event,
                                      campaign.format,
                                      campaign.formatId,
                                      "formats"
                                    )
                                  }
                                >
                                  {campaign.format}
                                </Typography>
                              ),
                              icon: <ArtTrackIcon />,
                            },
                            {
                              id: "spots-listed-filter",
                              text: (
                                <Typography variant={"body1"} component="p">
                                  {campaign.spots} spots
                                </Typography>
                              ),
                              icon: <ImageIcon />,
                            },
                            {
                              id: "pview-listed-filter",
                              text: (
                                <Typography variant={"body1"} component="p">
                                  pViewability{" "}
                                  {campaign.pViewability !== null
                                    ? campaign.pViewability
                                    : "-"}
                                </Typography>
                              ),
                              icon: <LastSeen />,
                            },
                            {
                              id: "last-seen-listed-filter",
                              text: (
                                <Typography variant={"body1"} component="p">
                                  {campaign.lastSeen}
                                </Typography>
                              ),
                              icon: <WebIcon />,
                            },
                            {
                              id: "gmt-zone-listed-filter",
                              text: (
                                <Typography variant={"body2"} component="small">
                                  *
                                  {props.intl.formatMessage({
                                    id: "campaignsPage.moreInfo.gmtZone",
                                  })}
                                </Typography>
                              ),
                              icon: <Timer />,
                            },
                          ]}
                        />
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() =>
                            handleViewCreativity(
                              {
                                date: campaign.lastSeenDate,
                                advertiser: campaign.advertiser,
                                style: campaign.style,
                                styleAttributes: campaign.styleAttributes,
                                creativity: campaign.creativity,
                                platform: campaign.platform,
                                format: campaign.format,
                                category: campaign.category,
                                industry: campaign.industry,
                                pViewability: campaign.pViewability,
                                screenshot: campaign.lastSeenScreenshot,
                              },
                              campaign.product + " @ " + campaign.lastSeen
                            )
                          }
                        >
                          {props.intl.formatMessage({
                            id: "spotlistPage.dialog.screenshotTooltip",
                          })}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div hidden={props.data_state.isDataLoading}>
              <TablePaging
                count={totalPages}
                rowsPerPage={resultsPerPage}
                currentPage={currentPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                label={props.intl.formatMessage({
                  id: "navigation.tablePagination.resultsPerPage",
                })}
              />
            </div>
          </main>
          <CreativityGallery
            isOpen={open}
            toggle={() => setOpen(false)}
            media={selectedCampaign!}
            title={title}
          />
          <QuickFilterMenu
            position={state}
            handleClose={handleCloseMenu}
            id={selectedFilter.id}
            currentPage={"campaigns"}
            name={selectedFilter.name}
            type={selectedFilter.type}
          />
        </>
      )}
      <LoadingDialog
        isLoading={
          props.data_state.isDataLoading || props.data_state.isGalleryDownloading
        }
      />
    </React.Fragment>
  );
}
