import React from "react"
import { unstable_trace as trace } from "scheduler/tracing";
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import { Grid } from "@material-ui/core"
import MediaPlanChart from "../../components/graphs/MediaPlanChart"
import PieChart from "../../components/graphs/PieChart"
import {
  useFormatNumber
} from "@comparaonline/react-intl-hooks"

const SingleCountryCharts = () => {
  const { hasRequestFailed } = useSelector((state: RootState) => state.data)
  const filtered = useSelector((state: RootState) => state.data.shareOfVoice)
  const formatNumber = useFormatNumber()
  const { isDataLoading } = useSelector((state: RootState) => state.data)

  return trace("single country sov render", performance.now(), () => (
    <>
      <Grid item xs={12} md={6}>
        <PieChart
          title="Share of Voice"
          country={0}
          items={(filtered[0] && !hasRequestFailed ) ? filtered[0].pieData : []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan"
          series={
            (filtered[0] && !hasRequestFailed)
              ? filtered[0].mediaData
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={(value: number) => {
            return `${formatNumber(value)} spots`
          }}
          loading={isDataLoading}
          type="stacked"
          titleYAxis="Spots"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan Bar"
          series={
            (filtered[0] && !hasRequestFailed)
              ? filtered[0].mediaData
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={(value: number) => {
            return `${formatNumber(value)} spots`
          }}
          loading={isDataLoading}
          type="column"
          titleYAxis="Spots"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MediaPlanChart
          title="Media Plan Evo"
          series={
            (filtered[0] && !hasRequestFailed)
              ? filtered[0].mediaData
              : []
          }
          categories={
            (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
          }
          tooltipFormatter={(value: number) => {
            return `${formatNumber(value)} spots`
          }}
          loading={isDataLoading}
          type="line"
          titleYAxis="Spots"
        />
      </Grid>
    </>
  ));
  
}

export default SingleCountryCharts
