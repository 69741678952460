/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { investmentDataRequest } from "../../store/data/actions";
import { TabPropNames, TABS } from "../../utils/TabCodes";
import TabLayout from "../../components/TabLayout";
import { Grid, LinearProgress, makeStyles } from "@material-ui/core";
import SingleCountryCharts from "./SingleCountryCharts";
import {
  Investment,
  InvestmentItemTable,
  // InvestmentItem
} from "../../store/data/types";
import ShortTableView, {
  MultiCountryRow,
  Column,
} from "../../components/dashboard/table/ShortTable";
import PercentageBar from "../../components/dashboard/PercentageBar";
import ChipValues from "../../components/dashboard/table/ChipValues";
import MultiCountryCharts from "./MultiCountryCharts";
import {
  clearAllParameters,
  queueParameter,
  setAllParameters,
} from "../../store/search/actions";
import { useHistory, useParams } from "react-router-dom";
import { SearchParameters } from "../../store/search/types";
import { logout } from "../../store/login/actions";
import { getDateFromReports, numberWithCommas } from "../../utils/functions";
import { SidebarPages } from "../../store/navigation/types";
import { choose_tab, set_sidebar_page } from "../../store/navigation/actions";

const useStyles = makeStyles({
  percentage: {
    display: "inline-block",
    height: 15,
    width: "55%",
  },
  amountWrapper: {
    display: "inline-block",
    width: "100%",
  },
  amountValue: {
    marginLeft: "10px",
    width: "40%",
  },
});

const LinearMemo = React.memo(
  (props: {
    item: InvestmentItemTable;
    filtered: Investment[];
    isLoading: boolean;
  }) => {
    const styles = useStyles();
    const { item, filtered } = props;
    const { advancedFilters } = useSelector((state: RootState) => state.search);
    const { selectedTab } = useSelector((state: RootState) => state.navigation);

    const getPercentageWithFilter = (
      total: Array<InvestmentItemTable>,
      totalItem: number
    ) => {
      let totalItems = 0;
      total.forEach((item) => {
        totalItems += item.amount;
      });

      return (totalItem * 100) / totalItems;
    };

    return (
      <div className={styles.amountWrapper}>
        <LinearProgress
          className={styles.percentage}
          variant={"determinate"}
          value={
            item && item.percentage
              ? advancedFilters[TabPropNames[selectedTab]].length > 0
                ? getPercentageWithFilter(filtered[0].tableData, item.amount)
                : item.percentage
              : 0
          }
        />
        <span className={styles.amountValue}>
          {numberWithCommas(item.amount)} {filtered[0] && filtered[0].currency}
        </span>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
      prevProps.isLoading === nextProps.isLoading &&
      prevProps.filtered[0].tableData.length ===
        nextProps.filtered[0].tableData.length
    );
  }
);

const InvestmentPage = () => {
  const { hasRequestFailed, isDataLoading } = useSelector(
    (state: RootState) => state.data
  );
  const filtered = useSelector((state: RootState) => state.data.investment);
  const {
    countries,
    startDate,
    endDate,
    advancedFilters,
    paginationParameters,
  } = useSelector((state: RootState) => state.search);
  const { selectedTab, selectedPage } = useSelector(
    (state: RootState) => state.navigation
  );
  const { userReports } = useSelector((state: RootState) => state.data);
  const { token } = useSelector((state: RootState) => state.login);
  const multiCountriesView = countries.length > 1;
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { reportId } = useParams<{
    reportId: string;
  }>();
  const { roles } = useSelector((state: RootState) => state.login);
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(roles)) {
      const isAllowed = roles.some((item) => item === "ROLE_FEATURE_INVESTMENT");
      if (!isAllowed) {
        history.push("/404");
      }
    } else {
      dispatch(logout());
      dispatch(clearAllParameters());
    }
  }, [roles, history, dispatch]);

  useEffect(() => {
    !localStorage.getItem("sharedSearchParameters") &&
      dispatch(
        investmentDataRequest(
          { countries, startDate, endDate, advancedFilters },
          paginationParameters,
          token,
          TABS[selectedTab]
        )
      );
  }, [
    countries,
    startDate,
    endDate,
    advancedFilters,
    paginationParameters,
    token,
    selectedTab,
    dispatch,
  ]);

  // useEffect(() => {
  //   if (multiCountriesView) {
  //     const converFiltered: Investment[] = [];
  //     filtered.forEach(item => {
  //       const currency = item.investment.currency;
  //       item.investment.items.forEach((investmentItem, i) => {
  //         const amount = getTotalAmount(item.investment.items[i].histogram);
  //       })
  //     });
  //   }
  // });

  useEffect(() => {
    const report = reportId && userReports.find((r) => r.id === reportId);
    if (report) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { country, page, countries, ...params } = report.parameters;
      const advanced = {
        advertisers: [],
        formats: [],
        platforms: [],
        publishers: [],
        excludedPublishers: [],
        categories: [],
        industries: [],
        products: [],
        sources: [],
      };
      dispatch(queueParameter(advanced));
      const newAdvanced = {
        ...advanced,
        ...params,
      };

      if (newAdvanced.products.length > 0) {
        dispatch(choose_tab(2));
      } else if (newAdvanced.advertisers.length > 0) {
        dispatch(choose_tab(1));
      }

      const dates = getDateFromReports(report.frequency, true);

      dispatch(
        setAllParameters(countries ?? [country], dates[1], dates[0], {
          ...newAdvanced,
        })
      );
      history.push(`/dashboard/${SidebarPages.shareofvoice}`);
      dispatch(set_sidebar_page(SidebarPages.shareofvoice));
    }
  }, [startDate, endDate, reportId, userReports, history, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("sharedSearchParameters") !== null) {
      const sharedParameters: SearchParameters = JSON.parse(
        localStorage.getItem("sharedSearchParameters")!
      );
      const startDate = new Date(sharedParameters.startDate.toString());
      const endDate = new Date(sharedParameters.endDate.toString());
      const newCountries = sharedParameters.countries;
      localStorage.removeItem("sharedSearchParameters");
      setTimeout(() => {
        dispatch(
          setAllParameters(
            newCountries,
            startDate,
            endDate,
            sharedParameters.advancedFilters
          )
        );
      }, 500);
    }
  }, [dispatch]);

  const getPrintsValues = (filter: string) => {
    const data: { label: string; value: string }[] = [];
    filtered.forEach((countryData) => {
      const item = countryData.tableData.find((x) => x.label === filter);
      const prints = (item && item.prints) || 0;
      data.push({
        label: countryData.country,
        value: numberWithCommas(prints),
      });
    });
    return data;
  };

  const tableColumns: Column[] = [
    {
      field: "label",
      title: formatMessage("shareofvoicePage.table.column.name"),
      customSort: (rowa: InvestmentItemTable, rowb: InvestmentItemTable) => {
        if (rowa.label.toLowerCase() < rowb.label.toLowerCase()) {
          return -1;
        }
        if (rowa.label.toLowerCase() > rowb.label.toLowerCase()) {
          return 1;
        }
        return 0;
      },
    },
    {
      field: "amount",
      title: formatMessage("table.column.amount"),
      grouping: false,
      headerStyle: {
        width: "50%",
      },
      cellStyle: {
        color: "#000",
        width: "50%",
      },
      render: (row: InvestmentItemTable) => {
        return (
          <LinearMemo item={row} filtered={filtered} isLoading={isDataLoading} />
        );
      },
    },
    {
      field: "prints",
      title: formatMessage("table.column.prints"),
      render: (row: InvestmentItemTable) => {
        return <span>{numberWithCommas(row.prints)}</span>;
      },
    },
  ];

  const multiCountriesTableColumns: Column[] = [
    {
      field: "label",
      title: formatMessage("shareofvoicePage.table.column.name"),
    },
    {
      field: "amount",
      title: formatMessage("table.column.amount"),
      render: (row: MultiCountryRow) => (
        <PercentageBar filter={row.label} data={filtered} />
      ),
    },
    {
      field: "prints",
      title: formatMessage("table.column.prints"),
      render: (row: MultiCountryRow) => (
        <ChipValues data={getPrintsValues(row.label)} />
      ),
    },
  ];

  if (
    TabPropNames[selectedTab] === "sources" ||
    TabPropNames[selectedTab] === "platforms"
  ) {
    filtered.forEach((invest: Investment) => {
      invest.tableData.forEach((item: InvestmentItemTable) => {
        const label = item.label;
        item.label =
          label === "Medios"
            ? formatMessage("graphs.label.desktop")
            : label === "Google"
            ? formatMessage("graphs.label.programmatic")
            : label === "Otros"
            ? formatMessage("graphs.label.direct")
            : label;
      });
    });
  }

  return (
    <TabLayout>
      <Grid container spacing={2}>
        {multiCountriesView ? <MultiCountryCharts /> : <SingleCountryCharts />}
        <Grid item xs={12}>
          <ShortTableView
            columns={multiCountriesView ? multiCountriesTableColumns : tableColumns}
            data={(filtered[0] && !hasRequestFailed && filtered) || []}
            tabType={TABS[selectedTab].toLowerCase()}
            currentPage={selectedPage}
            multiCountries={multiCountriesView}
          />
        </Grid>
      </Grid>
    </TabLayout>
  );
};

export default InvestmentPage;
