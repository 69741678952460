import {
  PaginationParametersInterface,
  SearchActionTypes,
  AdvancedFilters,
  AdvancedSearchInterface
} from "./types"
import { action } from "typesafe-actions"

export const set_date_range = (startDate: Date, endDate: Date) =>
  action(SearchActionTypes.SET_DATE_RANGE, { startDate, endDate })

export const set_countries = (countries: string[]) =>
  action(SearchActionTypes.SET_COUNTRIES, { countries })

export const set_PaginationParameters = (
  parameters: PaginationParametersInterface
) => action(SearchActionTypes.SET_PAGINATION_PARAMETERS, { parameters })

export const queueParameter = (parameters: AdvancedFilters) =>
  action(SearchActionTypes.QUEUE_PARARM, { parameters })

export const clearAllParameters = () => action(SearchActionTypes.CLEAR_ALL)

export const setAdvancedFilters = (filters: AdvancedFilters) =>
  action(SearchActionTypes.SET_ADVANCED_FILTERS, { filters });

export const setSnapType = (snapType: "industries"  | "advertisers" | "publishers") =>
  action(SearchActionTypes.SET_SNAP_TYPE, { snapType });

export const setSnapFilter = (filter: AdvancedSearchInterface | null) =>
  action(SearchActionTypes.SET_SNAP_FILTER, { filter });

export const setAllParameters = (countries: string[], startDate: Date, endDate: Date, advancedFilters: AdvancedFilters) =>
  action(SearchActionTypes.SET_ALL, { countries, startDate, endDate, advancedFilters });