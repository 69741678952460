import { useState } from "react";
import ReactPlayer from "react-player";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

import { CampaignMediaTypes } from "../../../utils/CampaignMediaTypes";
import {
  StyleAttributes,
  FacebookPostStyles,
  FacebookStoryStyles,
  TwitterStyles,
} from "../../../store/data/types";
import ImageCarousel, {
  ElementCarouselItem,
} from "../../dashboard/data/ImageCarousel";
import { FacebookPost } from "../FacebookPostView";
import { FacebookStory } from "../FacebookStoryView";
import { TwitterStyle } from "../TwitterStyleView";

export interface MediaInterface {
  date: string;
  advertiser: string;
  screenshot: string;
  creativity: string;
  platform: string;
  category: string;
  format: string;
  industry: string;
  pViewability: number | null;
  styleAttributes?: StyleAttributes;
  style: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      height: "auto",
      maxWidth: "100%",
    },
    youtube: {
      height: "100%",
      position: "relative",
      width: "100%",
      "@media(min-width: 457px)": {
        height: "65vh",
        width: "100%",
      },
      "@media(min-width: 992px)": {
        width: "60vw",
      },
      "@media(min-width: 1440px)": {
        width: "50vw",
      },
    },
    growActions: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
    },
    growButton: {
      marginLeft: "10em",
      marginRight: "1em",
    },
  })
);

// TODO: Merge with CreativityGalleryView.tsx
export const Creativity: React.FC<{ media: MediaInterface }> = ({ media }) => {
  const theme = useTheme();
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  let carouselData: ElementCarouselItem[] = [];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (media !== undefined) {
    switch (media.style) {
      case CampaignMediaTypes.youtube:
        carouselData = [
          {
            label: "Screenshot",
            component: (
              <img
                className={classes.img}
                src={media.screenshot}
                alt={media.advertiser}
              />
            ),
          },
          {
            label: "Creativity",
            component: (
              <div className={classes.youtube}>
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${
                    media.creativity.split("https://youtube.com/embed/")[1]
                  }`}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            ),
          },
        ];
        break;
      case CampaignMediaTypes.facebookPost:
        carouselData = [
          {
            label: "",
            component: (
              <FacebookPost
                facebookPostAttributes={media.styleAttributes as FacebookPostStyles}
                mediaSource={media.screenshot}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.facebookStory:
        carouselData = [
          {
            label: "",
            component: (
              <FacebookStory
                facebookStoryAttributes={
                  media.styleAttributes as FacebookStoryStyles
                }
                advertiser={media.advertiser}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.video:
        carouselData = [
          {
            label: "Screenshot",
            component: (
              <img
                className={classes.img}
                src={media.screenshot}
                alt={media.advertiser}
              />
            ),
          },
          {
            label: "",
            component: (
              <FacebookStory
                facebookStoryAttributes={
                  media.styleAttributes as FacebookStoryStyles
                }
                advertiser={media.advertiser}
              />
            ),
          },
        ];
        break;
      case CampaignMediaTypes.twitter:
        carouselData = [
          {
            label: "",
            component: (
              <TwitterStyle
                twitterAttributes={media.styleAttributes as TwitterStyles}
                advertiser={media.advertiser}
              />
            ),
          },
        ];
        break;
      default:
        carouselData = [
          {
            label: "Screenshot",
            component: (
              <img
                className={classes.img}
                src={media.screenshot}
                alt={media.advertiser}
              />
            ),
          },
          {
            label: "Creativity",
            component: (
              <img
                className={classes.img}
                src={media.creativity}
                alt={media.advertiser}
              />
            ),
          },
        ];
    }
    const maxSteps = carouselData.length;

    return (
      <>
        <Grid container>
          <Grid item xs={12} md={10}>
            <ImageCarousel data={carouselData} activeStep={activeStep} />
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>
              <strong>{formatMessage("spotslistPage.column.date")}:</strong>{" "}
              {media.date}
            </Typography>
            <Typography>
              <strong>{formatMessage("spotslistPage.column.platform")}:</strong>{" "}
              {media.platform}
            </Typography>
            <Typography>
              <strong>{formatMessage("spotslistPage.column.category")}:</strong>{" "}
              {media.category}
            </Typography>
            <Typography>
              <strong>{formatMessage("spotslistPage.column.format")}:</strong>{" "}
              {media.format}
            </Typography>
            <Typography>
              <strong>{formatMessage("spotslistPage.column.industry")}:</strong>{" "}
              {media.industry}
            </Typography>
            <Typography>
              <strong>pViewability:</strong>{" "}
              {media.pViewability ? media.pViewability : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <div className={classes.growActions}>
            <Button
              size="small"
              color="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
            <span>
              {activeStep + 1} / {maxSteps}
            </span>
            <Button
              size="small"
              color="secondary"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </div>
        </Box>
      </>
    );
  } else {
    return null;
  }
};
