import React from "react";
import { History } from "history";
import { Route, Redirect, Switch } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { InjectIntlContext } from "@comparaonline/react-intl-hooks";
import { ThemeProvider, Theme } from "@material-ui/core/styles";
import { store, persistor, history } from "./store";
import { IntlProvider } from "react-intl";
import { RootState } from "typesafe-actions";
import { ConnectedRouter } from "connected-react-router";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import AppLayout from "./components/dashboard/AppLayout";
import NotFoundPage from "./pages/NotFoundPage";
import { AdCualityRootTheme } from "./utils/theme";
import PrivateRouteContainer from "./utils/PrivateRoute/PrivateRouteContainer";
import LoginPage from "./pages/LoginPage";
import { ReportSignUp } from "./pages/marketing/ReportSignUp";
import { MarketingLayout } from "./pages/marketing/Layout";
import { ReportList } from "./pages/marketing/ReportList";
import { CountryReports } from "./pages/marketing/CountryReports";
import { SpotView } from "./pages/marketing/Spot";

function initializeReactGA() {
  ReactGA.initialize("G-D1DDDCHF8V");
}

const messages: any = {
  es: messages_es,
  en: messages_en,
  pt: messages_pt,
};

interface AppProps {
  history: History;
}

const ConnectedAppWrapper = ({ history }: AppProps) => {
  const { language } = useSelector((state: RootState) => state.general);
  const { prefersDarkMode } = useSelector((state: RootState) => state.general);
  const adCualityTheme: Theme = React.useMemo(
    () => AdCualityRootTheme(prefersDarkMode),
    [prefersDarkMode]
  );
  return (
    <ThemeProvider theme={adCualityTheme}>
      <IntlProvider locale={language} messages={messages[language]}>
        <InjectIntlContext>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <PrivateRouteContainer path={"/dashboard"} component={AppLayout} />
              <Route path={"/login"} component={LoginPage} />
              <MarketingLayout>
                <Route path={"/marketing/signup"} component={ReportSignUp} />
                <Route path={"/marketing/report-list"} component={ReportList} />
                <Route
                  path="/marketing/report/:countryId"
                  component={CountryReports}
                />
                <Route path={"/marketing/spot/:id"} component={SpotView} />
              </MarketingLayout>
              <Route component={NotFoundPage} />
            </Switch>
          </ConnectedRouter>
        </InjectIntlContext>
      </IntlProvider>
    </ThemeProvider>
  );
};

const App = () => {
  initializeReactGA();
  setupLogRocketReact(LogRocket);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedAppWrapper history={history} />
      </PersistGate>
    </Provider>
  );
};

export default App;
