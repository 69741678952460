import { RootAction, RootState } from "typesafe-actions"
import { Dispatch } from "redux"
import { toggle_dialog } from "../../../store/navigation/actions"
import { connect } from "react-redux"
import HelpDialogView from "./HelpDialogView"
import { injectIntl, InjectedIntlProps } from "react-intl"
import { HelpFormDataInterface } from "../../../store/general/types"
import { helpTicketRequest } from "../../../store/general/actions"

const mapStateToProps = (state: RootState) => ({
  navigation_state: state.navigation,
  helpFormData: state.general.helpFormData,
})

const dispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    toggleDialog: () => dispatch(toggle_dialog()),
    helpTicketRequest: (formData: HelpFormDataInterface) =>
      dispatch(helpTicketRequest(formData)),
  }
}

export type HelpDialogProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof dispatchToProps> &
  InjectedIntlProps

export default injectIntl(connect(mapStateToProps, dispatchToProps)(HelpDialogView))
