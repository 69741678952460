//Action types
export enum GeneralActionTypes {
  SELECT_LANGUAGE = "@general/SELECT_LANGUAGE",
  HELP_TICKET_REQUEST = "@general/HELP_TICKET_REQUEST",
  HELP_TICKET_SUCCESS = "@general/HELP_TICKET_SUCCESS",
  TOGGLE_DARK_MODE = "@general/TOGGLE_DARK_MODE"
}

//Interfaces
export interface HelpFormDataInterface {
  email: string
  title: string
  message: string
  company: string
  messageType: string
  name: string
  country: string
  file: any //todo super rancio pero es que no se que tipo poner
}

//State type

export interface GeneralState {
  readonly language: string
  readonly helpFormData: HelpFormDataInterface
  readonly prefersDarkMode: boolean,
}
