import { Dispatch } from "redux"
import { RootState, RootAction } from "typesafe-actions"
import { injectIntl, InjectedIntlProps } from "react-intl"
import { connect } from "react-redux"
import { logout } from "../../../../store/login/actions"
import SessionExpiredDialog from "./SessionExpiredDialog"
import { clearAllParameters } from "../../../../store/search/actions"

const mapStateToProps = (state: RootState) => ({
  login: state.login,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    logout: () => {
      dispatch(logout())
      dispatch(clearAllParameters())
    },
  }
}

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedIntlProps

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SessionExpiredDialog)
)
