import React, { Component } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }

  componentDidCatch() {
    // También puedes registrar el error en un servicio de reporte de errores
    this.logErrorToMyService();
  }

  logErrorToMyService() {
    if (!this.state.hasError) {
      this.setState({ hasError: !this.state.hasError });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1>
          Hubo un error con el componente interno, por favor revisar o avisar al
          administrador.
        </h1>
      );
    }
    return this.props.children;
  }
}
