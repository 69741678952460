import React from "react";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import ProfileCard from "./ProfileCard";
import SecurirtyCard from "./SecurityCard";
import MessagesCard from "./MessagesCard";
import SavedReports from "./SavedReports";
import SavedAlerts from "./SavedAlerts";

const useStyles = makeStyles({
  item: {
    padding: "12px",
  },
  divider: {
    margin: "10px 0",
  },
});

const ProfilePage = () => {
  const styles = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={6} className={styles.item}>
        <ProfileCard />
        <Divider className={styles.divider} />
        <SecurirtyCard />
      </Grid>
      <Grid item xs={12} md={6} className={styles.item}>
        <MessagesCard />
        <Divider className={styles.divider} />
        <SavedReports />
        <Divider className={styles.divider} />
        <SavedAlerts />
      </Grid>
    </Grid>
  );
};

export default ProfilePage;
