import {
  createStyles,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SidebarListView from "./sidebar_list/SidebarListContainer";
import React from "react";
import { DrawerReduxProps } from "./DrawerContainer";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      "@media print": {
        display: "none",
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    iconButton: {
      backgroundColor: "#25D366",
      bottom: 20,
      height: 60,
      left: 20,
      position: "fixed",
      width: 60,
      zIndex: 3000,
      "&:focus, &:hover, &:active": {
        backgroundColor: "#128C7E",
      },
      "@media print": {
        display: "none",
      },
    },
    iconWhatsapp: {
      color: "white",
      fontSize: 35,
    },
  })
);

const DrawerView = (props: DrawerReduxProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const formattedMessage = useFormatMessage();

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden lgUp implementation="css">
          <Drawer
            //container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={props.isSidebarOpen}
            onClose={() => props.toggle_sidebar()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <SidebarListView />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SidebarListView />
          </Drawer>
        </Hidden>
      </nav>
      <Tooltip title={formattedMessage("navigation.sidebar.whatsapp")}>
        <IconButton
          aria-label="Whatsapp Button"
          className={classes.iconButton}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=543484385372&text&app_absent=0",
              "_blank"
            )
          }
        >
          <WhatsAppIcon className={classes.iconWhatsapp} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DrawerView;
