/**
 * Tipos de acciones
 */

export enum NavigationActionTypes {
  TOGGLE_SIDEBAR = "@navigation/TOGGLE_SIDEBAR",
  CHOOSE_TAB = "@navigation/CHOOSE_TAB",
  SET_PAGE = "@navigation/SET_PAGE",
  EDIT_FORM = "@navigation/EDIT_FORM_BUTTON",
  TOGGLE_DIALOG = "@navigation/TOGGLE_DIALOG",
  TOGGLE_CUSTOMER = "@navigation/TOGGLE_CUSTOMER",
  TOGGLE_SNACKBAR = "@navigation/TOGGLE_SNACKBAR",
  TOGGLE_ADVANCED_SEARCH = "@navigation/TOGGLE_ADVANCED_SEARCH",
  TOGGLE_ALERTS_DIALOG = "@navigation/TOGGLE_ALERTS_DIALOG",
  TOGGLE_COUNTRY_PICKER = "@navigation/TOGGLE_COUNTRY_PICKER",
  TOGGLE_DATERANGE = "@navigation/TOGGLE_DATERANGE_DIALOG",
  TOGGLE_SEARCH = "@navigation/TOGGLE_QUICKSEARCH_DIALOG",
  TOGGLE_MOREINFO = "@navigation/TOGGLE_MOREINFO_DIALOG",
  SET_PAGE_NUMBER = "@navigation/SET_PAGINATION_PAGE_NUMBER",
  SET_TOTALPAGES = "@navigation/SET_TOTAL_PAGES",
  SET_RESULTS_PER_PAGE = "@navigation/SET_RESULTS_PER_PAGE",
  TOGGLE_SWITCH = "@navigation/TOGGLE_SWITCH",
  TOGGLE_INVESTMENT_VIEW = "@navigation/TOGGLE_INVESTMENT_VIEW",
  SET_NOTIFICATION = "@navigation/SET_NOTIFICATION",
  SET_NOTIFICATION_OFF = "@navigation/SET_NOTIFICATION_OFF",
  TOGGLE_WELCOME_DIALOG = "@navigation/TOGGLE_WELCOME_DIALOG",
}

/**
 * Tipos de páginas de sidebar
 */

export enum SidebarPages {
  account = "account",
  shareofvoice = "shareofvoice",
  investment = "investment",
  home = "home",
  spotspreview = "campaigns",
  spotslist = "spotslist",
  snapshots = "snapshots",
  exportReport = "export",
  myReports = "myreports",
  support = "support",
  guides = "guides",
  whatsapp = "whatsapp",
}

export enum SearchablePages {
  shareofvoice = "shareofvoice",
  investment = "investment",
  spotspreview = "campaigns",
  spotslist = "spotslist",
}

export enum SingleCountryPages {
  spotspreview = "campaigns",
  spotslist = "spotslist",
}

interface PaginationInterface {
  currentPage: number;
  totalPages: number;
  resultsPerPage: number;
}

/**
 * State para el módulo de navegación
 */
export interface NavigationState {
  readonly isSidebarOpen: boolean;
  readonly isProfileMenuOpen: boolean;
  readonly selectedTab: number;
  readonly selectedPage: string;
  readonly canEditForm: boolean;
  readonly isDialogOpen: boolean;
  readonly isSnackbarOpen: boolean;
  readonly isCustomerOpen: boolean;
  readonly notificationSeverity: "success" | "error" | "info" | "warning";
  readonly notificationMessage: string;
  readonly isAdvSearchOpen: boolean;
  readonly isAlertsDialogOpen: boolean;
  readonly isCountryPickerOpen: boolean;
  readonly isDateRangePickerOpen: boolean;
  readonly isQuickSearchDialogOpen: boolean;
  readonly isMoreInfoDialogOpen: boolean;
  readonly pagination: PaginationInterface;
  readonly isSwitchToggled: boolean;
  readonly amountView: boolean;
  readonly isWelcomeDialogOpen: boolean;
}
