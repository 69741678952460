import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Chip,
  Paper,
  Collapse,
  Grid,
  Box,
} from "@material-ui/core";
import {
  LocationCity as IndustriesIcon,
  Work as AdIcon,
  Category as CategoriesIcon,
  AspectRatio as FormatsIcon,
  StopScreenShare as ExcludedPublishersIcon,
  RecordVoiceOver as PublishersIcon,
  ImportantDevices as PlatformsIcon,
  Receipt as SourcesIcon,
  ShoppingCart as ProductIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";

import { ReduxProps } from "./ActiveFiltersToolbarContainer";
import {
  AdvancedSearchInterface,
  PaginationParametersInterface,
  SearchParameters,
  AdvancedFilters,
} from "../../../store/search/types";
import { DataType, Tabs } from "../../../utils/TabCodes";

import { SidebarPages } from "../../../store/navigation/types";

export const ActiveFiltersToolbarView = (props: ReduxProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { selectedTab } = props.navigation_state;

  function performSearch(
    searchParameters: SearchParameters,
    paginationParameters: PaginationParametersInterface
  ) {
    switch (props.navigation_state.selectedPage) {
      case SidebarPages.spotslist:
        props.fetchListedSpots(searchParameters, paginationParameters, props.token);
        break;
      case SidebarPages.spotspreview:
        props.fetchCampaigns(searchParameters, paginationParameters, props.token);
        break;
      case SidebarPages.shareofvoice:
        props.fetchShareOfVoice(
          searchParameters,
          paginationParameters,
          props.token,
          Tabs[props.navigation_state.selectedTab]
        );
        break;
      case SidebarPages.investment:
        props.fetchInvestment(
          searchParameters,
          paginationParameters,
          props.token,
          Tabs[props.navigation_state.selectedTab]
        );
    }
  }

  function handleDelete(toDeleteFilter: AdvancedSearchInterface, dataType: string) {
    const filters: AdvancedFilters = props.search_state.advancedFilters;
    switch (dataType) {
      case DataType.industry:
        filters.industries = filters.industries.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.advertiser:
        filters.advertisers = filters.advertisers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.product:
        filters.products = filters.products.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.category:
        filters.categories = filters.categories.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.platform:
        filters.platforms = filters.platforms.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.format:
        filters.formats = filters.formats.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.publisher:
        filters.publishers = filters.publishers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.excludedPublishers:
        filters.excludedPublishers = filters.excludedPublishers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.source:
        filters.sources = filters.sources.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
    }
    props.setFilters(filters);
    if (
      props.navigation_state.selectedTab === 2 &&
      filters.advertisers.length === 0 &&
      filters.products.length === 0
    ) {
      props.goToIndustriesOnClear();
    }
    performSearch(
      { ...props.search_state, ...filters },
      props.search_state.paginationParameters
    );
  }

  const handleDeleteQueued = (
    toDeleteFilter: AdvancedSearchInterface,
    dataType: string
  ) => {
    const queue: AdvancedFilters = props.search_state.parameterQueue;
    switch (dataType) {
      case DataType.industry:
        queue.industries = queue.industries.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.advertiser:
        queue.advertisers = queue.advertisers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.product:
        queue.products = queue.products.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.category:
        queue.categories = queue.categories.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.platform:
        queue.platforms = queue.platforms.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.format:
        queue.formats = queue.formats.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.publisher:
        queue.publishers = queue.publishers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.excludedPublishers:
        queue.excludedPublishers = queue.excludedPublishers.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
      case DataType.source:
        queue.sources = queue.sources.filter(
          (filter) => filter.id !== toDeleteFilter.id
        );
        break;
    }
    props.updateQueue(queue);
  };

  const showFilters: boolean =
    props.search_state.advancedFilters.advertisers.length !== 0 ||
    props.search_state.advancedFilters.industries.length !== 0 ||
    props.search_state.advancedFilters.products.length !== 0 ||
    props.search_state.advancedFilters.platforms.length !== 0 ||
    props.search_state.advancedFilters.publishers.length !== 0 ||
    props.search_state.advancedFilters.excludedPublishers.length !== 0 ||
    props.search_state.advancedFilters.formats.length !== 0 ||
    props.search_state.advancedFilters.sources.length !== 0 ||
    props.search_state.advancedFilters.categories.length !== 0 ||
    props.search_state.parameterQueue.advertisers.length !== 0 ||
    props.search_state.parameterQueue.industries.length !== 0 ||
    props.search_state.parameterQueue.products.length !== 0 ||
    props.search_state.parameterQueue.platforms.length !== 0 ||
    props.search_state.parameterQueue.publishers.length !== 0 ||
    props.search_state.parameterQueue.excludedPublishers.length !== 0 ||
    props.search_state.parameterQueue.formats.length !== 0 ||
    props.search_state.parameterQueue.sources.length !== 0 ||
    props.search_state.parameterQueue.categories.length !== 0;

  return (
    <>
      <div>
        <Collapse in={showFilters} mountOnEnter unmountOnExit>
          <Paper className={classes.root} elevation={3}>
            {props.search_state.parameterQueue.industries.map((filter) => (
              <Chip
                key={filter.id}
                icon={<IndustriesIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.industry)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.products.map((filter) => (
              <Chip
                key={filter.id}
                icon={<ProductIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.product)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.advertisers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<AdIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.advertiser)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.categories.map((filter) => (
              <Chip
                key={filter.id}
                icon={<CategoriesIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.category)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.publishers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<PublishersIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.publisher)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.excludedPublishers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<ExcludedPublishersIcon />}
                label={filter.label}
                onDelete={() =>
                  handleDeleteQueued(filter, DataType.excludedPublishers)
                }
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.platforms.map((filter) => (
              <Chip
                key={filter.id}
                icon={<PlatformsIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.platform)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.formats.map((filter) => (
              <Chip
                key={filter.id}
                icon={<FormatsIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.format)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}
            {props.search_state.parameterQueue.sources.map((filter) => (
              <Chip
                key={filter.id}
                icon={<SourcesIcon />}
                label={filter.label}
                onDelete={() => handleDeleteQueued(filter, DataType.source)}
                className={classes.chip}
                color={"secondary"}
              />
            ))}

            {props.search_state.advancedFilters.industries.map((filter) => (
              <Chip
                key={filter.id}
                icon={<IndustriesIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.industry)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.advertisers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<AdIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.advertiser)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.products.map((filter) => (
              <Chip
                key={filter.id}
                icon={<ProductIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.product)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.categories.map((filter) => (
              <Chip
                key={filter.id}
                icon={<CategoriesIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.category)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.publishers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<PublishersIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.publisher)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.excludedPublishers.map((filter) => (
              <Chip
                key={filter.id}
                icon={<ExcludedPublishersIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.excludedPublishers)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.platforms.map((filter) => (
              <Chip
                key={filter.id}
                icon={<PlatformsIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.platform)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.formats.map((filter) => (
              <Chip
                key={filter.id}
                icon={<FormatsIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.format)}
                className={classes.chip}
              />
            ))}
            {props.search_state.advancedFilters.sources.map((filter) => (
              <Chip
                key={filter.id}
                icon={<SourcesIcon />}
                label={filter.label}
                onDelete={() => handleDelete(filter, DataType.source)}
                className={classes.chip}
              />
            ))}
          </Paper>
        </Collapse>
      </div>
      {(props.search_state.advancedFilters.products.length > 0 &&
        (selectedTab === 0 || selectedTab === 1)) ||
      (props.search_state.advancedFilters.advertisers.length > 0 &&
        selectedTab === 0) ? (
        <Box marginY={1}>
          <Grid container justify="space-between">
            <Grid item xs>
              <Alert className={classes.alertMessage}>
                {formatMessage("autoFilters.alert")}
              </Alert>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      marginTop: 20,
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(0.5),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 1,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    alertMessage: {
      color: "#737373",
      fontStyle: "italic",
    },
  })
);
