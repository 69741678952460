/* eslint-disable react/display-name */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useTheme } from "@material-ui/core";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { CsvBuilder } from "filefy";

import {
  ColumnSeriesInterface,
  ColumnsOptions,
  LineOptions,
  StackedColumnsOptions,
} from "../../utils/ChartOptions";
import Widget from "../dashboard/Widget";
import { RootState } from "typesafe-actions";
import { COLORS, darkCOLORS } from "../../utils/colors";
import { CSVObject } from "../../store/data/types";
import { DataPages } from "../../utils/constants";

interface MediaPlanProps {
  title: string;
  series: ColumnSeriesInterface[];
  categories: string[];
  tooltipFormatter: (arg0: number) => string;
  loading: boolean;
  type: "stacked" | "column" | "line";
  titleYAxis: string;
  snap?: boolean;
  isDoubleAxis?: boolean;
  yAxis?: object[];
  yTitleFormatter?: (seriesName: string) => string;
  investment?: boolean;
}

const CHART_OPTIONS = {
  line: { ...LineOptions },
  stacked: { ...StackedColumnsOptions },
  column: { ...ColumnsOptions },
};

const ChartType = (type: string): "line" | "bar" => {
  switch (type) {
    case "stacked" || "column":
      return "bar";
    case "line":
      return "line";
    default:
      return "bar";
  }
};

const MediaPlanChart = React.memo(
  (props: MediaPlanProps) => {
    const { prefersDarkMode } = useSelector((state: RootState) => state.general);
    const { selectedPage, amountView } = useSelector(
      (state: RootState) => state.navigation
    );
    const formatMessage = useFormatMessage();
    const currency = useSelector(
      (state: RootState) =>
        state.data.investment[0] && state.data.investment[0].currency
    );
    const theme = useTheme();
    const chartOpt = CHART_OPTIONS[props.type];
    const chartRef = useRef<Chart>(null);

    const customTooltip = ({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      series: any[];
      seriesIndex: number;
      dataPointIndex: number;
      w: any;
    }) => {
      const label =
        w.globals.categoryLabels.length > 0
          ? w.globals.categoryLabels[dataPointIndex]
          : w.globals.labels[dataPointIndex];
      const value =
        selectedPage === "investment" || props.investment === true
          ? props.tooltipFormatter(series[seriesIndex][dataPointIndex])
          : "";
      return (
        '<div class="apexcharts-tooltip-title">' +
        label +
        "</div>" +
        '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex">' +
        '<span class="apexcharts-tooltip-marker" style="background: ' +
        w.globals.colors[seriesIndex] +
        '"></span>' +
        '<div class="apexcharts-tooltip-text">' +
        '<div class="apexcharts-tooltip-y-group">' +
        '<span class="apexcharts-tooltip-text-label">' +
        w.globals.seriesNames[seriesIndex] +
        "</span>" +
        '<span class="apexcharts-tooltip-text-value">' +
        value +
        "</span>" +
        "</div>" +
        '<div class="apexcharts-tooltip-z-group">' +
        '<span class="apexcharts-tooltip-text-z-label"></span>' +
        '<span class="apexcharts-tooltip-text-z-value"></span>' +
        "</div>" +
        "</div>" +
        "</div>"
      );
    };

    const customTooltipIsDouble = ({
      series,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      series: any[];
      seriesIndex: number;
      dataPointIndex: number;
      w: any;
    }) => {
      const label =
        w.globals.categoryLabels.length > 0
          ? w.globals.categoryLabels[dataPointIndex]
          : w.globals.labels[dataPointIndex];
      const valueSOV = props.tooltipFormatter(series[0][dataPointIndex]);
      const valueSOI = props.tooltipFormatter(series[1][dataPointIndex]);

      const SOISeriesName = `${w.globals.seriesNames[1]} (${
        currency ? currency : "Loading currency..."
      })`;

      return (
        '<div class="apexcharts-tooltip-title">' +
        label +
        "</div>" +
        '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex">' +
        '<span class="apexcharts-tooltip-marker" style="background: ' +
        w.globals.colors[0] +
        '"></span>' +
        '<div class="apexcharts-tooltip-text">' +
        '<div class="apexcharts-tooltip-y-group">' +
        '<span class="apexcharts-tooltip-text-label">' +
        w.globals.seriesNames[0] +
        "</span>" +
        '<span class="apexcharts-tooltip-text-value">' +
        valueSOV +
        "</span>" +
        "</div>" +
        '<div class="apexcharts-tooltip-z-group">' +
        '<span class="apexcharts-tooltip-text-z-label"></span>' +
        '<span class="apexcharts-tooltip-text-z-value"></span>' +
        "</div>" +
        "</div>" +
        "</div>" +
        '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: flex">' +
        '<span class="apexcharts-tooltip-marker" style="background: ' +
        w.globals.colors[1] +
        '"></span>' +
        '<div class="apexcharts-tooltip-text">' +
        '<div class="apexcharts-tooltip-y-group">' +
        '<span class="apexcharts-tooltip-text-label">' +
        SOISeriesName +
        "</span>" +
        '<span class="apexcharts-tooltip-text-value">' +
        valueSOI +
        "</span>" +
        "</div>" +
        '<div class="apexcharts-tooltip-z-group">' +
        '<span class="apexcharts-tooltip-text-z-label"></span>' +
        '<span class="apexcharts-tooltip-text-z-value"></span>' +
        "</div>" +
        "</div>" +
        "</div>"
      );
    };

    if (props.series.length === 0) {
      return null;
    }

    const getCsvData = () => {
      const data: CSVObject = [];
      const headers = [formatMessage("shareofvoicePage.table.column.name")];
      props.categories.forEach((val) => {
        if (selectedPage === DataPages.investmentPage) {
          headers.push(
            `${val} (${
              amountView ? currency : formatMessage("investmentPage.prints")
            })`
          );
        } else {
          headers.push(`${val} (Spots)`);
        }
      });
      data.push(headers);

      props.series.forEach((val) => {
        const name = val.name;
        data.push([name, ...val.data.map((item) => item.toString())]);
      });

      return data;
    };

    const exportMedia = () => {
      const builder = new CsvBuilder(
        `${props.isDoubleAxis ? "Trending" : "Media"} Graph - ${
          typeof props.title === "string" ? props.title : "Exported"
        }.csv`
      );
      const csvData = getCsvData();
      builder.setDelimeter(",").addRows(csvData).exportFile();
    };

    return (
      <Widget title={props.title} snap={props.snap} handleDownloadCSV={exportMedia}>
        <Chart
          ref={chartRef}
          options={{
            ...chartOpt,
            chart: {
              id: `bar-chart-${props.title.split(" ").join("-")}`,
              ...chartOpt.chart,
            },
            colors: theme.palette.type === "dark" ? darkCOLORS : COLORS,
            yaxis: props.isDoubleAxis
              ? props.yAxis
              : {
                  title: {
                    text: props.titleYAxis,
                    style: {
                      color: "#78909C",
                    },
                  },
                  labels: {
                    formatter: (value: string) => {
                      return (
                        value &&
                        value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    },
                  },
                },
            xaxis: {
              categories: props.categories,
            },
            tooltip: {
              enabled: true,
              shared: props.isDoubleAxis ? true : false,
              custom: props.isDoubleAxis ? customTooltipIsDouble : customTooltip,
            },
            theme: {
              mode: prefersDarkMode ? "dark" : "light",
            },
            animations: {
              enabled: false,
            },
          }}
          type={ChartType(props.type)}
          series={props.series}
          height={props.snap ? 300 : 450}
        />
      </Widget>
    );
  },
  (prevProps: MediaPlanProps, nextProps: MediaPlanProps) => {
    return (
      prevProps.series.length === nextProps.series.length &&
      JSON.stringify(prevProps.series[0]) === JSON.stringify(nextProps.series[0])
    );
  }
);

export default MediaPlanChart;
