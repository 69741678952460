import { NavigationActionTypes, NavigationState, SidebarPages } from "./types";
import { ActionType } from "typesafe-actions";
import * as navigationActions from "./actions";

type NavigationAction = ActionType<typeof navigationActions>;

const initial_state: NavigationState = {
  isSidebarOpen: false,
  isProfileMenuOpen: false,
  selectedTab: 0,
  selectedPage: SidebarPages.snapshots,
  canEditForm: false,
  isDialogOpen: false,
  isSnackbarOpen: false,
  isCustomerOpen: false,
  notificationSeverity: "success",
  notificationMessage: "",
  isAdvSearchOpen: false,
  isAlertsDialogOpen: false,
  isCountryPickerOpen: false,
  isDateRangePickerOpen: false,
  isQuickSearchDialogOpen: false,
  isMoreInfoDialogOpen: false,
  pagination: {
    currentPage: 1,
    totalPages: 0,
    resultsPerPage: 15,
  },
  isSwitchToggled: false,
  amountView: true,
  isWelcomeDialogOpen: false,
};

function reducer(state = initial_state, action: NavigationAction) {
  let isDialogOpen;
  switch (action.type) {
    case NavigationActionTypes.TOGGLE_SIDEBAR:
      if (action.payload.isOpen === undefined)
        return { ...state, isSidebarOpen: !state.isSidebarOpen };
      else return { ...state, isSidebarOpen: action.payload.isOpen };
    case NavigationActionTypes.CHOOSE_TAB:
      return { ...state, selectedTab: action.payload.tabIndex };
    case NavigationActionTypes.SET_PAGE:
      return { ...state, selectedPage: action.payload.page };
    case NavigationActionTypes.EDIT_FORM:
      return { ...state, canEditForm: !state.canEditForm };
    case NavigationActionTypes.TOGGLE_DIALOG:
      isDialogOpen = !state.isDialogOpen;
      if (isDialogOpen) {
        // @ts-expect-error
        if (window.FreshworksWidget) {
          // @ts-expect-error
          window.FreshworksWidget("open");
        }
      } else {
        // @ts-expect-error
        if (window.FreshworksWidget) {
          // @ts-expect-error
          window.FreshworksWidget("close");
        }
      }
      return { ...state, canEditForm: false, isDialogOpen: !state.isDialogOpen };
    case NavigationActionTypes.TOGGLE_SNACKBAR:
      return { ...state, isSnackbarOpen: !state.isSnackbarOpen };
    case NavigationActionTypes.TOGGLE_CUSTOMER:
      return { ...state, isCustomerOpen: !state.isCustomerOpen };
    case NavigationActionTypes.TOGGLE_ADVANCED_SEARCH:
      return { ...state, isAdvSearchOpen: !state.isAdvSearchOpen };
    case NavigationActionTypes.TOGGLE_ALERTS_DIALOG:
      return { ...state, isAlertsDialogOpen: !state.isAlertsDialogOpen };
    case NavigationActionTypes.TOGGLE_COUNTRY_PICKER:
      return { ...state, isCountryPickerOpen: !state.isCountryPickerOpen };
    case NavigationActionTypes.TOGGLE_DATERANGE:
      return { ...state, isDateRangePickerOpen: !state.isDateRangePickerOpen };
    case NavigationActionTypes.TOGGLE_SEARCH:
      return { ...state, isQuickSearchDialogOpen: !state.isQuickSearchDialogOpen };
    case NavigationActionTypes.TOGGLE_MOREINFO:
      return { ...state, isMoreInfoDialogOpen: !state.isMoreInfoDialogOpen };
    case NavigationActionTypes.SET_TOTALPAGES:
      return {
        ...state,
        pagination: { ...state.pagination, totalPages: action.payload.totalPages },
      };
    case NavigationActionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload.newPage },
      };
    case NavigationActionTypes.SET_RESULTS_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          resultsPerPage: action.payload.numberOfResults,
        },
      };
    case NavigationActionTypes.TOGGLE_SWITCH:
      return { ...state, isSwitchToggled: !state.isSwitchToggled };
    case NavigationActionTypes.TOGGLE_INVESTMENT_VIEW:
      return { ...state, amountView: !state.amountView };
    case NavigationActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        isSnackbarOpen: true,
        notificationMessage: action.payload.message,
        notificationSeverity: action.payload.severity,
      };
    case NavigationActionTypes.SET_NOTIFICATION_OFF:
      return {
        ...state,
        isSnackbarOpen: false,
      };
    case NavigationActionTypes.TOGGLE_WELCOME_DIALOG:
      return { ...state, isWelcomeDialogOpen: !state.isWelcomeDialogOpen };
    default:
      return state;
  }
}

export { reducer as navigationReducer };
