import { Dispatch } from "redux"
import { connect } from "react-redux"
import { RootState, RootAction } from "typesafe-actions"
import ProfileFormView from "./ProfileFormView"
import { edit_form, toggle_dialog } from "../../store/navigation/actions"
import { changeUsernameAndEmailRequest } from "../../store/login/actions"

const mapStateToProps = (state: RootState) => ({
  login_state: state.login,
  general_state: state.general,
  navigation_state: state.navigation,
})

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => {
  return {
    edit_form: () => dispatch(edit_form()),
    toggle_dialog: () => dispatch(toggle_dialog()),
    submit_form: (name: string, surname: string, message: string) =>
      dispatch(changeUsernameAndEmailRequest(name, surname, message)),
  }
}

export type ProfileFormReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const ProfileForm = connect(mapStateToProps, mapDispatchToProps)(ProfileFormView)

export default ProfileForm
