import React from "react"
import { AdvancedSearchInterface } from "../../store/search/types"
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core"
import { useSelector } from "react-redux"
import { RootState } from "typesafe-actions"
import { useFormatMessage } from "@comparaonline/react-intl-hooks"
import { DataFilterNames } from "../../utils/constants"
import MediaPlanChart from "../../components/graphs/MediaPlanChart"
import {
  /* getDates,
  getHistogramValues, */
  tooltipFormatter,
  // getShareOfVoicePieData,
} from "../../utils/functions"
import { ShareVoice } from "../../store/data/types"
import { ColumnSeriesInterface } from "../../utils/ChartOptions"
import PercentajeStackedBar from "../../components/graphs/PercentageStackedChart"
import { COUNTRIES_BALLS } from "../../utils/Countries"
import PieChart from "../../components/graphs/PieChart"

interface FilterTabs {
  [key: string]: AdvancedSearchInterface[];
}

const tooltipBarFormatter = (value: number) => {
  return `${value.toFixed(2)}%`
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      justifyContent: "center",
      display: "flex",
    },
    ball: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
)

const MultiCountryCharts = () => {
  const { hasRequestFailed } = useSelector((state: RootState) => state.data)
  const filtered = useSelector((state: RootState) => state.data.shareOfVoice)
  const {countries} = useSelector(
    (state: RootState) => state.search
  )
  const {
    industries,
    advertisers,
    products,
    categories,
    sources,
    publishers,
    formats,
    platforms,
  } = useSelector((state: RootState) => state.search.advancedFilters)
  const selectedTab = useSelector((state: RootState) => state.navigation.selectedTab)
  const formatMessage = useFormatMessage()
  const styles = useStyles()
  /* const filters: { [key: string]: AdvancedSearchInterface[] } = useSelector((state: RootState) => {
    return {
      advertisers: state.search.advancedFilters.advertisers,
      industries: state.search.advancedFilters.industries,
      products: state.search.advancedFilters.products,
      categories: state.search.advancedFilters.categories,
      platforms: state.search.advancedFilters.platforms,
      publishers: state.search.advancedFilters.publishers,
      sources: state.search.advancedFilters.sources,
      formats: state.search.advancedFilters.formats,
    }
  });
  const { startDate, endDate } = useSelector((state: RootState) => state.search); */

  const tabs: FilterTabs = {
    industries: industries,
    advertisers: advertisers,
    products: products,
    categories: categories,
    sources: sources,
    publishers: publishers,
    formats: formats,
    platforms: platforms,
  }

  function getMultiCountryFilteredData(data: ShareVoice[], index: number) {
    const series: ColumnSeriesInterface[] = []
    data.forEach((country) => {
      const item: ColumnSeriesInterface = {
        name: formatMessage(country.country),
        data: country.mediaData[index]
          ? country.mediaData[index].data
          : [],
      }
      series.push(item)
    })
    return series
  }

  const getCountriesData = (filterId: number) => {
    const data: number[] = []
    filtered.forEach((country) => {
      const item = country.tableData.find((item) => item.id.toString() === filterId.toString())
      data.push((item && item.percentage) || 0)
    })
    return data
  }

  const getFilteredValues = (index: number, series: ColumnSeriesInterface[]) => {
    let filteredValues = 0
    series.forEach((serie) => {
      filteredValues += serie.data[index]
    })
    return filteredValues
  }

  const getOthersValue = (series: ColumnSeriesInterface[]) => {
    const data: number[] = []
    countries.forEach((country, index) => {
      data.push(100 - getFilteredValues(index, series))
    })
    return data
  }

  const getBarData = (label: string, id: number, filterType: string) => {
    const series: ColumnSeriesInterface[] = []

    series.push({
      name: label,
      data: getCountriesData(id),
    })

    series.push({
      name: formatMessage(`labels.other.${filterType}`),
      data: getOthersValue(series),
    })

    return series
  }

  if (tabs[DataFilterNames[selectedTab]].length > 0) {
    return (
      <>
        {tabs[DataFilterNames[selectedTab]].map((filter, index) => {
          return (
            <>
              <Grid item xs={12} md={6}>
                <MediaPlanChart
                  categories={
                    (filtered[0] && !hasRequestFailed) ? filtered[0].mediaCategories : []
                  }
                  loading={false}
                  series={
                    (filtered[0] && !hasRequestFailed) ? getMultiCountryFilteredData(filtered, index) : []
                  }
                  title={`${formatMessage("labels.widget.ad-noise")} - ${
                    filter.label
                  }`}
                  key={`${filter.label}-LineGraph`}
                  tooltipFormatter={tooltipFormatter}
                  type={"line"}
                  titleYAxis="Spots"
                />
              </Grid>
              <PercentajeStackedBar
                title={`Share of Voice - ${filter.label}`}
                data={getBarData(
                  filter.label,
                  filter.id,
                  DataFilterNames[selectedTab]
                )}
                tooltipFormatter={tooltipBarFormatter}
                key={`${filter.label}-PercentageBar`}
              />
            </>
          )
        })}
      </>
    )
  } else
    return (
      <>
        {countries.map((country, index) => {
          return (
            <Grid item xs={12} md={6} key={country}>
              <PieChart
                title={
                  <div className={styles.titleWrapper}>
                    Share of Voice -{" "}
                    <div className={styles.ball}>{COUNTRIES_BALLS[country]}</div>{" "}
                    {formatMessage(country)}
                  </div>
                }
                country={index}
                items={
                  (filtered[index] && !hasRequestFailed)
                    ? filtered[index].pieData
                    : []
                }
              />
            </Grid>
          )
        })}
      </>
    )
}

export default MultiCountryCharts
