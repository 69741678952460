import React, { ChangeEvent } from "react";
import cx from "clsx";
import { makeStyles, createStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Theme, Grid, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typesafe-actions";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import { changeLanguage } from "../../store/general/actions";
import { FormattedMessage } from "react-intl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 4,
      minWidth: 256,
      textAlign: "center",
      background: theme.palette.background.paper,
    },
    avatar: {
      width: 200,
      height: 200,
      backgroundColor: theme.palette.primary.light,
      margin: "auto",
      fontSize: "90px",
    },
    heading: {
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      // color: palette.grey[500],
      marginBottom: "0.875em",
    },
    statLabel: {
      fontSize: 12,
      // color: palette.grey[500],
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: 4,
      letterSpacing: "1px",
    },
    switchWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    item: {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

const ProfileCard = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useFormatMessage();
  const { email, username } = useSelector((state: RootState) => state.login);
  const { language } = useSelector((state: RootState) => state.general);

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    dispatch(changeLanguage(String(event.target.value)));
  };

  return (
    <Card className={cx(styles.card)}>
      <CardContent>
        <Avatar className={styles.avatar}>
          {(username && username[0].toUpperCase()) || email[0].toUpperCase()}
        </Avatar>
        <h3 className={styles.heading}>{username}</h3>
        <span className={styles.subheader}>{email}</span>
      </CardContent>
      <Divider variant={"middle"} light />
      <Grid container>
        <Grid item xs={6} className={cx(styles.item)}>
          <p className={styles.statLabel}>{intl("help.dialog.country")}</p>
          <p className={styles.statValue}>
            <span role={"img"} aria-label={"country"}>
              🇦🇷
            </span>
          </p>
        </Grid>
        <Grid item xs={6} className={cx(styles.item)}>
          <p className={styles.statLabel}>
            {intl("loginPage.languageSelectorLabel")}
          </p>
          <p className={styles.statValue}>
            <FormControl className={styles.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                <FormattedMessage id={"loginPage.languageSelectorLabel"} />
              </InputLabel>
              <Select value={language} onChange={handleChange}>
                <MenuItem value={"es"}>
                  {/* <div className={classes.ball}>{COUNTRIES_BALLS['AR']}</div> */}
                  {intl("loginPage.languageSelectorSpanish")}
                </MenuItem>
                <MenuItem value={"en"}>
                  {/* <div className={classes.ball}>{COUNTRIES_BALLS['US']}</div> */}
                  {intl("loginPage.languageSelectorEnglish")}
                </MenuItem>
                <MenuItem value={"pt"}>
                  {/* <div className={classes.ball}>{COUNTRIES_BALLS['BR']}</div> */}
                  {intl("loginPage.languageSelectorPortuguese")}
                </MenuItem>
              </Select>
            </FormControl>
          </p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileCard;
