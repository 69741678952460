import React from "react";
import { useFormatMessage } from "@comparaonline/react-intl-hooks";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const LoadingDialog: React.FC<{
  isLoading: boolean;
  title?: string;
}> = (props) => {
  const formatMessage = useFormatMessage();

  return (
    <Dialog open={props.isLoading} aria-labelledby="loading-dialog-title">
      <DialogTitle id="form-dialog-title">
        {props.title ? props.title : formatMessage("snapshots.loading")}
      </DialogTitle>
      <DialogContent
        style={{
          textAlign: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
