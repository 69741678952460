import { ReduxProps } from "./DialogContainer"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"

const SessionExpiredDialog = (props: ReduxProps) => {
  function handleClick() {
    props.logout()
  }
  return (
    <Dialog open={props.login.hasTokenExpired}>
      <DialogTitle>
        {props.intl.formatMessage({ id: "user.dialogs.sessionExpired.title" })}
      </DialogTitle>
      <DialogContent>
        {props.intl.formatMessage({ id: "user.dialogs.sessionExpired.body" })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>
          <Link to={"/login"}>Ok</Link>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SessionExpiredDialog
